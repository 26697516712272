import { Button, Col, Form, Row } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import ReactQuill from "react-quill";
import { useEffect, useState, useRef } from "react";
import { errorToast } from "../../../../toast/toast";
import { useDispatch, useSelector } from "react-redux";
import WebFont from "webfontloader";
import {
  googleFonts,
  googleFontsFamilies,
} from "../../../../../utils/constant/googlePhonts";
import CombineCropperModal from "../../../../image-cropper/CombineCropperModal";
import {
  closeTemplate3SectionEditorAction,
  saveTemplate3SectionBySectionIDAction,
  setPreviewOfTemplate3Action,
} from "../../../../../ReduxStore/slice/community-site-template/template-3/template-3-slice";
import { updateSectionOfTemplate3BySiteIDActionThunk } from "../../../../../ReduxStore/slice/community-site-template/template-3/template-3-async-thynk";
import { Range, getTrackBackground } from "react-range";
import { Slider, Switch } from "@mui/material";

const buttonObj = { Text: "", Link: "" };

const EditModal = ({ data, setData }) => {
  const dispatch = useDispatch();

  const siteID = useSelector((state) => state?.template3?.siteID);
  const save_btn_loading = useSelector(
    (state) => state?.template3?.loading?.section1
  );
  const show = useSelector((state) => {
    const is_Editable = state?.template3?.is_Editable;
    return is_Editable?.status && is_Editable?.current_edit_section === 1;
  });

  const [showImageCroperModal, setShowImageCroperModal] = useState({
    show: false,
    whichImage: "",
  });

  const [src, setSrc] = useState({ image: null, fileName: "" });

  const STEP = 0.1;
  const MIN = 0;
  const MAX = 1;

  const [rangeValue, setRangeValues] = useState(data?.BackgroundOpacity);

  const handleRangeChange = (value) => {
    console.log(value.target.value);
    setRangeValues(value.target.value);

    console.log(rangeValue);

    setData((prevData) => ({
      ...prevData,
      BackgroundOpacity: value.target.value,
    }));

    console.log(data);
    // data.BackgroundOpacity = value;
  };
  /**
   * close image crop modal and set the selected image in setBackroundImage props
   * @param {*} blob
   */
  const handleCloseImageCropModal = (fileUrl) => {
    setShowImageCroperModal((prev) => ({ ...prev, show: false }));
    if (fileUrl) {
      if (showImageCroperModal?.whichImage === "backgorundImage") {
        setData((prevData) => ({ ...prevData, BackgroundImage: fileUrl }));
        setData((prevData) => ({
          ...prevData,
          Section1BackgroundVisible: true,
        }));
      } else if (showImageCroperModal?.whichImage === "brandLogoImage") {
        setData((prevData) => ({ ...prevData, Logo: fileUrl }));
      } else {
        errorToast("Something went wrong");
      }
    }
  };

  /**
   * image input field onChange handler
   */
  const changeHandlerImage = (event, whichImage) => {
    setSrc({
      image: URL.createObjectURL(event.target.files[0]),
      fileName: event.target.files[0]?.name,
    });
    setShowImageCroperModal({ show: true, whichImage: whichImage });
    console.log(event.target.value);

    // //event.target.value = null;
  };

  const handleLogoVisibleChange = (value) => {
    console.log(value);

    setData((prevData) => ({
      ...prevData,
      IsLogoVisible: value.target.checked,
    }));

    // data.BackgroundOpacity = value;
  };

  /**
   * nav button lists handling
   */
  const addNavItemInLists = () => {
    const values = Array.isArray(data?.NavItems) ? [...data?.NavItems] : [];
    values?.push({ ...buttonObj });
    setData((prev) => ({ ...prev, NavItems: values }));
  };

  const removeNavItemInLists = (i) => {
    const values = [...data?.NavItems];
    values?.splice(i, 1);
    setData((prev) => ({ ...prev, NavItems: values }));
  };

  const navItemListsChangeHandler = (event, i) => {
    var tempValues = JSON.parse(JSON.stringify(data?.NavItems));
    if (event?.target?.name === "btnName") {
      tempValues[i]["Text"] = event?.target?.value;
    } else if (event?.target?.name === "btnLink") {
      tempValues[i]["Link"] = event?.target?.value;
    }
    setData((prev) => ({ ...prev, NavItems: tempValues }));
  };

  /**
   * edit modal close handler
   */
  const editModalCloseHandler = () => {
    dispatch(closeTemplate3SectionEditorAction({ sectionID: 1, data }));
  };

  /**
   * preview button handler
   */
  const previewButtonHandler = () => {
    dispatch(saveTemplate3SectionBySectionIDAction({ sectionID: 1, data }));
    dispatch(setPreviewOfTemplate3Action());
  };

  /**
   * Save Section Data
   */
  const saveSectionDataHandler = () => {
    dispatch(
      updateSectionOfTemplate3BySiteIDActionThunk({
        siteID,
        sectionID: 1,
        data,
      })
    );
  };

  const fileInput = useRef(null);

  const onRemoveImageClick = (event) => {
    setData((prevData) => ({ ...prevData, Section1BackgroundVisible: false }));
    console.log(fileInput);
    fileInput.current.value = "";
    console.log(data);
  };

  useEffect(() => {
    if (googleFontsFamilies?.length > 0) {
      WebFont.load({
        google: {
          families: googleFontsFamilies,
        },
      });
    }
  }, []);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={editModalCloseHandler}
        placement="end"
        scroll={true}
      >
        <Offcanvas.Header closeButton className="border-bottom">
          <Offcanvas.Title>Edit</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="d-flex flex-column">
          <Form
            className="d-flex flex-column flex-fill"
            onSubmit={(e) => {
              e.preventDefault();
              saveSectionDataHandler();
            }}
          >
            <Form.Group className="mb-3" controlId="plainText">
              <Form.Label>Brand Logo</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={(event) =>
                  changeHandlerImage(event, "brandLogoImage")
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Brand Logo Visibility</Form.Label>
              <br></br>
              <Switch
                checked={
                  typeof data?.IsLogoVisible === "undefined"
                    ? true
                    : data?.IsLogoVisible
                }
                onChange={(value) => handleLogoVisibleChange(value)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Form.Group>
            <Row md={2} className="form-row">
              <Col>
                <Form.Group className="mb-3" controlId="plainText">
                  <Form.Label>Brand Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter heading"
                    value={data?.BrandName}
                    onChange={(e) =>
                      setData((prevData) => ({
                        ...prevData,
                        BrandName: e.target?.value,
                      }))
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Fonts</Form.Label>
                  <Form.Select
                    value={data?.BrandFont}
                    onChange={(e) =>
                      setData((prevData) => ({
                        ...prevData,
                        BrandFont: e?.target?.value,
                      }))
                    }
                  >
                    <option selected disabled hidden>
                      Select Font
                    </option>
                    {googleFonts?.map((option, index) => (
                      <option
                        key={index}
                        value={option?.family}
                        style={{ fontFamily: option?.family }}
                      >
                        {option?.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Form.Group controlId="formBasicPassword" className="mb-2">
                <Col className="d-flex align-items-center justify-content-between mb-2">
                  <Form.Label className="m-0">Navbar Tabs</Form.Label>
                  <Button
                    className="btn-primary-theme"
                    onClick={() => addNavItemInLists()}
                  >
                    <i className="fa fa-plus"></i>
                  </Button>
                </Col>
                {data?.NavItems?.map((btn, i) => (
                  <Col xs={12} className="d-flex gap-2 mb-2" key={i}>
                    <Form.Control
                      type="text"
                      required
                      placeholder="Enter button name"
                      name="btnName"
                      value={btn?.Text}
                      onChange={(e) => navItemListsChangeHandler(e, i)}
                    />
                    <Form.Control
                      type="url"
                      placeholder="Enter link"
                      name="btnLink"
                      value={btn?.Link}
                      onChange={(e) => navItemListsChangeHandler(e, i)}
                    />
                    <Button
                      size="sm"
                      variant="danger"
                      className="px-3"
                      onClick={() => removeNavItemInLists(i)}
                    >
                      <i className="fa fa-trash"></i>
                    </Button>
                  </Col>
                ))}
              </Form.Group>
            </Row>
            <Row>
              {data?.IsStoreEnable && (
                <Form.Group className="mb-3">
                  <Form.Check
                    label="Show Shop"
                    type="switch"
                    checked={data?.ShowShop}
                    onChange={(e) =>
                      setData((prev) => ({
                        ...prev,
                        ShowShop: e?.target?.checked,
                      }))
                    }
                  />
                </Form.Group>
              )}
            </Row>
            <Row>
              <Form.Group controlId="plainText" className="mb-3">
                <Form.Label>Heading Line 1</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter heading line 1"
                  value={data?.HeadingLine1}
                  onChange={(e) =>
                    setData((prevData) => ({
                      ...prevData,
                      HeadingLine1: e.target?.value,
                    }))
                  }
                />
              </Form.Group>
              <Form.Group controlId="plainText" className="mb-3">
                <Form.Label>Heading Line 2</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter heading line 1"
                  value={data?.HeadingLine2}
                  onChange={(e) =>
                    setData((prevData) => ({
                      ...prevData,
                      HeadingLine2: e.target?.value,
                    }))
                  }
                />
              </Form.Group>
              <Form.Group controlId="plainText" className="mb-3">
                <Form.Label>Heading Line 3</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter heading line 1"
                  value={data?.HeadingLine3}
                  onChange={(e) =>
                    setData((prevData) => ({
                      ...prevData,
                      HeadingLine3: e.target?.value,
                    }))
                  }
                />
              </Form.Group>
            </Row>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Content (Max limit is 150 character)</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={data?.Content}
                onChange={(e) =>
                  setData((prevData) => ({
                    ...prevData,
                    Content: e?.target?.value?.substring(0, 150),
                  }))
                }
              />
              <span>{data?.Content?.length} character</span>
            </Form.Group>
            <Row>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Button</Form.Label>
                <Col xs={12} className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Enter Button Name"
                    name="btnName"
                    className="mb-2"
                    value={data?.Button?.Name}
                    onChange={(e) =>
                      setData((prev) => ({
                        ...prev,
                        Button: { ...prev.Button, Name: e.target?.value },
                      }))
                    }
                  />
                  <Form.Control
                    type="url"
                    placeholder="Enter Button Link"
                    name="btnLink"
                    value={data?.Button?.Link}
                    onChange={(e) =>
                      setData((prev) => ({
                        ...prev,
                        Button: { ...prev.Button, Link: e.target?.value },
                      }))
                    }
                  />
                </Col>
              </Form.Group>
            </Row>
            <Row>
              <Col className="d-flex gap-4 mb-3">
                <Form.Group controlId="colorPicker">
                  <Form.Label>Text color 1</Form.Label>
                  <Form.Control
                    type="color"
                    value={data?.TextColor1}
                    onChange={(e) =>
                      setData((prevData) => ({
                        ...prevData,
                        TextColor1: e.target?.value,
                      }))
                    }
                  />
                </Form.Group>
                <Form.Group controlId="colorPicker">
                  <Form.Label>Text color 2</Form.Label>
                  <Form.Control
                    type="color"
                    value={data?.TextColor2}
                    onChange={(e) =>
                      setData((prevData) => ({
                        ...prevData,
                        TextColor2: e.target?.value,
                      }))
                    }
                  />
                </Form.Group>
                <Form.Group controlId="colorPicker">
                  <Form.Label>Theme color</Form.Label>
                  <Form.Control
                    type="color"
                    value={data?.ThemeColor}
                    onChange={(e) =>
                      setData((prevData) => ({
                        ...prevData,
                        ThemeColor: e.target?.value,
                      }))
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-4" controlId="plainText">
              <Form.Label>Background Image</Form.Label>
              <div style={{ display: "flex" }}>
                <Form.Control
                  ref={fileInput}
                  type="file"
                  accept="image/*"
                  onChange={(event) =>
                    changeHandlerImage(event, "backgorundImage")
                  }
                />
                <Button
                  style={{ marginLeft: "10px" }}
                  className="btn-primary-theme font-size-14"
                  onClick={(event) => onRemoveImageClick(event)}
                >
                  Remove
                </Button>
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label>Background Shadow</Form.Label>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  marginBottom: "2em",
                  marginLeft: "1em",
                  marginRight: "1em",
                }}
              >
                <Slider
                  // valueLabelDisplay={"auto"}
                  step={STEP}
                  min={MIN}
                  max={MAX}
                  value={data?.BackgroundOpacity}
                  onChange={(newValues) => handleRangeChange(newValues)}
                  renderTrack={({ props, children }) => (
                    <div
                      onMouseDown={props.onMouseDown}
                      onTouchStart={props.onTouchStart}
                      style={{
                        ...props.style,
                        height: "36px",
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <div
                        ref={props.ref}
                        style={{
                          height: "5px",
                          width: "100%",
                          borderRadius: "4px",
                          background: getTrackBackground({
                            values: rangeValue,
                            colors: ["#548BF4", "#ccc"],
                            min: MIN,
                            max: MAX,
                          }),
                          alignSelf: "center",
                        }}
                      >
                        {children}
                      </div>
                    </div>
                  )}
                  renderThumb={({ props, isDragged }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        height: "42px",
                        width: "42px",
                        borderRadius: "4px",
                        backgroundColor: "#FFF",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0px 2px 6px #AAA",
                      }}
                    >
                      <div
                        style={{
                          height: "16px",
                          width: "5px",
                          backgroundColor: isDragged ? "#548BF4" : "#CCC",
                        }}
                      />
                    </div>
                  )}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Background color</Form.Label>
              <Form.Control
                type="color"
                value={data?.Section1BackgroundColor}
                onChange={(e) =>
                  setData((prevData) => ({
                    ...prevData,
                    Section1BackgroundColor: e.target?.value,
                  }))
                }
              />
            </Form.Group>
            <div className="pt-3 d-flex justify-content-end border-top mt-auto">
              <Button
                onClick={() => previewButtonHandler()}
                className="btn-secondary-theme btn-outline me-2"
              >
                Preview
              </Button>
              <Button
                type="submit"
                className="btn-primary-theme font-size-14"
                disabled={save_btn_loading}
              >
                Save{" "}
                {save_btn_loading && (
                  <i className="fa fa-spinner fa-spin ms-2"></i>
                )}
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      {showImageCroperModal?.show &&
        (showImageCroperModal?.whichImage === "backgorundImage" ? (
          <CombineCropperModal
            show={showImageCroperModal?.show}
            handleClose={handleCloseImageCropModal}
            src={src}
            aspect={16 / 9}
            spectString="16 / 9"
            canChangeAspect={true}
            cropRation={{
              unit: "%",
              x: 0,
              y: 0,
              height: 100,
              width: 100,
            }}
          />
        ) : (
          <CombineCropperModal
            show={showImageCroperModal?.show}
            handleClose={handleCloseImageCropModal}
            src={src}
            aspect={1 / 1}
            canChangeAspect={true}
            spectString="1 / 1"
            cropRation={{
              unit: "%",
              x: 0,
              y: 0,
              height: 100,
              width: 100,
            }}
          />
        ))}
    </>
  );
};

export default EditModal;

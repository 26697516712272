import { createSlice } from "@reduxjs/toolkit";
import { getAllGroupByCommunityIDUserIDActionThunk } from "./current-user-groups-async-thunk";

const initialState = {
    loading: { get_groups: false },
    group_list: [],
    errors: {
        get_groups: null,
    }
}

const currentUserGroupSlice = createSlice({
    name: 'currentUserGroups',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllGroupByCommunityIDUserIDActionThunk.pending, (state, action) => {
                state.loading.get_groups = true;
                state.errors.get_groups = null;
            })
            .addCase(getAllGroupByCommunityIDUserIDActionThunk.fulfilled, (state, action) => {
                state.loading.get_groups = false;
                state.errors.get_groups = null;
                state.group_list = action?.payload
            })
            .addCase(getAllGroupByCommunityIDUserIDActionThunk.rejected, (state, action) => {
                state.loading.get_groups = false;
                state.errors.get_groups = action.error.message;
                state.group_list = [];
            })
    }
});


export const actions = currentUserGroupSlice.actions;

const currentUserGroupReducer = currentUserGroupSlice.reducer;
export default currentUserGroupReducer;
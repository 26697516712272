import { REHYDRATE } from "redux-persist";
import { GET_ALL_PERMISSIONS, GET_COMMUNITY_PERMISSIONS, PERMISSION_LOADED, PERMISSION_LOADING } from "./roleAndPermission.constant";

const INITIAL_STATE = {
    loading: { byCommunity: false, all: false },
    communityPermissions: [],
    allPermissions: []
}

export const roleAndPermissionReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case PERMISSION_LOADING:
            return { ...state, loading: { ...state?.loading, ...(action?.payload === "byCommunity" ? { byCommunity: true } : { all: true }) } }

        case PERMISSION_LOADED:
            return { ...state, loading: { ...state?.loading, ...(action?.payload === "byCommunity" ? { byCommunity: false } : { all: false }) } }

        case GET_COMMUNITY_PERMISSIONS:
            return { ...state, communityPermissions: action?.payload }

        case GET_ALL_PERMISSIONS:
            return { ...state, allPermissions: action?.payload }

        case REHYDRATE:
            if (action?.payload && action?.payload?.roleAndPermission) {
                return { ...action?.payload?.roleAndPermission, communityPermissions: [], loading: { ...action?.payload?.roleAndPermission?.loading, all: false } }
            }
            return state;

        default:
            return state
    }
}
const roles = {
    1: "System Administrator",
    2: "Community Owner",
    3: "Community Admin",
    4: "Group Admin",
    5: "Group Member",
    6: "Visitor",
    7: "Community Member"
}

export const roleIdToRoleName = (roleId) => {
    return roles[Number(roleId)]
}
import { ArrowBackIosNewOutlined } from '@mui/icons-material';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { truncateText } from '../../../../../utils/helpers/truncateHtml';
import { ConditionalWrapper } from '../../../../conditional-Wrapper/ConditionalWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentFolderInSharedMediaEntriesAction } from '../../../../../ReduxStore/slice/group-shared-media-entries/group-shared-media-entries-slice';

const GroupSharedMediaEntriesHeader = () => {

    const dispatch = useDispatch();

    const current_folder = useSelector(state => state?.groupSharedMediaEntries?.file_enteries?.FolderDetail);

    const handleGoBack = (backFolderId) => {
        dispatch(setCurrentFolderInSharedMediaEntriesAction(backFolderId || null));
    }

    return (
        <div className="d-flex gap-2 align-items-center border-bottom pb-3 my-3">
            <Button
                className={`p-0 ${current_folder ? "cursor-pointer" : "text-black-theme"}`}
                onClick={() => current_folder && handleGoBack(current_folder?.ParentFolderId)}
                disabled={!current_folder}
                variant="link"
            >
                <ArrowBackIosNewOutlined style={{ fontSize: "15px", color: "#333333", marginTop: '-2px' }} />
            </Button>
            <ConditionalWrapper
                condition={current_folder?.Path?.length > 50}
                wrapper={(children) => (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                All files {current_folder?.Path}
                            </Tooltip>
                        }
                    >
                        {children}
                    </OverlayTrigger>
                )}
            >
                <small className="text-muted">All files {truncateText(current_folder?.Path, 50)}</small>
            </ConditionalWrapper>
        </div>
    )
}

export default GroupSharedMediaEntriesHeader
import { createSlice } from "@reduxjs/toolkit";
import { deleteSystemGroupActionThunk, getAllGroupWithCommunityNameAsyncThunk } from "./system-groups-async-thunk";

const initialState = {
  loading: {
    fetch_group_list: false,
    delete_group: { status: false, groupId: "" },
  },
  group_list: {
    list: [],
    totalRecords: 0
  },
  errors: {
    fetch_group_list: null,
    delete_group: null
  }
}

const systemGroupsSlice = createSlice({
  name: 'systemGroups',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder

      // get group lists
      .addCase(getAllGroupWithCommunityNameAsyncThunk.pending, (state, action) => {
        state.loading.fetch_group_list = true;
        state.errors.fetch_group_list = null;
      })
      .addCase(getAllGroupWithCommunityNameAsyncThunk.fulfilled, (state, action) => {
        state.loading.fetch_group_list = false;
        state.errors.fetch_group_list = null;
        state.group_list.list = action?.payload?.entities;
        state.group_list.totalRecords = action?.payload?.totalRecords;
      })
      .addCase(getAllGroupWithCommunityNameAsyncThunk.rejected, (state, action) => {
        state.loading.fetch_group_list = false;
        state.errors.fetch_group_list = action.error.message;
        state.group_list.list = [];
        state.group_list.totalRecords = 0;
      })

      //delete system group
      .addCase(deleteSystemGroupActionThunk.pending, (state, action) => {
        state.loading.delete_group = { groupId: action?.meta?.arg, status: true }
        state.errors.delete_group = null
      })
      .addCase(deleteSystemGroupActionThunk.fulfilled, (state, action) => {
        state.loading.delete_group = { groupId: "", status: false }
        state.errors.delete_group = null
      })
      .addCase(deleteSystemGroupActionThunk.rejected, (state, action) => {
        state.loading.delete_group = { groupId: "", status: false }
        state.errors.delete_group = action?.error?.message;
      })
  }
});


export const actions = systemGroupsSlice.actions;

const systemGroupsRducer = systemGroupsSlice.reducer;
export default systemGroupsRducer;
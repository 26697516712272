import { memo, useCallback, useEffect, useState } from "react";
import { SplitButton, } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { errorToast, successToast } from "../../Component/toast/toast";
import GroupService from "../../Services/group/GroupService";
import GetGuestModal from "../../Component/GetGuestModal/GetGuestModal";
import { emptyVisitorDataAction } from "../../ReduxStore/auth/auth.action";
import SkeletonEventConfirmBtn from "../../Component/loader/SkeletonEventConfirmBtn";
import UserListDropDown from "./UserListDropDown";

const ConfirmButtons = ({ eventId, eventDetails, setEventDetails, loading, setLoading }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const showGetGuestModal = location?.state?.showGetGuestModal;
    const token = useSelector(state => state?.auth?.token);
    const UserID = useSelector((state) => state?.profileData?.profileData?.profileId);
    const visitorId = useSelector(state => state?.auth?.visitorData?.data?.userId);

    const [confirmId, setConfirmID] = useState(null);
    const [userID, setUserID] = useState(null);

    //get user confirmation details
    const GetEventdetailByUserIdAndEventId = useCallback(() => {
        setLoading((prev) => ({ ...prev, confirmBtn: true }));
        GroupService.GetEventdetailByUserIdAndEventId(eventId, userID)
            .then((res) => {
                setConfirmID(res?.data?.data);
                setLoading((prev) => ({ ...prev, confirmBtn: false }));
            })
            .catch(err => {
                errorToast("err: EventDetailModal.jsx, GetEventdetailByUserIdAndEventId " + err.message);
                setLoading((prev) => ({ ...prev, confirmBtn: false }));
                setConfirmID(null)
            })
    }, [eventId, setLoading]);

    //update user event confirmaiton
    const AddEventConfirmationDetails = async (going, mayBe, cantGo) => {
        const data = {
            eventId: eventId,
            communityId: eventDetails?.siteID,
            groupId: eventDetails?.groupID,
            UserID: userID,
            going: going,
            cantGo: cantGo,
            mayBe: mayBe,
            eventConfirmId: confirmId?.eventConfirmId || null
        };
        setLoading(prev => ({ ...prev, eventConfirmation: { status: true, btn: `${String(going) + mayBe + cantGo}` } }))
        GroupService.AddEventConfirmationDetails(data)
            .then(res => {
                if (res?.data) {
                    const data = res?.data?.data
                    successToast("Your response is send successfully.");
                    setEventDetails(data?.response);
                    setConfirmID(() => ({ eventConfirmId: data?.eventConfirmId, going: data?.going, cantGo: data?.cantGo, mayBe: data?.mayBe }))
                    setLoading(prev => ({ ...prev, eventConfirmation: { status: false, btn: `${String(going) + mayBe + cantGo}` } }))
                }
            })
            .catch(err => {
                errorToast("something went wrong")
                setLoading(prev => ({ ...prev, eventConfirmation: { status: false, btn: `${String(going) + mayBe + cantGo}` } }))
            })

    };

    useEffect(() => {
        if (!userID && !visitorId) {
            navigate(location.pathname, { state: { ...location?.state, showGetGuestModal: true }, replace: true })
        } else {
            GetEventdetailByUserIdAndEventId();
        }
    }, [GetEventdetailByUserIdAndEventId, location.pathname, navigate, userID, visitorId])

    useEffect(() => {
        if ((token && UserID) || visitorId) setUserID(UserID || visitorId);
        return () => {
            dispatch(emptyVisitorDataAction())
        };
    }, [UserID, dispatch, token, visitorId]);

    return (
        <>
            {loading?.confirmBtn ? (
                <SkeletonEventConfirmBtn />
            ) : (
                <div className="invited-buttons public-event-page">
                    <SplitButton
                        className={`${confirmId?.going ? "active" : undefined}`}
                        id="dropdown-button-drop-down-1"
                        drop="down"
                        align="end"
                        variant="primary"
                        title={(loading?.eventConfirmation?.status && loading?.eventConfirmation?.btn === "100") ? "Updating..." : `(${eventDetails?.going || 0}) Going`}
                        onClick={() => AddEventConfirmationDetails(1, 0, 0)}
                    >
                        <UserListDropDown
                            userLists={eventDetails?.goingUsers}
                        />
                    </SplitButton>
                    <SplitButton
                        className={`${confirmId?.mayBe ? "active" : undefined}`}
                        id="dropdown-button-drop-down-2"
                        drop="down"
                        align="end"
                        variant="primary"
                        title={(loading?.eventConfirmation?.status && loading?.eventConfirmation?.btn === "010") ? "Updating..." : `(${eventDetails?.mayBe || 0}) Maybe`}
                        onClick={() => AddEventConfirmationDetails(0, 1, 0)}
                    >
                        <UserListDropDown
                            userLists={eventDetails?.mayBeUsers}
                        />
                    </SplitButton>
                    <SplitButton
                        className={`${confirmId?.cantGo ? "active" : undefined}`}
                        id="dropdown-button-drop-down-3"
                        drop="down"
                        align="end"
                        variant="primary"
                        title={(loading?.eventConfirmation?.status && loading?.eventConfirmation?.btn === "001") ? "Updating..." : `(${eventDetails?.cantGo || 0}) Can't go`}
                        onClick={() => AddEventConfirmationDetails(0, 0, 1)}
                    >
                        <UserListDropDown
                            userLists={eventDetails?.cantGoUsers}
                        />
                    </SplitButton>
                </div>
            )}
            {(!userID && showGetGuestModal) && <GetGuestModal />}
        </>
    )
}

export default memo(ConfirmButtons)
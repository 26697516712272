import { useFormik } from "formik";
import { useState } from "react";
import { Button, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import * as Yup from "yup";
import { ReactComponent as EditIconSvg } from "../../../assets/Images/edit-icon-svg.svg";
import { ReactComponent as CloseIconRed } from "../../../assets/Images/close-icon-red.svg";
import { ReactComponent as CameraIcon } from "../../../assets/Images/camera-icon.svg";
import moment from "moment";
import GroupService from "../../../Services/group/GroupService";
import { useDispatch, useSelector } from "react-redux";
import { modalAct } from "../../../ReduxStore/Actions/modalActs";
import { getJoinGroupMemDetail } from "../../../ReduxStore/Actions/joinGroupAction";
import { errorToast } from "../../toast/toast";
import { loginActionThunk } from "../../../ReduxStore/auth/auth.actions.async";
import CombineCropperModal from "../../image-cropper/CombineCropperModal";

const SignUp = ({ formRef, setLoading, navigateToGroup }) => {
  const dispatch = useDispatch();

  const modalReducer = useSelector((state) => state.modalReducer);

  const [togglePassword, setTogglePassword] = useState({
    password: true,
    confirmPassword: true,
  });
  const [showImgCropModal, setShowImgCropModal] = useState(false);
  const [src, setSrc] = useState({ image: null, fileName: "" });

  const closeImgCropModal = (urlLink) => {
    if (urlLink) {
      formik.setFieldValue("profilePicture", urlLink);
    }
    setShowImgCropModal(false);
  };

  /**
   * image change handler
   */
  const changeHandlerImage = (event) => {
    setSrc({
      image: URL.createObjectURL(event.target.files[0]),
      fileName: event.target.files[0]?.name,
      fileType: event.target.files[0]?.type,
    });
    setShowImgCropModal(true);
    //event.target.value = null;
  };

  //handle toggle password
  const handleTogglePassword = (field) => {
    setTogglePassword((prev) => ({
      ...prev,
      ...(field === "password"
        ? { password: !prev?.password }
        : { confirmPassword: !prev?.confirmPassword }),
    }));
  };

  /**
   * phone number validation
   */
  let validatePhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  /**
   * validatoin schema for formik form
   */
  const profileDataSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(25, ({ max }) => `First name cannot exceed ${max} characters`)
      .min(3, ({ min }) => `First name must be at least ${min} characters`)
      .required("First name is required.")
      .matches(/^[aA-zZ\s]+$/, "Please enter a valid first name"),
    lastName: Yup.string()
      .max(25, ({ max }) => `Last name cannot exceed ${max} characters`)
      .min(3, ({ min }) => `Last name must be at least ${min} characters`)
      .required("Last name is required.")
      .matches(/^[aA-zZ\s]+$/, "Please enter a valid last name"),
    phone: Yup.string()
      .min(14, "Phone number is not valid")
      .required("Phone number is required."),
    profilePicture: Yup.string(),
    dateOfBirth: Yup.date().required("Date of birth is required"),
    email: Yup.string().email().required("Email is required"),
    password: Yup.string()
      .required("Please Enter your password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      )
      .max(64, "Password must be 64 characters or less"),
    confirmpassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),

    gorupID: Yup.string(),
    GroupAccessStatus: Yup.string(),
    RoleID: Yup.number(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: profileDataSchema,
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
      profilePicture: "",
      dateOfBirth: "",
      email: "",
      password: "",
      GroupID: modalReducer.groupId,
      GroupAccessStatus: "Pending",
      RoleID: 5,
    },

    onSubmit: (values, { resetForm }) => {
      setLoading((prev) => ({ ...prev, signup: true }));
      GroupService.getGroupMembersDetailsByEmailId(
        values?.email,
        values?.GroupID
      )
        .then((res) => {
          if (!res?.data?.data?.memberExist) {
            const config = {
              headers: { "content-type": "multipart/form-data" },
            };
            GroupService.CreateGroupMember(values, config)
              .then((res) => {
                if (res.data.statusCode === 200) {
                  dispatch(
                    modalAct({
                      status: !modalReducer.status,
                      groupId: null,
                      communityID: null,
                    })
                  );
                  dispatch(getJoinGroupMemDetail(res.data.data));
                  resetForm();
                  dispatch(
                    loginActionThunk(
                      {
                        email: values.email,
                        password: values.password,
                      },
                      false,
                      navigateToGroup
                    )
                  );
                  setLoading((prev) => ({ ...prev, signup: false }));
                } else {
                  errorToast(res?.data?.message);
                  setLoading((prev) => ({ ...prev, signup: false }));
                }
              })
              .catch(() => {
                errorToast("something went wrong.");
                setLoading((prev) => ({ ...prev, signup: false }));
              });
          } else {
            formik?.setErrors({
              ...formik?.errors,
              email: "Email is already registered",
            });
            setLoading((prev) => ({ ...prev, signup: false }));
          }
        })
        .catch(() => {
          errorToast("something went wrong.");
          setLoading((prev) => ({ ...prev, signup: false }));
        });
    },
  });

  return (
    <>
      <form
        className="form-row row"
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
        ref={formRef}
      >
        <div className="row form-row">
          <div className="col-sm-12 text-center">
            <div className="small-profile-container d-inline-block position-relative m-0 mb-3 text-center">
              <label htmlFor="image-input-field" name="logoImage">
                <div className="small-profile-image-box">
                  <div className="small-profile-image">
                    {formik?.values?.profilePicture ? (
                      <Image
                        width="100%"
                        height="100%"
                        roundedCircle
                        src={formik?.values?.profilePicture}
                        alt="logo"
                      />
                    ) : (
                      <CameraIcon className="camera-icon" fill="#5F6C85" />
                    )}
                    <input
                      hidden
                      id="image-input-field"
                      type="file"
                      accept="image/*"
                      name="myImage"
                      onChange={(event) => {
                        changeHandlerImage(event);
                      }}
                    />
                  </div>
                </div>
              </label>
              {!formik?.values?.profilePicture ? (
                <label
                  htmlFor="image-input-field"
                  className="btn btn-secondary-theme btn-outline small-btn image-edit-icon"
                >
                  <EditIconSvg fill="#212121" />
                </label>
              ) : (
                <Button
                  variant="link"
                  className="image-close-icon"
                  onClick={() =>
                    formik?.setValues({ ...formik?.values, profilePicture: "" })
                  }
                >
                  <CloseIconRed />
                </Button>
              )}
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="form-group mb-3">
              <label className="form-label">First Name</label>
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Enter First Name"
                name="firstName"
                value={formik.values.firstName}
                onChange={(e) =>
                  formik.setValues({
                    ...formik.values,
                    firstName: e.target?.value?.trimStart(),
                  })
                }
                onBlur={formik.handleBlur}
              />
              {formik.touched.firstName && formik.errors.firstName ? (
                <small className="form-text text-danger">
                  {formik.errors.firstName}
                </small>
              ) : null}
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="form-group mb-3">
              <label className="form-label">Last Name</label>
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Enter Last Name"
                name="lastName"
                value={formik.values.lastName}
                onChange={(e) =>
                  formik.setValues({
                    ...formik.values,
                    lastName: e.target?.value?.trimStart(),
                  })
                }
                onBlur={formik.handleBlur}
              />
              {formik.touched.lastName && formik.errors.lastName ? (
                <small className="form-text text-danger">
                  {formik.errors.lastName}
                </small>
              ) : null}
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="form-group mb-3">
              <label className="form-label">Phone Number</label>
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Enter Phone Number"
                name="phone"
                value={validatePhoneNumber(formik.values.phone)}
                onChange={(e) => formik.handleChange(e)}
                onBlur={formik.handleBlur}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <small className="form-text text-danger">
                  {formik.errors.phone}
                </small>
              ) : null}
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="form-group mb-3">
              <label className="form-label">Email</label>
              <input
                name="email"
                className="form-control form-control-lg"
                placeholder="Email"
                value={formik?.values?.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? (
                <small className="form-text text-danger">
                  {formik.errors.email}
                </small>
              ) : null}
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="form-group mb-3 position-relative password-field">
              <label className="form-label">Password</label>
              <input
                type={togglePassword?.password ? "password" : "text"}
                name="password"
                value={formik?.values?.password}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                className="form-control"
                placeholder="Password"
              />
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip className="password-tooltip">
                    <span className="d-block">
                      Please Enter Valid Password.{" "}
                    </span>
                    <ul className="m-0 ps-3">
                      <li>Minimum Length : 8</li>
                      <li>Mininum Lower Case Letters : 1</li>
                      <li>Minimum Upper Case Letters : 1</li>
                      <li>Minimum Numbers : 1</li>
                      <li>Minimum Symbols : 1</li>
                    </ul>
                  </Tooltip>
                }
              >
                <button
                  className="btn btn-outline-primary hide-show-eye shadow-none me-2"
                  type="button"
                  onClick={() => handleTogglePassword("password")}
                >
                  <i
                    className={
                      togglePassword?.password ? "fa fa-eye-slash" : "fa fa-eye"
                    }
                    aria-hidden="true"
                  />
                </button>
              </OverlayTrigger>
              {formik.touched.password && formik.errors.password ? (
                <small className="form-text text-danger">
                  {formik.errors.password}
                </small>
              ) : null}
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="form-group mb-3 position-relative password-field">
              <label className="form-label">Confirm Password</label>
              <input
                type={togglePassword?.confirmPassword ? "password" : "text"}
                name="confirmpassword"
                value={formik?.values?.confirmpassword}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                className="form-control"
                placeholder="Password"
              />
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip className="password-tooltip">
                    <span className="d-block">
                      Please Enter Valid Password.{" "}
                    </span>
                    <ul className="m-0 ps-3">
                      <li>Minimum Length : 8</li>
                      <li>Mininum Lower Case Letters : 1</li>
                      <li>Minimum Upper Case Letters : 1</li>
                      <li>Minimum Numbers : 1</li>
                      <li>Minimum Symbols : 1</li>
                    </ul>
                  </Tooltip>
                }
              >
                <button
                  className="btn btn-outline-primary hide-show-eye shadow-none me-2"
                  type="button"
                  onClick={() => handleTogglePassword("confirmPassword")}
                >
                  <i
                    className={
                      togglePassword?.confirmPassword
                        ? "fa fa-eye-slash"
                        : "fa fa-eye"
                    }
                    aria-hidden="true"
                  />
                </button>
              </OverlayTrigger>
              {formik.touched.confirmpassword &&
              formik.errors.confirmpassword ? (
                <small className="form-text text-danger">
                  {formik.errors.confirmpassword}
                </small>
              ) : null}
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="form-group">
              <label className="form-label">Date Of Birth</label>
              <input
                type="date"
                className="form-control form-control-lg"
                name="dateOfBirth"
                value={moment(formik.values.dateOfBirth).format("YYYY-MM-DD")}
                onChange={(e) =>
                  formik.setValues({
                    ...formik.values,
                    dateOfBirth: e.target.value,
                  })
                }
                onBlur={formik.handleBlur}
                max={moment().format("YYYY-MM-DD")}
              />
              {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? (
                <small className="form-text text-danger">
                  {formik.errors.dateOfBirth}
                </small>
              ) : null}
            </div>
          </div>
        </div>
      </form>
      {showImgCropModal && (
        <CombineCropperModal
          show={showImgCropModal}
          handleClose={closeImgCropModal}
          src={src}
          aspect={1 / 1}
          spectString="1 / 1"
          canChangeAspect={true}
          cropRation={{
            unit: "%",
            x: 0,
            y: 0,
            height: 100,
            width: 100,
          }}
        />
      )}
    </>
  );
};

export default SignUp;

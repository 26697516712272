import { useEffect, useState } from "react";
import { getTemplateUrl } from "../../../utils/helpers/navigate-to-template";
import { CreateDynamicPWAManifest } from "../../../utils/helpers/update-manifest";
import { confirmAlert } from "react-confirm-alert";

const InstallPwaPage = () => {

    const [deferredPrompt, setDeferredPrompt] = useState(null);

    const handleInstallClick = () => {

        if (deferredPrompt) {
            confirmAlert({
                title: "Install",
                message: "Are you sure to install community site?",
                buttons: [
                    {
                        label: "Yes",
                        onClick: () => {
                            deferredPrompt?.prompt();
                            deferredPrompt.userChoice.then(choiceResult => {
                                if (choiceResult.outcome === 'accepted') {
                                    console.log('User accepted the PWA installation');
                                } else {
                                    console.log('User dismissed the PWA installation');
                                }
                                setDeferredPrompt(null);
                                setTimeout(() => {
                                    window.close();
                                }, 1000)
                            });
                        },
                    },
                    {
                        label: "No",
                        onClick: () => {
                            window.close();
                        },
                    },
                ],
            });
        }
    };

    useEffect(() => {

        const urlSearchParams = new URLSearchParams(window.location.search);
        const communitySiteName = urlSearchParams.get('communitySiteName');
        const communityLogo = urlSearchParams.get('communityLogo');
        const communityDomain = urlSearchParams.get('communityDomain');
        const url = getTemplateUrl(communityDomain)
        CreateDynamicPWAManifest(communitySiteName, communityLogo, "#ffffff", "#3F444D", url);


        const handleBeforeInstallPrompt = event => {
            event.preventDefault();
            setDeferredPrompt(event);
        };
        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            // Clean up event listener when the component unmounts
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    return (
        handleInstallClick()
    )
}

export default InstallPwaPage
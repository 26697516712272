import {
  CLOSE_MEDIA,
  SHOW_MEDIA
} from "./mediaView.constant";

const INITIAL_STATE = {
  status: false,
  media: { type: "", src: "" },
};

const mediaView = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_MEDIA:
      return { status: true, media: { type: action?.payload?.type, src: action?.payload?.src, title: action?.payload?.title } }
    case CLOSE_MEDIA:
      return { status: false, media: { type: "", src: "" } }
    default:
      return state;
  }
};
export default mediaView;

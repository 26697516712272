import { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import { Form } from "react-bootstrap";
import { errorToast } from "../toast/toast";
import uploadFileToBlob from "../../utils/helpers/azure-storage-blob";
import { SpinnerLoader } from "../loader/SpinnerLoader";
import { v4 as uuidv4 } from "uuid";
import getCroppedImg from "./crop";
import { generateImageName } from "../../utils/helpers/getFileNameFromURL";

const aspectOptions = [
  { label: "1:1 (Square)", value: 1 },
  { label: "4:3 (Standard)", value: 4 / 3 },
  { label: "3:2 (35mm Film)", value: 3 / 2 },
  { label: "16:9 (Widescreen)", value: 16 / 9 },
  { label: "5:4 (Large Format)", value: 5 / 4 },
  { label: "7:5 (Medium Format)", value: 7 / 5 },
  { label: "8:10 (Large Format)", value: 8 / 10 },
  { label: "3:1 (Panorama)", value: 3 },
  { label: "2:1 (Panorama)", value: 2 },
  { label: "21:9 (Ultra Widescreen)", value: 21 / 9 },
];

const ReactEasyCrop = forwardRef(
  ({ handleClose, src, aspect, canChangeAspect, loading, setLoading }, ref) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [aspects, setAspects] = useState(aspect);

    const handleAspectChange = (event) => {
      setAspects(parseFloat(event.target.value));
    };

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    /**
     * get cropped image
     */
    useImperativeHandle(ref, () => ({
      async handleImageCrop() {
        try {
          const { file, url } = await getCroppedImg(
            src?.image,
            croppedAreaPixels,
            rotation,
            src?.fileType
          );
          const imageName = generateImageName(src?.fileName);
          file.name = imageName;
          setLoading(true);
          uploadFileToBlob(file)
            .then((fileUrl) => {
              setLoading(false);
              console.log("456", fileUrl?.split("?")[0]);
              handleClose(fileUrl?.split("?")[0]);
            })
            .catch((err) => {
              errorToast("Something went wrong.");
              setLoading(false);
              handleClose("");
            });
        } catch (e) {
          console.error(e);
        }
      },
    }));

    return (
      <div>
        {loading ? (
          <SpinnerLoader />
        ) : (
          <div className="easy-crop-container">
            <div className="crop-container">
              <Cropper
                image={src?.image}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                minZoom={0.2}
                zoomSpeed={0.2}
                maxZoom={3}
                zoomWithScroll={true}
                showGrid={true}
                aspect={aspects}
                restrictPosition={false}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                onRotationChange={setRotation}
              />
            </div>
            <div className="controls">
              {canChangeAspect && (
                <Form.Select
                  className="mb-3"
                  value={aspects}
                  onChange={handleAspectChange}
                >
                  {aspectOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
              )}
              <label className="mb-2">
                Rotate
                <Slider
                  value={rotation}
                  min={0}
                  max={360}
                  step={1}
                  aria-labelledby="rotate"
                  onChange={(e, rotation) => setRotation(rotation)}
                  className="range"
                />
              </label>
              <label>
                Zoom
                <Slider
                  value={zoom}
                  // min={1}
                  min={0.2}
                  max={3}
                  step={0.1}
                  aria-labelledby="zoom"
                  onChange={(e, zoom) => setZoom(zoom)}
                  className="range"
                />
              </label>
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default ReactEasyCrop;

import { createAsyncThunk } from "@reduxjs/toolkit";
import GroupService from "../../../Services/group/GroupService";
import { successToast } from "../../../Component/toast/toast";

/**
 * get group details with user details and permissions in the group
 */
export const getGroupDetailByUserIdAndGroupIdActionThunk = createAsyncThunk("groupOpen/getGroupByUserIdAndGroupId", async (groupId) => {
    const response = await GroupService.getGroupByGroupID(groupId);
    return response?.data;
});

/**
 * join group api call
 */
export const joinGroupActionThunk = createAsyncThunk("groupOpen/joinGroup", async (groupID, { dispatch }) => {
    const response = await GroupService.JoinGroup(undefined, groupID, "")
    if (response?.data?.statusCode !== 200) {
        throw new Error(response?.data?.message);
    } else {
        dispatch(getGroupDetailByUserIdAndGroupIdActionThunk(groupID));
        successToast(response?.data?.data === "Pending" ? "Group join request sended to admin" : "Group join successfully")
        return response?.data;
    }
})

/**
 * leave from group api call
 */
export const leaveFromGroupActionThunk = createAsyncThunk("groupOpen/leaveFromGroup", async (groupID, { dispatch }) => {
    const response = await GroupService.LeaveFromGroup(groupID);
    if (response?.data?.statusCode !== 200) {
        throw new Error(response?.data?.message);
    } else {
        dispatch(getGroupDetailByUserIdAndGroupIdActionThunk(groupID));
        successToast("You left the group");
        return response?.data;
    }
})
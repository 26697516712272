import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { onClickOnFileHandler, openPreviewOfFileHandler } from "../utils/file-click-handler"
import FileOptionsDD from "../drop-downs/FileOptionsDD"

const ImageCard = ({ fileObj }) => {

    return (
        <>
            <div className="new-media-box folder-media-box" >
                <div
                    className="media-group-box"
                    onDoubleClick={() => openPreviewOfFileHandler(fileObj)}
                    onClick={() => onClickOnFileHandler(fileObj)}
                >
                    <div className="group-img" >
                        <img src={fileObj?.SRC} alt="" />
                    </div>
                    <div className="edit-site-tmplt modal d-flex cursor-pointer"
                    >
                        <FileOptionsDD fileObj={fileObj} />
                    </div>
                </div>
                <div className="name">
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id={`tooltip-1`}>{fileObj?.Name}</Tooltip>}
                    >
                        <small className="disable-text-selection">{fileObj?.Name}</small>
                    </OverlayTrigger>
                </div>
            </div>
        </>
    )
}

export default ImageCard
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactPlayer from "react-player";
import { useDispatch } from "react-redux";
import { ReactComponent as DownloadIcon } from '../../../assets/Images/download-icon.svg';
import { errorToast } from "../../toast/toast";
import { showMediaAction } from "../../../ReduxStore/media-view/mediaView.actions";
import { ReactComponent as TrashIcon } from "../../../assets/Images/trash-icon.svg";
import { getFileNameFromURL } from "../../../utils/helpers/getFileNameFromURL";
import { handleDownload } from "../../../utils/helpers/download-handler";

export const ImageMediaCard = ({ url, name, title, deleteHandler, id }) => {

    const dispatch = useDispatch();

    return (<>
        <div className="new-media-box">
            <div className="media-group-box">
                <div className="group-img" >
                    <img src={url} alt="" />
                </div>
                <div className="edit-site-tmplt modal d-flex cursor-pointer"
                    onClick={() => dispatch(showMediaAction({ type: "image", src: url, title: title }))}
                >
                    <Button
                        className="btn btn-secondary-theme btn-outline small-btn"
                        onClick={async (event) => {
                            event.stopPropagation();
                            handleDownload(url)
                        }}
                    >
                        <DownloadIcon fill="#212121" />
                    </Button>
                    {deleteHandler && (
                        <Button
                            className="btn btn-secondary-theme btn-outline small-btn ms-1"
                            onClick={(event) => {
                                event.stopPropagation();
                                deleteHandler(id);
                            }}
                        >
                            <TrashIcon fill="#212121" />
                        </Button>
                    )}
                </div>
            </div>
            <div className="name">
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id={`tooltip-1`}>{title || getFileNameFromURL(url) || ""}</Tooltip>}
                >
                    <small>{title || getFileNameFromURL(url) || ""}</small>
                </OverlayTrigger>
            </div>
        </div>
    </>)
}


export const VideoMediaCard = ({ url, title, name, deleteHandler, id }) => {

    const dispatch = useDispatch();

    return (<>
        <div className="new-media-box">
            <div className="video-play media-group-box">
                <ReactPlayer
                    className="react-player"
                    url={url}
                    width="100%"
                    height="100%"
                />
                <div
                    className="edit-site-tmplt modal d-flex cursor-pointer"
                    onClick={() => dispatch(showMediaAction({ type: "video", src: url, title: title ? title : "" }))}
                >
                    {/* <Button
                        className="btn btn-secondary-theme btn-outline small-btn me-1"
                        onClick={() => dispatch(showMediaAction({ type: "video", src: url, title: title ? title : "" }))}
                    >
                        <EyeIcon fill="#212121" />
                    </Button> */}
                    <Button
                        className="btn btn-secondary-theme btn-outline small-btn"
                        onClick={async (event) => {
                            event.stopPropagation();
                            handleDownload(url)
                        }}
                    >
                        <DownloadIcon fill="#212121" />
                    </Button>
                    {deleteHandler && (<Button
                        className="btn btn-secondary-theme btn-outline small-btn ms-1"
                        onClick={(event) => {
                            event.stopPropagation();
                            deleteHandler(id)
                        }}
                    >
                        <TrashIcon fill="#212121" />
                    </Button>
                    )}
                </div>
            </div>
            <div className="name">
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id={`tooltip-2`}>{title || getFileNameFromURL(url) || ""}</Tooltip>}
                >
                    <small>{title || getFileNameFromURL(url) || ""}</small>
                </OverlayTrigger>
            </div>
        </div>
    </>)
}



export const DocumentMediaCard = ({ url, title, name, deleteHandler, id }) => {

    const dispatch = useDispatch();

    /**
     * get type from document url
     * @param {*} url 
     * @returns 
     */
    const getType = (url) => {
        // const regexPattern = /\.([a-zA-Z0-9]+)\?/;
        const regexPattern = /\.(\w+)(?:\?|$)/;

        const matches = url.match(regexPattern);
        if (matches && matches.length > 1) {
            const documentType = matches[1];
            return documentType
        } else {
            errorToast("Unable to extract document type.");
        }
    }

    return (<>
        <div className="new-media-box">
            <div
                className="docfile media-group-box d-flex flex-column justify-content-center align-items-center m-0"
                style={{ cursor: "pointer" }}
                onClick={async () => {
                    const type = await getType(url);
                    dispatch(showMediaAction({ type: type, src: url }));
                }}
            >
                {/* <i
                    className="fa fa-file-pdf-o doc-icon m-0"
                    aria-hidden="true"
                ></i> */}
                <div className="edit-site-tmplt modal d-flex">
                    {/* <Button
                        className="btn btn-secondary-theme btn-outline small-btn me-1"
                        onClick={async () => {
                            const type = await getType(url);
                            dispatch(showMediaAction({ type: type, src: url }));
                        }}
                    >
                        <EyeIcon fill="#212121" />
                    </Button> */}
                    <Button
                        className="btn btn-secondary-theme btn-outline small-btn"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDownload(url);
                        }}
                    >
                        <DownloadIcon fill="#212121" />
                    </Button>
                    {deleteHandler && (
                        <Button
                            className="btn btn-secondary-theme btn-outline small-btn ms-1"
                            onClick={(e) => {
                                e.stopPropagation();
                                deleteHandler(id);
                            }}
                        >
                            <TrashIcon fill="#212121" />
                        </Button>
                    )}
                </div>
            </div>
            <div className="name">
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id={`tooltip-1`}>{title || getFileNameFromURL(url) || ""}</Tooltip>}
                >
                    <small> {title || getFileNameFromURL(url) || ""} </small>
                </OverlayTrigger>
            </div>
        </div>
    </>)
}
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addTemplate3DataAction } from '../../../ReduxStore/slice/community-site-template/template-3/template-3-slice';
import { SpinnerLoaderFullPage } from '../../../Component/loader/SpinnerLoaderFullPage';
import Section1 from '../../../Component/templates/template3/section1/Section1';
import Section2 from '../../../Component/templates/template3/section2/Sectoin2';
import Section3 from '../../../Component/templates/template3/section3/Section3';
import Section4 from '../../../Component/templates/template3/section4/Section4';
import Section5 from '../../../Component/templates/template3/section5/Section5';
import Section6 from '../../../Component/templates/template3/section6/Section6';
import Section7 from '../../../Component/templates/template3/section7/Section7';
import Section8 from '../../../Component/templates/template3/section8/Section8';

const Template3 = ({ templateData }) => {

    const dispatch = useDispatch();

    const fetch_loading = useSelector(state => state?.template2?.loading?.fetch_data)

    useEffect(() => {
        dispatch(addTemplate3DataAction(templateData))
    }, [dispatch, templateData]);

    return (
        fetch_loading ? (
            <SpinnerLoaderFullPage />
        ) : (
            <div className="wrapper custom-template advanced-template new-advanced-template">
                <Section1 />
                <Section2 />
                <Section3 />
                <Section4 />
                <Section5 />
                <Section6 />
                <Section7 />
                <Section8 />
            </div>
        )
    )
}

export default Template3
import { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Lightbox from "yet-another-react-lightbox";
import { ReactComponent as CopyLinkIcon } from "../../../../../assets/Images/copy-link-icon.svg";
import SkeletonForImg from "../../../../loader/SkeletonForImg";

const PhotoLIstModal = ({ show, handleClose, galleryData }) => {
  const [index, setIndex] = useState(-1);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        className="mid-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-black-theme font-size-18 h5">
            Gallery
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="align-items-center images form-row modal-gallery-list">
            {galleryData?.gallery?.length > 0 ? (
              galleryData?.gallery.map((slide, index) => {
                return (
                  <Col
                    xs={6}
                    md={4}
                    lg={4}
                    className="mb-3"
                    onClick={() => setIndex(index)}
                    style={{ cursor: "pointer" }}
                    key={index}
                  >
                    <div className="media-group-box" key={index}>
                      <div className="group-img mb-0">
                        <SkeletonForImg
                          src={slide?.src}
                          imgClassNames={""}
                          skeletonClassNames={""}
                          imgStyles={{ objectFit: "cover", cursor: "pointer" }}
                        />
                      </div>
                      <div className="edit-site-tmplt modal d-flex gap-1">
                        {slide?.url && (
                          <a
                            className="btn btn-secondary-theme btn-outline small-btn"
                            href={slide.url || ""}
                            target="_blank"
                            rel="noreferrer"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <CopyLinkIcon fill="#212121" />
                          </a>
                        )}
                      </div>
                    </div>
                  </Col>
                );
              })
            ) : (
              <Col md={6} lg={4} className="mb-3" key={index}>
                No images
              </Col>
            )}
          </Row>
        </Modal.Body>
        <Lightbox
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
          slides={galleryData?.gallery}
        />
      </Modal>
    </>
  );
};

export default PhotoLIstModal;

import { createSlice } from "@reduxjs/toolkit";
import { getAllAdminDashboardData, getAllCommunityOwnerDashboardData } from "./system-admin-dashboard-async-thunk";

const initialState = {
  loading: false,
  adminDashboardData: {},
  errors: null,
};

const handleAsyncAction = (builder, asyncAction) => {
  builder
    .addCase(asyncAction.pending, (state, action) => {
      state.loading = true;
      state.errors = null;
    })
    .addCase(asyncAction.fulfilled, (state, action) => {
      state.loading = false;
      state.errors = null;
      state.adminDashboardData = action?.payload;
    })
    .addCase(asyncAction.rejected, (state, action) => {
      state.loading = false;
      state.errors = action.error.message;
      state.adminDashboardData = {};
    });
};

const adminDashboardSlice = createSlice({
  name: "adminDashboardData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    handleAsyncAction(builder, getAllAdminDashboardData);
    handleAsyncAction(builder, getAllCommunityOwnerDashboardData);
    // builder
    //   .addCase(getAllAdminDashboardData.pending, (state, action) => {
    //     state.loading = true;
    //     state.errors = null;
    //   })
    //   .addCase(getAllAdminDashboardData.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.errors = null;
    //     state.adminDashboardData = action?.payload;
    //   })
    //   .addCase(getAllAdminDashboardData.rejected, (state, action) => {
    //     state.loading = false;
    //     state.errors = action.error.message;
    //     state.adminDashboardData = {};
    //   });

  
  }
});

export const actions = adminDashboardSlice.actions;

const adminDashboardReducer = adminDashboardSlice.reducer;
export default adminDashboardReducer;

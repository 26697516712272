import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import './App.scss'

import { NotificationContainer } from 'react-notifications'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'

import { useEffect, useState } from 'react'
import WebFont from 'webfontloader'
import store, { persistor } from '../src/ReduxStore/store'
import InternetConnectionMessage from './Component/CommonComponents/internet-connectivity/InternetConnectionMessage'
import { getToken, messaging } from './Services/firebase-service'
import AppRoute from './routes/AppRoute'
import { googleFontsFamilies } from './utils/constant/googlePhonts'

let App = () => {
  const [isConnected, setIsConnected] = useState(true)

  const requestNotificationPermission = async () => {
    const permission = await Notification.requestPermission()
    if (permission === 'granted') {
      const deviceId = await getToken(messaging)
      localStorage.setItem('deviceId', deviceId)
    }
  }

  useEffect(() => {
    if (localStorage.getItem('deviceId')) return
    requestNotificationPermission()
  }, [])

  useEffect(() => {
    if (googleFontsFamilies?.length > 0) {
      WebFont.load({
        google: {
          families: googleFontsFamilies
        }
      })
    }
  }, [])

  // for checking internet connectivity
  useEffect(() => {
    const handleConnectivityChange = () => {
      setIsConnected(navigator.onLine)
    }

    window.addEventListener('online', handleConnectivityChange)
    window.addEventListener('offline', handleConnectivityChange)

    return () => {
      window.removeEventListener('online', handleConnectivityChange)
      window.removeEventListener('offline', handleConnectivityChange)
    }
  }, [])


  useEffect(() => {
    // Ensure that this useEffect is triggered by a user gesture
    const handleUserGesture = () => {
      requestNotificationPermission();
    };

    // Attach the event listener to a user gesture (e.g., button click)
    window.addEventListener('click', handleUserGesture);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener('click', handleUserGesture);
    };
  }, []);

  //if internet connection is loss then show message
  if (!isConnected) return <InternetConnectionMessage />

  return (
    <Provider store={store}>
      <ToastContainer autoClose={3000} limit={1} />
      <NotificationContainer />
      <PersistGate loading={null} persistor={persistor}>
        <AppRoute />
      </PersistGate>
    </Provider>
  )
}

export default App

import moment from "moment";
import { API } from "../../http-Common";

class AdminDashboardService {
  //  System Amdin Analytical Dashboard Api

  getAllSystemAdminDashboardCount(startDate, endDate) {
    const formateStartDate =
      startDate !== null ? moment(startDate)?.format("YYYY-MM-DD") : "";
    const formateEndDate =
      endDate !== null ? moment(endDate)?.format("YYYY-MM-DD") : "";
    // console.log('formateStartDate',formateStartDate);
    // console.log('formateEndDate',formateEndDate);

    return API.get("/Group/GetAllCountforSystemAdminDatefilter", {
      params: { startDate: formateStartDate, endDate: formateEndDate },
    });
  }

  getAllEventsWithGroups = (searchText, pageIndex, pageSize) => {
    return API.get("/Group/GetAllEventsList", {
      params: { searchText, pageIndex, pageSize },
    });
  };
  getAllLiveStreamsWithGroups = (searchText, pageIndex, pageSize) => {
    return API.get("/Group/GetAllLiveStreamList", {
      params: { searchText, pageIndex, pageSize },
    });
  };

  getAllCommunityChartCount = (duration) => {
    return API.get("/Site/GetAllCountforCommunityChart", {
      params: { duration },
    });
  };
  getAllGroupChartCount = (communityId, duration) => {
    return API.get("/Site/GetAllCountForGroupChart", {
      params: { communityId, duration },
    });
  };
  getAllEventChartCount = (groupId, duration) => {
    return API.get("/Group/GetAllCountForEventChart", {
      params: { groupId, duration },
    });
  };

  getAllNewsFeedChartCount = (groupId, duration) => {
    return API.get("/Group/GetAllCountForNewsFeedChart", {
      params: { groupId, duration },
    });
  };

  getAllDiscussionChartCount = (groupId, duration) => {
    return API.get("/Group/GetAllCountForDiscussionChart", {
      params: { groupId, duration },
    });
  };

  getAllLiveStreamChartCount = (groupId, duration) => {
    return API.get("/Group/GetAllCountForLiveStreamChart", {
      params: { groupId, duration },
    });
  };

  getAllUsersChartCount = (duration) => {
    return API.get("/User/GetAllCountForMembersChart", {
      params: { duration },
    });
  };

  //  Community owner Analytical Dashboard Api

  // getAllCommunityOwnerDashboardCount(data) {
  //   console.log('data56',data);
  //   const formateStartDate =
  //     data?.startDate !== null ? moment(data.startDate)?.format("YYYY-MM-DD") : "";
  //   const formateEndDate =
  //     data?.endDate !== null ? moment(data?.endDate)?.format("YYYY-MM-DD") : "";
  //   console.log('formateStartDate',formateStartDate);
  //   console.log('formateEndDate',formateEndDate);

  //   return API.get("/Group/GetAllCountforSystemAdminDatefilter", {
  //     params: { startDate: formateStartDate, endDate: formateEndDate },
  //   });
  // }

  getAllCommunityOwnerDashboardCount(startDate, endDate, communityId) {
    const formateStartDate =
      startDate !== null ? moment(startDate)?.format("YYYY-MM-DD") : "";
    const formateEndDate =
      endDate !== null ? moment(endDate)?.format("YYYY-MM-DD") : "";

    return API.get("/Group/GetAllCountforCommunnityOwnerDatefilter", {
      params: {
        startDate: formateStartDate,
        endDate: formateEndDate,
        communityId: communityId,
      },
    });
    // return API.get("/Group/GetAllCountforSystemAdminDatefilter", {params:{startDate,endDate}});
  }

  // getAllEventsWithGroups = (searchText, pageIndex, pageSize) => {
  //   return API.get("/Group/GetAllEventsList", {
  //     params: { searchText, pageIndex, pageSize },
  //   });
  // };
  // getAllLiveStreamsWithGroups = (searchText, pageIndex, pageSize) => {
  //   return API.get("/Group/GetAllLiveStreamList", {
  //     params: { searchText, pageIndex, pageSize },
  //   });
  // };

  getAllCommunityOwnerCommunityChartCount = (duration) => {
    return API.get("/Site/GetAllCountforCommunityChart", {
      params: { duration },
    });
  };
  getAllCommunityOwnerGroupChartCount = (communityId, duration) => {
    return API.get("/Site/GetAllCountForCommunityGroupChart", {
      params: { communityId, duration },
    });
  };
  getAllCommunityOwnerEventChartCount = (groupId, duration) => {
    return API.get("/Group/GetAllCountForCommunityEventChart", {
      params: { groupId, duration },
    });
  };

  getAllCommunityOwnerNewsFeedChartCount = (groupId, duration) => {
    return API.get("/Group/GetAllCountForCommunityNewsFeedChart", {
      params: { groupId, duration },
    });
  };

  getAllCommunityOwnerDiscussionChartCount = (groupId, duration) => {
    return API.get("/Group/GetAllCountForCommunityDiscussionChart", {
      params: { groupId, duration },
    });
  };

  getAllCommunityOwnerLiveStreamChartCount = (groupId, duration) => {
    return API.get("/Group/GetAllCountForCommuntiyLiveStreamChart", {
      params: { groupId, duration },
    });
  };

  getAllCommunityOwnerUsersChartCount = (duration) => {
    return API.get("/User/GetAllCountForMembersChart", {
      params: { duration },
    });
  };
}
const adminDashboardService = new AdminDashboardService();
export default adminDashboardService;

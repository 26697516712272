import { createAsyncThunk } from "@reduxjs/toolkit";
import GroupService from "../../../Services/group/GroupService";


export const getAllProfileUserGroupByCommunityIDActionThunk = createAsyncThunk("communty/getProfileUserGroup", async ({ communityID, userID }) => {
    const response = await GroupService.GetAllGroupByCommunityIDUserID(communityID, userID)
    if (Array.isArray(response?.data)) {
        return response?.data;
    } else {
        return []
    }
});
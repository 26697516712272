import { errorToast } from "../../Component/toast/toast";
import GroupService from "../../Services/group/GroupService";
import { getAllCompletedEventListAction, getAllOngoingEventListAction, getAllUpcomingEventListAction, loadedAction, loadingAction } from "./communityAll.actions";

export const getAllUpcomEventsListActionThunk = (groupId) => {
    return (dispatch) => {
        dispatch(loadingAction("evn1"));
        GroupService.getUpcomingEventsList(groupId)
            .then(res => {
                if (res.data.length > 0) {
                    dispatch(getAllUpcomingEventListAction(res.data));
                } else {
                    dispatch(getAllUpcomingEventListAction([]));
                }
                dispatch(loadedAction("evn1"));
            })
            .catch(error => {
                dispatch(loadedAction("evn1"));
                errorToast("Something went wrong.")
            })
    }
};

export const getAllOnGoingEventsListActionThunk = (groupId) => {
    return (dispatch) => {
        dispatch(loadingAction("evn2"));
        GroupService.GetOngoingEventsList(groupId)
            .then(res => {
                if (res.data.length > 0) {
                    dispatch(getAllOngoingEventListAction(res.data));
                } else {
                    dispatch(getAllOngoingEventListAction([]));
                }
                dispatch(loadedAction("evn2"));
            })
            .catch(error => {
                dispatch(loadedAction("evn2"));
                errorToast("Something went wrong.")
            })
    }
};

export const getAllCompletedEventsListActionThunk = (groupId) => {
    return (dispatch) => {
        dispatch(loadingAction("evn3"));
        GroupService.getOutgoingEventsList(groupId)
            .then(res => {
                if (res.data.length > 0) {
                    dispatch(getAllCompletedEventListAction(res.data));
                } else {
                    dispatch(getAllCompletedEventListAction([]));
                }
                dispatch(loadedAction("evn3"));
            })
            .catch(error => {
                dispatch(loadedAction("evn3"));
                errorToast("Something went wrong.")
            })
    }
};


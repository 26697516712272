import { useState, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import CombineCropperModal from "../../../../image-cropper/CombineCropperModal";
import {
  closeTemplate3SectionEditorAction,
  saveTemplate3SectionBySectionIDAction,
  setPreviewOfTemplate3Action,
} from "../../../../../ReduxStore/slice/community-site-template/template-3/template-3-slice";
import { updateSectionOfTemplate3BySiteIDActionThunk } from "../../../../../ReduxStore/slice/community-site-template/template-3/template-3-async-thynk";
import { errorToast } from "../../../../toast/toast";
import { Slider } from "@mui/material";
import { getTrackBackground } from "react-range";

const EditModal = ({ data, setData }) => {
  const dispatch = useDispatch();

  const siteID = useSelector((state) => state?.template3?.siteID);
  const save_btn_loading = useSelector(
    (state) => state?.template3?.loading?.section7
  );
  const show = useSelector((state) => {
    const is_Editable = state?.template3?.is_Editable;
    return is_Editable?.status && is_Editable?.current_edit_section === 7;
  });

  const [showImageCroperModal, setShowImageCroperModal] = useState({
    show: false,
    whichImage: "",
  });

  const [src, setSrc] = useState({ image: null, fileName: "" });

  const STEP = 0.1;
  const MIN = 0;
  const MAX = 1;

  const [rangeValue, setRangeValues] = useState(data?.BackgroundOpacity);

  const handleRangeChange = (value) => {
    console.log(value.target.value);
    setRangeValues(value.target.value);

    console.log(rangeValue);

    setData((prevData) => ({
      ...prevData,
      BackgroundOpacity: value.target.value,
    }));

    console.log(data);
    // data.BackgroundOpacity = value;
  };

  // const [showImageCroperModal, setShowImageCroperModal] = useState(false)
  // const [src, setSrc] = useState({ image: null, fileName: "" });

  /**
   * close image crop modal and set the selected image in setBackroundImage props
   * @param {*} blob
   */
  const handleCloseImageCropModal = async (fileUrl) => {
    setShowImageCroperModal((prev) => ({ ...prev, show: false }));
    if (fileUrl) {
      if (showImageCroperModal?.whichImage === "backgorundImage") {
        setData((prevData) => ({ ...prevData, BackgroundImage: fileUrl }));
        setData((prevData) => ({
          ...prevData,
          Section7BackgroundVisible: true,
        }));
      } else if (showImageCroperModal?.whichImage === "roundImage") {
        setData((prevData) => ({ ...prevData, RoundImage: fileUrl }));
      } else {
        errorToast("Something went wrong");
      }
    }
  };

  const fileInput = useRef(null);

  const onRemoveImageClick = (event) => {
    setData((prevData) => ({
      ...prevData,
      Section7BackgroundVisible: false,
    }));
    console.log(fileInput);
    fileInput.current.value = "";
    console.log(data);
  };

  /**
   * image input field onChange handler
   */
  const changeHandlerImage = (event, whichImage) => {
    // setSrc({ image: URL.createObjectURL(event.target.files[0]), fileName: event.target.files[0]?.name, fileType: event.target.files[0]?.type });
    // setShowImageCroperModal(true);
    // //event.target.value = null;

    setSrc({
      image: URL.createObjectURL(event.target.files[0]),
      fileName: event.target.files[0]?.name,
    });
    setShowImageCroperModal({ show: true, whichImage: whichImage });
    //event.target.value = null;
  };

  /**
   * edit modal close handler
   */
  const editModalCloseHandler = () => {
    dispatch(closeTemplate3SectionEditorAction({ sectionID: 7, data }));
  };

  /**
   * preview button handler
   */
  const previewButtonHandler = () => {
    dispatch(saveTemplate3SectionBySectionIDAction({ sectionID: 7, data }));
    dispatch(setPreviewOfTemplate3Action());
  };

  /**
   * Save Section Data
   */
  const saveSectionDataHandler = () => {
    dispatch(
      updateSectionOfTemplate3BySiteIDActionThunk({
        siteID,
        sectionID: 7,
        data,
      })
    );
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={editModalCloseHandler}
        placement="end"
        scroll={true}
      >
        <Offcanvas.Header closeButton className="border-bottom">
          <Offcanvas.Title>Edit</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="d-flex flex-column">
          <Form className="d-flex flex-column flex-fill">
            <Form.Group className="mb-3" controlId="plainText">
              <Form.Label>Heading</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter heading"
                value={data?.Heading}
                onChange={(e) =>
                  setData((prevData) => ({
                    ...prevData,
                    Heading: e.target?.value,
                  }))
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Content (Max limit is 150 character)</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={data?.Content}
                onChange={(e) =>
                  setData((prevData) => ({
                    ...prevData,
                    Content: e?.target?.value?.substring(0, 150),
                  }))
                }
              />
              <span>{data?.Content?.length} character</span>
            </Form.Group>
            <Form.Group className="mb-4" controlId="plainText">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={(event) => changeHandlerImage(event, "roundImage")}
              />
            </Form.Group>
            <Form.Group className="mb-4" controlId="plainText">
              <Form.Label>Background Image</Form.Label>
              <div style={{ display: "flex" }}>
                <Form.Control
                  ref={fileInput}
                  type="file"
                  accept="image/*"
                  onChange={(event) =>
                    changeHandlerImage(event, "backgorundImage")
                  }
                />
                <Button
                  style={{ marginLeft: "10px" }}
                  className="btn-primary-theme font-size-14"
                  onClick={(event) => onRemoveImageClick(event)}
                >
                  Remove
                </Button>
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label>Background Shadow</Form.Label>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  marginBottom: "2em",
                  marginLeft: "1em",
                  marginRight: "1em",
                }}
              >
                <Slider
                  // valueLabelDisplay={"auto"}
                  step={STEP}
                  min={MIN}
                  max={MAX}
                  value={data?.BackgroundOpacity}
                  onChange={(newValues) => handleRangeChange(newValues)}
                  renderTrack={({ props, children }) => (
                    <div
                      onMouseDown={props.onMouseDown}
                      onTouchStart={props.onTouchStart}
                      style={{
                        ...props.style,
                        height: "36px",
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <div
                        ref={props.ref}
                        style={{
                          height: "5px",
                          width: "100%",
                          borderRadius: "4px",
                          background: getTrackBackground({
                            values: rangeValue,
                            colors: ["#548BF4", "#ccc"],
                            min: MIN,
                            max: MAX,
                          }),
                          alignSelf: "center",
                        }}
                      >
                        {children}
                      </div>
                    </div>
                  )}
                  renderThumb={({ props, isDragged }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        height: "42px",
                        width: "42px",
                        borderRadius: "4px",
                        backgroundColor: "#FFF",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0px 2px 6px #AAA",
                      }}
                    >
                      <div
                        style={{
                          height: "16px",
                          width: "5px",
                          backgroundColor: isDragged ? "#548BF4" : "#CCC",
                        }}
                      />
                    </div>
                  )}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Background color</Form.Label>
              <Form.Control
                type="color"
                value={data?.Section7BackgroundColor}
                onChange={(e) =>
                  setData((prevData) => ({
                    ...prevData,
                    Section7BackgroundColor: e.target?.value,
                  }))
                }
              />
            </Form.Group>
            <div className="pt-3 d-flex justify-content-end border-top mt-auto">
              <Button
                className="btn-secondary-theme btn-outline me-2"
                onClick={() => previewButtonHandler()}
              >
                Preview
              </Button>
              <Button
                className="btn-primary-theme font-size-14"
                onClick={() => saveSectionDataHandler()}
                disabled={save_btn_loading}
              >
                Save{" "}
                {save_btn_loading && (
                  <i className="fa fa-spinner fa-spin ms-2"></i>
                )}
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>

      {showImageCroperModal?.show &&
        (showImageCroperModal?.whichImage === "backgorundImage" ? (
          <CombineCropperModal
            show={showImageCroperModal.show}
            handleClose={handleCloseImageCropModal}
            src={src}
            // aspect={1 / 1}
            // spectString="1 / 1"
            aspect={16 / 9}
            spectString="16 / 9"
            canChangeAspect={true}
            cropRation={{
              unit: "%",
              x: 0,
              y: 0,
              height: 100,
              width: 100,
            }}
          />
        ) : (
          <CombineCropperModal
            show={showImageCroperModal.show}
            handleClose={handleCloseImageCropModal}
            src={src}
            aspect={1 / 1}
            spectString="1 / 1"
            canChangeAspect={true}
            cropRation={{
              unit: "%",
              x: 0,
              y: 0,
              height: 100,
              width: 100,
            }}
          />
        ))}
    </>
  );
};

export default EditModal;

import { createSlice } from "@reduxjs/toolkit";
import {
  UpdateCommunityEcommerceStoreStatusActionThunk,
  getAlleCommerceStore,
} from "./e-commerce-store-async-thunk";

const initialState = {
  loading: {
    get_e_commerce_list: false,
    e_commerce_store_status: { status: false, communityId: "" },
  },
  e_commerce_list: [],

  errors: {
    get_e_commerce_list: null,
    e_commerce_store_status: null,
  },
};

const eCommerceStoreSlice = createSlice({
  name: "eCommerceStore",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAlleCommerceStore.pending, (state, action) => {
        state.loading.get_e_commerce_list = true;
        state.errors.get_e_commerce_list = null;
      })
      .addCase(getAlleCommerceStore.fulfilled, (state, action) => {
        state.loading.get_e_commerce_list = false;
        state.errors.get_e_commerce_list = null;
        state.e_commerce_list = action?.payload;
      })
      .addCase(getAlleCommerceStore.rejected, (state, action) => {
        state.loading.get_e_commerce_list = false;
        state.errors.get_e_commerce_list = action.error.message;
        state.e_commerce_list = [];
      })

      //update community E commerce Store  status
      .addCase(
        UpdateCommunityEcommerceStoreStatusActionThunk.pending,
        (state, action) => {
          state.loading.e_commerce_store_status = {
            communityId: action?.meta?.arg?.communityId,
            status: true,
          };
          state.errors.e_commerce_store_status = null;
        }
      )
      .addCase(
        UpdateCommunityEcommerceStoreStatusActionThunk.fulfilled,
        (state, action) => {
          state.loading.e_commerce_store_status = {
            communityId: "",
            status: false,
          };
          state.errors.e_commerce_store_status = null;
          const index = state?.e_commerce_list?.findIndex(
            (community) =>
              community?.communityId === action?.payload?.data?.communityId
          );
          if (index > -1) {
            state.e_commerce_list[index].isStoreEnable =
              action?.meta?.arg?.storeStatus;
          }
        }
      )
      .addCase(
        UpdateCommunityEcommerceStoreStatusActionThunk.rejected,
        (state, action) => {
          state.loading.e_commerce_store_status = {
            communityId: "",
            status: false,
          };
          state.errors.e_commerce_store_status = action?.error?.message;
        }
      );
  },
});

export const actions = eCommerceStoreSlice.actions;

const eCommerceStoreReducer = eCommerceStoreSlice.reducer;
export default eCommerceStoreReducer;

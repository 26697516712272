import { initializeApp } from 'firebase/app'
import { getMessaging, getToken } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyBJ-lUZDNLtE8CO39WLVKvUAd9v00zScS4',
  authDomain: 'combo-77d22.firebaseapp.com',
  projectId: 'combo-77d22',
  storageBucket: 'combo-77d22.appspot.com',
  messagingSenderId: '705490124937',
  appId: '1:705490124937:web:54c1c005ea35b33867c658',
  measurementId: 'G-3YGCWZGWEJ'
}

const app = initializeApp(firebaseConfig)

const messaging = getMessaging(app)

export { messaging, getToken }

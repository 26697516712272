// Function to create a dynamic PWA manifest
export const CreateDynamicPWAManifest = (name, logo, bgColor, themeColor, startUrl) => {
    // Construct a dynamic manifest for Progressive Web App (PWA)
    var myDynamicManifest = {
        // Set the name of the app as well as the short name (displayed on homescreen)
        "name": name,
        "short_name": name,
        // Specify the background color of the app
        "background_color": bgColor,
        // Define the display mode of the PWA (standalone means it runs as a standalone app)
        "display": "standalone",
        // Define the start URL when the app is launched
        "start_url": startUrl ?? window?.location?.href,
        // Specify the theme color that affects the browser's UI when the app is launched
        "theme_color": themeColor,
        // Define an array of icons in different sizes and types
        "icons": [
            {
                "src": logo,
                "sizes": "192x192",
                "type": "image/png"
            },
            {
                "src": logo,
                "sizes": "256x256",
                "type": "image/png"
            },
            {
                "src": logo,
                "sizes": "384x384",
                "type": "image/png"
            },
            {
                "src": logo,
                "sizes": "512x512",
                "type": "image/png"
            },
            {
                "src": logo,
                "sizes": "196x196",
                "type": "image/png",
                "purpose": "maskable"
            }
        ]
    };
    // Convert the manifest object to a JSON string
    const stringManifest = JSON.stringify(myDynamicManifest);
    // Create a Blob (Binary Large Object) containing the JSON string
    const blob = new Blob([stringManifest], { type: 'application/json' });
    // Generate a URL for the Blob object
    const manifestURL = URL.createObjectURL(blob);
    // Set the "href" attribute of the element with ID "my-manifest-placeholder" to the generated manifest URL
    // document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);

    const existingManifest = document.querySelector("link[rel='manifest']");
    if (existingManifest) {
        existingManifest.href = manifestURL;
    } else {
        const link = document.createElement("link");
        link.rel = "manifest";
        link.href = manifestURL;
        document.head.appendChild(link);
    }

}
import { GET_SIDEBAR_LIST_INITIAL_FAIL, GET_SIDEBAR_LIST_MORE_FAIL, GET_SIDEBAR_LIST_MORE_PENDING, GET_SIDEBAR_LIST_MORE_SUCCESS, GET_SIDEBAR_LIST_PAGINATION, GET_SIDEBAR_LIST_INITIAL_PENDING, GET_SIDEBAR_LIST_INITIAL_SUCCESS, SET_CURRENT_CHATBOARD } from "./groupMessage.constant";




export const getSidebarListInitialPendingActionCreator = () => ({ type: GET_SIDEBAR_LIST_INITIAL_PENDING })

export const getSidebarListInitialSuccessActionCreator = (list) => ({ type: GET_SIDEBAR_LIST_INITIAL_SUCCESS, payload: list });

export const getSidebarListInitialFailActionCreator = () => ({ type: GET_SIDEBAR_LIST_INITIAL_FAIL });


export const getSidebarListMorePendingActionCreator = () => ({ type: GET_SIDEBAR_LIST_MORE_PENDING })

export const getSidebarListMoreSuccessActionCreator = (list) => ({ type: GET_SIDEBAR_LIST_MORE_SUCCESS, payload: list });

export const getSidebarListMoreFailActionCreator = () => ({ type: GET_SIDEBAR_LIST_MORE_FAIL });

export const setCurrentChatBoardActionCreator = (chatBoardObj) => ({ type: SET_CURRENT_CHATBOARD, payload: chatBoardObj })

export const getSidebarListPaginationActionCreator = (pagination) => ({ type: GET_SIDEBAR_LIST_PAGINATION, payload: pagination });
import { Button, Dropdown } from 'react-bootstrap';
import { addToGoogle, generateICSFile } from '../../utils/helpers/exportEvent';

const AddToCalendar = ({ event }) => {
    return (
        <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className="btn-primary-theme btn-outline">
                Add to calendar
            </Dropdown.Toggle>
            <Dropdown.Menu className='group-dropdown-menu w-175'>
                <div className="arrow-up"></div>
                <ul className='list-unstyled m-0'>
                    <li>
                        <Button variant='link' className='text-start' onClick={() => addToGoogle(event)}>
                            <i className="fa fa-google fa-fw me-1"></i> Google
                        </Button>
                    </li>
                    <li>
                        <Button variant='link' className='text-start' onClick={() => generateICSFile(event)}>
                            <i className="fa fa-envelope fa-fw me-1"></i> Outlook
                        </Button>
                    </li>
                    <li>
                        <Button variant='link' className='text-start' onClick={() => generateICSFile(event)}>
                            <i className="fa fa-apple fa-fw me-1"></i> Apple
                        </Button>
                    </li>
                </ul>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default AddToCalendar
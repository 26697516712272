import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

import { Container } from "react-bootstrap";
import { createRef, useEffect} from 'react';

import { SpinnerLoader } from '../../../loader/SpinnerLoader';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCompletedEventsListActionThunk, getAllOnGoingEventsListActionThunk, getAllUpcomEventsListActionThunk } from '../../../../ReduxStore/communityAll/communityAll.actions.async';
import { useLocation, useNavigate } from 'react-router';


const FullCalendarComp = ({ groupID, eventTypeId }) => {

    const dispatch = useDispatch();
    const calendarRef = createRef();
    const location = useLocation();
    const navigate = useNavigate();

    const isAuthenticated = useSelector((state) => state.auth?.token);
    const upComingEvents = useSelector(state => state?.communityAll?.eventsAll?.upComing);
    const onGoingEvents = useSelector(state => state?.communityAll?.eventsAll?.onGoing);
    const completedEvents = useSelector(state => state?.communityAll?.eventsAll?.completed);
    const loading = useSelector(state => state?.communityAll?.eventsAll?.loading?.upComing || state?.communityAll?.eventsAll?.loading?.onGoing || state?.communityAll?.eventsAll?.loading?.completed);


    // handle event click
    const showEventDetials = (clickInfo) => {
        if ((isAuthenticated) && (eventTypeId ? eventTypeId !== 3 : true)) {
            clickInfo = clickInfo.event.toPlainObject();
            navigate(location?.pathname, { state: { ...location?.state, showEvent: true, eventId: clickInfo?.extendedProps?.eventID, groupId: groupID } });
        }
    };

    useEffect(() => {
        eventTypeId === 1 ?
            dispatch(getAllUpcomEventsListActionThunk(groupID)) :
            eventTypeId === 2 ?
                dispatch(getAllOnGoingEventsListActionThunk(groupID)) :
                eventTypeId === 3 ?
                    dispatch(getAllCompletedEventsListActionThunk(groupID)) :
                    dispatch(getAllUpcomEventsListActionThunk(groupID))

    }, [dispatch, eventTypeId, groupID]);

    return (
        <>
            <section className='bg-white'>
                {loading?.upComingEvents ? (
                    <SpinnerLoader />
                ) : (
                    <Container>
                        <div className='full-calendar'>
                            <FullCalendar
                                ref={calendarRef}
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                headerToolbar={{
                                    left: 'prev,next today',
                                    center: 'title',
                                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                                }}
                                initialView='dayGridMonth'
                                editable={false}
                                selectable={false}
                                selectMirror={true}
                                dayMaxEvents={true}
                                weekends={true}
                                events={eventTypeId === 1 ? upComingEvents : eventTypeId === 2 ? onGoingEvents : eventTypeId === 3 ? completedEvents : upComingEvents} // alternatively, use the `events` setting to fetch from a feed
                                // select={handleDateSelect}
                                // eventContent={renderEventContent} // custom render function
                                eventClick={showEventDetials}
                                // eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                                // you can update a remote database when these fire:
                                // eventAdd={handleEventAdd}
                                // eventChange={handleEventChange}
                                eventRemove={function (e) { console.log("eventRemove", e) }}
                            />
                        </div>
                    </Container>)
                }
            </section>
        </>
    )
}

export default FullCalendarComp
import MessageCard from './message-card/MessageCard'

const MessageList = ({ dataList, extraData }) => {



    return (
        <>
            {dataList?.map((message, index) => (
                    <MessageCard
                        key={index}
                        receiverData={extraData}
                        message={message}
                    />
            ))
            }
        </>
    )
}

export default MessageList
import { createAsyncThunk } from "@reduxjs/toolkit";
import AdminDashboardService from "../../../Services/admin-dashboard/AdminDashboardService";

export const getAllAdminDashboardData = createAsyncThunk(
    "system-admin/getAllAdminDashboardData",
    async ({startDate,endDate}) => {
      const response = await AdminDashboardService.getAllSystemAdminDashboardCount(startDate,endDate);
  
      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      } else {
        return response?.data;
      }
    }
  );

  export const getAllCommunityOwnerDashboardData = createAsyncThunk(
    "system-admin/getAllCommunityOwnerDashboardData",
    async ({startDate,endDate, communityId}) => {
      const response = await AdminDashboardService.getAllCommunityOwnerDashboardCount(startDate,endDate, communityId);
  console.log('response',response);
      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      } else {
        return response?.data;
      }
    }
  );

  // export const getAllAdminDashboardData = createAsyncThunk(
  //   "system-admin/getAllAdminDashboardData",
  //   async () => {
  //     console.log('5656');
  //     const response = await AdminDashboardService.getAllDataCount();
  
  //     if (response?.status !== 200) {
  //       throw new Error(response?.data?.message);
  //     } else {
  //       return response?.data;
  //     }
  //   }
  // );


import { createAsyncThunk } from "@reduxjs/toolkit";
import CommunityService from "../../../../Services/community/CommunityService";

/**
 * get all seciton of template by siteID
 * @param {*} siteID 
 * @returns 
 */
export const getTemplate1BySiteIDActionThunk = createAsyncThunk("template_1/getTemplate1DataBySiteId", async (siteID) => {
    const response = await CommunityService.GetAllTemplateSectionsBySiteID(siteID);
    if (response?.status !== 200) {
        throw new Error(response?.data?.message);
    } else {
        return response?.data;
    }
});

/**
 * update template section
 * @param {*} valuetemplateSectionDataIDs 
 * @param {*} templateID 
 * @param {*} sectionID 
 * @param {*} siteID 
 * @param {*} json 
 * @returns 
 */
export const updateSectionOfTemplate1BySiteIDActionThunk = createAsyncThunk("template_1/updateSectionOfTemplate1BySiteID", async ({ siteID, sectionID, data }) => {
    const response = await CommunityService.UpdateTemplateSectionBySiteID(siteID, sectionID, 1, JSON.stringify(data))
    return response?.data;
});

/**
 * update all section of template
 * @returns 
 */
export const updateAllTemplate1SectionsBySiteIDActionThunk = createAsyncThunk("template_1/updateAllTemplate1SectionsBySiteID", async (param1, { getState }) => {
    const jsonSectionList = [];
    let template1 = getState()?.template1?.template_1_sections;
    for (const key in template1) {
        jsonSectionList.push(JSON.stringify(template1[key]));
    }
    const data = { siteID: getState()?.template1?.siteID, templateID: 1, jsonSectionList }
    const response = await CommunityService.UpdateAllTemplateSectionBySiteID(data);
    return response?.data;
});
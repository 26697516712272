import { Button, Form } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useDispatch, useSelector } from "react-redux";
import {
  closeTemplate3SectionEditorAction,
  saveTemplate3SectionBySectionIDAction,
  setPreviewOfTemplate3Action,
} from "../../../../../ReduxStore/slice/community-site-template/template-3/template-3-slice";
import { updateSectionOfTemplate3BySiteIDActionThunk } from "../../../../../ReduxStore/slice/community-site-template/template-3/template-3-async-thynk";
import { Switch } from "@mui/material";

const EditModal = ({ data, setData }) => {
  const dispatch = useDispatch();

  const siteID = useSelector((state) => state?.template3?.siteID);
  const save_btn_loading = useSelector(
    (state) => state?.template3?.loading?.section8
  );
  const show = useSelector((state) => {
    const is_Editable = state?.template3?.is_Editable;
    return is_Editable?.status && is_Editable?.current_edit_section === 8;
  });

  /**
   * edit modal close handler
   */
  const editModalCloseHandler = () => {
    dispatch(closeTemplate3SectionEditorAction({ sectionID: 8, data }));
  };

  /**
   * preview button handler
   */
  const previewButtonHandler = () => {
    dispatch(saveTemplate3SectionBySectionIDAction({ sectionID: 8, data }));
    dispatch(setPreviewOfTemplate3Action());
  };

  /**
   * Save Section Data
   */
  const saveSectionDataHandler = () => {
    dispatch(
      updateSectionOfTemplate3BySiteIDActionThunk({
        siteID,
        sectionID: 8,
        data,
      })
    );
  };

  const handleLogoVisibleChange = (value) => {
    console.log(value);

    setData((prevData) => ({
      ...prevData,
      IsLogoVisible: value.target.checked,
    }));

    // data.BackgroundOpacity = value;
  };

  return (
    <>
      <Offcanvas show={show} onHide={editModalCloseHandler} placement="end">
        <Offcanvas.Header closeButton className="border-bottom">
          <Offcanvas.Title>Edit</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="d-flex flex-column">
          <Form className="d-flex flex-column flex-fill">
            <Form.Group>
              <Form.Label>Brand Logo Visibility</Form.Label>
              <br></br>
              <Switch
                checked={
                  typeof data?.IsLogoVisible === "undefined"
                    ? true
                    : data?.IsLogoVisible
                }
                onChange={(value) => handleLogoVisibleChange(value)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="plainText">
              <Form.Label>Powered By</Form.Label>
              <Form.Control
                placeholder="Enter powered by"
                type="text"
                value={data?.PoweredBy}
                onChange={(e) =>
                  setData((prevData) => ({
                    ...prevData,
                    PoweredBy: e.target?.value,
                  }))
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicLink">
              <Form.Label>Facebook</Form.Label>
              <Form.Control
                placeholder="Enter facebook URL"
                type="url"
                value={data?.Facebook}
                onChange={(e) =>
                  setData((prevData) => ({
                    ...prevData,
                    Facebook: e.target?.value,
                  }))
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicLink">
              <Form.Label>Twitter</Form.Label>
              <Form.Control
                placeholder="Enter twitter URL"
                type="url"
                value={data?.Twitter}
                onChange={(e) =>
                  setData((prevData) => ({
                    ...prevData,
                    Twitter: e.target?.value,
                  }))
                }
              />
            </Form.Group>
            <Form.Group className="mb-4" controlId="formBasicLink">
              <Form.Label>LinkedIN</Form.Label>
              <Form.Control
                placeholder="Enter linkedIn URL"
                type="url"
                value={data?.LinkedIN}
                onChange={(e) =>
                  setData((prevData) => ({
                    ...prevData,
                    LinkedIN: e.target?.value,
                  }))
                }
              />
            </Form.Group>
            <div className="pt-3 d-flex justify-content-end border-top mt-auto">
              <Button
                onClick={() => previewButtonHandler()}
                className="btn-secondary-theme btn-outline me-2"
              >
                Preview
              </Button>
              <Button
                onClick={() => saveSectionDataHandler()}
                className="btn-primary-theme font-size-14"
                disabled={save_btn_loading}
              >
                Save{" "}
                {save_btn_loading && (
                  <i className="fa fa-spinner fa-spin ms-2"></i>
                )}
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default EditModal;

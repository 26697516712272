import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { publicRoutes } from './routes';

const PublicRoute = ({ children }) => {
    const isAuthenticated = useSelector((state) => state.auth?.token);
    const location = useLocation();

    // Check if the current route path is in the publicRoutes array
    const isPublicRoute = publicRoutes?.some((route) =>
        location.pathname.startsWith(route.path)
    );

    if (isAuthenticated && isPublicRoute) {
        return <Navigate to="/admin-tools" state={{ from: location }} replace />;
    }

    return children;
};

export default PublicRoute;

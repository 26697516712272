import { API } from "../../http-Common";

class CommunityService {
  //get first level lookup data (create community)
  getAllFirstLevelLookUpData() {
    return API.get("/Site/GetAllFirstLevelLookUpData");
  }

  //get all second level lookup data (create community)
  getAllSecondLevelLookUpData() {
    return API.get("/Site/GetAllSecondLevelLookUpData");
  }

  getAllCommunityData(userID) {
    return API.get("/Site/GetAllCommunityData", {
      params: { userID: userID ? userID : undefined },
    });
  }

  communitySiteSetUp(data) {
    return API.post("/Site/CommunitySiteSetUp", { ...data });
  }

  getUserCommunityByUserID(
    userID,
    published,
    categoryOne,
    categoryTwo,
    searchText
  ) {
    return API.get("/Site/GetUserCommunityByUserID", {
      params: { userID, published, categoryOne, categoryTwo, searchText },
    });
  }

  getAllDefaultSectionsTemplate() {
    return API.get("/Template/GetAllDefaultSectionsTemplate");
  }

  addCommunitySiteSection(data) {
    return API.post("/Site/AddCommunitySiteSection", data);
  }

  updateCommunitySiteSection(data) {
    return API.put("/Site/UpdateCommunitySiteSection", data);
  }

  getCommunitySiteSection(siteID) {
    return API.get(`/Site/GetCommunitySiteSection?siteID=${siteID}`);
  }

  getAllCommunitySiteDetailsByUserID(userID, siteID) {
    return API.get(
      `/Site/GetAllCommunitySiteDetailsByUserID?userID=${userID}&siteID=${siteID}`
    );
  }

  //create the new community header into the site database
  communityHeader(data, config) {
    return API.post("/Site/CommunityHeader", data, config);
  }

  //fetching the existing community header details about the logged-in user from the site database
  getAllCommunityHeaderDetails(userID, siteID) {
    return API.get(
      `/Site/getAllCommunityHeaderDetails?userID=${userID}&siteID=${siteID}`
    );
  }

  //update the existing community details into the database
  changeCommunityHeader(data, config) {
    return API.put("/Site/ChangeCommunityHeader", data, config);
  }

  //update the existing community details into the database
  getCommunitySiteDetailsBySiteID(sideID) {
    return API.get(`/Site/GetCommunitySiteDetailsBySiteID?siteID=${sideID}`);
  }

  //find is community site name and domain is exit or not
  isExistCommunitySiteName(SiteName, SiteDomain) {
    return API.get(
      `/Site/IsExistCommunitySiteName?communitySiteName=${SiteName}&domain=${SiteDomain}`
    );
  }

  //find is community site name and domain is exit or not
  isExistCommunitySiteDomain(SiteName, SiteDomain) {
    return API.get(
      `/Site/IsExistCommunitySiteDomain?communitySiteDomain=${SiteName}&name=${SiteDomain}`
    );
  }

  //find is community site name and domain is exit or not
  updateCommunitySiteDomain(SiteDomain, CommunityId) {
    return API.get(
      `/Site/UpdateCommunitySiteDomain?domain=${SiteDomain}&communityId=${CommunityId}`
    );
  }

  updateCommunitySiteName(SiteName, CommunityId) {
    return API.get(
      `/Site/UpdateCommunitySiteName?name=${SiteName}&communityId=${CommunityId}`
    );
  }

  //update the existing community details into the database
  getUserCommunityCount(userID) {
    return API.get(`/Site/GetUserCommunityCount?userID=${userID}`);
  }

  updateSitePublishStatus(siteID, publishStatus) {
    return API.put(
      `/Site/UpdateSitePublishStatus?siteID=${siteID}&publishStatus=${publishStatus}`
    );
  }

  getCommunitySiteSectionByDomain(domainName) {
    return API.get(
      `Site/GetCommunitySiteSectionByDomain?domainName=${domainName}`
    );
  }
  AddCommunityAdminDetails(data, config) {
    return API.post("/Site/AddCommunityAdminDetails", data, config);
  }

  GetAllCommunityAdminList(CommunityId, pageIndex, pageSize, nameSearched) {
    return API.get("Site/GetAllCommunityAdminList", {
      params: { CommunityId, pageIndex, pageSize, nameSearched },
    });
  }

  DeleteCommuinty(SiteID) {
    return API.delete(`Site/DeleteCommuinty?siteID=${SiteID}`);
  }

  /**
   * get template with all section by site id
   * @param {*} siteID
   * @returns
   */
  GetAllTemplateSectionsBySiteID(siteID) {
    return API.get("/Template/GetAllTemplateSectionsBySiteID", {
      params: { siteID },
    });
  }

  GetAllTemplateSectionsByDomainName(domainName) {
    return API.get("Template/GetAllTemplateSectionsByDomainName", {
      params: { domainName },
    });
  }

  GetTemplateSectionBySiteID = (siteID, sectionID) => {
    return API.get("/Template/GetTemplateSectionBySiteID", {
      params: { siteID, sectionID },
    });
  };

  UpdateTemplateSectionBySiteID = (siteID, sectionID, templateID, json) => {
    return API.post("/Template/UpdateTemplateSectionBySiteID", {
      siteID,
      sectionID,
      templateID,
      json,
    });
  };

  GetCommunityUserListBySiteID = (
    siteId,
    nameSearched,
    pageIndex,
    pageSize,
    noAdmin
  ) => {
    return API.get("Site/GetCommunityUserListBySiteID", {
      params: { siteId, nameSearched, pageIndex, pageSize, noAdmin },
    });
  };
  UpdateAllTemplateSectionBySiteID = (data) => {
    return API.post("Template/UpdateAllTemplateSectionBySiteID", data);
  };
  GetMemberShipByUserID = (userId) => {
    return API.get(`/MemberShip/GetMemberShipByUserID?userId=${userId}`);
  };

  GetSnapShotOfTemplate = (typeID, templateID) => {
    return API.get("Site/GetSnapShotOfTemplate", {
      params: { typeID, templateID },
    });
  };
  /**
   * Wizard flow community api
   */
  GetUserCommunityAdminCount = (communityId) => {
    return API.get(
      `/Site/GetUserCommunityAdminCount?communityID=${communityId}`
    );
  };

  GetAllCommunityDataByUserID = (userID) => {
    return API.get("/Site/GetAllCommunityDataByUserID", { params: { userID } });
  };

  MakeCommunityAdmin = (communityMemberID) => {
    return API.put("/Site/MakeCommunityAdmin", null, {
      params: { communityMemberID },
    });
  };

  //dismiss community admin from community
  dismissFromCommunityAdmin = (communityMemberID) => {
    return API.put("Site/DismissFromCommunityAdmin", null, {
      params: { communityMemberID },
    });
  };

  //remove member from community
  removeMemberFromCommunity = (communityMemberID) => {
    return API.put("/Site/RemoveUserFromCommunity", null, {
      params: { communityMemberID },
    });
  };

  //invite community adminb
  inviteCommunityAdmin(
    communityID,
    userIdList,
    userEmailList,
    url,
    type,
    invitedBy,
    communityName
  ) {
    return API.post("/Site/InviteCommunityAdmin", {
      communityID,
      userIdList,
      userEmailList,
      url,
      type,
      invitedBy,
      communityName,
    });
  }

  getTemplateByTemplateId(templateID) {
    return API.get("Site/GetTemplateByTemplateId", { params: { templateID } });
  }

  communityEcommerce = (communityId) => {
    return API.get("Site/CommunityEcommerce", { params: { communityId } });
  };

  updateCommunityEcommerce = (communityId, isEnable) => {
    return API.post("Site/UpdateCommunityEcommerce", null, {
      params: { communityId, isEnable },
    });
  };

  getAllUserCommunity = (searchText, pageIndex, pageSize) => {
    return API.get("Site/GetAllUserCommunity", {
      params: { searchText, pageIndex, pageSize },
    });
  };
}
const communityService = new CommunityService();
export default communityService;

import { toast } from "react-toastify";

const errorToast = (msg) => {
  toast.clearWaitingQueue();
  toast.error(msg);
};
const successToast = (msg) => {
  toast.clearWaitingQueue();
  toast.success(msg)

};
const warningToast = (msg) => {
  toast.clearWaitingQueue();
  toast.warning(msg)

};

export { errorToast, successToast, warningToast };
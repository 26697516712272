import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import PhotoLIstModal from "./Modal/PhotoLIstModal";
import SkeletonForImg from "../../../loader/SkeletonForImg";

const PhotoGallery = ({ folder }) => {
  const [galleryData, setGalleryData] = useState({ status: false });

  const openPhotoListModal = (gallery) => {
    setGalleryData({ ...gallery, status: true });
  };

  const closePhotoListModal = () => {
    setGalleryData({ status: false });
  };

  return (
    <>
      <Row className="justify-content-center align-items-center mb-4 mb-lg-5 images small-gap">
        {folder?.length > 0 &&
          folder.map((gallery, index) => {
            return (
              <Col xs={6} lg={4} className="mb-3 mb-md-4" key={index}>
                <div
                  className="single"
                  key={index}
                  onClick={() => openPhotoListModal(gallery)}
                >
                  <SkeletonForImg
                    src={gallery?.src}
                    imgClassNames=""
                    skeletonClassNames="abc"
                    imgStyles={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    alt=""
                  />
                </div>
              </Col>
            );
          })}
      </Row>
      {galleryData?.status && (
        <PhotoLIstModal
          show={galleryData?.status}
          handleClose={closePhotoListModal}
          galleryData={galleryData}
        />
      )}
    </>
  );
};

export default PhotoGallery;

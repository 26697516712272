import { Col, Modal, Row, } from "react-bootstrap"
import GroupService from "../../../Services/group/GroupService.js";
import { useEffect, useState } from "react";
import { errorToast } from "../../toast/toast";
import { SpinnerLoader } from "../../loader/SpinnerLoader";
import CalendarInModal from "./calendar/CalendarInModal.jsx";
import GroupCard2 from "../../Group/group-list-card/GroupCard2.jsx";

const GroupListModal = ({ siteID, show, handleClose, ThemeColor, isAdvance }) => {

    const [loading, setLoading] = useState(false);
    const [groupLists, setGroupLists] = useState([]);
    const [showCalendarInModal, setShowCalendarInModal] = useState(false);

    /**
     * get all group lists api call
     */
    const getAllGroupByCommunityID = (siteID) => {
        setLoading(true);
        GroupService.getAllGroupByCommunityID(siteID)
            .then((res) => {
                setLoading(false);
                setGroupLists(res.data);
            })
            .catch((err) => {
                setLoading(false);
                errorToast("error with getAllGroupByCommunityID")
            })
    };

    /**
 * show calendar in modal handler
 */
    const calendarInModalCloseHandler = () => {
        setShowCalendarInModal(false);
    }

    useEffect(() => {
        if (siteID) {
            getAllGroupByCommunityID(siteID);
        } else {
            errorToast("something went wrong with open group list modal");
            handleClose();
        }
    }, [siteID])
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
            >
                <Modal.Header className="px-lg-4" closeButton>
                    <Modal.Title className="font-size-18 text-black-theme" id="contained-modal-title-vcenter">
                        Groups
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-lg-4">
                    <Row className='justify-content-center align-items-center'>
                        {loading ? (<SpinnerLoader />) : ((groupLists !== []) && (groupLists?.length > 0)) ? (
                            <>
                                {groupLists &&
                                    groupLists.map((group, index) => (
                                        <Col lg={6} className="mb-4" key={index}>
                                            <GroupCard2
                                                group={group}
                                                ThemeColor={ThemeColor}
                                                isAdvance={isAdvance}
                                                removeAppend={true}
                                            />
                                        </Col>
                                    ))}
                            </>
                        ) : (
                            <Col md={6} lg={6} className="m-4 text-center">
                                Don't have any group in this community
                            </Col>
                        )}
                    </Row>

                </Modal.Body>
            </Modal>
            {showCalendarInModal &&
                <CalendarInModal
                    show={showCalendarInModal}
                    handleClose={calendarInModalCloseHandler}
                />
            }
        </>
    )
}

export default GroupListModal
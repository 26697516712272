import { createAsyncThunk } from "@reduxjs/toolkit";
import CommunityService from "../../../../Services/community/CommunityService";

/**
 * get all seciton of template by siteID
 * @param {*} siteID 
 * @returns 
 */
export const getTemplate2BySiteIDActionThunk = createAsyncThunk("template_2/getTemplate2DataBySiteId", async (siteID) => {
    const response = await CommunityService.GetAllTemplateSectionsBySiteID(siteID);
    if (response?.status !== 200) {
        throw new Error(response?.data?.message);
    } else {
        return response?.data;
    }
});

/**
 * update template section
 * @param {*} valuetemplateSectionDataIDs 
 * @param {*} templateID 
 * @param {*} sectionID 
 * @param {*} siteID 
 * @param {*} json 
 * @returns 
 */
export const updateSectionOfTemplate2BySiteIDActionThunk = createAsyncThunk("template_2/updateSectionOfTemplate2BySiteID", async ({ siteID, sectionID, data }) => {
    const response = await CommunityService.UpdateTemplateSectionBySiteID(siteID, sectionID, 2, JSON.stringify(data))
    return response?.data;
});

/**
 * update all section of template
 * @returns 
 */
export const updateAllTemplate2SectionsBySiteIDActionThunk = createAsyncThunk("template_2/updateAllTemplate2SectionsBySiteID", async (param1, { getState }) => {
    const jsonSectionList = [];
    let template2 = getState()?.template2?.template_2_sections;
    for (const key in template2) {
        jsonSectionList.push(JSON.stringify(template2[key]));
    }
    const data = { siteID: getState()?.template2?.siteID, templateID: 2, jsonSectionList }
    const response = await CommunityService.UpdateAllTemplateSectionBySiteID(data);
    return response?.data;
});
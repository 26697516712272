import { createAsyncThunk } from "@reduxjs/toolkit";
import CommunityService from "../../../Services/community/CommunityService";


export const getProfileUserCommunityActionThunk = createAsyncThunk("communty/getProfileUserCommunity", async (filter) => {
    const response = await CommunityService.getUserCommunityByUserID(
        filter?.userId,
        filter?.published,
        filter?.categoryOne,
        filter?.categoryTwo,
        filter?.search,
    )
    if (Array.isArray(response?.data)) {
        return response?.data
    } else {
        return [];
    };
});
import { createAsyncThunk } from "@reduxjs/toolkit";
import UserServices from "../../../Services/user/UserServices";
import { getFileNameFromURL, getFileNameReplacedSplaceWithUnderScore, getFileNameWithoutExtension } from "../../../utils/helpers/getFileNameFromURL";
import uploadFileToBlob from "../../../utils/helpers/azure-storage-blob";
import { cancelFileSelectionInSharedMediaAction, handleDownloadProgressInSharedMediaAction, handleUploadProgressInSharedMediaAction } from "./group-shared-media-slice";


/**
 * Get file entries
 */
export const fetchFolderOfSharedMediaAsyncThunk = createAsyncThunk("group-shared-media/file-entries", async ({ folderID, loading }, { dispatch, getState }) => {
    const groupId = getState()?.groupSharedMedia?.currentGroupId;
    const response = await UserServices.fetchUserFolder(folderID, groupId)
    if (response?.data?.statusCode !== 200) {
        throw new Error(response?.data?.message);
    } else {
        return response?.data;
    }
});

/**
 * create a folder
 */
export const createFoldrInSharedMediaAsyncThunk = createAsyncThunk("group-shared-media/createFolderInSharedMedi", async ({ folderName, errorHandler }, { getState, dispatch }) => {
    const parentFolderId = getState()?.groupSharedMedia?.currentFolderId;
    const groupId = getState()?.groupSharedMedia?.currentGroupId;
    const response = await UserServices.createFolder(folderName, parentFolderId, groupId);
    if (response?.data?.statusCode !== 200) {
        errorHandler && errorHandler(response?.data?.message);
        throw new Error(response?.data?.message);
    } else {
        return response?.data;
    }
});

/**
 * delete user folder
 */
export const deleteFolderInSharedMediaAsyncThunk = createAsyncThunk("group-shared-media/deleteFolderInSharedMedia", async (ids, { dispatch, getState }) => {
    const folderID = getState()?.groupSharedMedia?.currentFolderId;
    const response = await UserServices.deleteUserFolder(ids);
    if (response?.data?.statusCode !== 200) {
        throw new Error(response?.data?.message);
    } else {
        dispatch(fetchFolderOfSharedMediaAsyncThunk({ folderID, loading: false }));
        return response?.data;
    }
})

/**
 * rename folder
 */
export const renameFolderInSharedMediaAsyncThunk = createAsyncThunk("group-shared-media/renameFolerInSharedMedia", async ({ folderID, folderName, errorHandler }, { dispatch, getState }) => {
    const response = await UserServices.updateUserFolder(folderID, folderName);
    if (response?.data?.statusCode !== 200) {
        errorHandler && errorHandler(response?.data?.message);
        throw new Error(response?.data?.message);
    } else {
        dispatch(fetchFolderOfSharedMediaAsyncThunk({ folderID: getState()?.groupSharedMedia?.currentFolderId, loading: false }));
        return response?.data;
    }
})

/**
 * upload a file in drive
 */
export const addFileInSharedMediaAsyncThunk = createAsyncThunk("group-shared-media/addFileInSharedMedia", async (fileInfoList, { getState, dispatch }) => {
    try {
        const folderID = getState()?.groupSharedMedia?.currentFolderId;
        const uploadPromises = fileInfoList?.map(async (fileInfo) => {
            dispatch(handleUploadProgressInSharedMediaAction({ fileName: getFileNameWithoutExtension(getFileNameReplacedSplaceWithUnderScore(fileInfo?.file?.name)), progress: 0 }));
            const url = await uploadFileToBlob(fileInfo?.file, (fileName, progress) => {
                dispatch(handleUploadProgressInSharedMediaAction({ fileName, progress }));
            });
            if (url) {
                const updatedFileInfo = {
                    ...fileInfo,
                    src: url,
                    fileName: getFileNameFromURL(url),
                    file: undefined,
                };
                const fileObj = { fileList: [updatedFileInfo] };
                const response = await UserServices.addUserFile(fileObj);
                if (response?.data?.statusCode !== 200) {
                    throw new Error(response?.data?.message);
                } else {
                    return response?.data;
                }
            }
        });
        await Promise.all(uploadPromises);
        dispatch(fetchFolderOfSharedMediaAsyncThunk({ folderID, loading: false }));
    } catch (error) {
        throw error;
    }
});

/**
 * rename file
 */
export const renameFileInSharedMediaAsyncThunk = createAsyncThunk("group-shared-media/renameFileInSharedMedia", async ({ fileID, fileName, errorHandler }, { dispatch, getState }) => {
    const folderID = getState()?.groupSharedMedia?.currentFolderId;
    const response = await UserServices.updateUserFile(fileID, fileName);
    if (response?.data?.statusCode !== 200) {
        errorHandler && errorHandler(response?.data?.message);
        throw new Error(response?.data?.message);
    } else {
        dispatch(fetchFolderOfSharedMediaAsyncThunk({ folderID, loading: false }));
        return response?.data;
    }
})


/**
 * delete user file
 */
export const deleteFileInSharedMediaAsyncThunk = createAsyncThunk("group-shared-media/deleteFileInSharedMedia", async (ids, { dispatch, getState }) => {
    const folderID = getState()?.groupSharedMedia?.currentFolderId;
    const response = await UserServices.deleteUserFile(ids);
    if (response?.data?.statusCode !== 200) {
        throw new Error(response?.data?.message);
    } else {
        dispatch(fetchFolderOfSharedMediaAsyncThunk({ folderID, loading: false }));
        dispatch(cancelFileSelectionInSharedMediaAction())
        return response?.data;
    }
})

/**
*move folder into another folder
* @param {string} newFolderId folder id where you want to move
* @param {string} folderIdList folder id which folder you want to move
*/
export const moveFolderOrFileInSharedMediaAsyncThunk = createAsyncThunk("group-shared-media/moveFolderOrFileInSharedMedia", async ({ newFolderId, fileIdList, folderIdList }, { getState, dispatch }) => {
    const folderID = getState()?.groupSharedMedia?.currentFolderId;
    const response = await UserServices.moveUserFileAndFolder(newFolderId, fileIdList, folderIdList)
    if (response?.data?.statusCode !== 200) {
        throw new Error(response?.data?.message);
    } else {
        dispatch(fetchFolderOfSharedMediaAsyncThunk({ folderID, loading: false }));
        return response?.data;
    }
})


/**
 * download files
 */
export const donwloadFileFromSharedMediaAsyncThunk = createAsyncThunk("group-shared-media/donwloadFileFromSharedMedia", async (file_list, { getState, dispatch }) => {
    const downloadPromises = [];

    // Define a progress callback function
    function updateProgress(fileName, progress) {
        dispatch(handleDownloadProgressInSharedMediaAction({ fileName, progress }));
    }

    for (const file of file_list) {
        const downloadPromise = new Promise((resolve, reject) => {
            const fileUrl = file?.SRC;
            const fileName = file?.Name;

            const request = new XMLHttpRequest();
            request.responseType = 'blob';
            request.open('get', fileUrl, true);
            request.send();

            request.onreadystatechange = function () {
                if (this.readyState === 4) {
                    if (this.status === 200) {
                        var obj = window.URL.createObjectURL(this.response);

                        // Create a temporary link element to trigger the download
                        var link = document.createElement('a');
                        link.href = obj;
                        link.download = fileName;
                        link.style.display = 'none';

                        // Append the link to the DOM and trigger the download
                        document.body.appendChild(link);
                        link.click();

                        // Clean up the temporary link
                        setTimeout(function () {
                            window.URL.revokeObjectURL(obj);
                            link.remove();
                            resolve(); // Resolve this promise when the download is successful
                        }, 1000);
                    } else {
                        // Reject this promise if there's an error
                        reject(new Error(`File download failed with status: ${this.status}`));
                    }
                }
            };

            request.onprogress = function (e) {
                if (e.total === 0) {
                    // Avoid division by zero
                    return;
                }

                const percentComplete = Math.round((e.loaded / e.total) * 100);
                updateProgress(fileName, percentComplete);
            };

            request.onerror = function () {
                // Handle network errors and reject this promise
                reject(new Error('Network error during file download'));
            };

            request.onabort = function () {
                // Handle download aborts and reject this promise
                reject(new Error('File download aborted'));
            };
        });

        downloadPromises.push(downloadPromise);
    }

    await Promise.all(downloadPromises);
    return { success: true };
});
import { persistor } from "../store";
import {
  AUTH_LOADING,
  AUTH_LOADED,
  LOGIN_SUCCESS,
  LOGOUT,
  CHANGE_ROLE_LOADED,
  CHANGE_ROLE_LOADING,
  GET_VISITORS_DATA,
  VISITOR_LOADER,
  EMPTY_VISITOR_DATA
} from "./auth.constant";

/**
 * auth pending action creator
 * @returns
 */
const authLoading = () => ({ type: AUTH_LOADING });

/**
 * auth success action creator
 * @returns
 */
const authLoaded = () => ({ type: AUTH_LOADED });

/**
 * auth login action creator
 * @param token
 * @returns
 */
const loginSuccess = (loginData) => ({
  type: LOGIN_SUCCESS,
  payload: loginData,
});

/**
 * auth logout action creator
 * @returns
 */
const logout = () => {
  persistor.purge();
  return { type: LOGOUT };
};

/**
 * change role lodaing action creator
 * @returns
 */
const changeRoleLoadingAction = () => ({ type: CHANGE_ROLE_LOADING });

/**
 * change role loaded action creator
 * @returns
 */
const changeRoleLoadedAction = () => ({ type: CHANGE_ROLE_LOADED });

/**
 * get data of visitors who is not member of combo (Guest)
 * @param {*} data 
 * @returns 
 */
const getVisitorsDataAction = (data) => ({
  type: GET_VISITORS_DATA,
  payload: data
})

const visitorLoaderAction = (status) => ({
  type: VISITOR_LOADER,
  payload: status
})

const emptyVisitorDataAction = (data) => ({
  type: EMPTY_VISITOR_DATA
})



export {
  authLoading,
  authLoaded,
  loginSuccess,
  logout,
  changeRoleLoadingAction,
  changeRoleLoadedAction,
  getVisitorsDataAction,
  visitorLoaderAction,
  emptyVisitorDataAction,
};

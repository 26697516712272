import { createSlice } from "@reduxjs/toolkit"
import { getAllGroupMembersNotInGroupActionThunk, sendInvitationActionThunk } from "./group-invite-async-thunk";


const initialState = {
    loading: { user_list: false, submit: false },
    user_list: {
        pagination: { currentPage: 0, totalRecords: 0, pageSize: 10, totalPages: 0, hasNextPage: false },
        list: [],
    },
    errors: {
        user_list: null,
    }
}

const groupInviteSlice = createSlice({
    name: 'groupInvite',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllGroupMembersNotInGroupActionThunk.pending, (state, action) => {
                state.loading.user_list = true;
                state.errors.user_list = null;
            })
            .addCase(getAllGroupMembersNotInGroupActionThunk.fulfilled, (state, action) => {
                state.loading.user_list = false;
                state.errors.user_list = null;
                state.user_list.pagination = { currentPage: action?.payload?.pageIndex, hasNextPage: action?.payload?.hasNextPage, pageSize: action?.payload?.pageSize, totalPages: action?.payload?.totalPages, totalRecords: action?.payload?.totalRecords }
                if (action?.meta?.arg?.pageNo === 1) {
                    state.user_list.list = action?.payload?.entities
                } else {
                    state.user_list.list = [...state?.user_list?.list, ...action?.payload?.entities]
                }
            })
            .addCase(getAllGroupMembersNotInGroupActionThunk.rejected, (state, action) => {
                state.loading.user_list = false;
                state.errors.user_list = action.error.message;
            })

            .addCase(sendInvitationActionThunk.pending, (state, action) => {
                state.loading.submit = true;
                state.errors.submit = null;
            })
            .addCase(sendInvitationActionThunk.fulfilled, (state, action) => {
                state.loading.submit = false;
                state.errors.submit = null;
            })
            .addCase(sendInvitationActionThunk.rejected, (state, action) => {
                state.loading.submit = false;
                state.errors.submit = null;
            })
    }
});

export const actions = groupInviteSlice.actions;

const groupInviteReducer = groupInviteSlice.reducer;
export default groupInviteReducer;
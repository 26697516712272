import { createAsyncThunk } from "@reduxjs/toolkit";
import UserServices from "../../../Services/user/UserServices";



/**
 * get or set current dashboard community id
 * @param {*} id if pass id it will set current dashboard id
 * @returns return current dashboard id
 */
export const getOrSetCurrentDashboardCommunityActionThunk = createAsyncThunk("dashboard/getOrSetCurrentDashboardCommunity", async (id) => {
    const response = await UserServices.getOrSetCurrentSelectedCommunity(id);
    return response?.data
});
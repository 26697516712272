import { useCallback, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import GroupService from '../../../Services/group/GroupService';
import { SpinnerLoader } from '../../loader/SpinnerLoader';
import { errorToast } from '../../toast/toast';
import SearchIcon from "../../../assets/Images/search-icon.svg";
import InfiniteScroll from 'react-infinite-scroll-component';


const ExistingUserInvite = ({ setSelectedUsers, groupID }) => {

    const [nameSearched, setNameSearched] = useState("");
    const [loading, setLoading] = useState({ userLists: false });
    const [memberList, setMemberList] = useState([]);
    const [pagination, setPagination] = useState({ currentPage: 1, totalRecords: 0, pageSize: 10, totalPages: 0, hasNextPage: false });

    //get existing user list 
    const getAllGroupMembersNotInGroup = useCallback((nameSearched) => {
        setLoading(prev => ({ ...prev, userLists: true }));
        setPagination(prev => ({ ...prev, currentPage: 1 }))
        GroupService.getAllGroupMembers(groupID, nameSearched, 1, pagination?.pageSize)
            .then((res) => {

                const tempArr = res.data?.entities?.map(member => ({ id: member?.userId, data: member, checked: false }))
                setMemberList(tempArr);
                setPagination(prev => ({ ...prev, totalRecords: res?.data?.totalRecords, totalPages: res?.data?.totalPages, hasNextPage: res?.data?.hasNextPage }))
                setLoading(prev => ({ ...prev, userLists: false }));

            })
            .catch(err => {
                errorToast("Something went wrong.")
                setMemberList([]);
                setLoading(prev => ({ ...prev, userLists: false }));
            })
    }, [pagination?.pageSize])

    //handle load more members
    const getAllGroupMembersNotInGroupMore = useCallback((nameSearched) => {
        GroupService.getAllGroupMembers(groupID, nameSearched, pagination?.currentPage, pagination?.pageSize)
            .then((res) => {
                let tempMembers = [...memberList];
                const tempArr = res?.data?.entities?.map(member => ({ id: member?.userId, data: member, checked: false }))
                tempMembers = [...tempMembers, ...tempArr];
                setMemberList(tempMembers);
                setPagination(prev => ({ ...prev, totalRecords: res?.data?.totalRecords, totalPages: res?.data?.totalPages, hasNextPage: res?.data?.hasNextPage }))
            })
            .catch(err => {
                errorToast("Something went wrong")
                setLoading(prev => ({ ...prev, userLists: false }));
            })

    }, [groupID, pagination?.currentPage, pagination?.pageSize]);

    //fetch more data by infinite scrolling
    const fetchMoreData = () => {
        setPagination(prev => ({ ...prev, currentPage: prev?.currentPage + 1 }))
    }

    //ckeckbox hndler
    const selectUserListHandler = (event) => {
        const tempList = [...memberList];
        const index = tempList.findIndex(tmpUsr => tmpUsr?.id === event.target.value);
        if (index > -1) {
            tempList[index].checked = event.target.checked;
        }
        setMemberList(tempList);

        let selectedUsers = tempList?.filter((value) => value?.checked);
        let selectedUserIDs = selectedUsers.map((value) => { return value?.id; });
        setSelectedUsers(selectedUserIDs);
    }

    useEffect(() => {
        if (pagination?.currentPage > 1) {
            getAllGroupMembersNotInGroupMore(nameSearched);
        }
    }, [getAllGroupMembersNotInGroupMore, pagination?.currentPage])

    useEffect(() => {
        getAllGroupMembersNotInGroup(nameSearched);
    }, [getAllGroupMembersNotInGroup]);

    return (
        <>
            <Form.Group
                className="form-group has-icon mb-3"
                controlId="exampleForm.ControlInput1"
            >
                <img src={SearchIcon} alt="Search Icon" />
                <Form.Control
                    type="text"
                    placeholder="Search"
                    onChange={(e) => setNameSearched(e.target?.value)}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") {
                            getAllGroupMembersNotInGroup(nameSearched)
                        }
                    }}
                />
            </Form.Group>
            {/* Users */}
            <div className="list-unstyled checkbox-list m-0" id="scrollableDiv">
                {loading?.userLists ? (
                    <SpinnerLoader />
                ) : (
                    memberList?.length > 0 ? (
                        <>
                            <InfiniteScroll
                                dataLength={memberList?.length}
                                next={fetchMoreData}
                                hasMore={Number(memberList?.length) < Number(pagination?.totalRecords)}
                                loader={<SpinnerLoader />}
                                refreshFunction={getAllGroupMembersNotInGroup}
                                scrollableTarget="scrollableDiv"
                            >
                                {memberList?.map((member, index) => (
                                    <li key={index}>
                                        <div className="d-flex align-items-center">
                                            <label className="checkbox-container">
                                                <input
                                                    type="checkbox"
                                                    value={member?.id}
                                                    checked={member?.ckecked}
                                                    onChange={(e) => selectUserListHandler(e)}
                                                />
                                                <span className="checkmark-new"></span>
                                                <div className="d-flex align-items-center">
                                                    <img src={member?.data?.profilePicture || require("../../../assets/Images/guest-user.jpg")} alt="" />
                                                    <div className="d-flex flex-column">
                                                        <h6 className="mb-1">{member?.data?.firstName} {member?.data?.lastName}</h6>
                                                        <small>{member?.data?.email}</small>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </li>))}
                            </InfiniteScroll>
                        </>
                    )
                        : (
                            <h6 className="text-black-theme text-center my-4 my-md-5">No Users Found</h6>
                        ))}
            </div>
        </>
    )
}

export default ExistingUserInvite
import { API } from "../../http-Common";

class StoreService {
  GetAllStore(userID) {
    return API.get("/Site/GetCommunityAndStoreDetailsByUserID", {
      params: { userID },
    });
  }

  updateEcommerceStoreStatus(communityId, storeStatus) {
    return API.post(
      `/Site/UpdateCommunityEcommerce?communityId=${communityId}&isEnable=${storeStatus}`
    );
  }
}
const storeService = new StoreService();
export default storeService;

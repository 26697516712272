import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setSharedMediaEntriesModalsAction } from '../../../ReduxStore/slice/group-shared-media-entries/group-shared-media-entries-slice';
import SharedMediaEntries from './shared-media/SharedMediaEntries';
import { copyBlobInAzureStorage } from '../../../utils/helpers/azure-storage-blob';
import { SpinnerLoader } from '../../loader/SpinnerLoader';
import { ReactComponent as CloseIcon } from "../../../assets/Images/close-icon.svg";



const UploadFromSharedMedia = ({ handleClose }) => {

    const dispatch = useDispatch();

    const show = useSelector(state => state?.groupSharedMediaEntries?.modals?.show_group_shared_media_entries_modal);
    const selected_files = useSelector(state => state?.groupSharedMediaEntries?.file_selection?.selected_files);

    const [loading, setLoading] = useState({ sbmt_button: false })

    const handleCancel = () => {
        dispatch(setSharedMediaEntriesModalsAction({ show_group_shared_media_entries_modal: { status: false, groupId: "" } }))
    }

    const handleUpload = async () => {
        setLoading(prev => ({ ...prev, sbmt_button: true }));
        const new_selected_files = []
        if (selected_files?.length > 0) {
            const uploadPromises = selected_files?.map(async (file) => {
                const newSRC = await copyBlobInAzureStorage(file?.SRC);
                new_selected_files.push({ ...file, SRC: newSRC });
            })
            await Promise.all(uploadPromises);
        }
        setLoading(prev => ({ ...prev, sbmt_button: false }));
        handleClose(new_selected_files)
    }

    return (
        <>
            <Modal show={show?.status} onHide={handleCancel} centered backdrop="static" scrollable className="common-modal mid-modal files-modal">
                <Modal.Header>
                    <Modal.Title className="text-black-theme">{show?.groupId ? "Files" : "My Media"}</Modal.Title>
                    <Button variant="link" onClick={handleCancel}>
                        <CloseIcon fill="#212121" />
                    </Button>
                </Modal.Header>
                <Modal.Body className="common-modal-body">
                    {loading?.sbmt_button ? (
                        <SpinnerLoader />
                    ) : (
                        <SharedMediaEntries />
                    )}
                </Modal.Body>
                <Modal.Footer className="common-modal-footer">
                    <Button className="btn-secondary-theme btn-outline" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button
                        className="btn-primary-theme"
                        onClick={handleUpload}
                        disabled={loading?.sbmt_button || !selected_files?.length}
                    >
                        Upload
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default UploadFromSharedMedia
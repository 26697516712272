import { createAsyncThunk } from "@reduxjs/toolkit";
import CommunityService from "../../../../Services/community/CommunityService";

/**
 * get all seciton of template by siteID
 * @param {*} siteID 
 * @returns 
 */
export const getTemplate3BySiteIDActionThunk = createAsyncThunk("template_3/getTemplate3DataBySiteId", async (siteID) => {
    const response = await CommunityService.GetAllTemplateSectionsBySiteID(siteID);
    if (response?.status !== 200) {
        throw new Error(response?.data?.message);
    } else {
        return response?.data;
    }
});

/**
 * update template section
 * @param {*} valuetemplateSectionDataIDs 
 * @param {*} templateID 
 * @param {*} sectionID 
 * @param {*} siteID 
 * @param {*} json 
 * @returns 
 */
export const updateSectionOfTemplate3BySiteIDActionThunk = createAsyncThunk("template_3/updateSectionOfTemplate3BySiteID", async ({ siteID, sectionID, data }) => {
    const response = await CommunityService.UpdateTemplateSectionBySiteID(siteID, sectionID, 3, JSON.stringify(data))
    return response?.data;
});

/**
 * update all section of template
 * @returns 
 */
export const updateAllTemplate3SectionsBySiteIDActionThunk = createAsyncThunk("template_3/updateAllTemplate3SectionsBySiteID", async (param1, { getState }) => {
    const jsonSectionList = [];
    let template3 = getState()?.template3?.template_3_sections;
    for (const key in template3) {
        jsonSectionList.push(JSON.stringify(template3[key]));
    }
    const data = { siteID: getState()?.template3?.siteID, templateID: 3, jsonSectionList }
    const response = await CommunityService.UpdateAllTemplateSectionBySiteID(data);
    return response?.data;
});
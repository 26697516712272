import { useContext } from 'react'
import NewsfeedModalProvider, { NewsfeedModalContext } from '../../../../../Component/Group/newsfeed/newsfeed-modal/NewsfeedModalContext'
import { Button, Dropdown, Modal, Spinner } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router'
import { SpinnerLoader } from '../../../../../Component/loader/SpinnerLoader'
import { isOwner } from '../../../../../utils/helpers/isOwner'
import { ReactComponent as DotsVerticalIcon } from "../../../../../assets/Images/dots-vertical-icon.svg";
import { ReactComponent as TrashIcon } from "../../../../../assets/Images/trash-icon.svg";
import { ReactComponent as CloseIcon } from "../../../../../assets/Images/close-icon.svg";
import { renderContentWithLinks } from '../../../../../utils/helpers/renderContentWithLinks';
import { ReactComponent as LikeNewIcon } from '../../../../../assets/Images/like-new-icon.svg';
import { ReactComponent as DownloadIcon } from '../../../../../assets/Images/download-icon.svg';
import { useDispatch } from 'react-redux'
import ReactPlayer from 'react-player'
import { DocumentMediaCard, ImageMediaCard, VideoMediaCard } from '../../../../../Component/media-view/media-cards/MediaCards'
import CreateCommentForm from '../../../../../Component/Group/newsfeed/newsfeed-modal/create-comment-form/CreateCommentForm'
import CreateReplyCommentForm from '../../../../../Component/Group/newsfeed/newsfeed-modal/create-reply-comment-form/CreateReplyCommentForm'
import { formatDateForChat } from '../../../../../utils/helpers/formatDateForChat'
import { showMediaAction } from '../../../../../ReduxStore/media-view/mediaView.actions'
import EditCommentModal from '../../../../../Component/Group/newsfeed/EditCommentModal'
import { ReactComponent as CommentIcon } from '../../../../../assets/Images/comment-icon.svg';
import { Link } from 'react-router-dom'
import TruncateText from '../../../../../Component/Group/about/TruncateText'

const ModalComp = () => {

    const {
        loading,
        newsfeedData,
        likeNewsfeedHandlerFun,
        deleteNewsfeedHandlerFun,
        comments,
        likeCommentFunc,
        deleteCommentHandlerFun,
        showCommentReplyForm,
        showCommentReplyFormHandler,

        editParentCommentApiCall,
        showEditCommentModal,
        showEditCommentModalHandler,
        editChildCommentApiCall
    } = useContext(NewsfeedModalContext);

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const showNewsfeed = location?.state?.showNewsfeed;


    //close newsfeed modal
    const handleClose = () => {
        navigate(location?.pathname, { state: { ...location?.state, showNewsfeed: false, newsfeedId: "" } })
    }

    //check, is comment like loading
    const isCommentLikeLoading = (commentID) => {
        return loading?.likeComment?.status && loading?.likeComment?.commentID === commentID;
    }

    //check, is comment delete loading 
    const isCommentDeleteLoading = (commentID) => {
        return loading?.deleteComment?.status && loading?.deleteComment?.commentID === commentID;
    }


    return (
        <>
            <Modal
                show={showNewsfeed}
                onHide={handleClose}
                centered
                scrollable
                size="lg"
                className="common-modal discussions-container newsfeed-modal"
            >
                {loading?.newsfeedData || loading?.comments ? (
                    <SpinnerLoader />
                ) : newsfeedData?.statusCode === 404 ? (
                    <div className="spinner-container">
                        <h5 className="font-size-18 m-0">Newsfeed Not Found</h5>
                    </div>
                ) : (
                    <>
                        <Modal.Body>
                            <div className="media-posts-outer-box" >
                                <div className="media-posts-inner-box">
                                    <div className="media-posts-card">
                                        <div className="media-posts-card-header">
                                            <div className="media-posts-card-profile">
                                                <div className="media-posts-card-profile-pic">
                                                    <img
                                                        src={newsfeedData?.data?.profilePicture || require("../../../../../assets/Images/guest-user.jpg")}
                                                        alt="profile"
                                                    />
                                                </div>
                                                <div className="media-posts-card-profile-name">
                                                    <Link className='text-decoration-none' to={"/profile/about/" + newsfeedData?.data?.userID}>
                                                        <p>{newsfeedData?.data?.userName || ""}</p>
                                                    </Link>
                                                    <span>{formatDateForChat(newsfeedData?.data?.postTime)}</span>
                                                </div>
                                                {isOwner(newsfeedData?.data?.userID) && (
                                                    <Dropdown className='discussion-dropdown' align="end">
                                                        <Dropdown.Toggle variant="link" id="dropdown-basic">
                                                            <DotsVerticalIcon fill="#212121" />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="group-dropdown-menu help-support w-175 mt-0">
                                                            <ul className="list-unstyled m-0">
                                                                {/* <li>
                                                            <Button variant='link'>
                                                                <EditIconSvg fill="#212121" className="me-2" /> Edit
                                                            </Button>
                                                        </li> */}
                                                                <li>
                                                                    <Button variant='link' onClick={() => deleteNewsfeedHandlerFun(newsfeedData?.data?.postID)}>
                                                                        <TrashIcon fill="#212121" className="me-2" /> Delete
                                                                    </Button>
                                                                </li>
                                                            </ul>
                                                        </Dropdown.Menu>
                                                    </Dropdown>)}
                                                <Button variant="link" onClick={handleClose}>
                                                    <CloseIcon fill="#212121" />
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="media-posts-card-content">
                                            <p className="m-0 text_wrap" dangerouslySetInnerHTML={{ __html: renderContentWithLinks(newsfeedData?.data?.content || "") }} />
                                        </div>
                                        <div className="media-posts-card-image">
                                            {newsfeedData?.data?.fileType && newsfeedData?.data?.fileType === "Video" ? (
                                                <div className="player-wrapper">
                                                    {newsfeedData?.data?.postImageOrVedio && (
                                                        <ReactPlayer
                                                            className="react-player"
                                                            url={newsfeedData?.data.postImageOrVedio ? newsfeedData?.data.postImageOrVedio : ""}
                                                            width="100%"
                                                            height="100%"
                                                            controls={true}
                                                        />
                                                    )}
                                                </div>
                                            ) : newsfeedData?.data?.fileType === "Image" ? (
                                                <div className="player-wrapper">
                                                    {newsfeedData?.data?.postImageOrVedio && (
                                                        <img
                                                            src={newsfeedData?.data?.postImageOrVedio || ""}
                                                            alt="img"
                                                            onClick={() => dispatch(showMediaAction({ type: "image", src: newsfeedData?.data?.postImageOrVedio, title: "" }))}
                                                        />
                                                    )}
                                                </div>
                                            ) : newsfeedData?.data?.fileType && newsfeedData?.data?.fileType === "Document" && (
                                                <div className="player-wrapper file-reader-option">
                                                    {newsfeedData?.data?.fileName && newsfeedData?.data?.fileName?.length > 0 && newsfeedData?.data?.fileName?.split(".")?.includes("pdf") ? (
                                                        <>
                                                            {newsfeedData?.data?.postImageOrVedio &&
                                                                /* <FileViewer
                                                                    fileType={"pdf"}
                                                                    filePath={newsfeedData?.data?.postImageOrVedio}
                                                                /> */
                                                                <iframe
                                                                    className="p-2 rounded-4"
                                                                    src={newsfeedData?.data?.postImageOrVedio}
                                                                    title="PDF Viewer"
                                                                    width="100%"
                                                                    height="100%"
                                                                />
                                                            }
                                                        </>
                                                    ) : (
                                                        <>
                                                            <a
                                                                href={newsfeedData?.data.postImageOrVedio}
                                                                download
                                                                target="_blank"
                                                                className="btn btn-secondary-theme btn-outline small-btn downloadFileButton"
                                                                rel="noreferrer"
                                                            >
                                                                <DownloadIcon fill="#606770" />
                                                            </a>
                                                            {/* <FileViewer
                                                            fileType={"docx"}
                                                            filePath={newsfeedData?.data?.postImageOrVedio}
                                                        /> */}
                                                            <iframe
                                                                className="p-2 rounded-4"
                                                                src={newsfeedData?.data?.postImageOrVedio}
                                                                title="PDF Viewer"
                                                                width="100%"
                                                                height="100%"
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        <div className="d-flex align-items-center add-like-comment">

                                            <div
                                                className="d-flex align-items-center custom-btn like"
                                                onClick={() => likeNewsfeedHandlerFun(newsfeedData?.data?.postID)}
                                            >

                                                <div className={newsfeedData?.data?.isLikeOrUnlike ? 'text-primary-theme' : ""}>
                                                    <LikeNewIcon fill={newsfeedData?.data?.isLikeOrUnlike ? "#047ED6" : "#606770"} className="me-2" />
                                                    <span className={newsfeedData?.data?.isLikeOrUnlike ? "text-primary-theme" : ""}>{newsfeedData?.data?.isLikeOrUnlike ? "Liked" : "Like"}</span>
                                                    <span className="ms-1"> {newsfeedData?.data?.likeCount}</span>
                                                </div>

                                            </div>

                                            <div className="d-flex align-items-center custom-btn">
                                                <CommentIcon fill="#047ED6" className="me-2" />
                                                <span className="text-primary-theme">Comment</span>
                                                <span className="text-primary-theme ms-1">
                                                    {newsfeedData?.data?.commentCount}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* comments */}
                            {comments && comments?.length > 0 ? (
                                comments?.map((data, index) => (
                                    <div key={index} className={`comments-section-custom ${data?.commentReply?.length > 0 ? "inner-child" : ""}`}>
                                        <div className="commentitem">
                                            <div className="d-flex commentitem-inner">
                                                <img
                                                    className="commentprofile"
                                                    src={data?.profilePicture || require("../../../../../assets/Images/guest-user.jpg")}
                                                    alt="img"
                                                />
                                                <div className="d-flex flex-column comment-details-container">
                                                    <div id={data?.commentID} className="comment-details">
                                                        <div className='d-flex align-items-center mb-2'>
                                                            <h2 className='m-0'>{data?.userName}</h2>
                                                            <small className='font-size-12 ms-auto'> {formatDateForChat(data?.creationDate)}</small>
                                                        </div>
                                                        {/* <p className="m-0 text_wrap" dangerouslySetInnerHTML={{ __html: renderContentWithLinks(data?.comments) }} /> */}
                                                        <TruncateText
                                                            text={data?.comments}
                                                            length={150}
                                                        />
                                                        {(data?.mediaFileUrl && data?.mediaFileType) && (data?.mediaFileType === "image" ?
                                                            <ImageMediaCard
                                                                url={data?.mediaFileUrl}
                                                            /> : data?.mediaFileType === "video" ?
                                                                <VideoMediaCard
                                                                    url={data?.mediaFileUrl}
                                                                /> :
                                                                <DocumentMediaCard
                                                                    url={data?.mediaFileUrl}
                                                                />)
                                                        }
                                                    </div>
                                                    <div className="d-flex comment-details-btn">
                                                        <div className="commentlike">

                                                            <button
                                                                onClick={() => likeCommentFunc(data?.commentID)}
                                                                className={data?.isLiked ? 'text-primary-theme' : ''}
                                                            >
                                                                {data?.isLiked ? "Liked" : "Like"}
                                                            </button>

                                                            <span className="likeoverlay">{data?.likeCount}</span>
                                                        </div>
                                                        <div className="commentlike replybutton">
                                                            <button
                                                                onClick={() => showCommentReplyFormHandler(data?.commentID)}
                                                                disabled={loading?.createCommentReplyBtn?.status}
                                                            >
                                                                Reply
                                                            </button>
                                                        </div>
                                                        {isOwner(data?.userID) && (
                                                            <div className="commentlike replybutton">

                                                                <button
                                                                    onClick={() => showEditCommentModalHandler(true, data, false)}
                                                                >
                                                                    Edit
                                                                </button>
                                                            </div>
                                                        )}
                                                        {isOwner(data?.userID) && (<div className="deletebutton">
                                                            {isCommentDeleteLoading(data?.commentID) ? (
                                                                <Spinner animation="grow" size='sm' />
                                                            ) : (
                                                                <button
                                                                    onClick={() => deleteCommentHandlerFun(data?.commentID)}
                                                                >
                                                                    Delete
                                                                </button>
                                                            )}
                                                        </div>)}
                                                    </div>
                                                </div>
                                            </div>

                                            {/* comments reply */}
                                            {showCommentReplyForm?.status &&
                                                showCommentReplyForm?.commentID === data?.commentID &&
                                                (<CreateReplyCommentForm commentID={data?.commentID} />)}
                                            <div>
                                                <div className="commentReplay">
                                                    {data?.commentReply?.map((item, index) => (
                                                        <div className="d-flex commentitem-inner" key={index}>
                                                            <img
                                                                className="commentprofile"
                                                                src={item?.profilePicture || require("../../../../../assets/Images/guest-user.jpg")}
                                                                alt="img"
                                                            />
                                                            <div className="d-flex flex-column comment-details-container"                                                        >
                                                                <div
                                                                    id={item?.replyID}
                                                                    className="comment-details"
                                                                >
                                                                    <div className='d-flex align-items-center mb-2'>
                                                                        <h2
                                                                            className='m-0 cursor-pointer'
                                                                            onClick={() => navigate("/profile/about/" + item?.userID)}
                                                                        >
                                                                            {item?.userName}
                                                                        </h2>
                                                                        <small className='font-size-12 d-block ms-auto'> {formatDateForChat(data?.creationDate)}</small>
                                                                    </div>
                                                                    <TruncateText
                                                                        text={item?.replyMessage}
                                                                        length={150}
                                                                    />
                                                                    {item?.mediaFileUrl && (item?.mediaFileType === "image" ?
                                                                        <ImageMediaCard
                                                                            url={item?.mediaFileUrl}
                                                                        /> : item?.mediaFileType === "video" ?
                                                                            <VideoMediaCard
                                                                                url={item?.mediaFileUrl}
                                                                            /> :
                                                                            <DocumentMediaCard
                                                                                url={item?.mediaFileUrl}
                                                                            />)
                                                                    }
                                                                </div>
                                                                <div className="d-flex comment-details-btn">
                                                                    {isOwner(item?.userID) && (
                                                                        <div className="commentlike replybutton">
                                                                            <button
                                                                                onClick={() => showEditCommentModalHandler(true, item, true)}
                                                                            >
                                                                                Edit
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="comments-section-custom no-comments">
                                    No Comments
                                </div>
                            )}
                            <CreateCommentForm />
                        </Modal.Body>
                    </>
                )}
            </Modal>
            {showEditCommentModal?.status && (
                <EditCommentModal
                    loading={loading}
                    editParentCommentApiCall={editParentCommentApiCall}
                    showEditCommentModal={showEditCommentModal}
                    showEditCommentModalHandler={showEditCommentModalHandler}
                    editChildCommentApiCall={editChildCommentApiCall}
                    groupId={newsfeedData?.data?.groupID}
                />
            )}
        </>
    )
}

const NewsFeedPostModal = () => {
    return (
        <NewsfeedModalProvider>
            <ModalComp />
        </NewsfeedModalProvider>
    )
}

export default NewsFeedPostModal
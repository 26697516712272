import React from 'react'

const SkeletonEventConfirmBtn = () => {
    return (
        <div className="skeleton-blog">
            <header className="d-flex align-align-items-center gap-2">
                <div className="skeleton-author">
                </div>
                <div className="skeleton-author">
                </div>
                <div className="skeleton-author">
                </div>
            </header>
        </div>

    )
}

export default SkeletonEventConfirmBtn
import { createAsyncThunk } from "@reduxjs/toolkit";
import UserServices from "../../../Services/user/UserServices";
import { handleDownloadProgressInSharedMediaEntriesAction } from "./group-shared-media-entries-slice";


/**
 * Get file entries
 */
export const fetchFolderOfSharedMediaEntriesAsyncThunk = createAsyncThunk("group-shared-media-entries/file-entries", async ({ folderID, loading }, { dispatch, getState }) => {
    const groupId = getState()?.groupSharedMediaEntries?.modals?.show_group_shared_media_entries_modal?.groupId;
    const response = await UserServices.fetchUserFolder(folderID, groupId)
    if (response?.data?.statusCode !== 200) {
        throw new Error(response?.data?.message);
    } else {
        return response?.data;
    }
});

/**
 * download files
 */
export const donwloadFileFromSharedMediaEntriesAsyncThunk = createAsyncThunk("group-shared-media-entries/donwloadFileFromSharedMedia", async (file_list, { getState, dispatch }) => {
    const downloadPromises = [];

    // Define a progress callback function
    function updateProgress(fileName, progress) {
        dispatch(handleDownloadProgressInSharedMediaEntriesAction({ fileName, progress }));
    }

    for (const file of file_list) {
        const downloadPromise = new Promise((resolve, reject) => {
            const fileUrl = file?.SRC;
            const fileName = file?.Name;

            const request = new XMLHttpRequest();
            request.responseType = 'blob';
            request.open('get', fileUrl, true);
            request.send();

            request.onreadystatechange = function () {
                if (this.readyState === 4) {
                    if (this.status === 200) {
                        var obj = window.URL.createObjectURL(this.response);

                        // Create a temporary link element to trigger the download
                        var link = document.createElement('a');
                        link.href = obj;
                        link.download = fileName;
                        link.style.display = 'none';

                        // Append the link to the DOM and trigger the download
                        document.body.appendChild(link);
                        link.click();

                        // Clean up the temporary link
                        setTimeout(function () {
                            window.URL.revokeObjectURL(obj);
                            link.remove();
                            resolve(); // Resolve this promise when the download is successful
                        }, 1000);
                    } else {
                        // Reject this promise if there's an error
                        reject(new Error(`File download failed with status: ${this.status}`));
                    }
                }
            };

            request.onprogress = function (e) {
                if (e.total === 0) {
                    // Avoid division by zero
                    return;
                }

                const percentComplete = Math.round((e.loaded / e.total) * 100);
                updateProgress(fileName, percentComplete);
            };

            request.onerror = function () {
                // Handle network errors and reject this promise
                reject(new Error('Network error during file download'));
            };

            request.onabort = function () {
                // Handle download aborts and reject this promise
                reject(new Error('File download aborted'));
            };
        });

        downloadPromises.push(downloadPromise);
    }

    await Promise.all(downloadPromises);
    return { success: true };
});
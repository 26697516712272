import { useState, useRef } from "react";
import { Button, Col, Form } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import CombineCropperModal from "../../../../image-cropper/CombineCropperModal";
import {
  closeTemplate3SectionEditorAction,
  saveTemplate3SectionBySectionIDAction,
  setPreviewOfTemplate3Action,
} from "../../../../../ReduxStore/slice/community-site-template/template-3/template-3-slice";
import { updateSectionOfTemplate3BySiteIDActionThunk } from "../../../../../ReduxStore/slice/community-site-template/template-3/template-3-async-thynk";

const EditModal = ({ data, setData }) => {
  const dispatch = useDispatch();

  const siteID = useSelector((state) => state?.template3?.siteID);
  const save_btn_loading = useSelector(
    (state) => state?.template3?.loading?.section2
  );
  const show = useSelector((state) => {
    const is_Editable = state?.template3?.is_Editable;
    return is_Editable?.status && is_Editable?.current_edit_section === 4;
  });

  const [showImageCroperModal, setShowImageCroperModal] = useState({
    show: false,
    whichImage: "",
  });
  const [src, setSrc] = useState({ image: null, fileName: "" });

  const fileInput = useRef(null);

  const onRemoveImageClick = (event) => {
    setData((prevData) => ({ ...prevData, Section4ImageVisible: false }));
    console.log(fileInput);
    fileInput.current.value = "";
    console.log(data);
  };

  /**
   * close image crop modal and set the selected image in setBackroundImage props
   * @param {*} blob
   */
  const handleCloseImageCropModal = (fileUrl) => {
    setShowImageCroperModal((prev) => ({ ...prev, show: false }));
    setData((prevData) => ({
      ...prevData,
      Image: fileUrl,
    }));
    setData((prevData) => ({ ...prevData, Section4ImageVisible: true }));
  };

  /**
   * image input field onChange handler
   */
  const changeHandlerImage = (event, whichImage) => {
    setSrc({
      image: URL.createObjectURL(event.target.files[0]),
      fileName: event.target.files[0]?.name,
      fileType: event.target.files[0]?.type,
    });
    setShowImageCroperModal({ show: true, whichImage: whichImage });
    //event.target.value = null;
  };

  /**
   * edit modal close handler
   */
  const editModalCloseHandler = () => {
    dispatch(closeTemplate3SectionEditorAction({ sectionID: 4, data }));
  };

  /**
   * preview button handler
   */
  const previewButtonHandler = () => {
    dispatch(saveTemplate3SectionBySectionIDAction({ sectionID: 4, data }));
    dispatch(setPreviewOfTemplate3Action());
  };

  /**
   * Save Section Data
   */
  const saveSectionDataHandler = () => {
    dispatch(
      updateSectionOfTemplate3BySiteIDActionThunk({
        siteID,
        sectionID: 4,
        data,
      })
    );
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={editModalCloseHandler}
        placement="end"
        scroll={true}
      >
        <Offcanvas.Header closeButton className="border-bottom">
          <Offcanvas.Title>Edit</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="d-flex flex-column pt-2">
          <Form
            onSubmit={(e) => {
              e?.preventDefault();
              saveSectionDataHandler();
            }}
            className="d-flex flex-column flex-fill"
          >
            <Form.Group className="mb-3" controlId="plainText">
              <Form.Label>Tab Heading</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter tab heading"
                value={data?.TabHeading}
                onChange={(e) =>
                  setData((prevData) => ({
                    ...prevData,
                    TabHeading: e.target.value,
                  }))
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="plainText">
              <Form.Label>Tab Content</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter tab content"
                value={data?.TabContent}
                onChange={(e) =>
                  setData((prevData) => ({
                    ...prevData,
                    TabContent: e.target.value,
                  }))
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="plainText">
              <Form.Label>Heading</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter heading"
                value={data?.Heading}
                onChange={(e) =>
                  setData((prevData) => ({
                    ...prevData,
                    Heading: e.target.value,
                  }))
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="plainText">
              <Form.Label>Content</Form.Label>
              <ReactQuill
                preserveWhitespace
                value={data?.Content}
                onChange={(values) =>
                  setData((prevData) => ({
                    ...prevData,
                    Content: values,
                  }))
                }
              />
            </Form.Group>
            <span className="d-block mb-2">Images</span>
            <Form.Group
              className="d-flex align-items-center gap-3 mb-3"
              controlId="formBasicEmail"
            >
              <Form.Label className="m-0 me-1">1</Form.Label>
              <div style={{ display: "flex" }}>
                <Form.Control
                  ref={fileInput}
                  type="file"
                  accept="image/*"
                  onChange={(event) => changeHandlerImage(event)}
                />
                <Button
                  style={{ marginLeft: "10px" }}
                  className="btn-primary-theme font-size-14"
                  onClick={(event) => onRemoveImageClick(event)}
                >
                  Remove
                </Button>
              </div>
            </Form.Group>
            <Form.Group className="mb-4" controlId="plainText">
              <Form.Label>Button</Form.Label>
              <Col xs={12} className="mb-2">
                <Form.Control
                  type="text"
                  placeholder="Enter Button Name"
                  name="btnName"
                  className="mb-2"
                  value={data?.Button?.Name}
                  onChange={(e) =>
                    setData((prevData) => ({
                      ...prevData,
                      Button: {
                        ...prevData?.Button,
                        Name: e?.target?.value,
                      },
                    }))
                  }
                />
                <Form.Control
                  type="url"
                  placeholder="Enter Button Link"
                  name="btnLink"
                  value={data?.Button?.Link}
                  onChange={(e) =>
                    setData((prevData) => ({
                      ...prevData,
                      Button: {
                        ...prevData?.Button,
                        Link: e?.target?.value,
                      },
                    }))
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Background color</Form.Label>
              <Form.Control
                type="color"
                value={data?.BackgroundColor}
                onChange={(e) =>
                  setData((prevData) => ({
                    ...prevData,
                    BackgroundColor: e.target?.value,
                  }))
                }
              />
            </Form.Group>
            <div className="pt-3 d-flex justify-content-end border-top mt-auto">
              <Button
                onClick={() => previewButtonHandler()}
                className="btn-secondary-theme btn-outline me-2"
              >
                Preview
              </Button>
              <Button
                type="submit"
                className="btn-primary-theme font-size-14"
                disabled={save_btn_loading}
              >
                Save{" "}
                {save_btn_loading && (
                  <i className="fa fa-spinner fa-spin ms-2"></i>
                )}
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      {showImageCroperModal?.show && (
        <CombineCropperModal
          show={showImageCroperModal?.show}
          handleClose={handleCloseImageCropModal}
          src={src}
          aspect={1 / 1}
          spectString="1 / 1"
          canChangeAspect={true}
          cropRation={{
            unit: "%",
            x: 0,
            y: 0,
            height: 100,
            width: 100,
          }}
        />
      )}
    </>
  );
};

export default EditModal;


export const GET_SUPPORT_SUCCESS = "GET_SUPPORT_SUCCESS";
export const GET_SUPPORT_PENDING = "GET_SUPPORT_PENDING";
export const GET_SUPPORT_FAILED = "GET_SUPPORT_FAILED";

export const GET_SINGLE_SUPPORT_SUCCESS = "GET_SINGLESUPPORT_SUCCESS";
export const GET_SINGLE_SUPPORT_PENDING = "GET_SINGLE_SUPPORT_PENDING";
export const GET_SINGLE_SUPPORT_FAILED = "GET_SINGLE_SUPPORT_FAILED";

export const ADD_SUPPORT_SUCCESS = "ADD_SUPPORT_SUCCESS";
export const ADD_SUPPORT_PENDING = "ADD_SUPPORT_PENDING";
export const ADD_SUPPORT_FAILED = "ADD_SUPPORT_FAILED";

export const UPDATE_SUPPORT_SUCCESS = "UPDATE_SUPPORT_SUCCESS";
export const UPDATE_SUPPORT_PENDING = "UPDATE_SUPPORT_PENDING";
export const UPDATE_SUPPORT_FAILED = "UPDATE_SUPPORT_FAILED";


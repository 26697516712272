import { useEffect, useState } from "react";
import moment from "moment";
import { ReactComponent as LiveStreamIcon } from "../../../../assets/Images/live-stream-icon.svg";
import { showLiveStreamTimer } from "../../../../utils/constant/googlePhonts";

const ViewLiveStreamTimer = ({ startDate, liveStreamDetail }) => {
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  function calculateTimeRemaining() {
    const now = moment();
    const startDateTime = moment(startDate);
    const duration = moment.duration(startDateTime.diff(now));

    
    const hours =Math.max(0, duration.hours());
    const minutes = Math.max(0, duration.minutes());
    const seconds = Math.max(0, duration.seconds());

    return {
      hours,
      minutes,
      seconds,
    };
  }

  return (
    // <div>
    //   {timeRemaining.hours < 2 ? (
    //     <p>{`${timeRemaining.hours} hours, ${timeRemaining.minutes} minutes, ${timeRemaining.seconds} seconds`}</p>
    //   ) : null}
    // </div>
    <>
      {
      // timeRemaining.hours === 0 && timeRemaining.minutes === 0 && timeRemaining.seconds === 0 ? (
      //   ""
      // ) : (
        // timeRemaining.hours < 2 && (timeRemaining.hours !== 0 && timeRemaining.minutes !== 0 && timeRemaining.seconds !== 0 ) ? (
          timeRemaining?.hours < showLiveStreamTimer && !(timeRemaining?.hours === 0 && timeRemaining?.minutes === 0 && timeRemaining?.seconds === 0) && (
          // <p className="text-center fw-semibold m-0">{`${padWithLeadingZeros(timeRemaining?.hours, 2)} : ${padWithLeadingZeros(timeRemaining?.minutes, 2)} : ${padWithLeadingZeros(timeRemaining?.seconds,2)} `}</p>

          <div className="countdown-timer d-flex align-items-center fw-semibold">
          <LiveStreamIcon
              fill="#ffffff"
              className="live-stream-icon"
            />
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center mb-1">
                <p className="me-2 mb-0">Live in</p>      <div className="countdown-timer"> 
          <p className="text-center fw-semibold m-0">{`${timeRemaining?.hours.toString().padStart(2, '0')} : ${timeRemaining?.minutes.toString().padStart(2, '0')} : ${timeRemaining?.seconds.toString().padStart(2, '0')} `}</p>
           </div>
              </div>
              {/* {moment.utc(liveStrems?.startDateTime).local().format(
                          "DD MMM YYYY - hh:mm A"
                        )} */}
                         <p className="m-0">  {moment.utc(liveStreamDetail?.liveStartTime).local().format(
                          "DD MMM YYYY - hh:mm A"
                        )}</p>
              {/* <p className="m-0"> {moment(liveStreamDetail?.liveStartTime).format(
                  "DD MMM YYYY - hh:mm A"
                )}</p> */}
            </div>
        </div>

     

        ) 
        // : null
      // )
      }
    </>
  );
};

export default ViewLiveStreamTimer;

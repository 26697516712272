import { Button, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AppendedMyComponent } from "../../appendToBody/appendToBody";
import { SpinnerLoader } from "../../loader/SpinnerLoader";
import { roleIdToRoleName } from "../../../utils/helpers/roleIdToRoleName";
import { useNavigate } from "react-router";
import { useCallback, useEffect, useState } from "react";
import GroupService from "../../../Services/group/GroupService";
import { errorToast } from "../../toast/toast";
import InfiniteScrollComp from "../../infinite-scroll/InfiniteScroll";
import { ConditionalWrapper } from "../../conditional-Wrapper/ConditionalWrapper";
import { isPrivateProfile } from "../../../utils/helpers/isPrivacy";

const MemberListDD = ({ profilePictureList, groupId, ddRef }) => {

  const [groupMemberLists, setGroupMemberLists] = useState([]);
  const [loading, setLoading] = useState({ memberList: false });
  const [pagination, setPagination] = useState({ currentPage: 1, totalRecords: 0, pageSize: 10, totalPages: 0, hasNextPage: false });

  /**
    * get initial member list
    */
  const getAllGroupMembers = useCallback(() => {
    setLoading(prev => ({ ...prev, memberList: true }));
    setPagination(prev => ({ ...prev, currentPage: 1 }))
    GroupService.getAllGroupMembers(groupId, "", 1, pagination?.pageSize)
      .then(res => {
        setGroupMemberLists(res?.data?.entities)
        setPagination(prev => ({ ...prev, totalRecords: res?.data?.totalRecords, totalPages: res?.data?.totalPages, hasNextPage: res?.data?.hasNextPage }))
        setLoading(prev => ({ ...prev, memberList: false }));
      })
      .catch(err => {
        errorToast("Something went wrong.")
        setGroupMemberLists([])
        setLoading(prev => ({ ...prev, memberList: false }));
      })
  }, [groupId, pagination?.pageSize]);

  const getAllGroupMembersMore = useCallback(() => {
    GroupService.getAllGroupMembers(groupId, "", pagination?.currentPage, pagination?.pageSize)
      .then(res => {
        setGroupMemberLists(prevList => [...prevList, ...res?.data?.entities])
        setPagination(prev => ({ ...prev, totalRecords: res?.data?.totalRecords, totalPages: res?.data?.totalPages, hasNextPage: res?.data?.hasNextPage }))
      })
      .catch(err => {
        errorToast("Something went wrong")
        setLoading(prev => ({ ...prev, discussionList: false }));
      })
  }, [groupId, pagination?.currentPage, pagination?.pageSize]);

  useEffect(() => {
    if (pagination?.currentPage > 1) {
      getAllGroupMembersMore();
    }
  }, [getAllGroupMembersMore, pagination?.currentPage])

  return (
    <Dropdown className="group-member-2">
      <Dropdown.Toggle
        className="btn btn-link"
        id="dropdown-basic-2"
      >
        <div
          className=''
          onClick={() => getAllGroupMembers()}
          style={{ cursor: "pointer" }}
          ref={ddRef}
        >
          {(profilePictureList?.length > 0) ? profilePictureList?.map((pic, index) => (
            <img src={pic || require("../../../assets/Images/guest-user.jpg")} alt="User" key={index} />
          )) : (<img src={require("../../../assets/Images/guest-user.jpg")} alt="User" />)}
        </div>

      </Dropdown.Toggle>
      <AppendedMyComponent>
        <Dropdown.Menu className="group-dropdown-menu">
          <div className="arrow-up"></div>
          <ul className="list-unstyled m-0" id="scrollableUl">
            {loading?.memberList ? (
              <SpinnerLoader />
            ) : (
              groupMemberLists?.length > 0 ? (
                <InfiniteScrollComp
                  dataLength={groupMemberLists.length}
                  refreshFunction={getAllGroupMembers}
                  pagination={pagination}
                  setPagination={setPagination}
                  dataList={groupMemberLists}
                  ItemsComponent={MemberList}
                  height={groupMemberLists.length > 5 ? 250 : undefined}
                />
              ) : (
                <li>
                  <Button variant="link">
                    <div className="d-flex align-items-center">No user</div>
                  </Button>
                </li>
              ))}
          </ul>
        </Dropdown.Menu>
      </AppendedMyComponent>
    </Dropdown>
  );
};

export default MemberListDD;


export const MemberList = ({ dataList }) => {

  const navigate = useNavigate();
  return (
    <>
      {dataList?.map((usr, index) => (
        <li key={index}>
          <ConditionalWrapper
            condition={isPrivateProfile(usr?.privacy?.profilePrivacy)}
            wrapper={(children) => (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    Account is private
                  </Tooltip>
                }
              >{children}
              </OverlayTrigger>)}
          >
            <Button
              variant="link"
              onClick={() => !isPrivateProfile(usr?.privacy?.profilePrivacy) && navigate("/profile/about/" + usr?.userId)}
            >
              <div className="d-flex align-items-center">
                <img
                  src={usr?.profilePicture || require("../../../assets/Images/guest-user.jpg")}
                  alt="User"
                  height={30}
                  className="ms-0 me-2 rounded-circle"
                />
                <div className="d-flex flex-column">
                  <div
                    style={{
                      textAlign: "left",
                    }}
                  >
                    {" "}
                    {usr?.firstName} {usr?.lastName}
                  </div>
                  <div
                    className=" text-wrap"
                    style={{
                      fontSize: "10px",
                      ...(usr?.roleId <= 3 && {
                        fontWeight: "600",
                      }),
                      textAlign: "left",
                    }}
                  >
                    {roleIdToRoleName(usr?.roleId)}
                  </div>
                </div>
              </div>
            </Button>
          </ConditionalWrapper>
        </li>
      ))}
    </>
  )
}
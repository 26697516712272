export const AUTH_LOADING = "AUTH_LOADING";
export const AUTH_LOADED = "AUTH_LOADED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";

export const CHANGE_ROLE_LOADING = "CHANGE_ROLE_LOADING";
export const CHANGE_ROLE_LOADED = "CHANGE_ROLE_LOADED";

export const GET_VISITORS_DATA = "GET_VISITORS_DATA";
export const VISITOR_LOADER = "VISITOR_LOADER";
export const EMPTY_VISITOR_DATA = "EMPTY_VISITOR_DATA";

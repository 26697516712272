import { createAsyncThunk } from "@reduxjs/toolkit";
import NotificationService from "../../../Services/notification/NotificationService";


export const getNotificationListActionThunk = createAsyncThunk("notification/getNotificationList", async ({ pageIndex, itemsPerPage, needLoading }) => {
    const response = await NotificationService.getAllNotificationByUserID(pageIndex, itemsPerPage);
    if (response?.data?.statusCode !== 200) {
        throw new Error(response?.data?.message);
    } else {
        return response?.data;
    }
});

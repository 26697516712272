import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { renderContentWithLinks } from "../../../utils/helpers/renderContentWithLinks";
import { truncateHtml } from "../../../utils/helpers/truncateHtml";

const TruncateText = ({ text, length }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  function toggleTruncate() {
    setIsTruncated(!isTruncated);
  }

  return (
    <>
      <div
        className="d-inline value font-size-14"
        dangerouslySetInnerHTML={{
          __html: isTruncated
            ? renderContentWithLinks(truncateHtml(text || "", length))
            : renderContentWithLinks(text || ""),
        }}
      />
      {text?.length > length && (
        <Button
          variant="link"
          className="ms-1 align-bottom p-0 font-size-14 text-primary-theme text-decoration-none pointer"
          onClick={toggleTruncate}
        >
          {isTruncated ? "Read More" : "Read Less"}
        </Button>
      )}
    </>
  );
};

export default TruncateText;

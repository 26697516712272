import { TEMPLATE_COMMUNITY_DETAILS_FAIL, TEMPLATE_COMMUNITY_DETAILS_REQUEST, TEMPLATE_COMMUNITY_DETAILS_SUCCESS } from "../Constants/communityConstants";
import {
  COMMUNITY_ALL_LOADING,
  COMMUNITY_ALL_LOADED,
  GET_ALL_UPCOMING_EVENT_LIST,
  GET_ALL_ONGOING_EVENT_LIST,
  GET_ALL__COMPLETED_EVENT_LIST,
} from "./communityAll.constant";

const INITIAL_STATE = {
  loading: false,
  communitiesAll: [],
  groupAll: { loading: false, groups: [] },
  eventsAll: { loading: { upComing: false, onGoing: false, completed: false }, upComing: [], onGoing: [], completed: [] }
};

const communityAllReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COMMUNITY_ALL_LOADING:
      return { ...state, eventsAll: { ...state?.eventsAll, loading: { ...state?.eventsAll?.loading, ...(action?.payload === "evn1" ? { upComing: true } : action?.payload === "evn2" ? { onGoing: true } : { completed: true }) } } };

    case COMMUNITY_ALL_LOADED:
      return { ...state, eventsAll: { ...state?.eventsAll, loading: { ...state?.eventsAll?.loading, ...(action?.payload === "evn1" ? { upComing: false } : action?.payload === "evn2" ? { onGoing: false } : { completed: false }) } } };


    case GET_ALL_UPCOMING_EVENT_LIST:
      return {
        ...state,
        eventsAll: { ...state?.eventsAll, upComing: action?.payload }
      };

    case GET_ALL_ONGOING_EVENT_LIST:
      return {
        ...state,
        eventsAll: { ...state?.eventsAll, onGoing: action?.payload }
      };

    case GET_ALL__COMPLETED_EVENT_LIST:
      return {
        ...state,
        eventsAll: { ...state?.eventsAll, completed: action?.payload }
      };


      case TEMPLATE_COMMUNITY_DETAILS_REQUEST:
        return { 
          ...state, 
          loading: true, 
          // error: null 
        };
  
        case TEMPLATE_COMMUNITY_DETAILS_SUCCESS:
        return { 
          ...state, 
          loading: false, 
          communitiesAll: action.payload
        };
  
        case TEMPLATE_COMMUNITY_DETAILS_FAIL:
        return { 
          loading: false, 
          // error: action.payload,
          communitiesAll: []
        };

    default:
      return state;
  }
};
export default communityAllReducer;


import { createAsyncThunk } from "@reduxjs/toolkit";
import AdminDashboardService from "../../../Services/admin-dashboard/AdminDashboardService";

export const getAllLiveStreamListData = createAsyncThunk(
    "system-admin/getAllLiveStreamListData",
    async ({ searchText, pageIndex, pageSize }) => {
      const response = await AdminDashboardService.getAllLiveStreamsWithGroups(searchText, pageIndex, pageSize);
  
      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      } else {
        return response?.data;
      }
    }
  );
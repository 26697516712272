export const needSidebar = (currentPath) => {
    const withoutSidebarRoutes = [
        "/template/edit/1/:siteID",
        "/template/edit/2/:siteID",
        "/template/edit/3/:siteID",
    ]

    return !withoutSidebarRoutes.some((route) => {
        const regexp = new RegExp(`^${route.replace(/:\w+/g, '[^/]+')}$`);
        return regexp.test(currentPath);
    });


}
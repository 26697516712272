export const PROFILE_DATA_LOADING = "PROFILE_DATA_LOADING";
export const PROFILE_DATA_LOADED = "PROFILE_DATA_LOADED";
export const GET_PROFILE_DATA = "GET_PROFILE_DATA";

export const UPDATE_PROFILE_DATA = "UPDATE_PROFILE_DATA";
export const UPDATE_PROFILE_DATA_LOADING = "UPDATE_PROFILE_DATA_LOADING";
export const UPDATE_PROFILE_DATA_LOADED = "UPDATE_PROFILE_DATA_LOADED";

export const DO_EMPTY_PROFILE_DATA = "DO_EMPTY_PROFILE_DATA";

export const ROLE_AND_PERMISSIONS_REQUEST = "ROLE_AND_PERMISSIONS_REQUEST";
export const ROLE_AND_PERMISSIONS_SUCCESS = "ROLE_AND_PERMISSIONS_SUCCESS";
export const ROLE_AND_PERMISSIONS_FAIL = "ROLE_AND_PERMISSIONS_FAIL";

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { onClickOnFileHandler, openPreviewOfFileHandler } from '../utils/file-click-handler';
import FileOptionsDD from '../drop-downs/FileOptionsDD';
import { VideoFileOutlined } from '@mui/icons-material';

const VideoCard = ({ fileObj }) => {

    return (
        <>
            <div className='media-file-card-container'>
                <div
                    className="media-file-card d-flex flex-column justify-content-center align-items-center m-0"
                    onDoubleClick={() => openPreviewOfFileHandler(fileObj)}
                    onClick={() => onClickOnFileHandler(fileObj)}
                    draggable
                >
                    <VideoFileOutlined style={{ fontSize: "40px", color: "#818080" }} />
                    <div className="edit-site-tmplt modal d-flex justify-content-end">
                        <FileOptionsDD fileObj={fileObj} />
                    </div>
                </div>
                <div className="name">
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id={`tooltip-1`}>{fileObj?.Name}</Tooltip>}
                    >
                        <small className='disable-text-selection'> {fileObj?.Name} </small>
                    </OverlayTrigger>
                </div>
            </div>
        </>
    )
}

export default VideoCard
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";

import { apiMiddleware } from "../http-Common";
import { openEditorReducer } from "./Reducers/openEditorReducer";
import { modalReducer } from "./Reducers/modalReducer";
import profileDataReducer from "./profileData/profileData.reducers";
import authReducer from "./auth/auth.reducer";
import communityByUserReducer from "./communityByUser/community.reducers";
import calendar from "./calendar/calendar.reducers";
import mediaView from "./media-view/mediaView.reducers";
import { memberShipPlanReducer } from "./MemberShipPlan/memberShipPlan.reducers";
import { roleAndPermissionReducer } from "./roleAndPermission/roleAndPermission.reducers";
import communityAllReducer from "./communityAll/communityAll.reducers";
import supportReducer from "./support/support.reducer";
import groupMessageReducers from "./group-message/groupMessage.reducer";
import signalrConnectionReducer from "./signalr-connection/signalrConnection.reducers";
import myDriveReducer from "./slice/my-drive/myDriveSlice";
import groupInviteReducer from "./slice/group-invite/group-invite-slice";
import notificationReducer from "./slice/notification/notificationSlice";
import currentUserCommunityReducer from "./slice/curret-user-community/current-user-community-slice";
import currentUserGroupReducer from "./slice/current-user-groups/current-user-groups-slice";
import groupOpenReducer from "./slice/group-open/group-open-slice";
import profileUserCommunityReducer from "./slice/profile-user-community/profile-user-community-slice";
import profileUserGroupReducer from "./slice/profile-user-groups/profile-user-groups-slice";
import groupSharedMediaReducer from "./slice/group-shared-media/group-shared-media-slice";
import groupSharedMediaEntriesReducer from "./slice/group-shared-media-entries/group-shared-media-entries-slice";
import template1Reducer from "./slice/community-site-template/template-1/template-1-slice";
import template2Reducer from "./slice/community-site-template/template-2/template-2-slice";
import template3Reducer from "./slice/community-site-template/template-3/template-3-slice";
import systemCommunitiesRducer from "./slice/system-community/system-community-slice";
import systemGroupsRducer from "./slice/system-groups/system-groups-slice";
import dashboardReducer from "./slice/dashboard/dashboard-slice";
import eCommerceStoreReducer from "./slice/e-commerce-store/e-commerce-store-slice";
import liveStreamReducer from "./slice/group-livestream/group-livestream-slice";
import adminDashboardReducer from "./slice/system-admin-dashboard/system-admin-dashboard-slice";
import systemEventsRducer from "./slice/system-events/system-events-slice";
import systemLiveStreamsRducer from "./slice/system-livestreams/system-livestreams-slice";

const reducer = combineReducers({
  systemGroups: systemGroupsRducer,
  systemCommunities: systemCommunitiesRducer,
  template2: template2Reducer,
  template1: template1Reducer,
  template3: template3Reducer,
  groupSharedMediaEntries: groupSharedMediaEntriesReducer,
  groupSharedMedia: groupSharedMediaReducer,
  profileUserGroups: profileUserGroupReducer,
  profileUserCommunity: profileUserCommunityReducer,
  groupOpen: groupOpenReducer,
  communityByUser: communityByUserReducer,
  currentUserCommunity: currentUserCommunityReducer,
  currentUserGroups: currentUserGroupReducer,
  group_invite: groupInviteReducer,
  myDrive: myDriveReducer,
  signalrConnection: signalrConnectionReducer,
  groupMessage: groupMessageReducers,
  notification: notificationReducer,
  dashboardCommunity: dashboardReducer,
  profileData: profileDataReducer,
  auth: authReducer,

  communityAll: communityAllReducer,
  modalReducer: modalReducer,
  support: supportReducer,
  calendar: calendar,
  mediaView: mediaView,
  openEditorReducer: openEditorReducer,
  roleAndPermission: roleAndPermissionReducer,
  memberShipPlan: memberShipPlanReducer,
  eCommerceStore: eCommerceStoreReducer,
  liveStream: liveStreamReducer,
  adminDashboardData: adminDashboardReducer,
  systemEvents: systemEventsRducer,
  systemLivestrems: systemLiveStreamsRducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "profileData", "communityByUser", "currentUserCommunity"],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable the serializableCheck since Redux-Persist uses non-serializable values
    }).concat(thunk, apiMiddleware),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

export default store;

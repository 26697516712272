import { useEffect, useState } from "react";

const LiveStreamMemberListDD = ({
  livestreamUsers,
  loading,
  getAllLiveStreamMemberList,
}) => {
  // const [loading, setLoading] = useState({ cmntLoading: false });
  // const [userCommunity, setUserCommunity] = useState([]);
  // const [livestreamMember, setLiveStreamMember] = useState([]);

  //get user community list for dropdown
  // const getUserCommunityByUserID = () => {
  //     setLoading((prev) => ({ ...prev, cmntLoading: true }));
  //     CommunityService.getUserCommunityByUserID(profileId)
  //         .then((res) => {
  //             console.log('res66', res);
  //             setUserCommunity(res.data);
  //             setLoading((prev) => ({ ...prev, cmntLoading: false }));
  //         })
  //         .catch((err) => {
  //             errorToast("Something went wrong");
  //             setLoading((prev) => ({ ...prev, cmntLoading: false }));
  //         });
  // };

  // useEffect(() => {
  //     getUserCommunityByUserID()
  // },[])

  return (
    <div
      className="d-flex align-items-center"
      // onClick={() => getAllLiveStreamMemberList()}
    >
      <div className="group-content">
        {livestreamUsers?.length > 0 &&
          livestreamUsers
            ?.map((cmnt, index) => (
              <img
                key={index}
                src={
                  cmnt?.profilePicture ||
                  require("../../../assets/Images/group_logo_img.png")
                }
                alt="Avatar"
              />
            ))
            .slice(0, 5)}
      </div>
      {/* <span className="avatar-count ms-2">
        {count > 0 ? `${("0" + count).slice(-2)}` : "No community"}
        </span> */}
    </div>
  );
};

export default LiveStreamMemberListDD;

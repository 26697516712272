import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { privateRoutes, userPrivateRoutes } from "./routes";
import AdminHeader from "../Component/header/admin-header";
import { needSidebar } from "../utils/helpers/needSidebar";
import { ConditionalWrapper } from "../Component/conditional-Wrapper/ConditionalWrapper";

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const isAuthenticated = useSelector((state) => state.auth?.token);
  const roleId = useSelector(
    (state) => state?.profileData?.profileData?.roleID
  );

  // Check if the current route path is in the privateRoutes array
  const isPrivateRoute = () => {
    const routes = roleId === 2 ? userPrivateRoutes : privateRoutes;
    const currentPath = location.pathname;

    return routes.some((route) => {
      const { path } = route;
      const regexp = new RegExp(`^${path.replace(/:\w+/g, "[^/]+")}$`);
      return regexp.test(currentPath);
    });
  };

  if (isAuthenticated && !isPrivateRoute()) {
    return <Navigate to="/admin-tools" state={{ from: location }} replace />;
  }

  if (!isAuthenticated && isPrivateRoute()) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <>
      <ConditionalWrapper
        condition={needSidebar(location?.pathname)}
        wrapper={(children) => <AdminHeader>{children}</AdminHeader>}
      >
        {children}
      </ConditionalWrapper>
    </>
  );
};

export default ProtectedRoute;

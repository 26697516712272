import { useNavigate } from "react-router";
import TruncateMessage from "../../../truncat-message/TruncateMessage";
import { formatDateForChat } from "../../../../utils/helpers/formatDateForChat";
import { isOwner } from "../../../../utils/helpers/isOwner";
import { useSelector } from "react-redux";

const LiveStreamSubGroupMessageCard = ({ message }) => {
  const { firstName, lastName, profilePicture } = useSelector(
    (state) => state?.profileData?.profileData
  );

  const test = useSelector(
    (state) => state?.profileData?.profileData.firstName
  );
  const navigate = useNavigate();

  return (
    <>
      {isOwner(message?.senderID) ? (
        <div className="outgoing-msg-box">
          <div className="outgoing-msg-content">
            <div className="d-inline-block align-middle">
              <div className="d-flex align-items-center">
                <img
                  className="rounded-circle"
                  src={
                    profilePicture ||
                    require("../../../../assets/Images/group_logo_img.png")
                  }
                  height={24}
                  width={24}
                  alt="Avatar"
                />
                <h6 className="mb-0 mx-2">{firstName + " " + lastName}</h6>

              </div>
            </div>
            <TruncateMessage message={message?.messageString} length={500} />
            {/* <small className="d-block text-end">
              {formatDateForChat(message?.timestamp)}
            </small> */}
          </div>
        </div>
      ) : (
        <div className="incoming-msg-box">
          <div className="incoming-msg-content">
            <div className="d-inline-block align-middle">
              <div className="d-flex align-items-center">
                <img
                  className="rounded-circle"
                  src={
                    message?.senderProfilePicture||
                    require("../../../../assets/Images/group_logo_img.png")
                  }
                  height={24}
                  width={24}
                  alt="Avatar"
                />
                <h6
                  className="mb-0 mx-2 cursor-pointer"
                  onClick={() => navigate("/profile/about/" + message?.senderID)}
                >
                 { message?.senderName}
                </h6>
              </div>
            </div>
            <TruncateMessage message={message?.messageString} length={500} />
            {/* <small className="d-block text-end">
              {" "}
              {formatDateForChat(message?.timestamp)}
            </small> */}
          </div>
        </div>
      )}
    </>
  );
};

export default LiveStreamSubGroupMessageCard;

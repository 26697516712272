import { createAsyncThunk } from "@reduxjs/toolkit";
import GroupService from "../../../Services/group/GroupService";

export const getAllLiveStream = createAsyncThunk(
  "liveStream/getAllLiveStream",
  async (getGroupID) => {
    const response = await GroupService.getAllGroupLiveStreamData(getGroupID);

    if (response?.status !== 200) {
      throw new Error(response?.data?.message);
    } else {
      return response?.data;
    }
  }
);

export const getAllUpcomingLiveStreamsListActionThunk = createAsyncThunk(
  "liveStream/getAllUpcomingLiveStreams",
  async (groupId) => {
    const response = await GroupService.getUpcomingLiveStremsList(groupId);
    if (response?.status !== 200) {
      throw new Error(response?.data?.message);
    } else {
      return response?.data;
    }
  }
);

export const getAllOngoingLiveStreamsListActionThunk = createAsyncThunk(
  "liveStream/getAllOngoingLiveStreams",
  async (groupId) => {
    const response = await GroupService.getOngoingLiveStremsList(groupId);
    if (response?.status !== 200) {
      throw new Error(response?.data?.message);
    } else {
      return response?.data;
    }
  }
);

export const getAllCompletedLiveStreamsListActionThunk = createAsyncThunk(
  "liveStream/getAllCompletedLiveStreams",
  async (groupId) => {
    const response = await GroupService.getCompletedLiveStremsList(groupId);
    if (response?.status !== 200) {
      throw new Error(response?.data?.message);
    } else {
      return response?.data;
    }
  }
);

export const updateLiveStream = createAsyncThunk(
  "liveStream/updateLiveStream",
  async (data) => {
    const response = await GroupService.updateLiveStreamDetails(data);
    if (response?.data?.statusCode !== 200) {
      throw new Error(response?.data?.message);
    } else {
      return response?.data?.data;
    }
  }
);

export const deleteLiveStreamActionThunk = createAsyncThunk(
  "liveStream/deleteLiveStreamActionThunk",
  async ({ liveStreamId, groupId }, { dispatch }) => {
    const response = await GroupService.deleteLiveStream(liveStreamId);
    if (response?.data?.statusCode !== 200) {
      throw new Error(response?.data?.message);
    } else {
      // dispatch(getAllLiveStream(groupId));
      return response?.data?.data;
    }
  }
);

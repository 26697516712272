import {
  AUTH_LOADED,
  AUTH_LOADING,
  CHANGE_ROLE_LOADED,
  CHANGE_ROLE_LOADING,
  EMPTY_VISITOR_DATA,
  GET_VISITORS_DATA,
  LOGIN_SUCCESS,
  LOGOUT,
  VISITOR_LOADER,
} from "./auth.constant";
import { REHYDRATE } from 'redux-persist';

const INITIAL_STATE = {
  loading: false,
  changeRoleLoading: false,
  loginData: null,
  visitorData: { loading: false, data: null },

  token: null,
  refreshToken: null,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case AUTH_LOADING:
      return { ...state, loading: true };

    case AUTH_LOADED:
      return { ...state, loading: false };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        loginData: action?.payload?.Token ? action.payload : state?.loginData,
        token: action?.payload?.Token,
        refreshToken: action.payload?.RefreshToken
      };

    case LOGOUT:
      localStorage.removeItem("lToken");
      localStorage.removeItem("lRefreshToken");
      return {
        ...state,
        loginData: {},
        membershipDetails: {},
        token: null,
        refreshToken: null,
      };

    case CHANGE_ROLE_LOADING:
      return { ...state, changeRoleLoading: true };

    case CHANGE_ROLE_LOADED:
      return { ...state, changeRoleLoading: false };

    /**
     * visitor cases
     */
    case VISITOR_LOADER:
      return {
        ...state,
        visitorData: { ...state?.visitorData, loading: action?.payload }
      };
    case GET_VISITORS_DATA:
      return {
        ...state,
        visitorData: { ...state?.visitorData, data: action?.payload }
      }

    case EMPTY_VISITOR_DATA:
      return {
        ...state,
        visitorData: null
      }

    case REHYDRATE: {
      // Check if the rehydrated action is for the 'auth' key
      if (action?.payload && action?.payload?.auth) {
        // Update the 'loading' property to false
        return { ...action?.payload?.auth, loading: false };
      }
      return state;
    }
    default:
      return state;
  }
};

export default authReducer;

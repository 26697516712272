import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Modal } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useDispatch, useSelector } from "react-redux";
import CombineCropperModal from "../../../../image-cropper/CombineCropperModal";
import SkeletonForImg from "../../../../loader/SkeletonForImg";
import {
  closeTemplate3SectionEditorAction,
  saveTemplate3SectionBySectionIDAction,
  setPreviewOfTemplate3Action,
} from "../../../../../ReduxStore/slice/community-site-template/template-3/template-3-slice";
import { updateSectionOfTemplate3BySiteIDActionThunk } from "../../../../../ReduxStore/slice/community-site-template/template-3/template-3-async-thynk";
import ReactQuill from "react-quill";
import OpenAI from "openai";
// const { Configuration, OpenAI } = require("openai");

const EditModal = ({ data, setData }) => {
  const dispatch = useDispatch();
  const [generatedImage, setGeneratedImage] = useState("");

  //const apiKey = "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";

  const apiKey = `${process.env.REACT_APP_OPEN_AI_API_KEY}`;
  // console.log(apiKey);

  // const configuration = () =>
  //   new Configuration({
  //     apiKey: apiKey,
  //     // process.env.OPEN_AI_API_KEY;
  //   });

  const openai = new OpenAI({
    apiKey: apiKey,
    dangerouslyAllowBrowser: true,
  });

  async function fetchData() {
    try {
      const response = await openai.images.generate({
        prompt: "Cat with wings",
        n: 1,
        size: "512x512",
      });
      console.log(response.data.data[0].url);
      setGeneratedImage(
        "https://donaidestage.blob.core.windows.net/prod/644be2364d4cba5ab3cca71f/Image_47.jpg"
      );
    } catch (e) {
      setGeneratedImage(
        "https://donaidestage.blob.core.windows.net/prod/644be2364d4cba5ab3cca71f/Image_47.jpg"
      );
      console.log(e);
    }
  }
  // const openai = () => new OpenAIApi(configuration);

  const siteID = useSelector((state) => state?.template3?.siteID);
  const save_btn_loading = useSelector(
    (state) => state?.template3?.loading?.section6
  );
  const show = useSelector((state) => {
    const is_Editable = state?.template3?.is_Editable;
    return is_Editable?.status && is_Editable?.current_edit_section === 6;
  });

  const [folderIndex, setSetFolderIndex] = useState(-1);

  const [generateModal, setGenerateModal] = useState(false);

  const [showImageCroperModal, setShowImageCroperModal] = useState({
    status: false,
    type: "",
  });
  const [src, setSrc] = useState({ image: null, fileName: "" });

  /**
   * close image crop modal and set the selected image in setBackroundImage props
   * @param {*} blob
   */
  const handleCloseImageCropModal = (fileUrl) => {
    setShowImageCroperModal({ type: false });

    if (fileUrl) {
      if (showImageCroperModal?.type === "folder") {
        const tempArr = JSON.parse(JSON.stringify(data?.GalleryImages));
        tempArr?.push({ src: fileUrl, gallery: [] });
        setData((prevData) => ({ ...prevData, GalleryImages: tempArr }));
      } else if (showImageCroperModal?.type === "image") {
        const tempFolder = JSON.parse(JSON.stringify(data?.GalleryImages));
        const tempArr = [...data?.GalleryImages[folderIndex]?.gallery];
        tempArr?.push({ key: tempArr?.length, src: fileUrl });
        tempFolder[folderIndex].gallery = tempArr;
        setData((prevData) => ({ ...prevData, GalleryImages: tempFolder }));
      }
      // const tempArr = [...data?.GalleryImages]
      // tempArr?.push({ SRC: fileUrl });
      // setData(prevData => ({ ...prevData, GalleryImages: tempArr }))
      // setFileUploadLoading(false);
    }
  };

  //  handleClick() {

  //   this.setGenerateModel(true);
  // };

  /**
   * image input field onChange handler
   */
  const changeHandlerImage = (event, type) => {
    console.log("image button clicked");
    setSrc({
      image: URL.createObjectURL(event.target.files[0]),
      fileName: event.target.files[0]?.name,
      fileType: event.target.files[0]?.type,
    });
    setShowImageCroperModal({ status: true, type: type });
    //event.target.value = null;
  };

  /**
   * Delete Image Handler
   * @param {*} index
   */
  const deleteImageHandler = (index, type) => {
    if (type === "folder") {
      const tempArr = [...data?.GalleryImages];
      tempArr?.splice(index, 1);
      setData((prev) => ({ ...prev, GalleryImages: tempArr }));
    } else if (type === "image") {
      const tempFolder = [...data?.GalleryImages];
      const tempArr = [...data?.GalleryImages[folderIndex]?.gallery];
      tempArr?.splice(index, 1);
      tempFolder[folderIndex].gallery = tempArr;
      setData((prevData) => ({ ...prevData, GalleryImages: tempFolder }));
    }
  };

  const urlInputFieldHandler = (e, i) => {
    const tempFolder = [...data?.GalleryImages];
    const tempArr = [...data?.GalleryImages[folderIndex]?.gallery];
    tempArr[i]["url"] = e.target.value;
    tempFolder[folderIndex].gallery = tempArr;
    setData((prevData) => ({ ...prevData, GalleryImages: tempFolder }));
  };

  /**
   * edit modal close handler
   */
  const editModalCloseHandler = () => {
    dispatch(closeTemplate3SectionEditorAction({ sectionID: 6, data }));
  };

  /**
   * preview button handler
   */
  const previewButtonHandler = () => {
    dispatch(saveTemplate3SectionBySectionIDAction({ sectionID: 6, data }));
    dispatch(setPreviewOfTemplate3Action());
  };

  /**
   * Save Section Data
   */
  const saveSectionDataHandler = () => {
    dispatch(
      updateSectionOfTemplate3BySiteIDActionThunk({
        siteID,
        sectionID: 6,
        data,
      })
    );
  };

  useEffect(() => {
    setSetFolderIndex(-1);
  }, [data?.GalleryImages?.length]);

  return (
    <>
      <Modal
        show={generateModal}
        // onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Generate image with help of AI</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>Enter Prompt To Generate Image:</label>
          <br />
          <input
            type="text"
            className="form-control "
            placeholder="Enter Prompt"
            style={{
              height: "35px",
              marginTop: "5px",
            }}
            // value={data?.Heading}
            onChange={(e) =>
              setData((prevData) => ({
                ...prevData,
                Heading: e.target?.value,
              }))
            }
          ></input>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Button
              onClick={() => {
                fetchData();
              }}
            >
              Generate
            </Button>
          </div>
          <br />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {generatedImage != "" && (
              <img
                src={generatedImage}
                style={{ height: "350px", width: "350px" }}
              ></img>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              // fetchData();
              setGeneratedImage("");
              setGenerateModal(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setGenerateModal(false);
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Offcanvas
        show={show}
        onHide={editModalCloseHandler}
        placement="end"
        scroll={true}
      >
        <Offcanvas.Header closeButton className="border-bottom">
          <Offcanvas.Title>Edit</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="d-flex flex-column">
          <Form
            className="d-flex flex-column flex-fill"
            onSubmit={(e) => {
              e.preventDefault();
              saveSectionDataHandler();
            }}
          >
            <Form.Group controlId="plainText" className="mb-4">
              <Form.Label>Heading</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Title"
                value={data?.Heading}
                onChange={(e) =>
                  setData((prevData) => ({
                    ...prevData,
                    Heading: e.target?.value,
                  }))
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="plainText">
              <Form.Label>Content</Form.Label>
              <ReactQuill
                preserveWhitespace
                value={data?.Content}
                onChange={(values) =>
                  setData((prevData) => ({
                    ...prevData,
                    Content: values,
                  }))
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Background color</Form.Label>
              <Form.Control
                type="color"
                value={data?.BackgroundColor}
                onChange={(e) =>
                  setData((prevData) => ({
                    ...prevData,
                    BackgroundColor: e.target?.value,
                  }))
                }
              />
            </Form.Group>
            {/* Folders */}
            <input
              hidden
              type="file"
              name=""
              id="input-folder"
              accept="image/x-png,image/jpeg,image/jpg"
              onChange={(e) => {
                const file = e.target.files && e.target.files[0];
                if (file) {
                  changeHandlerImage(e, "folder");
                }
              }}
            />
            <label
              className="btn btn-primary-theme font-size-14 text-white w-100 mb-3"
              htmlFor="input-folder"
            >
              Add Folder
            </label>
            {/* <Button
              className="btn-primary-theme font-size-14 text-white w-100 mb-3"
              onClick={() => {
                setGenerateModal(true);
              }}
            >
              Generate Image
            </Button> */}
            <Row className="form-row">
              {data?.GalleryImages?.map((img, i) => (
                <Col
                  xs={4}
                  className="position-relative modal-gallery-images mb-3"
                  key={i}
                >
                  <div
                    key={i}
                    style={
                      folderIndex === i
                        ? {
                            height: "100px",
                            width: "100%",
                            cursor: "pointer",
                            boxShadow: "2px 5px 5px black",
                            transition: "0.3s",
                          }
                        : {
                            height: "100px",
                            width: "100%",
                            cursor: "pointer",
                          }
                    }
                    onClick={() => {
                      setSetFolderIndex(i);
                    }}
                  >
                    <SkeletonForImg
                      src={img?.src}
                      styles={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <span
                    className="img-close-icon"
                    onClick={() => deleteImageHandler(i, "folder")}
                  >
                    <i className="fa fa-times"></i>
                  </span>
                </Col>
              ))}
            </Row>
            {/* Images */}
            {folderIndex >= 0 && (
              <>
                <input
                  hidden
                  type="file"
                  name=""
                  id="input-image"
                  accept="image/x-png,image/jpeg,image/jpg"
                  onChange={(e) => {
                    const file = e.target.files && e.target.files[0];
                    if (file) {
                      changeHandlerImage(e, "image");
                    }
                  }}
                />
                <label
                  className="btn btn-primary-theme font-size-14 text-white w-100 mb-3"
                  htmlFor="input-image"
                >
                  Add Photos
                </label>
                <Row className="form-row">
                  {data?.GalleryImages[folderIndex]?.gallery?.length > 0 ? (
                    data?.GalleryImages[folderIndex]?.gallery?.map((img, i) => (
                      <>
                        <Col
                          xs={4}
                          className="position-relative modal-gallery-images"
                          key={i}
                        >
                          <SkeletonForImg
                            src={img?.src}
                            styles={{
                              height: "100%",
                              width: "100%",
                              objectFit: "cover",
                            }}
                          />
                          <span
                            className="img-close-icon"
                            onClick={() => deleteImageHandler(i, "image")}
                          >
                            <i className="fa fa-times"></i>
                          </span>
                          <input
                            placeholder="Enter URL"
                            className="my-1"
                            value={img?.url || ""}
                            style={{ width: "100px" }}
                            type="url"
                            onChange={(e) => urlInputFieldHandler(e, i)}
                          />
                        </Col>
                      </>
                    ))
                  ) : (
                    <Col>
                      <span className="w-100 my-2 text-center">No Images</span>
                    </Col>
                  )}
                </Row>
              </>
            )}
            <div className="pt-3 d-flex justify-content-end border-top mt-auto">
              <Button
                onClick={() => previewButtonHandler()}
                className="btn-secondary-theme btn-outline me-2"
              >
                Preview
              </Button>
              <Button
                className="btn-primary-theme font-size-14"
                type="submit"
                disabled={save_btn_loading}
              >
                Save{" "}
                {save_btn_loading && (
                  <i className="fa fa-spinner fa-spin ms-2"></i>
                )}
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      {showImageCroperModal?.status && (
        <CombineCropperModal
          show={showImageCroperModal?.status}
          handleClose={handleCloseImageCropModal}
          src={src}
          aspect={1 / 1}
          canChangeAspect={true}
          spectString="1 / 1"
          cropRation={{
            unit: "%",
            x: 0,
            y: 0,
            // aspect: 1,
            height: 100,
            width: 100,
          }}
        />
      )}
    </>
  );
};

export default EditModal;

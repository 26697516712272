import { createSlice } from "@reduxjs/toolkit";
import { errorToast, successToast } from "../../../Component/toast/toast";
import {
    addFileInSharedMediaAsyncThunk,
    createFoldrInSharedMediaAsyncThunk,
    deleteFileInSharedMediaAsyncThunk,
    deleteFolderInSharedMediaAsyncThunk,
    donwloadFileFromSharedMediaAsyncThunk,
    fetchFolderOfSharedMediaAsyncThunk,
    moveFolderOrFileInSharedMediaAsyncThunk,
    renameFileInSharedMediaAsyncThunk,
    renameFolderInSharedMediaAsyncThunk
} from "./group-shared-media-async-thunk";


const initialState = {

    currentGroupId: "",
    currentFolderId: null,

    loading: {
        get_file_entries: false,
        add_folder: false,
        delete_folder: { status: false, folderID: "" },
        rename_folder: false,
        add_file: false,
        rename_file: false,
        delete_file: false,
        fetch_storage: false,
        move_file_or_folder: { ids: [], status: false },
        file_downloading_progress: [],
        download_file: false
    },

    errors: {
        file_entries: null,
        delete_folder: null,
        rename_folder: null,
        get_file_entries: null,
        add_file: null,
        rename_file: null,
        delete_file: null,
        fetch_storage: null,
        move_file_or_folder: null,
        download_file: null,
    },

    modals: {
        addModal: false,
        addFolderModal: false,
        addFileModal: false,
        rename_folder: { status: false, folder: null },
        rename_file: { status: false, file: null },
        select_folder_to_move_files: { status: false },
    },

    file_selection: {
        is_enable: false,
        selected_files: [], //{ FileID: "", SRC: "" }
    },

    file_enteries: { SubFolders: [], Files: [] },

    file_preview: { status: false, file: null },

    fetch_storage: { percentage: 0, usage: "" },

    file_upload_progress: [],

    dragged_card: { id: "", type: "", data: "" }
};

const groupSharedMediaSlice = createSlice({
    name: "groupSharedMedia",
    initialState,
    reducers: {
        setCurrentGroupIdInSharedMediaAction: (state, action) => {
            state.currentGroupId = action?.payload
        },
        setCurrentFolderInSharedMediaAction: (state, action) => {
            state.currentFolderId = action?.payload;
            // fetchUserFolderAsyncThunk({ folderID: action?.payload, loading: true });
        },
        setSharedMediaModalsAction: (state, action) => {
            state.modals = action?.payload
        },

        setFilePreviewInSharedMediaAction: (state, action) => {
            state.file_preview.status = action?.payload?.status;
            state.file_preview.file = action?.payload?.file;
        },
        handleUploadProgressInSharedMediaAction: (state, action) => {
            const existingIndex = state.file_upload_progress.findIndex((item) => Object.keys(item)[0] === action?.payload?.fileName);
            if (existingIndex > -1) {
                state.file_upload_progress[existingIndex] = { [action?.payload?.fileName]: action?.payload?.progress, };
            } else {
                state.file_upload_progress.push({ [action?.payload?.fileName]: action?.payload?.progress, });
            }
        },
        handleDownloadProgressInSharedMediaAction: (state, action) => {
            const existingIndex = state.loading.file_downloading_progress.findIndex((item) => Object.keys(item)[0] === action?.payload?.fileName);
            if (existingIndex > -1) {
                state.loading.file_downloading_progress[existingIndex] = { [action?.payload?.fileName]: action?.payload?.progress, };
            } else {
                state.loading.file_downloading_progress.push({ [action?.payload?.fileName]: action?.payload?.progress, });
            }
        },
        draggedCardInSharedMediaDataHandlerAction: (state, action) => {
            state.dragged_card = action?.payload
        },
        enableFileSelectionInSharedMediaAction: (state, action) => {
            state.file_selection.is_enable = true;
        },
        cancelFileSelectionInSharedMediaAction: (state, action) => {
            state.file_selection.is_enable = false;
            state.file_selection.selected_files = [];
        },
        selectFileInSharedMediaAction: (state, action) => {
            const index = state?.file_selection?.selected_files?.findIndex(file => file?.FileID === action?.payload?.FileID);
            if (index > -1) {
                state?.file_selection?.selected_files?.splice(index, 1);
            } else {
                state.file_selection.selected_files = [...state.file_selection.selected_files, action?.payload]
            }
        },
        selectAllFileInSharedMediaAction: (state, action) => {
            state.file_selection.selected_files = [...state.file_enteries?.Files]
        },
    },

    extraReducers: (builder) => {
        builder
            //get file entries
            .addCase(fetchFolderOfSharedMediaAsyncThunk.pending, (state, action) => {
                state.loading.get_file_entries = action?.meta?.arg?.loading ? true : false;
                state.errors.get_file_entries = null;
            })
            .addCase(fetchFolderOfSharedMediaAsyncThunk.fulfilled, (state, action) => {
                state.loading.get_file_entries = false;
                state.errors.get_file_entries = null;
                const folderData = JSON.parse(action?.payload?.data);
                if (action?.meta?.arg?.folderID === state?.currentFolderId) {
                    state.file_enteries = folderData;
                    folderData?.Files?.length < 1 && (state.file_selection = { is_enable: false, selected_files: [] })
                }
            })
            .addCase(fetchFolderOfSharedMediaAsyncThunk.rejected, (state, action) => {
                state.loading.get_file_entries = false;
                state.errors.get_file_entries = action?.payload?.message;
                state.file_enteries = { SubFolders: [], Files: [] };
                errorToast(action?.error?.message);
            })

            //create folder
            .addCase(createFoldrInSharedMediaAsyncThunk.pending, (state) => {
                state.loading.add_folder = true;
                state.errorPosts = null;
            })
            .addCase(createFoldrInSharedMediaAsyncThunk.fulfilled, (state, action) => {
                state.loading.add_folder = false;
                state.file_enteries = { ...state?.file_enteries, SubFolders: [...state?.file_enteries?.SubFolders, JSON.parse(action.payload?.data)] };
                state.modals = { add_folder: false, addFolderModal: false, addFileModal: false, }
                successToast("Folder created")
            })
            .addCase(createFoldrInSharedMediaAsyncThunk.rejected, (state, action) => {
                state.loading.add_folder = false;
                state.errorPosts = action?.error?.message;
            })

            //delete folder 
            .addCase(deleteFolderInSharedMediaAsyncThunk.pending, (state, action) => {
                state.loading.delete_folder = { status: true, folderID: action?.meta?.arg };
                state.errors.delete_folder = null;
            })
            .addCase(deleteFolderInSharedMediaAsyncThunk.fulfilled, (state, action) => {
                const index = state?.file_enteries?.SubFolders?.findIndex(folder => folder?.FolderID === action?.meta?.arg)
                if (index > -1) {
                    state?.file_enteries?.SubFolders?.splice(index, 1);
                }
                state.loading.delete_folder = { status: false, folderID: "" };
                state.errors.delete_folder = null;
            })
            .addCase(deleteFolderInSharedMediaAsyncThunk.rejected, (state, action) => {
                state.loading.delete_folder = { status: false, folderID: "" };
                state.errors.delete_folder = action?.error?.message;
                errorToast(action?.error?.message);
            })

            //rename folder 
            .addCase(renameFolderInSharedMediaAsyncThunk.pending, (state, action) => {
                state.loading.rename_folder = true;
                state.errors.rename_folder = null;
            })
            .addCase(renameFolderInSharedMediaAsyncThunk.fulfilled, (state, action) => {
                const index = state?.file_enteries?.SubFolders?.findIndex(folder => folder?.FolderID === action?.meta?.arg?.folderID)
                if (index > -1) {
                    state.file_enteries.SubFolders[index].FolderName = action?.meta?.arg?.folderName;
                }
                state.loading.rename_folder = false;
                state.errors.rename_folder = null;
                state.modals.rename_folder = { status: false, folder: null }
            })
            .addCase(renameFolderInSharedMediaAsyncThunk.rejected, (state, action) => {
                state.loading.rename_folder = false;
                state.errors.rename_folder = action?.error?.message;
            })

            //add file
            .addCase(addFileInSharedMediaAsyncThunk.pending, (state, action) => {
                state.loading.add_file = true;
                state.errors.add_file = null;
            })
            .addCase(addFileInSharedMediaAsyncThunk.fulfilled, (state, action) => {
                state.loading.add_file = false;
                state.errors.add_file = null;
                state.modals.addModal = false;
                state.file_upload_progress = [];
                successToast("File added successfully")
            })
            .addCase(addFileInSharedMediaAsyncThunk.rejected, (state, action) => {
                state.loading.add_file = false;
                state.errors.add_file = action?.error?.message;
                state.file_upload_progress = [];
                errorToast(action?.error?.message);
            })

            //rename file 
            .addCase(renameFileInSharedMediaAsyncThunk.pending, (state, action) => {
                state.loading.rename_file = true;
                state.errors.rename_file = null;
            })
            .addCase(renameFileInSharedMediaAsyncThunk.fulfilled, (state, action) => {
                const index = state?.file_enteries?.Files?.findIndex(file => file?.FileID === action?.meta?.arg?.fileID)
                if (index > -1) {
                    state.file_enteries.Files[index].Name = action?.meta?.arg?.fileName;
                }
                state.loading.rename_file = false;
                state.errors.rename_file = null;
                state.modals.rename_file = { status: false, file: null }
            })
            .addCase(renameFileInSharedMediaAsyncThunk.rejected, (state, action) => {
                state.loading.rename_file = false;
                state.errors.rename_file = action?.error?.message;
            })

            //delete file 
            .addCase(deleteFileInSharedMediaAsyncThunk.pending, (state, action) => {
                state.loading.delete_file = { status: true, fileID: action?.meta?.arg };
                state.errors.delete_file = null;
            })
            .addCase(deleteFileInSharedMediaAsyncThunk.fulfilled, (state, action) => {
                state.loading.delete_file = { status: false, fileID: "" };
                state.errors.delete_file = null;
            })
            .addCase(deleteFileInSharedMediaAsyncThunk.rejected, (state, action) => {
                state.loading.delete_file = { status: false, fileID: "" };
                state.errors.delete_file = action?.error?.message;
                errorToast(action?.error?.message);
            })

            // //fetch storage information
            // .addCase(fetchUserStorageUsageAsyncThunk.pending, (state, action) => {
            //     state.loading.fetch_storage = true;
            //     state.errors.fetch_storage = null;
            // })
            // .addCase(fetchUserStorageUsageAsyncThunk.fulfilled, (state, action) => {
            //     state.loading.fetch_storage = false;
            //     state.errors.fetch_storage = null;
            //     state.fetch_storage = action?.payload?.data
            // })
            // .addCase(fetchUserStorageUsageAsyncThunk.rejected, (state, action) => {
            //     state.loading.fetch_storage = false;
            //     state.errors.fetch_storage = action?.error?.message;
            //     state.fetch_storage = null;
            // })

            //move file or folder 
            .addCase(moveFolderOrFileInSharedMediaAsyncThunk.pending, (state, action) => {
                state.loading.move_file_or_folder = { ids: action?.meta?.arg?.fileIdList, status: true };
                state.errors.move_file_or_folder = null;
            })
            .addCase(moveFolderOrFileInSharedMediaAsyncThunk.fulfilled, (state, action) => {
                state.loading.move_file_or_folder = { ids: [], status: false }
                state.errors.move_file_or_folder = null;
            })
            .addCase(moveFolderOrFileInSharedMediaAsyncThunk.rejected, (state, action) => {
                state.loading.move_file_or_folder = { ids: [], status: false }
                state.errors.move_file_or_folder = action?.error?.message;
            })

            //download files
            .addCase(donwloadFileFromSharedMediaAsyncThunk.pending, (state, action) => {
                state.loading.download_file = true;
                state.errors.download_file = null;
            })
            .addCase(donwloadFileFromSharedMediaAsyncThunk.fulfilled, (state, action) => {
                state.loading.download_file = false;
                state.errors.download_file = null;
                state.loading.file_downloading_progress = [];
            })
            .addCase(donwloadFileFromSharedMediaAsyncThunk.rejected, (state, action) => {
                state.loading.download_file = false;
                state.errors.download_file = action?.error?.message;
                state.loading.file_downloading_progress = [];
                errorToast(action?.error?.message);
            })
    },
});

export const {
    setCurrentGroupIdInSharedMediaAction,
    setCurrentFolderInSharedMediaAction,
    setSharedMediaModalsAction,
    setFilePreviewInSharedMediaAction,
    handleUploadProgressInSharedMediaAction,
    handleDownloadProgressInSharedMediaAction,
    draggedCardInSharedMediaDataHandlerAction,
    enableFileSelectionInSharedMediaAction,
    cancelFileSelectionInSharedMediaAction,
    selectFileInSharedMediaAction,
    selectAllFileInSharedMediaAction,
} = groupSharedMediaSlice.actions;

const groupSharedMediaReducer = groupSharedMediaSlice?.reducer;

export default groupSharedMediaReducer;

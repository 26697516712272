import { useState } from 'react';
import { truncateText } from "../../utils/helpers/truncateHtml.js"
import { renderContentWithLinks } from '../../utils/helpers/renderContentWithLinks.js';
import { Button } from 'react-bootstrap';

const TruncateMessage = ({ message, length, pStyle, readBntStyle }) => {


    const [isTruncated, setIsTruncated] = useState(true);

    function toggleTruncate() {
        setIsTruncated(!isTruncated);
    }

    return (
        <>
            <p className={`${pStyle} ? "" : "m-0"`} style={pStyle}>
                <span dangerouslySetInnerHTML={{ __html: isTruncated ? renderContentWithLinks(truncateText(message, length)) : renderContentWithLinks(message) }} />
                {message?.length > length && (
                    <Button
                        variant='link'
                        className={`ms-1 p-0 ${readBntStyle ? "" : "text-primary-theme"} text-decoration-none pointer`}
                        onClick={toggleTruncate}
                        style={readBntStyle}
                    >
                        {isTruncated ? 'Read More' : 'Read Less'}
                    </Button>
                )}
            </p>
        </>
    )
}

export default TruncateMessage
import { errorToast } from "../../Component/toast/toast";
import { getFileNameFromURL } from "./getFileNameFromURL";

/**
    * download doucment
    * @param {*} url 
    */
export const handleDownload = async (url) => {
    try {
        const response = await fetch(url);
        const data = await response.blob();
        const blob = URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = blob;
        link.download = getFileNameFromURL(url);
        link.click();
        URL.revokeObjectURL(blob);
        link.remove();
    } catch (error) {
        errorToast(error.message)
    }
};
import { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import FolderCard from '../file-type-card/folder-card/FolderCard';
import ImageCard from '../file-type-card/ImageCard';
import VideoCard from '../file-type-card/VideoCard';
import AudioCard from '../file-type-card/AudioCard';
import TextCard from '../file-type-card/TextCard';
import PdfCard from '../file-type-card/PdfCard';
import PowerPointCard from '../file-type-card/PowerPointCard';
import SpreadsheetCard from '../file-type-card/SpreadsheetCard';
import ArchiveCard from '../file-type-card/ArchiveCard';
import WordCard from '../file-type-card/WordCard';

const SharedMediaEntriesDashboard = () => {

    const folders = useSelector((state) => state?.groupSharedMediaEntries?.file_enteries?.SubFolders);
    const file = useSelector(state => state?.groupSharedMediaEntries?.file_enteries?.Files);

    const [file_enteries_data, set_file_enteries_data] = useState([]);

    useEffect(() => {
        const tempData = [];
        folders?.map(folder => {
            const tempFolder = { ...folder };
            tempFolder.FileType = "folder";
            tempData?.push(tempFolder);
        });
        file?.map(file => tempData?.push(file));
        set_file_enteries_data(tempData)
    }, [file, folders])

    return (
        <>
            {file_enteries_data?.length > 0 ? (
                file_enteries_data?.map((file, index) => (
                    <Col key={index} xs='6' md='4'>
                        {file?.FileType === "folder" ? (
                            <FolderCard fileObj={file} />
                        ) : file?.FileType === "image" ? (
                            <ImageCard fileObj={file} />
                        ) : file?.FileType === "video" ? (
                            <VideoCard fileObj={file} />
                        ) : file?.FileType === "audio" ? (
                            <AudioCard fileObj={file} />
                        ) : (file?.FileType === "text") ? (
                            <TextCard fileObj={file} />
                        ) : file?.FileType === "pdf" ? (
                            <PdfCard fileObj={file} />
                        ) : file?.FileType === "powerpoint" ? (
                            <PowerPointCard fileObj={file} />
                        ) : file?.FileType === "excel" ? (
                            <SpreadsheetCard fileObj={file} />
                        ) : file?.FileType === "archive" ? (
                            <ArchiveCard fileObj={file} />
                        ) : (file?.FileType === "word") ? (
                            <WordCard fileObj={file} />
                        ) : (<WordCard fileObj={file} />)}
                    </Col>
                ))
            ) : (
                <div className="spinner-container">
                    <h5 className="font-size-18 m-0 text-black-theme"> No Record Found</h5>
                </div>
            )}
        </>
    )
}

export default SharedMediaEntriesDashboard
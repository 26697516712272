import { Modal } from 'react-bootstrap'
import FullCalendarComp from './Calendar'

const CalendarInModal = ({ show, handleClose, groupID }) => {
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
            >
                <Modal.Header className="px-lg-4" closeButton>
                    <Modal.Title className='text-black-theme h5'>Event Calendar</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-lg-4 px-2'>
                    <FullCalendarComp groupID={groupID} />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CalendarInModal
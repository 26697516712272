import striptags from "striptags";

export const truncateHtml = (html, maxLength) => {
  console.log(html);
  let plainText = striptags(String(html)); // Remove HTML tags
  // let plainText = html; // Remove HTML tags
  if (plainText.length > maxLength) {
    plainText = plainText.substring(0, maxLength) + "..."; // Truncate text
  }
  return plainText;
};

export const truncateText = (text, maxLength) => {
  if (text?.length > maxLength) {
    return text?.substring(0, maxLength - 3) + "...";
  } else return text;
};

import { getSubDomainFromUrl } from "./get-sub-domain";

/**
 * Navigate to the /site route, preserving protocol and subdomain
 * @param {*} subDomain
 */
export const navigateToTemplate = (subDomain) => {

    // Get the current URL
    const currentURL = window?.location?.href;

    // Extract the protocol and host (including port if present)
    const urlParts = currentURL?.split('//');
    const protocol = urlParts[0];
    const restOfUrl = urlParts[1]?.split('/')[0];

    // Check if a subdomain is already present in the host
    const haveSubDomain = getSubDomainFromUrl();

    // Remove the current subdomain (if present)
    const newHostWithPort = haveSubDomain
        ? restOfUrl?.replace(`${haveSubDomain}.`, "")
        : restOfUrl;

    // Construct the new URL with the provided subdomain and the /site route
    const newURL = `${protocol}//${subDomain}.${newHostWithPort}/site`;

    // Open a new tab with the updated URL
    window.open(newURL, '_blank');
};

/**
 * get the community template url
 * @param {*} subDomain
 */
export const getTemplateUrl = (subDomain) => {

    // Get the current URL
    const currentURL = window?.location?.href;

    // Extract the protocol and host (including port if present)
    const urlParts = currentURL?.split('//');
    const protocol = urlParts[0];
    const restOfUrl = urlParts[1]?.split('/')[0];

    // Check if a subdomain is already present in the host
    const haveSubDomain = getSubDomainFromUrl();

    // Remove the current subdomain (if present)
    const newHostWithPort = haveSubDomain
        ? restOfUrl?.replace(`${haveSubDomain}.`, "")
        : restOfUrl;

    // Construct the new URL with the provided subdomain and the /site route
    const newURL = `${protocol}//${subDomain}.${newHostWithPort}/site`;

    // Open a new tab with the updated URL
    return newURL
};







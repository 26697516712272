import { REHYDRATE } from "redux-persist";
import {
  COMMUNITY_LOADED,
  COMMUNITY_LOADING,
  GET_USER_GROUP_LIST,
  GET_USER_COMMUNITY_LIST,
  GET_GROUP_DETAILS,
  GET_USER_GROUP_DETAILS,
  DO_EMPTY_COMMUNITY_BY_USER_STATE,
} from "./community.constant";

const INITIAL_STATE = {
  loading: false,
  communities: [],
  group: { loading: false, groups: [] },
  groupView: { loading: false, details: {}, detailsByUser: {} },
};

const communityByUserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COMMUNITY_LOADING:
      return { ...state, ...(action?.payload === 1 ? { loading: true } : action?.payload === 2 ? { group: { ...state?.group, loading: true } } : { groupView: { ...state?.groupView, loading: true } }) };

    case COMMUNITY_LOADED:
      return { ...state, ...(action?.payload === 1 ? { loading: false } : action?.payload === 2 ? { group: { ...state?.group, loading: false } } : { groupView: { ...state?.groupView, loading: false } }) };

    case GET_USER_COMMUNITY_LIST:
      return {
        ...state,
        loading: false,
        communities: action?.payload,
      };

    case GET_USER_GROUP_LIST:
      return { ...state, group: { ...state?.group, groups: action?.payload } }

    case GET_GROUP_DETAILS:
      return { ...state, groupView: { ...state?.groupView, details: action?.payload } }

    case GET_USER_GROUP_DETAILS:
      return { ...state, groupView: { ...state?.groupView, detailsByUser: action?.payload } }

    case DO_EMPTY_COMMUNITY_BY_USER_STATE:
      return {
        loading: false,
        communities: [],
        group: { loading: false, groups: [] },
        groupView: { loading: false, details: {}, detailsByUser: {} },
      }

    case REHYDRATE:
      if (action?.payload && action?.payload?.communityByUser) {
        return {
          ...action?.payload?.communityByUser,
          loading: false,
          group: { ...action?.payload?.communityByUser?.group, loading: false },
          groupView: { ...action?.payload?.communityByUser?.groupView, loading: false }
        }
      }
      return state;

    default:
      return state;
  }
};
export default communityByUserReducer;

import {
  COMMUNITY_LOADED,
  COMMUNITY_LOADING,
  GET_USER_GROUP_LIST,
  GET_USER_COMMUNITY_LIST,
  GET_GROUP_DETAILS,
  GET_USER_GROUP_DETAILS,
  DO_EMPTY_COMMUNITY_BY_USER_STATE
} from "./community.constant";

/**
 * community data loading action creator
 * @returns
 */
export const loadingAction = (type) => ({ type: COMMUNITY_LOADING, payload: type });

/**
 * community data loaded action creator
 * @returns
 */
export const loadedAction = (type) => ({ type: COMMUNITY_LOADED, payload: type });

/**
 * get community list by userid action creator
 * @param {*} communityList 
 * @returns 
 */
export const getUserCommunityAction = (communityList) => ({ type: GET_USER_COMMUNITY_LIST, payload: communityList });

/**
 * get group list by community id & group id action creator
 * @param {*} groupList 
 * @returns 
 */
export const getGroupByCommunityIDUserIDAction = (groupList) => ({ type: GET_USER_GROUP_LIST, payload: groupList })

/**
 * get group details by group id action creator
 * @param {*} groupDetail 
 * @returns 
 */
export const getGroupDetailByGroupIdAction = (groupDetail) => ({ type: GET_GROUP_DETAILS, payload: groupDetail })

/**
 * get user group details by userid group id Action creater
 * @param {*} userGroupDetails 
 * @returns 
 */
export const getUserGroupDetailsAction = (userGroupDetails) => ({ type: GET_USER_GROUP_DETAILS, payload: userGroupDetails })

export const doEmptyCommunityByUserStateAction = () => ({ type: DO_EMPTY_COMMUNITY_BY_USER_STATE });
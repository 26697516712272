/**
 * check is message is disabled
 * @param {number} value 0 for disabled, 1 for enabled message
 * @returns 
 */
export const isDisabledMessage = (value) => {
    return value !== 1;
}

/**
 * check is profile is private
 * @param {number} value 0 for private, 1 for public profile
 * @returns 
 */
export const isPrivateProfile = (value) => {
    return value !== 1;
}
import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import InputEmoji from "react-input-emoji";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { setCurrentChatBoardActionCreator } from "../../../../ReduxStore/group-message/groupMessage.actions";
import { getSidebarListInitialActionThunk } from "../../../../ReduxStore/group-message/groupMessage.actions.async";
import GroupService from "../../../../Services/group/GroupService";
import {
  connection,
  deleteSubGroupSignalrMethod,
  isConnectionIsOnConnectedMod,
  joinSubGroup,
  leaveSubGroup,
  removeFromSubGroupSignalrMethod,
  sendSubGroupLiveStreamMessage,
  sendSubGroupMessage,
  startConnection,
} from "../../../../Services/signalR/signalrService";
import { ReactComponent as ChevronIcon } from "../../../../assets/Images/chevron-icon.svg";
import { ReactComponent as SendIcon } from "../../../../assets/Images/send-icon.svg";
import InfiniteScrollComp from "../../../infinite-scroll/InfiniteScroll";
import { SpinnerLoader } from "../../../loader/SpinnerLoader";
import { errorToast, successToast } from "../../../toast/toast";
import GroupMessageList from "./LiveStreamSubGroupMessageList";

const LiveStreamSubGroupMessageBoard = ({
  subGroupId,
  setLiveStreamMember,
  liveStreamMember,
  liveStremListType,
}) => {
  const messageEndRef = useRef();
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const groupId = params?.groupId;

  const profileId = useSelector(
    (state) => state?.profileData?.profileData?.profileId
  );
  // const subGroupId = useSelector(state => state?.groupMessage?.currentChatBoard?.receiverOrSubGroupId);
  const currentChatBoard = useSelector(
    (state) => state?.groupMessage?.currentChatBoard
  );

  const [isMessageEndInView, setIsMessageEndInView] = useState(false);
  const [loading, setLoading] = useState({
    receiverData: false,
    groupConversation: false,
    leaveSubGroup: false,
    subGroupData: false,
  });
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalRecords: 0,
    pageSize: 10,
    totalPages: 0,
    hasNextPage: false,
  });

  const [subGroupData, setSubGroupData] = useState(null);
  //get subgroup data
  const getSubGroupBysubGroupIdApiCall = (subGroupId) => {
    setLoading((prev) => ({ ...prev, subGroupData: true }));
    GroupService.getLiveStreamSubGroupsByGroupID(subGroupId)
      .then((res) => {
        setSubGroupData(res?.data);
        setLoading((prev) => ({ ...prev, subGroupData: false }));
      })
      .catch((err) => {
        errorToast("Something went wrong.");
        setLoading((prev) => ({ ...prev, subGroupData: false }));
      });
  };

  //edit sub group
  const subGroupEditHandler = () => {
    navigate(location?.pathname, {
      state: { showCreateSubGroup: true, groupId, subGroupId, isEdit: true },
      replace: true,
    });
  };

  //delete sub group
  const subGroupDeleteHandler = () => {
    confirmAlert({
      title: "Delete Message Group",
      message: "Are you sure to delete message group?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            GroupService.deleteSubGroup(subGroupId)
              .then((res) => {
                dispatch(getSidebarListInitialActionThunk(groupId, "", 1, 10));
                dispatch(
                  setCurrentChatBoardActionCreator({
                    boardType: 0,
                    receiverOrSubGroupId: "",
                  })
                );
                successToast("Message group deleted.");

                //signal r
                deleteSubGroupSignalrMethod(subGroupId);
              })
              .catch((err) => {
                errorToast("Something went wrong.");
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  //leave from sub group
  const subGroupLeavehandler = () => {
    confirmAlert({
      title: "Leave From Message Group",
      message: "Are you sure to leave message group?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setLoading((prev) => ({ ...prev, leaveSubGroup: true }));
            GroupService.getAllSubGroupMembers(subGroupId)
              .then((res) => {
                if (Array.isArray(res?.data)) {
                  const userIds = res?.data
                    ?.map((member) => member?.userId)
                    ?.filter((userId) => userId !== profileId);
                  GroupService.addOrUpdateSubGroupMember({
                    subGroupId,
                    userIds,
                  })
                    .then((res) => {
                      dispatch(
                        getSidebarListInitialActionThunk(groupId, "", 1, 10)
                      );
                      dispatch(
                        setCurrentChatBoardActionCreator({
                          ...currentChatBoard,
                          boardType: 0,
                          receiverOrSubGroupId: "",
                          itemObj: null,
                        })
                      );
                      successToast("You left the message group.");
                      setLoading((prev) => ({ ...prev, leaveSubGroup: false }));
                      //signal r
                      removeFromSubGroupSignalrMethod(subGroupId, profileId);
                    })
                    .catch((err) => {
                      errorToast("Something went wrong.");
                      setLoading((prev) => ({ ...prev, leaveSubGroup: false }));
                    });
                } else {
                  errorToast("Something went wrong.");
                  setLoading((prev) => ({ ...prev, leaveSubGroup: false }));
                }
              })
              .catch((err) => {
                errorToast("Something went wrong.");
                setLoading((prev) => ({ ...prev, leaveSubGroup: false }));
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  /**
   * get initial group discussion
   */
  const getSubGroupMessagesApiCall = useCallback(() => {
    setLoading((prev) => ({ ...prev, groupConversation: true }));
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
    GroupService.getLiveStreamSubGroupMessages(
      subGroupId,
      1,
      pagination?.pageSize
    )
      .then((res) => {
        if (res?.data && res?.data?.entities?.length > 0) {
          setMessages(res?.data?.entities);
          setPagination((prev) => ({
            ...prev,
            totalRecords: res?.data?.totalRecords,
            totalPages: res?.data?.totalPages,
            hasNextPage: res?.data?.hasNextPage,
          }));
        }
        setLoading((prev) => ({ ...prev, groupConversation: false }));
      })
      .catch((err) => {
        errorToast("Something went wrong.");
        setMessages([]);
        setLoading((prev) => ({ ...prev, groupConversation: false }));
      });
  }, [pagination?.pageSize]);

  const getSubGroupMessagesApiCallMore = useCallback(() => {
    GroupService.getLiveStreamSubGroupMessages(
      subGroupId,
      pagination?.currentPage,
      pagination?.pageSize
    )
      .then((res) => {
        setMessages((prevList) => [...prevList, ...res?.data?.entities]);
        setPagination((prev) => ({
          ...prev,
          totalRecords: res?.data?.totalRecords,
          totalPages: res?.data?.totalPages,
          hasNextPage: res?.data?.hasNextPage,
        }));
      })
      .catch((err) => {
        errorToast("Something went wrong");
        setLoading((prev) => ({ ...prev, groupConversation: false }));
      });
  }, [pagination?.currentPage, pagination?.pageSize]);

  /**
   * send message
   */
  const handleSendLiveStreamMessage = () => {
    if (newMessage.trim() !== "") {
      sendSubGroupLiveStreamMessage(profileId, subGroupId, newMessage)
        .then((res) => {
          if (res) {
            setMessages((prev) => [res, ...prev]);
            setNewMessage("");
            messageEndRef?.current?.scrollIntoView();
          }
        })
        .catch((err) => {
          errorToast("Something went wrong at message send " + err);
        });
    }
  };

  useEffect(() => {
    // Start SignalR connection when the component mounts
    startConnection().then((res) => {
      if (res) {
        // Join a sub group
        joinSubGroup(profileId, subGroupId);
        // get received message
        isConnectionIsOnConnectedMod() &&
          connection.on("subGroupMessage", (message) => {
            setMessages((prevMessages) => [message, ...prevMessages]);
            messageEndRef?.current?.scrollIntoView();
          });

        //get user remove or delete group information of sub group
        isConnectionIsOnConnectedMod() &&
          connection.on("updateGroup", (information) => {
            setSubGroupData(information?.Data);
            // setLiveStreamMember(information?.Data);
            // if (information?.Data !== undefined) {
            //   setLiveStreamMember(information?.Data);
            // }
            if (liveStreamMember?.memberCount > 0) {
              setLiveStreamMember((prevMember) => {
                // Update the state based on the previous state
                const newMember = { ...prevMember, ...information?.Data };
                // Your logic for updating the state
                return newMember;
              });
            } else {
              setLiveStreamMember(information?.Data);
            }

            // setLiveStreamMember((pre) => ({ ...pre, ...information?.Data }));
          });
      }
    });
    return () => {
      connection.off("subGroupMessage");
      connection.off("updateGroup");
      //   leaveSubGroup(profileId, subGroupId);
    };
  }, [subGroupId, profileId, liveStreamMember]);

  useEffect(() => {
    if (pagination?.currentPage > 1) {
      getSubGroupMessagesApiCallMore();
    }
  }, [getSubGroupMessagesApiCallMore, pagination?.currentPage]);

  useEffect(() => {
    getSubGroupMessagesApiCall();
    getSubGroupBysubGroupIdApiCall(subGroupId);
  }, []);

  return (
    <>
      {loading?.groupConversation || loading?.subGroupData ? (
        <div className="h-100 d-flex align-items-center justify-content-center">
          <SpinnerLoader />
        </div>
      ) : (
        <Card className="message-card-custom-border rounded-start-0 rounded-end m-0">
          {/* <Card.Header className='d-flex justify-content-between m-0 bg-white'>
                        <ConditionalWrapper
                            condition={subGroupData?.subGroupName?.length > 70}
                            wrapper={(children) => (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            {subGroupData?.subGroupName}
                                        </Tooltip>
                                    }
                                >{children}
                                </OverlayTrigger>)}
                        >

                            <div className='d-flex align-items-center'>
                                <img
                                    className='rounded-circle img-thumbnail'
                                    src={subGroupData?.logo || require("../../../../assets/Images/group_logo_img.png")}
                                    height={38}
                                    width={38}
                                    alt="Avatar"
                                />
                                <div
                                    className='d-flex flex-column ms-2'
                                >
                                    <h6 className='mb-1'>{truncateText(subGroupData?.subGroupName, 70)}</h6>
                                    <p className='font-size-14 m-0'>{padWithLeadingZeros(subGroupData?.memberCount, 2) + " " + pluralizeWord("Member", "Members", subGroupData?.memberCount)}</p>
                                </div>
                            </div>
                        </ConditionalWrapper>
                        <div className='d-flex gap-4 align-items-center'>
                            {isOwner(subGroupData?.userID) && (<OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        Edit
                                    </Tooltip>
                                }
                            >
                                <div
                                    className='cursor-pointer'
                                    onClick={() => subGroupEditHandler()}>
                                    <EditIconSvg fill="#212121" />
                                </div>
                            </OverlayTrigger>)}

                            {isOwner(subGroupData?.userID) && (<OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        Delete
                                    </Tooltip>
                                }
                            >
                                <div
                                    className='cursor-pointer'
                                    onClick={subGroupDeleteHandler}>
                                    <TrashIcon fill="#212121" />
                                </div>
                            </OverlayTrigger>)}

                            {(!subGroupData?.isDeleted && subGroupData?.isJoined && !isOwner(subGroupData?.userID)) && (<OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        Leave
                                    </Tooltip>
                                }
                            >
                                {loading?.leaveSubGroup ? (
                                    <SpinnerLoader />
                                ) : (
                                    <div
                                        className='cursor-pointer'
                                        onClick={subGroupLeavehandler}
                                    >
                                        <LeaveIcon fill="#212121" />
                                    </div>)}
                            </OverlayTrigger>)}
                        </div>
                    </Card.Header> */}
          <Card.Body className="p-3">
            <div className="position-relative">
              <div
                className={
                  messages?.length > 0
                    ? "message-history"
                    : "message-history justify-content-center"
                }
                id="scrollableDive"
                onScroll={(e) => {
                  if (e.target?.scrollTop > -150) {
                    setIsMessageEndInView(false);
                  } else {
                    setIsMessageEndInView(true);
                  }
                }}
              >
                <div ref={messageEndRef} />
                <InfiniteScrollComp
                  dataLength={messages?.length}
                  refreshFunction={getSubGroupMessagesApiCall}
                  pagination={pagination}
                  setPagination={setPagination}
                  endMessage=""
                  dataList={messages || []}
                  setDataList={setMessages}
                  ItemsComponent={GroupMessageList}
                  inverse={true}
                  scrollableTarget="scrollableDive"
                  innerStyle={{
                    display: "flex",
                    flexDirection: "column-reverse",
                  }}
                  loader={
                    <div className="text-center text-muted small">
                      Loading history...
                    </div>
                  }
                />
              </div>
              <div className="scroll-to-down-icon">
                {isMessageEndInView && (
                  <Button
                    variant="link"
                    className="btn-primary-theme btn-outline"
                    onClick={() =>
                      messageEndRef.current.scrollIntoView({
                        behavior: "smooth",
                      })
                    }
                  >
                    <ChevronIcon fill="#047ED6" />
                  </Button>
                )}
              </div>
            </div>

            {/* <div className='w-100 text-center'>
                            <small className='text-danger'>{subGroupData?.IsDelted ? "Group is delete" : !subGroupData?.isJoined ? "You're no loger part in group" : subGroupData?.memberCount <= 1 && "Please add more person to chating"}</small>
                        </div> */}
            {/* {(!subGroupData?.isDeleted && subGroupData?.isJoined && subGroupData?.memberCount >= 1) && ( */}
            {liveStremListType !== 1 && (
              <Card.Footer className="px-0 pb-0 border-top pt-3 bg-white">
                <Form className="d-flex align-items-center gap-1">
                  <Form.Group className="w-100" controlId="newMessage">
                    <InputEmoji
                      value={newMessage}
                      onChange={(text) => setNewMessage(text)}
                      cleanOnEnter
                      onEnter={handleSendLiveStreamMessage}
                      placeholder="Type a message"
                    />
                  </Form.Group>
                  <Button
                    onClick={handleSendLiveStreamMessage}
                    className="btn btn-primary-theme send-btn"
                  >
                    <SendIcon height={24} width={24} fill="#ffffff" />
                  </Button>
                </Form>
              </Card.Footer>
            )}

            {/* )}  */}
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default LiveStreamSubGroupMessageBoard;

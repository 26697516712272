import { useEffect, useRef, useState } from "react"
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import GuestUser from "./GuestUser";
import Login from "./Login";

const GetGuestModal = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const loginFormRef = useRef();
  const guestFormRef = useRef();

  const showGetGuestModal = location?.state?.showGetGuestModal;

  const guestLoading = useSelector(state => state?.auth?.loginData?.visitorData?.loading)

  const [show, setShow] = useState(false);
  const [isGuest, setIsGuest] = useState(true);
  const [loading, setLoading] = useState({ login: false, guest: false });

  //modal close handler
  const handleClose = () => {
    navigate(location?.pathname)
  }

  useEffect(() => {
    setShow(() => showGetGuestModal ? true : false)
  }, [showGetGuestModal])

  return (
    <>
      <Modal
        className="common-modal"
        show={show}
        onClose={() => handleClose()}
        backdrop="static"
        centered
        scrollable
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title className="text-black-theme">{isGuest ? "Guest User Login" : "Login"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='common-modal-body inner-tabs common-fields'>
          {
            isGuest ? (
              <>
                <GuestUser
                  formRef={guestFormRef}
                  setLoading={setLoading}
                />
                <p className="text-center font-size-14 m-0">If you are already have an user, just <span className="text-primary-theme pointer" onClick={() => setIsGuest(false)}>login</span>.</p>
              </>
            ) : (
              <>
                <Login
                  formRef={loginFormRef}
                  setLoading={setLoading}
                />
              </>
            )
          }
        </Modal.Body>
        <Modal.Footer className="common-modal-footer d-flex">
         {!isGuest && <p
            className="text-center text-primary-theme font-size-14 pointer m-0"
            onClick={() => setIsGuest(true)}
          >
            Back
          </p>}
          {(guestLoading || loading?.login) ? (
            <Button className="btn-primary-theme ms-auto">Loading <i className="fa fa-spinner fa-spin ms-1"></i></Button>
          ) : (
            <Button
              type="button"
              className="btn-primary-theme ms-auto"
              onClick={() => {
                isGuest ?
                  guestFormRef.current.requestSubmit() :
                  loginFormRef.current.requestSubmit()
              }}
            >
              {isGuest ? "Submit" : "Login"}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default GetGuestModal
import { useEffect, useState } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import Select from "react-select";
import { getCurrentUserCommunityByActionThunk } from "../../ReduxStore/slice/curret-user-community/current-user-community-async-thunk";
import { SpinnerLoader } from "../loader/SpinnerLoader";
import { roleIdToRoleName } from "../../utils/helpers/roleIdToRoleName";
import { confirmAlert } from "react-confirm-alert";
import { ConditionalWrapper } from "../conditional-Wrapper/ConditionalWrapper";
import { getOrSetCurrentDashboardCommunityActionThunk } from "../../ReduxStore/slice/dashboard/dashboard-async-thunk";

const SwitchRoleModal = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const show = location?.state?.showSwitchroleModal;

  const roleID = useSelector(
    (state) => state?.profileData?.profileData?.roleID
  );

  const current_user_community_list = useSelector((state) =>
    state?.currentUserCommunity?.communitiy_list?.map((c) => ({
      label:
        c?.communitySiteName + ": " + roleIdToRoleName(c?.userRoleInCommunity),
      value: c?.communityId,
      communitySiteName: c?.communitySiteName,
      userRoleInCommunity: c?.userRoleInCommunity,
    }))
  );
  const fetch_current_user_community_loading = useSelector(
    (state) => state?.currentUserCommunity?.loading?.get_community
  );

  const current_selected_role =
    useSelector((state) => state?.dashboardCommunity?.communityId) || "";
  const switch_role_loading = useSelector(
    (state) => state?.dashboardCommunity?.loading
  );

  const [selectedRole, setSelectedRole] = useState(null);

  // modal close handler
  const handleClose = () => {
    // navigate(location?.pathname, { state: { ...location?.state, showSwitchroleModal: false }, replace: true });
    navigate("/admin-tools", {
      state: { ...location?.state, showSwitchroleModal: false },
      replace: true,
    });
  };

  // selection handler from dropdown
  const selectHandler = (value) => {
    setSelectedRole(value);
  };

  //get option name from selected role id
  const getLabelFromCommunityList = (role) => {
    if (role == 1) {
      return "System Administrator";
    } else if (role == 2) {
      return "Community Owner";
    } else {
      let c = current_user_community_list?.filter(
        (cmnt) => cmnt?.value === role
      );
      if (c?.length) {
        return (
          c[0]?.communitySiteName +
            ": " +
            roleIdToRoleName(c[0]?.userRoleInCommunity) || ""
        );
      } else {
        return "Select Role";
      }
    }
  };

  // handle community
  const roleSelectHandler = () => {
    const toCommunityName = getLabelFromCommunityList(selectedRole?.value);
    const fromCommunityName = getLabelFromCommunityList(current_selected_role);

    confirmAlert({
      title: "Switch Role",
      message: (
        <p>
          Sure you want to switch dashboard from <b> {fromCommunityName} </b> to{" "}
          <b>{toCommunityName}</b> dashboard?
        </p>
      ),
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            // navigate("/admin-tools");
            dispatch(
              getOrSetCurrentDashboardCommunityActionThunk(selectedRole?.value)
            );
            handleClose();
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  useEffect(() => {
    dispatch(getCurrentUserCommunityByActionThunk());
  }, [dispatch]);

  useEffect(() => {
    setSelectedRole(() => ({
      value: current_selected_role,
      label: getLabelFromCommunityList(current_selected_role),
    }));
  }, [current_selected_role]);

  return (
    <Modal
      backdrop="static"
      centered
      keyboard={false}
      show={show}
      onHide={() => handleClose()}
      className="common-modal"
    >
      {fetch_current_user_community_loading ? (
        <SpinnerLoader />
      ) : (
        <>
          <Modal.Header>
            <Modal.Title className="text-black-theme">Select Role</Modal.Title>
          </Modal.Header>
          <Modal.Body className="common-modal-body pb-4">
            <Select
              placeholder="Select Role"
              value={selectedRole}
              onChange={selectHandler}
              className="custom-select-dropdown"
              classNamePrefix="react-select"
              options={[
                ...(roleID === 1
                  ? [{ value: 1, label: "System Administrator" }]
                  : []),
                { value: 2, label: "Community Owner" },
                ...current_user_community_list,
              ]}
            />
          </Modal.Body>
          <Modal.Footer className="common-modal-footer">
            <Button
              type="button"
              className="btn-secondary-theme btn-outline"
              onClick={() => handleClose()}
              // disabled={[{ value: 2, label: "Community Owner" }, { value: 1, label: "System Administrator" }, ...current_user_community_list]?.filter(cmnt => cmnt?.value == current_selected_role)?.length < 1}
              disabled={
                [
                  { value: 2, label: "Community Owner" },
                  { value: 1, label: "System Administrator" },
                  ...current_user_community_list,
                ]?.filter((cmnt) => cmnt?.value == current_selected_role)
                  ?.length < 1
              }
            >
              Cancel
            </Button>
            <ConditionalWrapper
              condition={selectedRole?.value === current_selected_role}
              wrapper={(children) => (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Please select community dashboard</Tooltip>}
                >
                  {children}
                </OverlayTrigger>
              )}
            >
              <div>
                <Button
                  className="btn-primary-theme"
                  type="submit"
                  disabled={
                    selectedRole?.value == current_selected_role ||
                    switch_role_loading
                  }
                  onClick={() => roleSelectHandler()}
                >
                  Select
                  {switch_role_loading && (
                    <i className="fa fa-spinner fa-spin ms-1"></i>
                  )}
                </Button>
              </div>
            </ConditionalWrapper>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default SwitchRoleModal;

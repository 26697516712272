import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="error-template text-center d-flex justify-content-center flex-column align-items-center min-h-100vh">
      <h1 className="text-black-theme mb-4 fw-bold">Oops!</h1>
      <h2 className="text-black-theme mb-3">404 - Page Not Found.</h2>
      <p className="error-details">
        Sorry, an error has occured, requested page not found!
      </p>
      <div className="mt-2">
        <Link to="/" className="btn btn-primary-theme btn-lg text-white p-2 px-3">
          Take Me Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;

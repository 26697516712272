import { useState } from 'react'

const SkeletonForImg = ({ src, imgClassNames, skeletonClassNames, imgStyles, alt }) => {
    const [loading, setLoading] = useState(true);

    return (
        <>
            {loading && (
                <div className={`skeleton-blog h-100 ${skeletonClassNames}`} >
                    <div className="skeleton-author h-100 m-0"></div>
                </div>
            )}
            <img
                src={src}
                className={imgClassNames}
                style={imgStyles}
                alt={alt}
                onLoad={() => setLoading(false)}
                hidden={loading}
            />
        </>
    )
}

export default SkeletonForImg
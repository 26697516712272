import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { closeMediaAciton } from "../../ReduxStore/media-view/mediaView.actions";
import { Button, Modal } from "react-bootstrap";

const MediaView = () => {

    const dispatch = useDispatch();

    const show = useSelector(state => state?.mediaView?.status);
    const media = useSelector(state => state?.mediaView?.media);

    const [mediaSrc, setMediaSrc] = useState([]);

    const closeMediaModal = () => {
        dispatch(closeMediaAciton());
    };

    useEffect(() => {
        if (media?.type === "image") {
            setMediaSrc([{ src: media?.src, caption: media?.title }]);
        } else if (media?.type === "video") {
            setMediaSrc([{
                type: "video",
                height: 720,
                width: 1280,
                poster: media?.src,
                sources: [
                    { src: media?.src, type: "video/mp4", caption: media?.title }
                ]
            }])
        } else if (media?.type === "" || !media?.type) {
            closeMediaModal();
        }
    }, [media?.src, media?.title, media?.type])

    return (
        <>
            {/*
            * demo
            * https://codesandbox.io/s/yet-another-react-lightbox-examples-forked-79cgnr?file=/src/examples/Playground.js:2096-2233
             */}
            {(media?.type === "image" || media?.type === "video") ? (
                <Lightbox
                    open={show}
                    close={closeMediaModal}
                    slides={mediaSrc}
                    plugins={[Captions, Fullscreen, Video, Zoom]}
                    render={{
                        buttonPrev: mediaSrc?.length > 1 ? undefined : () => null,
                        buttonNext: mediaSrc?.length > 1 ? undefined : () => null
                    }}
                    video={{
                        controls: true,
                        playsInline: true,
                        autoPlay: true,
                        loop: false,
                        muted: false,
                        disablePictureInPicture: false,
                        disableRemotePlayback: false,
                    }}
                />
            ) : (
                <Modal
                    show={show}
                    onHide={() => closeMediaModal()}
                    scrollable={true}
                    aria-labelledby="example-modal-sizes-title-sm"
                    fullscreen={true}
                >
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <div style={{ height: "100%", overflow: "auto" }}>
                            {/* <FileViewer
                                fileType={media?.type}
                                filePath={media?.src}
                                errorComponent={<div>Error loading file.</div>}
                            /> */}
                            <iframe
                                src={media?.src}
                                title="PDF Viewer"
                                width="100%"
                                height="500px"
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="btn-primary-theme"
                            onClick={() => closeMediaModal()}
                            type="button"
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    )
}

export default MediaView
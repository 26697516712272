
const InternetConnectionMessage = () => {
    return (
        <div className="d-flex flex-column align-items-center justify-content-center my-4 my-md-5">
            <h2>Internet Connection Lost</h2>
            <p>Please check your internet connection and try again.</p>
        </div>
    );
};

export default InternetConnectionMessage;

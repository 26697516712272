import { createAsyncThunk } from "@reduxjs/toolkit";
import GroupService from "../../../Services/group/GroupService";



/**
 * get user list
 */
export const getAllGroupMembersNotInGroupActionThunk = createAsyncThunk("group_invite/getMembers", async ({ groupID, nameSearched, pageNo, pageSize }) => {

    const response = await GroupService.GetAllGroupMembersNotInGroup(groupID, nameSearched, pageNo, pageSize)

    return response?.data;

});

export const sendInvitationActionThunk = createAsyncThunk("group_invite/sendInvitation", async (data) => {
    const response = await GroupService.sendInvitation(data);
    return response?.data;
})
import {
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from "@microsoft/signalr";
import { errorToast } from "../../Component/toast/toast";
import store from "../../ReduxStore/store";
import {
  getConnectionEstablishedActionCreator,
  getConnectionPendingActionCreator,
} from "../../ReduxStore/signalr-connection/signalrConnection.actions";
import { logoutHandler } from "../../utils/helpers/logoutHandler";
import { getUnreadNotificationCountAction } from "../../ReduxStore/slice/notification/notificationSlice";

export const connection = new HubConnectionBuilder()
  .withUrl(process.env.REACT_APP_API_URL + "chathub", {
    accessTokenFactory: () => {
      const token = store?.getState()?.auth?.token;
      const access_token = token;
      return access_token;
    },
  })
  .configureLogging(LogLevel.Information)
  .withAutomaticReconnect()
  .build();

// Set server timeout to a high value or remove the line altogether
// connection.serverTimeoutInMilliseconds = 2147483647; // Maximum value
connection.serverTimeoutInMilliseconds = 120000;
connection.keepAliveIntervalInMilliseconds = 2147483647;

/**
 * start connection
 */
export const startConnection = async () => {
  try {
    if (store?.getState()?.auth?.token) {
      if (
        connection?.state === HubConnectionState.Disconnected &&
        connection.state !== HubConnectionState.Connecting &&
        connection.state !== HubConnectionState.Reconnecting
      ) {
        store?.dispatch(getConnectionPendingActionCreator());
        await connection.start();
        store?.dispatch(getConnectionEstablishedActionCreator());
        return true;
      } else {
        return true;
      }
    } else {
      logoutHandler();
    }
  } catch (error) {
    console.log("Error starting SignalR connection:" + error);
    setTimeout(startConnection, 5000);
  }
};

/**
 * handle error
 * @param {*} callback
 */

connection.on("onError", (error) => {
  console.log("Signal-R Err: " + error);
  if (connection?.state === HubConnectionState.Disconnected) startConnection();
});

/**
 * stop hub connection
 */
export const stopConnection = () => {
  if (connection) {
    connection.stop();
  }
};

/**
 * check is connection is in connected
 * @returns
 */
export const isConnectionIsOnConnectedMod = () => {
  return connection.state === HubConnectionState.Connected;
};

/*******************************one to one message methods **************************/

/**
 * send one to one message
 * @param {*} profileId
 * @param {*} receiverId
 * @param {*} newMessage
 */
export const sendOneToOneMessage = (profileId, receiverId, newMessage) => {
  if (connection?.state !== HubConnectionState.Connected) {
    startConnection().then(() =>
      connection.invoke(
        "SendPrivateTemp",
        profileId,
        receiverId,
        newMessage,
        "1"
      )
    );
  } else {
    return connection.invoke(
      "SendPrivateTemp",
      profileId,
      receiverId,
      newMessage,
      "1"
    );
  }
};

/**
 * update read status of one to one message
 * @param {*} receiverId
 * @param {*} messageId
 * @param {*} isRead
 * @returns
 */
export const markMessageAsReadOrUnReadSignalr = (
  receiverId,
  messageId,
  isRead
) => {
  if (connection?.state === HubConnectionState.Disconnected) {
    startConnection().then(() =>
      connection.invoke(
        "MarkMessageAsReadOrUnRead",
        receiverId,
        messageId,
        isRead
      )
    );
  } else {
    return connection.invoke(
      "MarkMessageAsReadOrUnRead",
      receiverId,
      messageId,
      isRead
    );
  }
};

/**
 * join one to one message board
 * @param {*} userId
 * @param {*} receiverId
 */
export const joinOneToOneSignalrMethod = (userId, receiverId) => {
  if (connection?.state !== HubConnectionState.Connected) {
    startConnection().then(() =>
      connection.invoke("JoinOneToOne", userId, receiverId)
    );
  } else {
    connection.invoke("JoinOneToOne", userId, receiverId);
  }
};

/**
 * leave one to one message board
 * @param {*} userId
 * @param {*} receiverId
 */
export const leaveOneToOneSignalrMethod = (userId, receiverId) => {
  if (connection?.state !== HubConnectionState.Connected) {
    startConnection().then(() =>
      connection.invoke("LeaveOneToOne", userId, receiverId)
    );
  } else {
    connection.invoke("LeaveOneToOne", userId, receiverId);
  }
};

/****************************Group message methods ****************************/

/////////////////////////Group message//////////////////////////////
export const joinGroup = async (
  roomNameToJoin,
  roomNameToLeave,
  userId,
  groupId
) => {
  if (connection?.state === HubConnectionState.Disconnected) {
    startConnection().then(() => {
      connection.invoke(
        "JoinGroup",
        roomNameToJoin,
        roomNameToLeave,
        userId,
        groupId
      );
      // .catch(error => errorToast('Signal-R joining group Err:' + error));
    });
  } else {
    connection
      .invoke("JoinGroup", roomNameToJoin, roomNameToLeave, userId, groupId)
      .catch((error) => errorToast("Signal-R joining group Err:" + error));
  }
};

/**
 * send group message
 * @param {*} senderID
 * @param {*} groupName
 * @param {*} message
 * @param {*} groupID
 * @returns
 */
export const sendGroupMessage = (senderID, groupName, message, groupID) => {
  return connection.invoke(
    "SendGroupMessage",
    senderID,
    groupName,
    message,
    groupID
  );
};

export const leaveGroup = (groupId, groupName, userId) => {
  return connection.invoke("LeaveGroup", groupId, groupName, userId);
};

///////////////////////////////sub group message////////////////////////////
/**
 * join sub group
 * @param {*} userId
 * @param {*} subGroupId
 */
export const joinSubGroup = async (userId, subGroupId) => {
  if (connection?.state !== HubConnectionState.Connected) {
    startConnection().then(() => {
      connection
        .invoke("JoinSubGroup", userId, subGroupId)
        .catch((error) =>
          errorToast("Signal-R joining sub group Err:" + error)
        );
    });
  } else {
    connection
      .invoke("JoinSubGroup", userId, subGroupId)
      .catch((error) => errorToast("Signal-R joining group Err:" + error));
  }
};

/**
 * send message in subgroup
 * @param {*} senderID
 * @param {*} subGroupId
 * @param {*} message
 * @returns
 */
export const sendSubGroupMessage = (senderID, subGroupId, message) => {
  if (connection?.state !== HubConnectionState.Connected) {
    startConnection().then(() => {
      return connection.invoke(
        "sendSubGroupMessage",
        senderID,
        subGroupId,
        message
      );
    });
  } else {
    return connection.invoke(
      "sendSubGroupMessage",
      senderID,
      subGroupId,
      message
    );
  }
};

export const sendSubGroupLiveStreamMessage = (senderID, subGroupId, message) => {
  if (connection?.state !== HubConnectionState.Connected) {
    startConnection().then(() => {
      return connection.invoke(
        "SendLiveStreamChatMessage",
        senderID,
        subGroupId,
        message
      );
    });
  } else {
    return connection.invoke(
      "SendLiveStreamChatMessage",
      senderID,
      subGroupId,
      message
    );
  }
};

export const leaveSubGroup = (userId, subGroupId) => {
  return connection.invoke("LeaveSubGroup", subGroupId, userId);
};

/**
 * delete subgroup
 * @param {*} subGroupId
 * @param {*} userId
 * @returns
 */
export const deleteSubGroupSignalrMethod = (subGroupId) => {
  const userId = store?.getState()?.profileData?.profileData?.profileId;
  return connection.invoke("DeleteSubGroup", subGroupId, userId);
};

/**
 * remove from subgroup
 * @param {*} subGroupId
 * @param {*} userId
 * @returns
 */
export const removeFromSubGroupSignalrMethod = (subGroupId, userId) => {
  return connection.invoke("RemoveFromSubGroup", subGroupId, userId);
};

/**
 * get user remove or delete group information sub group messages
 */
// connection.on("updateGroup", (message) => {
// });

/****************************Notification methods ****************************/

/**
 * get received notification count
 */
connection.on("notificationCount", (notificationCount) => {
  store?.dispatch(
    getUnreadNotificationCountAction({ count: notificationCount?.data })
  );
});

/**
 * update notification view status
 * @param {*} notificationId
 * @returns
 */
export const UpdateNotification = (notificationId) => {
  const userId = store?.getState()?.profileData?.profileData?.profileId;
  return connection.invoke("UpdateNotification", notificationId, userId);
};

import { Button, Modal } from "react-bootstrap";
import PhotoGallery from "../PhotoGallery";

const PhotoGalleryInModal = ({ show, handleClose, folder }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-black-theme font-size-18 h5">
          Folders
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PhotoGallery folder={folder} />
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-primary-theme" onClick={() => handleClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PhotoGalleryInModal;

import { API } from "../../http-Common"

class Notification {
    getAllNotificationByUserID = (pageIndex, pageSize) => {
        return API.get("/Notification/GetAllNotificationByUserID", { params: { pageIndex, pageSize } })
    }

    markAllNotificationsAsRead = () => {
        return API.get("/Notification/MarkAllNotificationsAsRead");
    }
}
const notification =  new Notification();
export default notification;
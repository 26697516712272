import { useEffect, useState } from "react";
import { Button, Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import ArrowIcon from "../../../../assets/Images/arrow-icon.svg";
import { errorToast, successToast } from "../../../toast/toast";
import UserServices from "../../../../Services/user/UserServices";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import EditModal from "./modal/EditModal";
import { Slide } from "react-awesome-reveal";
import ContactUsBgNew from "../../../../assets/Images/new-advanced-template-contact-us-bg.svg";

const Section7 = ({ children }) => {
  const siteID = useSelector((state) => state?.template3?.siteID);
  const adminEmailID = useSelector((state) => state?.template3?.admin_email);
  const section1Data = useSelector(
    (state) => state?.template3?.template_3_sections?.section1
  );
  const section7Data = useSelector(
    (state) => state?.template3?.template_3_sections?.section7
  );
  const profileData = useSelector((state) => state?.profileData?.profileData);

  const [formLoading, setFrormLoading] = useState(false);
  const [data, setData] = useState(null);
  const [backgroundImageStyle, setBackgroundImageStyle] = useState({
    backgroundImage: "",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundColor: "",
  });

  //Form
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      fullName: Yup.string().required("Please enter your full name."),
      email: Yup.string()
        .required("Please enter your email.")
        .email("Email is invalid"),
      description: Yup.string().required("Please enter your description."),
    }),
    initialValues: {
      fullName: "",
      email: "",
      description: "",
    },
    onSubmit: (values, { resetForm }) => {
      const value = {
        firstName: values?.fullName,
        email: values?.email,
        message: values?.description,
        to: adminEmailID,
        communityID: siteID,
        type: "CommunityContactUS",
      };
      setFrormLoading(true);
      UserServices.sendEmail(value)
        .then((response) => {
          successToast("Message sent successfully.");
          resetForm();
          setFrormLoading(false);
        })
        .catch((e) => {
          if (e.message) {
            errorToast(e.message);
          } else {
            errorToast("Something went wrong");
          }
          setFrormLoading(false);
        });
    },
  });

  useEffect(() => {
    if (section7Data) {
      setData(section7Data);
    }
  }, [section7Data]);

  useEffect(() => {
    formik?.setValues((prev) => ({
      ...prev,
      fullName: profileData?.firstName
        ? profileData?.firstName + " " + profileData?.lastName
        : "",
      email: profileData?.email || "",
      description: "",
    }));
  }, []);

  useEffect(() => {
    setBackgroundImageStyle((prev) => ({
      ...prev,
      backgroundImage:
        data?.Section7BackgroundVisible !== undefined
          ? data.Section7BackgroundVisible
            ? `url('${data?.BackgroundImage}')`
            : null
          : `url('${data?.BackgroundImage}')`,
    }));
  }, [data?.BackgroundImage, data?.Section7BackgroundVisible]);

  useEffect(() => {
    setBackgroundImageStyle((prev) => ({
      ...prev,
      backgroundColor: data?.Section7BackgroundColor,
    }));
  }, [data?.Section7BackgroundColor, data?.Section7BackgroundVisible]);

  return (
    <>
      <section
        className="contact-us-section position-relative"
        // style={{
        //   backgroundImage: `url(${ContactUsBgNew})`,
        //   backgroundRepeat: "no-repeat",
        //   backgroundSize: "cover",
        //   backgroundPosition: "center center",
        // }}
        style={backgroundImageStyle}
      >
        <div
          className=""
          id="template-first-section-shadow"
          style={{
            backgroundColor: `rgba(33, 43, 50, ${data?.BackgroundOpacity})`,
            width: "-webkit-fill-available", // Adjust as needed
            margin: "-100px 0",
            padding: "100px 0",
          }}
        >
          <Container>
            <Row className="align-items-center">
              <Col lg={6} className="mb-3 mb-lg-0">
                <Slide direction="left">
                  <div className="section-content">
                    <div className="mb-3">
                      <img
                        src={data?.RoundImage}
                        height="200"
                        className="rounded-circle"
                        alt="Membership Enquiry"
                      />
                    </div>
                    <h3
                      style={{
                        fontSize: "50px",
                        fontFamily: "Sofia Pro",
                        fontWeight: "400",
                        marginBottom: "20px",
                        color: `#ffffff`,
                        lineHeight: "62px",
                      }}
                    >
                      {data?.Heading}
                    </h3>
                    <p
                      className="m-0"
                      style={{
                        fontSize: "22px",
                        fontWeight: "400",
                        color: "rgb(255 255 255 / 50%)",
                        lineHeight: "35px",
                        maxWidth: "85%",
                      }}
                      dangerouslySetInnerHTML={{ __html: data?.Content }}
                    />
                    {children}
                  </div>
                </Slide>
              </Col>
              <Col lg={6}>
                <Slide direction="right">
                  <div className="contact-form">
                    <Form onSubmit={formik?.handleSubmit}>
                      <Form.Group className="form-group" controlId="fullName">
                        <Form.Label>Name:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Full Name"
                          name="fullName"
                          onChange={formik?.handleChange}
                          onBlur={formik?.handleBlur}
                          value={formik?.values?.fullName}
                        />
                        {formik?.touched?.fullName &&
                          formik?.errors?.fullName && (
                            <Form.Text className="text-danger">
                              {formik?.errors?.fullName}
                            </Form.Text>
                          )}
                      </Form.Group>
                      <Form.Group className="form-group" controlId="email">
                        <Form.Label>Email:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Your Email Address"
                          name="email"
                          onChange={formik?.handleChange}
                          onBlur={formik?.handleBlur}
                          value={formik?.values?.email}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <Form.Text className="text-danger">
                            {formik?.errors?.email}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <Form.Group
                        className="form-group"
                        controlId="description"
                      >
                        <Form.Label>How Can We Help You?</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          className="h-auto"
                          placeholder="Description"
                          name="description"
                          onChange={formik?.handleChange}
                          onBlur={formik?.handleBlur}
                          value={formik?.values?.description}
                        />
                        {formik?.touched?.description &&
                          formik?.errors?.description && (
                            <Form.Text className="text-danger">
                              {formik?.errors?.description}
                            </Form.Text>
                          )}
                      </Form.Group>
                      <div className="d-flex justify-content-end">
                        <Button
                          style={{
                            backgroundColor: section1Data?.ThemeColor,
                            borderColor: section1Data?.ThemeColor,
                          }}
                          variant="primary"
                          size="lg"
                          type="submit"
                          className="d-flex align-items-center justify-content-center mt-2"
                          disabled={formLoading}
                        >
                          Submit
                          <img
                            src={ArrowIcon}
                            alt="Arrow Icon"
                            className="ms-2"
                            style={{ height: "10px" }}
                          />
                        </Button>
                      </div>
                    </Form>
                  </div>
                </Slide>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <EditModal data={data} setData={setData} />
    </>
  );
};

export default Section7;

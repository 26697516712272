import { Button, Form } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useDispatch, useSelector } from "react-redux";
import {
  closeTemplate3SectionEditorAction,
  saveTemplate3SectionBySectionIDAction,
  setPreviewOfTemplate3Action,
} from "../../../../../ReduxStore/slice/community-site-template/template-3/template-3-slice";
import { updateSectionOfTemplate3BySiteIDActionThunk } from "../../../../../ReduxStore/slice/community-site-template/template-3/template-3-async-thynk";
import ReactQuill from "react-quill";

const EditModal = ({ data, setData }) => {
  const dispatch = useDispatch();

  const siteID = useSelector((state) => state?.template3?.siteID);
  const save_btn_loading = useSelector(
    (state) => state?.template3?.loading?.section5
  );
  const show = useSelector((state) => {
    const is_Editable = state?.template3?.is_Editable;
    return is_Editable?.status && is_Editable?.current_edit_section === 5;
  });

  /**
   * edit modal close handler
   */
  const editModalCloseHandler = () => {
    dispatch(closeTemplate3SectionEditorAction({ sectionID: 5, data }));
  };

  /**
   * preview button handler
   */
  const previewButtonHandler = () => {
    dispatch(saveTemplate3SectionBySectionIDAction({ sectionID: 5, data }));
    dispatch(setPreviewOfTemplate3Action());
  };

  /**
   * Save Section Data
   */
  const saveSectionDataHandler = () => {
    dispatch(
      updateSectionOfTemplate3BySiteIDActionThunk({
        siteID,
        sectionID: 5,
        data,
      })
    );
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={editModalCloseHandler}
        placement="end"
        scroll={true}
      >
        <Offcanvas.Header closeButton className="border-bottom">
          <Offcanvas.Title>Edit</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="d-flex flex-column">
          <Form className="d-flex flex-column flex-fill">
            <Form.Group controlId="plainText" className="mb-4">
              <Form.Label>Heading</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Title"
                value={data?.Heading}
                onChange={(e) =>
                  setData((prevData) => ({
                    ...prevData,
                    Heading: e.target?.value,
                  }))
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="plainText">
              <Form.Label>Content</Form.Label>
              <ReactQuill
                preserveWhitespace
                value={data?.Content}
                onChange={(values) =>
                  setData((prevData) => ({
                    ...prevData,
                    Content: values,
                  }))
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Background color</Form.Label>
              <Form.Control
                type="color"
                value={data?.BackgroundColor}
                onChange={(e) =>
                  setData((prevData) => ({
                    ...prevData,
                    BackgroundColor: e.target?.value,
                  }))
                }
              />
            </Form.Group>
            <div className="pt-3 d-flex justify-content-end border-top mt-auto">
              <Button
                className="btn-secondary-theme btn-outline me-2"
                onClick={() => previewButtonHandler()}
              >
                Preview
              </Button>
              <Button
                className="btn-primary-theme font-size-14"
                onClick={() => saveSectionDataHandler()}
                disabled={save_btn_loading}
              >
                Save{" "}
                {save_btn_loading && (
                  <i className="fa fa-spinner fa-spin ms-2"></i>
                )}
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default EditModal;

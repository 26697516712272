import { createSlice } from "@reduxjs/toolkit";
import { getProfileUserCommunityActionThunk } from "./profile-user-community-async-thunk";

const initialState = {
    loading: { get_community: false },
    communitiy_list: [],
    errors: {
        get_community: null,
    }
}

const profileUserCommunitySlice = createSlice({
    name: 'profileUserCommunity',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getProfileUserCommunityActionThunk.pending, (state, action) => {
                state.loading.get_community = true;
                state.errors.get_community = null;
            })
            .addCase(getProfileUserCommunityActionThunk.fulfilled, (state, action) => {
                state.loading.get_community = false;
                state.errors.get_community = null;
                state.communitiy_list = action?.payload
            })
            .addCase(getProfileUserCommunityActionThunk.rejected, (state, action) => {
                state.loading.get_community = false;
                state.errors.get_community = action.error.message;
                state.communitiy_list = []
            })
    }
});


export const actions = profileUserCommunitySlice.actions;

const profileUserCommunityReducer = profileUserCommunitySlice.reducer;
export default profileUserCommunityReducer;
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ConditionalWrapper } from '../conditional-Wrapper/ConditionalWrapper'
import { isPrivateProfile } from '../../utils/helpers/isPrivacy'

const UserListDropDown = ({ userLists }) => {
    return (
        <>
            {Array.isArray(userLists) && userLists?.length > 0 ? (
                userLists?.map((usr, index) => (
                    <>
                        <Dropdown.Item key={index} >
                            <div className="btn-link">
                            <ConditionalWrapper
                                condition={isPrivateProfile(usr?.privacy?.profilePrivacy)}
                                wrapper={(children) => (
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                Account is private
                                            </Tooltip>
                                        }
                                    >{children}
                                    </OverlayTrigger>)}
                            >
                                <span to={(!usr?.isGuest && !isPrivateProfile(usr?.privacy?.profilePrivacy)) ? "/profile/about/" + usr?.userID : ""}>
                                    <div>
                                        <img
                                            src={usr?.profilePicture ? usr?.profilePicture : require("../../assets/Images/guest-user.jpg")}
                                            alt=""
                                        />
                                        {usr?.userName}
                                    </div>
                                </span>
                            </ConditionalWrapper>
                            </div>
                        </Dropdown.Item>
                    </>
                ))
            ) : (
                <Dropdown.Item eventKey="4" className='font-size-14 py-2'>No user</Dropdown.Item>
            )}
        </>
    )
}

export default UserListDropDown;
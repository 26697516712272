import GroupMessageCard from "./LiveStreamSubGroupMessageCard"

const LiveStreamSubGroupMessageList = ({ dataList }) => {

    return (
        <>
            {Array.isArray(dataList) && dataList?.map((message, index) => (
                <GroupMessageCard
                    key={index}
                    message={message}
                />
            ))}
        </>
    )
}

export default LiveStreamSubGroupMessageList

import {
  authLoading,
  loginSuccess,
  authLoaded,
  visitorLoaderAction,
  getVisitorsDataAction
} from './auth.action'
import UserService from '../../Services/user/UserServices'
import { setWithExpiry } from '../../utils/helpers/password'
import { getProfileDataActionThunk } from '../profileData/profileData.actions.async'
import { errorToast } from '../../Component/toast/toast'
import GroupService from '../../Services/group/GroupService'
import { getCurrentUserCommunityByActionThunk } from '../slice/curret-user-community/current-user-community-async-thunk'

/**
 * auth user login thunk
 * @param values
 */
export const loginActionThunk = (
  values,
  rememberMe,
  redirectHandler,
) => {
  return dispatch => {
    dispatch(authLoading())
    UserService.login(values?.email, values?.password, values?.deviceId)
      .then(response => {
        if (response?.data?.status !== 'ERROR') {
          dispatch(loginSuccess(response.data?.data))
          dispatch(
            getProfileDataActionThunk(response?.data?.data?.UserID, true)
          )
          dispatch(getCurrentUserCommunityByActionThunk())

          localStorage.setItem('lToken', response.data.data?.Token)
          localStorage.setItem(
            'lRefreshToken',
            response.data.data?.RefreshToken
          )

          //function will call after login
          redirectHandler && redirectHandler(response?.data?.data?.UserID)
          if (rememberMe) {
            setWithExpiry('encPass', values.password, values.email, 1)
          }
          if (!rememberMe) {
            localStorage.removeItem('rememberMe')
          }
        } else {
          errorToast('Something went wrong.')
        }
        dispatch(authLoaded())
      })
      .catch(error => {
        if (error.response && error?.response?.data?.status) {
          errorToast('Invalid email or password.')
        } else {
          errorToast('something went wrong')
        }
        dispatch(authLoaded())
      })
  }
}

/**
 * get visitor data who is not member of combo (Guest)
 * @param {*} data
 * @returns
 */
export const getVisitorDataActionThunk = (data, redirectHandler) => {
  return dispatch => {
    dispatch(visitorLoaderAction(true))
    GroupService.AddVisitorsInteraction(data)
      .then(res => {
        dispatch(getVisitorsDataAction(res?.data?.data))
        dispatch(visitorLoaderAction(false))
        redirectHandler && redirectHandler()
      })
      .catch(error => {
        dispatch(visitorLoaderAction(false))
        errorToast('something went wrong')
      })
  }
}

import { errorToast, successToast, warningToast } from "../../Component/toast/toast";
import GroupService from "../../Services/group/GroupService";
import { MODAL_STATUS, ADMIN_SEND_MSG_MODAL_STATUS } from "../Constants/templateConstant";

export const modalAct = (status) => {
  return ({
    type: MODAL_STATUS,
    payload: status
  })
}


export const adminSendMsgModalAct = (status) => {
  return ({
    type: ADMIN_SEND_MSG_MODAL_STATUS,
    payload: status
  })
}


////////////////////////// Action Thunk ///////////////////////////////////////////

/**
 * join gorup modal action thunk
 * @param {*} status 
 * @returns 
 */
export const modalActActionThunk = (
  data, //{status,groupId,communityID}
  redirectFunction, //redirect to another page
) => {
  return (dispatch, getState) => {
    const userID = getState()?.profileData?.profileData?.profileId;
    const token = getState()?.auth?.token;

    if (userID && token && data?.status) {
      GroupService.JoinGroup(userID, data?.groupId, data?.communityID)
        .then(res => {
          if (res?.data?.statusCode === 404) {
            warningToast("Already you are in group");
          } else {
            successToast("Group joined successfully");
            redirectFunction && redirectFunction(data?.groupId);
          }
        })
        .catch(err => {
          errorToast("something went wrong");
        })
    } else {
      dispatch(modalAct(data))
    }
  }
}




import moment from "moment";

/**
 * export event to google calendar
 * @param {*} event 
 */
export const addToGoogle = (event) => {
    var eventUrl = 'https://calendar.google.com/calendar/render?action=TEMPLATE&text=' +
        encodeURIComponent(event?.title) +
        '&dates=' + encodeURIComponent(moment(event?.startDate).format("YYYYMMDDTHHmmss") + '/' + moment(event?.endDate).format("YYYYMMDDTHHmmss")) +
        '&details=' + encodeURIComponent(event?.extendedProps?.description) +
        '&location=' + encodeURIComponent(event?.extendedProps?.address);
    window.open(eventUrl)
}

/**
 * generate ICS file for export event in apple, outlook etc calendars
 * @param {*} event 
 */
export const generateICSFile = (event) => {
    // Create the .ics URL
    let url = [
        "BEGIN:VCALENDAR",
        "VERSION:2.0",
        `PRODID: ${window.location.origin}/`,
        "METHOD: PUBLISH",
        "BEGIN:VEVENT",
        "DTSTART:" + moment(event?.startDate).format("YYYYMMDDTHHmmss"),
        "DTEND:" + moment(event?.endDate).format("YYYYMMDDTHHmmss"),
        "SUMMARY:" + event?.title,
        "DESCRIPTION:" + event?.extendedProps?.description,
        "LOCATION:" + event?.extendedProps?.address,
        "END:VEVENT",
        "END:VCALENDAR"

    ].join("\n");

    let blob = new Blob([url], { type: 'text/calendar;charset=utf-8' });

    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";

    if (/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) {
        // Open/Save link in IE and Edge
        window.navigator.msSaveBlob(blob, `.ics`);
    } else {
        // Open/Save link in Modern Browsers
        a.href = encodeURI("data:text/calendar;charset=utf8," + url);
        a.download = "combo-event";
        a.click();
        // window.open(encodeURI("data:text/calendar;charset=utf8," + url));
    }
}
import { memo, useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { modalAct } from '../../../ReduxStore/Actions/modalActs';
import Login from './Login';
import SignUp from './SignUp';
import { ReactComponent as CloseIcon } from "../../../assets/Images/close-icon.svg";
import { useNavigate } from 'react-router';
import { SpinnerLoader } from '../../loader/SpinnerLoader';

const JoinGroupModal = () => {

    const dispatch = useDispatch();
    const loginFormRef = useRef();
    const signUpFormRef = useRef();
    const navigate = useNavigate();

    const modalReducer = useSelector((state) => state.modalReducer);
    const token = useSelector((state) => state.auth?.token);

    const [loading, setLoading] = useState({ signup: false, login: false, joinGrpAPICall: false });
    const [key, setKey] = useState('login');

    //after join the gorup navigate to the group
    const navigateToGroup = () => {
        navigate("/group-view/newsfeed/" + modalReducer?.groupId);
        dispatch(modalAct({ status: !modalReducer.status, groupId: null, communityID: null }))
    }

    useEffect(() => {
        token && dispatch(modalAct({ status: false, groupId: null, communityID: null }))
    }, [dispatch, token])
    return (
        <>
            <Modal
                className="common-modal join-group-modal"
                show={modalReducer.status}
                onClose={() => dispatch(modalAct({ status: !modalReducer.status, groupId: null, communityID: null }))}
                backdrop="static"
                centered
                scrollable
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title className="text-black-theme">Join Group</Modal.Title>
                    <Button variant="link" onClick={() => dispatch(modalAct({ status: !modalReducer.status, groupId: null, communityID: null }))}>
                        <CloseIcon fill="#212121" />
                    </Button>
                </Modal.Header>
                <Modal.Body className='common-modal-body inner-tabs common-fields'>
                    {loading?.joinGrpAPICall ? (
                        <SpinnerLoader />
                    ) : (
                        <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3 mt-1">
                            <Tab eventKey="login" title="Login">
                                <Login
                                    formRef={loginFormRef}
                                    setLoading={setLoading}
                                    navigateToGroup={navigateToGroup}
                                />
                            </Tab>
                            <Tab eventKey="signup" title="Sign Up">
                                <SignUp
                                    formRef={signUpFormRef}
                                    setLoading={setLoading}
                                    navigateToGroup={navigateToGroup}
                                />
                            </Tab>
                        </Tabs>
                    )}
                </Modal.Body>
                <Modal.Footer className="common-modal-footer">
                    <Button className="btn-secondary-theme btn-outline" onClick={() => dispatch(modalAct({ status: !modalReducer.status, groupId: null, communityID: null }))}>
                        Cancel
                    </Button>
                    {((key === "login" && loading?.login) || (key === "signup" && loading?.signup) || loading?.joinGrpAPICall) ? (
                        <Button>Join Group <i className="fa fa-spinner fa-spin ms-1"></i></Button>
                    ) : (
                        <Button
                            className="btn-primary-theme"
                            onClick={() => {
                                key === "login" ?
                                    loginFormRef.current.requestSubmit() :
                                    signUpFormRef.current.requestSubmit()
                            }}
                        >
                            Join Group
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default memo(JoinGroupModal)





import { logout } from "../../ReduxStore/auth/auth.action"
import { doEmptyCommunityByUserStateAction } from "../../ReduxStore/communityByUser/community.actions"
import { doEmptyProfileAction } from "../../ReduxStore/profileData/profileData.actions"
import store from "../../ReduxStore/store"
import { stopConnection } from "../../Services/signalR/signalrService"

export const logoutHandler = (callBack) => {

    store.dispatch(logout());
    store.dispatch(doEmptyProfileAction());
    store.dispatch(doEmptyCommunityByUserStateAction());
    stopConnection();
    callBack && callBack();
    // persistor.purge();
}
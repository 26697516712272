import { createSlice } from "@reduxjs/toolkit";
import { getTemplate2BySiteIDActionThunk, updateAllTemplate2SectionsBySiteIDActionThunk, updateSectionOfTemplate2BySiteIDActionThunk } from "./template-2-async-thynk";
import { successToast } from "../../../../Component/toast/toast";

const initialState = {
    loading: {
        fetch_data: false,
        update_data: false,

        section1: false,
        section2: false,
        section3: false,
        section4: false,
        section5: false,
        section6: false,
    },
    is_Editable: { status: false, current_edit_section: 0 },
    is_preview: { status: false },
    siteID: "",
    siteDomain: "",
    admin_email: "",
    group_list: [],
    template_2_sections: {
        section1: null,
        section2: null,
        section3: null,
        section4: null,
        section5: null,
        section6: null,
    },
    errors: {
        fetch_data: null,
        update_data: null,

        section1: null,
        section2: null,
        section3: null,
        section4: null,
        section5: null,
        section6: null,
    }
}

const template2Slice = createSlice({
    name: 'template2',
    initialState,
    reducers: {

        /**
        * set is editable or noti
        */
        setIsEditableInTemplate2Action: (state, action) => {
            state.is_Editable = { status: action?.payload?.status, current_edit_section: action?.payload?.current_edit_section }
        },

        /**
         * preview of template 2 handler
         * @param {*} state 
         * @param {*} action 
         */
        setPreviewOfTemplate2Action: (state, action) => {
            state.is_preview = { status: !state.is_preview?.status }
        },

        /**
         * update tempate section data by sectionID
         */
        saveTemplate2SectionBySectionIDAction: (state, action) => {
            state.template_2_sections[`section${action?.payload?.sectionID}`] = action?.payload?.data;
        },

        /**
         *  open editor of template2 section
         * @param {object} state redux state
         * @param {object} action {sectionID}
         */
        openTemplate2SectionEditorAction: (state, action) => {
            state.is_Editable = { status: state?.is_Editable?.status, current_edit_section: action?.payload?.sectionID }
        },

        /**
         *  close editor of template2 section
         * @param {object} state redux state
         * @param {object} action {sectionID, data}
         */
        closeTemplate2SectionEditorAction: (state, action) => {
            state.is_Editable = { status: state?.is_Editable?.status, current_edit_section: 0 }
            state.template_2_sections[`section${action?.payload?.sectionID}`] = action?.payload?.data;
        },

        //add template data action
        addTemplate2DataAction: (state, action) => {
            state.loading.fetch_data = false;
            state.errors.fetch_data = null;
            state.siteDomain = action?.payload?.domainName;
            state.siteID = action?.payload?.siteID;
            state.group_list = action?.payload?.groups;
            state.admin_email = action?.payload?.adminEmailID
            for (const section in action?.payload?.jsonSectionList) {
                state.template_2_sections[(section).toLowerCase()] = JSON.parse(action?.payload.jsonSectionList[section]);
            }
        },
    },
    extraReducers: (builder) => {
        builder
            //get all section data 
            .addCase(getTemplate2BySiteIDActionThunk.pending, (state, action) => {
                state.loading.fetch_data = true;
                state.errors.fetch_data = null;
                state.template_2_sections = { section1: null, section2: null, section3: null, section4: null, section5: null, section6: null, };
            })
            .addCase(getTemplate2BySiteIDActionThunk.fulfilled, (state, action) => {
                state.loading.fetch_data = false;
                state.errors.fetch_data = null;
                state.siteDomain = action?.payload?.domainName;
                state.siteID = action?.payload?.siteID;
                state.group_list = action?.payload?.groups;
                state.admin_email = action?.payload?.adminEmailID
                for (const section in action?.payload?.jsonSectionList) {
                    state.template_2_sections[(section).toLowerCase()] = JSON.parse(action?.payload.jsonSectionList[section]);
                }
            })
            .addCase(getTemplate2BySiteIDActionThunk.rejected, (state, action) => {
                state.loading.fetch_data = false;
                state.errors.fetch_data = action?.error?.message;
                state.template_2_sections = { section1: null, section2: null, section3: null, section4: null, section5: null, section6: null, };
            })

            //update section data of template 2
            .addCase(updateSectionOfTemplate2BySiteIDActionThunk.pending, (state, action) => {
                state.loading[`section${action?.meta?.arg?.sectionID}`] = true;
                state.errors[`section${action?.meta?.arg?.sectionID}`] = null;
            })
            .addCase(updateSectionOfTemplate2BySiteIDActionThunk.fulfilled, (state, action) => {
                state.loading[`section${action?.meta?.arg?.sectionID}`] = false;
                state.errors[`section${action?.meta?.arg?.sectionID}`] = null;
                state.template_2_sections[`section${action?.meta?.arg?.sectionID}`] = action?.meta?.arg?.data
                state.is_Editable.current_edit_section = 0;
                successToast("Template updated successfully");
            })
            .addCase(updateSectionOfTemplate2BySiteIDActionThunk.rejected, (state, action) => {
                state.loading[`section${action?.meta?.arg?.sectionID}`] = false;
                state.errors[`section${action?.meta?.arg?.sectionID}`] = action?.error?.message;
            })

            //update all section of template 2
            .addCase(updateAllTemplate2SectionsBySiteIDActionThunk.pending, (state, action) => {
                state.loading.update_data = true;
                state.errors.update_data = null;
            })
            .addCase(updateAllTemplate2SectionsBySiteIDActionThunk.fulfilled, (state, action) => {
                state.loading.update_data = false;
                state.errors.update_data = null;
                successToast("Template updated successfully");
            })
            .addCase(updateAllTemplate2SectionsBySiteIDActionThunk.rejected, (state, action) => {
                state.loading.update_data = false;
                state.errors.update_data = action?.error?.message;
            })
    }
});


export const {
    openTemplate2SectionEditorAction,
    closeTemplate2SectionEditorAction,
    setIsEditableInTemplate2Action,
    setPreviewOfTemplate2Action,
    saveTemplate2SectionBySectionIDAction,
    addTemplate2DataAction,
} = template2Slice.actions;

const template2Reducer = template2Slice.reducer;
export default template2Reducer;
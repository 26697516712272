import { createSlice } from "@reduxjs/toolkit";
import {  getAllCompletedLiveStreamsListActionThunk, getAllOngoingLiveStreamsListActionThunk, getAllUpcomingLiveStreamsListActionThunk, updateLiveStream } from "./group-livestream-async-thunk";


const initialState = {
    loading: {
      // get_live_stream_list: false,
      get_upcoming_live_stream_list: false,
      get_ongoing_live_stream_list: false,
      get_completed_live_stream_list: false,


    },
    live_stream_list: [],
    liveSteamAll: { upComing: [], onGoing: [], completed: [] },
  
    errors: {
      get_upcoming_live_stream_list: null,
      get_ongoing_live_stream_list: null,
      get_completed_live_stream_list: null,

    },
  };

  const liveStreamSlice = createSlice({
    name: 'liveStremList',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
        // .addCase(getAllLiveStream.pending, (state, action) => {
        //   state.loading.get_live_stream_list = true;
        //   state.errors.get_live_stream_list = null;
        // })
        // .addCase(getAllLiveStream.fulfilled, (state, action) => {
        //   state.loading.get_live_stream_list = false;
        //   state.errors.get_live_stream_list = null;
        //   state.live_stream_list = action?.payload;
        // })
        // .addCase(getAllLiveStream.rejected, (state, action) => {
        //   state.loading.get_live_stream_list = false;
        //   state.errors.get_live_stream_list = action.error.message;
        //   state.live_stream_list = [];
        // })

      // updateLivestream
        .addCase(updateLiveStream.pending, (state, action) => {
          state.loading.get_live_stream_list = true;
          state.errors.get_live_stream_list = null;
        })
        .addCase(updateLiveStream.fulfilled, (state, action) => {
          state.loading.get_live_stream_list = false;
          state.errors.get_live_stream_list = null;
          // state.live_stream_list = action?.payload;
        })
        .addCase(updateLiveStream.rejected, (state, action) => {
          state.loading.get_live_stream_list = false;
          state.errors.get_live_stream_list = action.error.message;
          // state.live_stream_list = [];
        })

          //  delete liveStream
      // .addCase(deleteLiveStreamActionThunk.pending, (state, action) => {
      //   state.loading.get_live_stream_list = true;
      //     state.errors.get_live_stream_list = null;
      // })
      // .addCase(deleteLiveStreamActionThunk.fulfilled, (state, action) => {
      //   state.loading.get_live_stream_list = false;
      //   state.errors.get_live_stream_list = null;
      // })
      // .addCase(deleteLiveStreamActionThunk.rejected, (state, action) => {
      //   state.loading.get_live_stream_list = false;
      //   state.errors.get_live_stream_list = action.error.message;
      // })
      
      
      // UpComing LiveStream

      .addCase(getAllUpcomingLiveStreamsListActionThunk.pending, (state, action) => {
        state.loading.get_upcoming_live_stream_list = true;
        state.errors.get_upcoming_live_stream_list = null;
      })
      .addCase(getAllUpcomingLiveStreamsListActionThunk.fulfilled, (state, action) => {
        state.loading.get_upcoming_live_stream_list = false;
        state.errors.get_upcoming_live_stream_list = null;
        state.liveSteamAll.upComing = action?.payload;
      })
      .addCase(getAllUpcomingLiveStreamsListActionThunk.rejected, (state, action) => {
        state.loading.get_upcoming_live_stream_list = false;
        state.errors.get_upcoming_live_stream_list = action.error.message;
        state.liveSteamAll.upComing = [];
      })

       // Ongoing LiveStream

       .addCase(getAllOngoingLiveStreamsListActionThunk.pending, (state, action) => {
        state.loading.get_ongoing_live_stream_list = true;
        state.errors.get_ongoing_live_stream_list = null;
      })
      .addCase(getAllOngoingLiveStreamsListActionThunk.fulfilled, (state, action) => {
        state.loading.get_ongoing_live_stream_list = false;
        state.errors.get_ongoing_live_stream_list = null;
        state.liveSteamAll.onGoing = action?.payload;
      })
      .addCase(getAllOngoingLiveStreamsListActionThunk.rejected, (state, action) => {
        state.loading.get_ongoing_live_stream_list = false;
        state.errors.get_ongoing_live_stream_list = action.error.message;
        state.liveSteamAll.onGoing = [];
      })

             // Completed LiveStream

             .addCase(getAllCompletedLiveStreamsListActionThunk.pending, (state, action) => {
              state.loading.get_completed_live_stream_list = true;
              state.errors.get_completed_live_stream_list = null;
            })
            .addCase(getAllCompletedLiveStreamsListActionThunk.fulfilled, (state, action) => {
              state.loading.get_completed_live_stream_list = false;
              state.errors.get_completed_live_stream_list = null;
              state.liveSteamAll.completed = action?.payload;
            })
            .addCase(getAllCompletedLiveStreamsListActionThunk.rejected, (state, action) => {
              state.loading.get_completed_live_stream_list = false;
              state.errors.get_completed_live_stream_list = action.error.message;
              state.liveSteamAll.completed = [];
            })
    }
});

export const actions = liveStreamSlice.actions;

const liveStreamReducer = liveStreamSlice.reducer;
export default liveStreamReducer;

import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ReactComponent as CalendarIcon } from "../../../assets/Images/calendar-icon.svg";
import { ReactComponent as AddressIcon } from "../../../assets/Images/address-icon.svg";
import { ReactComponent as DescriptionIcon } from "../../../assets/Images/description-icon.svg";

import GroupService from "../../../Services/group/GroupService";
import { errorToast } from "../../toast/toast";
import moment from "moment";
import { SpinnerLoader } from "../../loader/SpinnerLoader";
import AddToCalendar from "../AddToCalendar";
import ConfirmButtons from "../ConfirmButtons";
import { Link } from "react-router-dom";
import ViewLiveStreamModel from "../../Group/livestream/ViewLiveStreamModel";

const EventDetail = ({ eventId }) => {
  const [loading, setLoading] = useState({
    eventDetail: false,
    eventConfirmation: { status: false, btn: "" },
  });
  const [eventDetails, setEventDetails] = useState(null);
  const [confirmId, setConfirmID] = useState(null);
  const [showModal, setShowModal] = useState({
    isOpen: false,
    url: "",
    subGroupID: "",
  });

  const openModal = (c) => {
    setShowModal({
      isOpen: true,
      url: c?.extendedProps.description,
      subGroupID: "",
    });
  };
  const closeModal = () => {
    setShowModal({ isOpen: false, url: "", subGroupID: "" });
  };

  //get event details
  const GetGroupEventById = async () => {
    GroupService.GetGroupEventById(eventId)
      .then((res) => {
        setEventDetails(res.data);
        setLoading((prev) => ({
          ...prev,
          eventDetail: false,
          eventConfirmation: { status: false, btn: "" },
        }));
      })
      .catch((err) => {
        errorToast(
          "err: EventDetailModal.jsx, GetGroupEventById " + err.message
        );
        setEventDetails(null);
        setLoading((prev) => ({
          ...prev,
          eventDetail: false,
          eventConfirmation: { status: false, btn: "" },
        }));
      });
  };

  //get user confirmation details
  const GetEventdetailByUserIdAndEventId = () => {
    GroupService.GetEventdetailByUserIdAndEventId(eventId)
      .then((res) => {
        setConfirmID(res?.data?.data);
      })
      .catch((err) => {
        errorToast(
          "err: EventDetailModal.jsx, GetEventdetailByUserIdAndEventId " +
            err.message
        );
        setConfirmID(null);
      });
  };

  useEffect(() => {
    GetGroupEventById();
    GetEventdetailByUserIdAndEventId();
    setLoading((prev) => ({ ...prev, eventDetail: true }));
  }, []);

  const handleClick = (data) => {
    openModal(data);
  };

  return (
    <>
      {loading?.eventDetail ? (
        <SpinnerLoader />
      ) : (
        <>
          <div className="event-details-header d-flex align-items-center justify-content-between">
            <div>
              <h4 className="mb-1">{eventDetails?.title}</h4>
              <p className="m-0">
                {eventDetails?.extendedProps?.communityName}
              </p>
            </div>
            <AddToCalendar event={eventDetails} />
          </div>
          <div className="event-details-date">
            <Row md={2}>
              <Col>
                <div className="d-flex align-items-center common-field">
                  <CalendarIcon className="common-icon" fill="#757575" />
                  <div className="d-flex flex-column flex-fill ms-3">
                    <p className="label mb-1"> Start Date </p>
                    <p className="time m-0">
                      {/* {moment(eventDetails?.startDate).format(
                        "DD MMM yyyy - hh:mm A"
                      )} */}
                           {moment.utc(eventDetails?.startDate).local().format(
                          "DD MMM YYYY - hh:mm A"
                        )}
                    </p>
                  </div>
                </div>
              </Col>
              <Col className="custom-border">
                <div className="d-flex align-items-center common-field">
                  <CalendarIcon className="common-icon" fill="#757575" />
                  <div className="d-flex flex-column flex-fill ms-3">
                    <p className="label mb-1"> End Date </p>
                    <p className="time m-0">
                      {/* {moment(eventDetails?.endDate).format(
                        "DD MMM yyyy - hh:mm A"
                      )} */}
                            {moment.utc(eventDetails?.endDate).local().format(
                          "DD MMM YYYY - hh:mm A"
                        )}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="event-details-address">
            <div className="d-flex align-items-center common-field">
              <DescriptionIcon className="common-icon" fill="#757575" />
              <div className="d-flex flex-column flex-fill ms-3">
                <p className="label mb-1"> Description </p>
                <p
                  // onClick={() => handleClick(eventDetails)}
                  className="time m-0"
                >
                  {eventDetails?.extendedProps?.description}
                </p>
              </div>
            </div>
          </div>
          <div className="event-details-address">
            <div className="d-flex align-items-center common-field">
              <AddressIcon className="common-icon" fill="#757575" />
              <div className="d-flex flex-column flex-fill ms-3">
                <p className="label mb-1"> Address </p>
                <p className="time m-0">
                  {eventDetails?.extendedProps?.address}
                </p>
              </div>
            </div>
          </div>
          <div className="event-details-body">
            <div className="d-flex">
              <img
                src={
                  eventDetails?.extendedProps?.profilePicture ||
                  require("../../../assets/Images/guest-user.jpg")
                }
                alt="Avatar"
              />
              <div className="d-flex justify-items-center flex-column flex-fill">
                <p className="user-name mt-1 mb-0">
                  {eventDetails?.extendedProps?.userName}{" "}
                </p>
                <p className="label mb-3">Invited You</p>
                <ConfirmButtons
                  eventDetails={eventDetails}
                  setEventDetails={setEventDetails}
                  eventId={eventId}
                  setLoading={setLoading}
                  loading={loading}
                />
              </div>
            </div>
          </div>
          {/* {showModal.isOpen && (
            <ViewLiveStreamModel
              show={showModal.isOpen}
              handleClose={closeModal}
              url={showModal.url}
              subGroupID={showModal.subGroupID}
            />
          )} */}
        </>
      )}
    </>
  );
};

export default EventDetail;

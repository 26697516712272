export const TEMPLATE_HEADING_REQUEST = "TEMPLATE_HEADING_REQUEST";
export const TEMPLATE_DESC_REQUEST = "TEMPLATE_DESC_REQUEST";

export const TEMPLATE_ABOUTUS_HEADING_REQUEST = "TEMPLATE_ABOUTUS_HEADING_REQUEST";
export const TEMPLATE_ABOUTUS_DESC_REQUEST = "TEMPLATE_ABOUTUS_DESC_REQUEST";

export const TEMPLATE_CONTENT_ID = "TEMPLATE_CONTENT_ID";
export const TEMPLATE_LOGO_COMMUNITY_NAME = "TEMPLATE_LOGO_COMMUNITY_NAME";

export const COMMUNITY_USER_DETAILS_REQUEST = "COMMUNITY_USER_DETAILS_REQUEST";
export const COMMUNITY_USER_DETAILS_SUCCESS = "COMMUNITY_USER_DETAILS_SUCCESS";
export const COMMUNITY_USER_DETAILS_FAIL = "COMMUNITY_USER_DETAILS_FAIL";

export const TEMPLATE_COMMUNITY_BANNER_REQUEST = "TEMPLATE_COMMUNITY_BANNER_REQUEST";
export const TEMPLATE_COMMUNITY_BANNER_SUCCESS = "TEMPLATE_COMMUNITY_BANNER_SUCCESS";
export const TEMPLATE_COMMUNITY_BANNER_FAIL = "TEMPLATE_COMMUNITY_BANNER_FAIL";

export const OPEN_FIRST_EDITOR = "OPEN_FIRST_EDITOR";
export const OPEN_SEC_EDITOR = "OPEN_SEC_EDITOR";
export const OPEN_THIRD_EDITOR = "OPEN_THIRD_EDITOR";
export const OPEN_FOURTH_EDITOR = "OPEN_FOURTH_EDITOR";

export const OPEN_SECTION_EDITOR = "OPEN_SECTION_EDITOR";
export const PREVIEW_EDIT_TEMPLATE_VIEW = "PREVIEW_EDIT_TEMPLATE_VIEW";

export const MODAL_STATUS = "MODAL_STATUS";
export const ADMIN_SEND_MSG_MODAL_STATUS = "ADMIN_SEND_MSG_MODAL_STATUS";
export const GET_JOIN_GROUP_MEM_DETAIL = "GET_JOIN_GROUP_MEM_DETAIL";

export const RENDER_COMPONENT = "RENDER_COMPONENT";
export const USER_PERMISSION = "USER_PERMISSION";


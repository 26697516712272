/**
 * get sub domain from current url
 * @returns 
 */
export const getSubDomainFromUrl = () => {
    const hostname = window?.location?.host;

    // Split the hostname by dots to get the subdomain
    const hostnameParts = hostname?.split('.');

    if (hostnameParts[1]?.includes("localhost:")) {
        return hostnameParts[0];
    }

    // Check if there is a subdomain (at least two parts in the hostname)
    else if (hostnameParts?.length > 2) {
        const subdomain = hostnameParts[0];
        return subdomain
    } else {
        return
    }
}


import {
  COMMUNITY_ALL_LOADING,
  COMMUNITY_ALL_LOADED,
  GET_ALL_COMMUNITY_LIST,
  GET_ALL_GROUP_LIST,
  GET_ALL_UPCOMING_EVENT_LIST,
  GET_ALL_ONGOING_EVENT_LIST,
  GET_ALL__COMPLETED_EVENT_LIST,

} from "./communityAll.constant";

/**
 * community data loading action creator
 * @returns
 */
export const loadingAction = (type) => ({ type: COMMUNITY_ALL_LOADING, payload: type });

/**
 * community data loaded action creator
 * @returns
 */
export const loadedAction = (type) => ({ type: COMMUNITY_ALL_LOADED, payload: type });

/**
 * get community list action creator
 * @param {*} communityList 
 * @returns 
 */
export const getAllCommunityAction = (communityList) => ({ type: GET_ALL_COMMUNITY_LIST, payload: communityList });

/**
 * get all group list by community id action creator
 * @param {*} groupList 
 * @returns 
 */
export const getAllGroupByCommunityIDAction = (groupList) => ({ type: GET_ALL_GROUP_LIST, payload: groupList })

/**
 * get all event list of group
 * @param {*} eventList 
 * @returns 
 */
export const getAllUpcomingEventListAction = (eventList) => ({ type: GET_ALL_UPCOMING_EVENT_LIST, payload: eventList });
export const getAllOngoingEventListAction = (eventList) => ({ type: GET_ALL_ONGOING_EVENT_LIST, payload: eventList });
export const getAllCompletedEventListAction = (eventList) => ({ type: GET_ALL__COMPLETED_EVENT_LIST, payload: eventList });

import { createSlice } from "@reduxjs/toolkit";
import { getGroupDetailByUserIdAndGroupIdActionThunk, joinGroupActionThunk, leaveFromGroupActionThunk } from "./group-open-async-thunk";

const initialState = {
    loading: {
        fetch_group_detail: false,
        join_group: false,
        leave_group: false,
    },
    group_detail: {},
    errors: {
        fetch_group_detail: null,
        join_group: null,
        leave_group: null,
    }
}

const groupOpenSlice = createSlice({
    name: 'groupOpen',
    initialState,
    reducers: {
        doEmptyGroupDetailsInGroupOpenStoreAction: (state, action) => {
            state.group_detail = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getGroupDetailByUserIdAndGroupIdActionThunk.pending, (state, action) => {
                state.loading.fetch_group_detail = true;
                state.errors.fetch_group_detail = null;
                state.group_detail = {};
            })
            .addCase(getGroupDetailByUserIdAndGroupIdActionThunk.fulfilled, (state, action) => {
                state.loading.fetch_group_detail = false;
                state.errors.fetch_group_detail = null;
                state.group_detail = action?.payload
            })
            .addCase(getGroupDetailByUserIdAndGroupIdActionThunk.rejected, (state, action) => {
                state.loading.fetch_group_detail = false;
                state.errors.fetch_group_detail = action.error.message;
                state.group_detail = {};
            })

            //join group
            .addCase(joinGroupActionThunk.pending, (state, action) => {
                state.loading.join_group = true;
                state.errors.join_group = null;
            })
            .addCase(joinGroupActionThunk.fulfilled, (state, action) => {
                state.loading.join_group = false;
                state.errors.join_group = null;
            })
            .addCase(joinGroupActionThunk.rejected, (state, action) => {
                state.loading.join_group = false;
                state.errors.join_group = action.error.message;
            })

            //leave from the group
            .addCase(leaveFromGroupActionThunk.pending, (state, action) => {
                state.loading.leave_group = true;
                state.errors.leave_group = null;
            })
            .addCase(leaveFromGroupActionThunk.fulfilled, (state, action) => {
                state.loading.leave_group = false;
                state.errors.leave_group = null;
            })
            .addCase(leaveFromGroupActionThunk.rejected, (state, action) => {
                state.loading.leave_group = false;
                state.errors.leave_group = action.error.message;
            })
    }
});


export const {
    doEmptyGroupDetailsInGroupOpenStoreAction
} = groupOpenSlice.actions;

const groupOpenReducer = groupOpenSlice.reducer;
export default groupOpenReducer;
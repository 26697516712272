import { useEffect, useRef, useState } from "react";
import { Button, Modal, ToggleButton, ToggleButtonGroup, } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "../../../assets/Images/close-icon.svg";
import GroupService from "../../../Services/group/GroupService";
import { errorToast, successToast } from "../../toast/toast";
import EventDetail from "../event-detail-modal/EventDetail";
import ExistingUserInvite from "../event-detail-modal/ExistingUserInvite";
import GuestInvite from "../event-detail-modal/GuestInvite";
import { useLocation, useNavigate } from "react-router";

const EventDetailModal = () => {

    const formRef = useRef();
    const guestSbmtBtnRef = useRef();
    const usrSbmtBtnRef = useRef();
    const location = useLocation();
    const navigate = useNavigate();

    const show = location?.state?.showEvent;
    const eventId = location?.state?.eventId;
    const groupId = location?.state?.groupId;

    const url = `${window.location.origin}/public-event/${eventId}`;

    const [inviteTab, setInviteTab] = useState(1);
    const [saveBtnLoading, setSaveBtnLoading] = useState(false);

    const [selectedUsers, setSelectedUsers] = useState([])

    const handleClose = () => {
        navigate(location?.pathname, { state: { ...location?.state, showEvent: false, eventId: "", groupId: "" }, replace: true })
    }

    /**
     * guest invitation submit handler
     * @param {*} eventId 
     * @param {*} url 
     * @param {*} UserID 
     * @param {*} emailList 
     * @param {*} resetForm 
     */
    const handleSubmitGuestInvitation = (eventId, url, emailList, message, resetForm) => {
        setSaveBtnLoading(true);
        GroupService.AddEventInvitationNewUser(eventId, url, emailList, message)
            .then(res => {
                if (res?.data?.statusCode !== 500) {
                    successToast("Invitation sent successfully")
                    resetForm && resetForm();
                } else {
                    errorToast("Something went wrong");
                }
                setSaveBtnLoading(false);
            })
            .catch(err => {
                errorToast("Something went wrong");
                setSaveBtnLoading(false);
            })
    }

    const handleSubmitExistingUserInvitation = (eventId, url, emailList) => {
        setSaveBtnLoading(true);
        GroupService.AddEventInvitationToExistingUser(eventId, url, emailList)
            .then(res => {
                handleClose();
                successToast("Invitation sent successfully")
                setSaveBtnLoading(false);
            })
            .catch(err => {
                errorToast("Something went wrong");
                setSaveBtnLoading(false);
            })
    }
    useEffect(() => {
        if (usrSbmtBtnRef?.current) {
            selectedUsers?.length > 0 ? usrSbmtBtnRef?.current.removeAttribute("disabled") : usrSbmtBtnRef?.current.setAttribute("disabled", true)
        }
    }, [selectedUsers?.length])
    return (
        <>
            <Modal
                className="common-modal event-details-modal"
                centered
                scrollable
                show={show}
                onHide={handleClose}
            >
                <Modal.Header>
                    <Modal.Title>Event Details</Modal.Title>
                    <Button variant="link" onClick={handleClose}>
                        <CloseIcon fill="#212121" />
                    </Button>
                </Modal.Header>
                <Modal.Body className="common-modal-body">
                    <EventDetail eventId={eventId} />
                    <h6 className="text-black-theme mb-3 mt-4">Invite Members</h6>
                    <>
                        <ToggleButtonGroup className="mb-20 w-100 custom-btn-group invite-members" type="radio" name="options" defaultValue={1}>
                            <ToggleButton id="tbg-radio-3" className={`${inviteTab === 1 ? "active" : undefined} btn-primary-theme btn-outline`}
                                value={1}
                                onClick={() => setInviteTab(1)}
                            >
                                New Invitation
                            </ToggleButton>
                            <ToggleButton
                                id="tbg-radio-4"
                                className={`${inviteTab === 2 ? "active" : undefined} btn-primary-theme btn-outline`}
                                value={2}
                                onClick={() => setInviteTab(2)}
                            >
                                Existing User
                            </ToggleButton>
                        </ToggleButtonGroup>
                        {inviteTab === 1 ? (
                            <GuestInvite
                                eventId={eventId}
                                handleSubmitGuestInvitation={handleSubmitGuestInvitation}
                                formRef={formRef}
                                url={url}
                                guestSbmtBtnRef={guestSbmtBtnRef}
                            />
                        ) : (
                            <ExistingUserInvite
                                setSelectedUsers={setSelectedUsers}
                                groupID={groupId}
                            />
                        )}
                    </>
                </Modal.Body>
                <Modal.Footer className="common-modal-footer">
                    <Button
                        className="btn-secondary-theme btn-outline"
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </Button>
                    {saveBtnLoading ? (
                        <Button>Submit <i className="fa fa-spinner fa-spin ms-1"></i></Button>
                    ) : (
                        <Button
                            className="btn-primary-theme"
                            ref={inviteTab === 1 ? guestSbmtBtnRef : usrSbmtBtnRef}
                            onClick={() => {
                                if (inviteTab === 1) {
                                    formRef.current.requestSubmit()
                                } else {
                                    handleSubmitExistingUserInvitation(eventId, url, selectedUsers)
                                }
                            }}
                        >
                            Submit
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EventDetailModal;
import { InstallMobileOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

const InstallAppButton = ({ themeColor }) => {

    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isInstalled, setIsInstalled] = useState(false);


    const handleInstallClick = () => {
        if (deferredPrompt) {
            deferredPrompt?.prompt();
            deferredPrompt.userChoice.then(choiceResult => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the PWA installation');
                } else {
                    console.log('User dismissed the PWA installation');
                }
                setDeferredPrompt(null);
            });
        }
    };

    useEffect(() => {
        const handleBeforeInstallPrompt = event => {
            event.preventDefault();
            setDeferredPrompt(event);
        };
        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            // Clean up event listener when the component unmounts
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    });

    useEffect(() => {
        if (window.matchMedia('(display-mode: standalone)').matches) {
            setIsInstalled(true);
        }
    })

    return (!isInstalled &&
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip className='password-tooltip'>Install community app</Tooltip>
            }
        >
            <Button
                variant="info"
                className='ms-3'
                style={{ backgroundColor: `${themeColor}`, borderColor: `${themeColor}` }}
                onClick={handleInstallClick}
            >
                <InstallMobileOutlined style={{ fontSize: "17px", position: 'relative', top: '-2px' }} /> Install
            </Button>
        </OverlayTrigger>
    )
}

export default InstallAppButton
import { createSlice } from "@reduxjs/toolkit"
import { getOrSetCurrentDashboardCommunityActionThunk } from "./dashboard-async-thunk";


const initialState = {
    loading: false,
    errors: null,
    communityId: "",
}

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOrSetCurrentDashboardCommunityActionThunk.pending, (state, action) => {
                state.loading = true;
                state.errors = null;
            })
            .addCase(getOrSetCurrentDashboardCommunityActionThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.errors = null;
                state.communityId = action?.payload?.data
            })
            .addCase(getOrSetCurrentDashboardCommunityActionThunk.rejected, (state, action) => {
                state.loading = false;
                state.errors = action.error.message;
            })
    }
});

export const actions = dashboardSlice.actions;

const dashboardReducer = dashboardSlice.reducer;
export default dashboardReducer;
import { API } from "../../http-Common";

class GroupService {
  createGroup(data, config) {
    return API.post("/Group/CreateGroup", data, config);
  }

  inviteMembersViaLink(data, config) {
    return API.post("/Group/InviteMembersViaLink", data, config);
  }

  updateGroup(data, config) {
    return API.put("/Group/UpdateGroup", data, config);
  }

  getAllGroupByUserID(userID) {
    return API.get(`/Group/GetAllGroupByUserID?userID=${userID}`);
  }

  getAllGroupByCommunityID(siteID) {
    return API.get(`/Group/GetAllGroupByCommunityID?communityID=${siteID}`);
  }

  deleteGroup(groupID) {
    return API.delete(`/Group/DeleteGroup?groupID=${groupID}`);
  }

  getGroupByGroupID(groupID) {
    return API.get(`/Group/GetGroupByGroupID?groupID=${groupID}`);
  }

  // getCommunityGroupsWithCommunityDetails() {
  //   return API.get(`/Group/GetCommunityGroupsWithCommunityDetails`);
  // }

  // GetAllGroupsByUserID(userID) {
  //   return API.get(`/Group/GetAllGroupsByUserID?userID=${userID}`);
  // }

  getAllGroupMembers(groupID, nameSearched, pageNo, pageSize) {
    return API.get("/Group/GetAllGroupMembers", {
      params: {
        groupID,
        nameSearched: nameSearched ? nameSearched : undefined,
        pageNo,
        pageSize
      },
    });
  }

  getGroupMembersDetailsByEmailId(emailId, groupID) {
    return API.get("/Group/GetGroupMembersDetailsByEmailId", { params: { emailId, groupID } });
  }
  getGroupMember(groupID) {
    return API.get(`/Group/GetGroupMembers?groupID=${groupID}`);
  }

  uploadGroupPostDetails(data, config) {
    return API.post("/Group/UploadGroupPostDetails", data, config);
  }

  getAllGroupPostDataByGroupIDCommunityID(groupId, communityId, pageNo, pageSize) {
    return API.get(
      `/Group/GetAllGroupPostDataByGroupIDCommunityID?groupID=${groupId}&communityID=${communityId}&pageNo=${pageNo}&pageSize=${pageSize}&`
    );
  }

  /**
   * create newsfeed comment
   * @param {*} data {PostID:"xyz",Comments:"abc", MediaFileType:"string",MediaFile:"binary"}
   * @param {*} config 
   * @returns 
   */
  createCommunityGroupComments(data) {
    return API.post("/Group/CreateCommunityGroupComments", { ...data });
  }

  updateCommunityGroupComments(data) {
    return API.put("/Group/UpdateCommunityGroupComments", { ...data }, { params: { commentID: data?.commentID } })
  }


  CreateGroupMember(data, config) {
    return API.post(`/Group/CreateGroupMember`, data, config);
  }

  getPostComments(postID, commentID) {
    return API.get(`Group/GetPostAllComments`, { params: { postID, commentID } });
  }
  AddPostLike(postID) {
    return API.post(`Group/AddPostLike?postID=${postID} `);
  }

  /**
   * like comment in newsfeed
   * @param {*} data {commentID: "string",postID: "string"}
   * @returns comment entity object
   */
  AddCommentLike(data) {
    return API.post(`Group/AddCommentLike`, data);
  }
  addUserNotificationMessage(data) {
    return API.post(`Group/AddUserNotificationMessage`, data);
  }
  getAllNotificationMessageByUserID() {
    return API.get(`Group/GetAllNotificationMessageByUserID`);
  }

  GetAllGroupMembersExceptAdmin(groupID, pageIndex, pageSize, nameSearched) {
    return API.get('/Group/GetAllGroupMembersExceptAdmin', { params: { groupID, pageIndex, pageSize, nameSearched } });
  }

  deleteGroupMember(groupMemberID) {
    return API.delete(`/Group/DeleteGroupMember?groupMemberID=${groupMemberID}`);
  }
  groupMemberStatusUpdate(groupMemberID, status) {
    return API.put(`/Group/GroupMemberStatusUpdate?groupMemberID=${groupMemberID}&status=${status}`);
  }

  /**
   * add reply of comment in newsfeed
   * @param {*} data {CommentID,MediaFileType,MediaFileName,ReplyMessage}
   * @param {*} config 
   * @returns 
   */
  AddCommentReply(data, config) {
    return API.post(`Group/AddCommentReply`, data, config);
  }

  addEventDetails(data) {
    return API.post(`Group/AddEventDetails `, data);
  }

  getUpcomingEventsList(groupID) {
    return API.get(`Group/GetUpcomingEventsList?groupID=${groupID}`);
  }
  GetGroupEventById(eventID) {
    return API.get(`/Group/GetGroupEventById?eventID=${eventID}`);
  }
  getOutgoingEventsList(groupID) {
    return API.get(`Group/GetOutgoingEventsList?groupID=${groupID}`);
  }
  GetOngoingEventsList(groupID) {
    return API.get(`Group/GetOngoingEventsList?groupID=${groupID}`);
  }
  GetAllGroupAdminList(groupID, pageIndex, pageSize, nameSearched) {
    return API.get('Group/GetAllGroupAdminList', { params: { groupID, pageIndex, pageSize, nameSearched } });
  }
  AddEventConfirmationDetails(data) {
    return API.post("Group/AddEventConfirmationDetails", data);
  }
  UpdateUserNotificationIsView() {
    return API.put(
      `/Group/UpdateUserNotificationIsView&isView=${true}`
    );
  }
  DeleteCommunityGroupComments(commentID) {
    return API.delete(
      `/Group/DeleteCommunityGroupComments?commentID=${commentID}`
    );
  }
  GetEventdetailByUserIdAndEventId(eventID, userID) {
    return API.get(`/Group/GetEventdetailByUserIdAndEventId`, { params: { eventID, userID } });
  }
  UpdateUserPofile(data) {
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    return API.put(`/Group/UpdateUserPofile`, data, config);
  }
  LeaveFromGroup(groupID) {
    return API.delete(
      `/Group/LeaveFromGroup?&groupID=${groupID}`
    );
  }
  GetGroupMediaListByGroupID(groupID) {
    return API.get("/Group/GetGroupMediaListByGroupID", {
      params: { groupID },
    });
  }
  GetGroupCountByCommunityId(communityId) {
    return API.get(`/Site/GetGroupCount?communityID=${communityId}`);
  }

  GetAllGroupByCommunityIDUserID(communityID, userID, isAdmin) {
    return API.get('/Group/GetAllGroupByCommunityIDUserID', { params: { communityID, userID, isAdmin } })
  }

  GetIndividualProfileEventsDetailsByUserID(userID) {
    return API.get('/Group/GetIndividualProfileEventsDetailsByUserID', { params: { userID } })
  }

  GetIndividualProfileUpComingEventsDetailsByUserID(userID) {
    return API.get('/Group/GetIndividualProfileUpComingEventsDetailsByUserID', { params: { userID } })
  }

  GetIndividualProfileOnGoingEventsDetailsByUserID(userID) {
    return API.get('/Group/GetIndividualProfileOnGoingEventsDetailsByUserID', { params: { userID } })
  }

  GetIndividualProfileOutGoingEventsDetailsByUserID(userID) {
    return API.get('/Group/GetIndividualProfileOutGoingEventsDetailsByUserID', { params: { userID } })
  }

  GetIndividualProfilePostsDetailsByUserID(userID) {
    return API.get('/Group/GetIndividualProfilePostsDetailsByUserID', { params: { userID } })
  }

  GetGroupMediaListByUserID(userID) {
    return API.get('/Group/GetGroupMediaListByUserID', { params: { userID } })
  }

  GetAllGroupWithRoleByCommunityIDUserID(communityID, userID) {
    return API.get('/Group/GetAllGroupWithRoleByCommunityIDUserID', { params: { communityID, userID } })
  }

  GetAllGroupMembersNotInGroup(groupID, nameSearched, pageNo, pageSize) {
    return API.get('/Group/GetAllGroupMembersNotInGroup', {
      params: {
        groupID,
        nameSearched: nameSearched ? nameSearched : undefined,
        pageNo,
        pageSize
      }
    })
  }

  AddInvitation(
    // inviter,
    whoInvitedList, groupID, siteID, status) {
    return API.post("/Group/AddInvitation", {
      // inviter,
      whoInvitedList, groupID, siteID, status
    })
  }

  AddEventInvitationNewUser(eventID, url, emailIDList, message) {
    return API.post("/Group/AddEventInvitationNewUser", { eventID, url, emailIDList, message })
  }

  AddEventInvitationToExistingUser(eventID, url, userIDList) {
    return API.post("/Group/AddEventInvitationToExistingUser", { eventID, url, userIDList })
  }

  DeleteEventDetails(eventID) {
    return API.delete("/Group/DeleteEventDetails", { params: { eventID } })
  }

  UpdateEventDetails(data) {
    return API.post("/Group/UpdateEventDetails", { ...data })
  }

  GetAllGroupMembersInSystem(nameSearched, pageNo, pageSize) {
    return API.get("/Group/GetAllGroupMembersInSystem", {
      params: {
        nameSearched: nameSearched ? nameSearched : undefined,
        pageNo,
        pageSize
      }
    })
  }

  CreateGroupDiscussionDetails(data) {
    return API.post("/Group/CreateGroupDiscussionDetails", { ...data })
  }

  GetAllGroupDiscussionByGroupID(groupID, userID, pageIndex, pageSize) {
    return API.get("/Group/GetAllGroupDiscussionByGroupID", { params: { groupID, userID, pageIndex, pageSize } })
  }

  GetAllGroupDiscussionByUserID(userID, pageIndex, pageSize) {
    return API.get("/Group/GetAllGroupDiscussionByUserID", { params: { userID, pageIndex, pageSize } })
  }

  AddOrUpdateLike(
    reactorID,//user id
    reactedOnID,//discussion, comment
    reactionType,//0-> post, 1-> discussion, 2-> comment
  ) {
    return API.post("/Group/AddOrUpdateLike", { reactorID, reactedOnID, reactionType });
  }

  GetGroupDiscussionByDiscussionID(discussionID, userID) {
    return API.get("/Group/GetGroupDiscussionByDiscussionID", { params: { discussionID, userID } });
  }

  PinThePost(
    pinItemID,
    pinItemType,//0-> post, 1-> discussion, 2-> comment
    userID
  ) {
    return API.post("/Group/PinThePost", { pinItemID, pinItemType, userID });
  }

  AddOrUpdateComment(data) {
    return API.post("/Group/AddOrUpdateComment", { ...data })
  }

  UpdateDiscussionCommentReply(data) {
    return API.put("Group/UpdateDiscussionCommentReply", { ...data });
  }

  GetAllComments(
    reactionType,//1
    reactionOnID,
    userID,
    pageIndex,
    pageSize,
    commentID
  ) {
    return API.get("/Group/GetAllComments", { params: { reactionType, reactionOnID, userID, pageIndex, pageSize, commentID } })
  }

  JoinGroup(userID, groupID, siteID) {
    return API.post("/Group/JoinGroup", { userID, groupID, siteID });
  }

  AddDiscussionCommentReply(
    data //{ commentID, userID, replyMessage,}
  ) {
    return API.post("/Group/AddDiscussionCommentReply", { ...data });
  }

  AddVisitorsInteraction(
    data // {firstName, lastName, email, itemType}    
  ) {
    return API.post("/Group/AddVisitorsInteraction", { ...data });
  }

  GetAllDiscussionCommentReply(commentID, userID) {
    return API.get("/Group/GetAllDiscussionCommentReply", { params: { commentID, userID } })
  }

  DeleteComment(commentID) {
    return API.delete("/Group/DeleteComment", { params: { commentID } })
  }

  DeleteCommentReply(replyID) {
    return API.delete("/Group/DeleteCommentReply", { params: { replyID } })
  }

  GetAllLikeUserList(
    reactionOnID,//discussion, comment
    pageIndex,
    pageSize
  ) {
    return API.get("/Group/GetAllLikeUserList", { params: { reactionOnID, pageIndex, pageSize } })
  }

  DeleteGroupDiscussion(groupDiscussionID) {
    return API.delete("/Group/DeleteGroupDiscussion", { params: { groupDiscussionID } });
  }

  UpdateGroupDiscussionDetails(data) {
    return API.put("/Group/UpdateGroupDiscussionDetails", { ...data });
  }

  DeleteGroupPostDetails(postDetailsID) {
    return API.delete("/Group/DeleteGroupPostDetails", { params: { postDetailsID } });
  }

  MakeGroupAdmin(groupMemberID) {
    return API.put("/Group/MakeGroupAdmin", null, { params: { groupMemberID } })
  }

  RemoveGroupAdmin(groupMemberID) {
    return API.put("/Group/RemoveGroupAdmin", null, { params: { groupMemberID } })
  }

  getGroupMessages(groupID, pageIndex, pageSize) {
    return API.get("/Group/GetGroupMessages", { params: { groupID, pageIndex, pageSize } })
  }

  getGroupMembersListByChatTiming(groupID, nameSearched, pageNo, pageSize) {
    return API.get("/Group/GetGroupMembersListByChatTiming", { params: { groupID, nameSearched, pageNo, pageSize } })
  }

  createSubGroup(formData, config) {
    return API.post("/Group/CreateSubGroup", formData, config,);
  }

  getSubGroupsByGroupID = (groupId) => {
    return API.get("/Group/GetSubGroupsByGroupID", { params: { groupId } })
  }

  getSubGroupMessages = (groupID, pageIndex, pageSize) => {
    return API.get("/Group/GetSubGroupMessages", { params: { groupID, pageIndex, pageSize } });
  }

  deleteSubGroup = (subGroupId) => {
    return API.delete("/Group/DeleteSubGroup", { params: { subGroupId } });
  }

  /**
   * update sub group api
   * @param {object} data {subGroupID:"",userIds:[]}
   * @returns 
   */
  addOrUpdateSubGroupMember = (data) => {
    return API.post("/Group/AddOrUpdateSubGroupMember", data);
  }

  getAllSubGroupMembers = (subGroupID) => {
    return API.get("/Group/GetAllSubGroupMembers", { params: { subGroupID } })
  }

  getSubGroupsBySubGroupID = (subGroupID) => {
    return API.get("/Group/GetSubGroupsBySubGroupID", { params: { subGroupID } });
  }

  getPostDetailsByPostID = (postID) => {
    return API.get("/Group/GetPostDetailsByPostID", { params: { postID } })
  }

  sendInvitation = (data) => {
    return API.post("/Group/SendInvitation", { ...data })
  }

  updateCommentReply = (data) => {
    return API.put("/Group/UpdateCommentReply", { ...data })
  }

  getAllGroupWithCommunityName = (searchText, pageIndex, pageSize) => {
    return API.get("/Group/GetAllGroupWithCommunityName", { params: { searchText, pageIndex, pageSize } });
  }

  // Create Live Stream

  CreateLiveStream = ({ title, liveStreamUrl, startDateTime, endDateTime, groupID, coverImage, description }) => {
    return API.post("/Group/CreateLiveStream", { title, liveStreamUrl, startDateTime, endDateTime, groupID, coverImage, description });

  }

  getAllGroupLiveStreamData = (GroupID) => {
    return API.get("/Group/GetLiveStream", { params: { GroupID } });
  }

  updateLiveStreamDetails(data) {
    return API.post("/Group/EditLiveStream", { ...data })
  }

  deleteLiveStream(liveStreamId) {
    return API.delete(`/Group/DeleteLiveStreamDetails?liveStreamingID=${liveStreamId}`);
  }

  getLiveStreamSubGroupsByGroupID = (groupId) => {
    return API.get("/Group/GetSubGroupsByGroupID", { params: { groupId } })
  }

  getLiveStreamSubGroupMessages = (groupID, pageIndex, pageSize) => {
    return API.get("/Group/GetSubGroupMessages", { params: { groupID, pageIndex, pageSize } });
  }

  addOrUpdateLiveStreamSubGroupMember = (subGroupID, userIds) => {
    return API.post("/Group/AddSubGroupMember", { subGroupID, userIds: [userIds] });
  }


  RemoveLiveStreamSubGroupMember = (subGroupId, userId) => {
    return API.delete("/Group/RemoveSubGroupMember", { params: { subGroupId, userId } });
  }


  getUpcomingLiveStremsList(groupId) {
    return API.get(`Group/GetUpcomingLiveStreamList?groupID=${groupId}`);
  }

  getOngoingLiveStremsList(groupId) {
    return API.get(`Group/GetOngoingLiveStreamList?groupID=${groupId}`);
  }

  getCompletedLiveStremsList(groupId) {
    return API.get(`Group/GetOutgoingLiveStreamList?groupID=${groupId}`);
  }

  getLiveStremsMemberList(subGroupID) {
    return API.get(`Group/GetAllLivestreamMembers?subGroupID=${subGroupID}`);
  }

  // getCompletedLiveStremsList(data) {
  //   console.log('data', data);
  //   return API.get(`Group/GetUpcomingLiveStreamList?groupID=${data.groupId}userIds=${data.profileId}`);
  // }
}
const groupService = new GroupService();
export default groupService;

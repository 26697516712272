




import { lazy } from "react";

export const publicRoutes = [
    {
        path: "/solutions",
        element: lazy(() => import("../Component/Solutions"))
    },
    {
        path: "/contact-us",
        element: lazy(() => import("../Component/ContactUS"))
    },
    {
        path: "/join-group",
        element: lazy(() => import("../Component/JoinGroup"))
    },
    {
        path: "/",
        element: lazy(() => import("../Component/GetStarted"))
    },
    {
        path: "/forgotpassword",
        element: lazy(() => import("../Component/ForgotPassword"))
    },
    {
        path: "/resetpassword",
        element: lazy(() => import("../Component/ResetPassword"))
    },
    {
        path: "/signup",
        element: lazy(() => import("../pages/signup/Signup.jsx"))
    },
    {
        path: "/login",
        element: lazy(() => import("../Component/Login"))
    }
]

export const masterPrivateRoutes = [
    {
        path: "/communities",
        element: lazy(() => import("../pages/admin-community/Template-Dashboard"))
    },


    //system administrator tools
    {
        path: "/system-administrator/system-users",
        element: lazy(() => import("../pages/system-administrator/system-users/SystemUsers"))
    },
    {
        path: "/system-administrator/system-communities",
        element: lazy(() => import("../pages/system-administrator/system-communities/SystemCommunities"))
    },
    {
        path: "/system-administrator/system-groups",
        element: lazy(() => import("../pages/system-administrator/system-groups/SystemGroups"))
    },


    {
        path: "/notification",
        element: lazy(() => import("../pages/notification/Notifications"))
    },
    {
        path: "/profile/:tabName/:userID",
        element: lazy(() => import("../pages/user-profile/UserProfilePage"))
    },
    {
        path: "/profile/edit/:userID",
        element: lazy(() => import("../Component/user-profile/updateProfile/UpdateUserProfile"))
    },
]

export const userPrivateRoutes = [
    {
        path: "/congratulation",
        element: lazy(() => import("../Component/Congratulation")),
    },
    {
        path: "/payment",
        element: lazy(() => import("../Component/purchase-plan/Payment")),
    },
    {
        path: "/admin-tools",
        element: lazy(() => import("../pages/dashboard/admin-tools")),
    },
    {
        path: "/admin-group",
        element: lazy(() => import("../pages/admin-group/group-list/CommunityList")),
    },

    {
        path: "/media/group/:tabId",
        element: lazy(() => import("../pages/media/group-media/GroupContainer")),
    },


    {
        path: "/media/user/:subType",
        element: lazy(() => import("../pages/media/user-media/UserMedia")),
    },

    {
        path: "/media/drive",
        element: lazy(() => import("../pages/media/drive/MyDrive")),
    },
    {
        path: "/media/drive/:folderId",
        element: lazy(() => import("../pages/media/drive/MyDrive")),
    },



    {
        path: "/group-view/message/:groupId",
        element: lazy(() => import("../pages/admin-group/group-view/message/GroupMessageContainer"))
    },
    {
        path: "/group-view/about/:groupId",
        element: lazy(() => import("../pages/admin-group/group-view/about/About")),
    },
    {
        path: "/group-view/newsfeed/:groupId",
        element: lazy(() => import("../pages/admin-group/group-view/newsfeed/Newsfeed")),
    },
    {
        path: "/group-view/discussion/:groupId",
        element: lazy(() => import("../pages/admin-group/group-view/discussion/Discussion")),
    },
    {
        path: "/group-view/member/:groupId",
        element: lazy(() => import("../pages/admin-group/group-view/member/Members")),
    },
    {
        path: "/group-view/event/upcoming/:groupId",
        element: lazy(() => import("../pages/admin-group/group-view/event/upcoming/UpcomingEvents")),
    },
    {
        path: "/group-view/event/ongoing/:groupId",
        element: lazy(() => import("../pages/admin-group/group-view/event/ongoing/OngoingEvents")),
    },
    {
        path: "/group-view/event/completed/:groupId",
        element: lazy(() => import("../pages/admin-group/group-view/event/completed/CompletedEvents")),
    },
    {
        path: "/group-view/media/image/:groupId",
        element: lazy(() => import("../pages/admin-group/group-view/media/ImageLIst")),
    },
    {
        path: "/group-view/media/video/:groupId",
        element: lazy(() => import("../pages/admin-group/group-view/media/VideoList")),
    },
    {
        path: "/group-view/media/document/:groupId",
        element: lazy(() => import("../pages/admin-group/group-view/media/DocumentList")),
    },
    {
        path: "/group-view/media/files/:groupId/:folderId",
        element: lazy(() => import("../pages/admin-group/group-view/shared-media/SharedMedia")),
    },
    {
        path: "/group-view/media/files/:groupId",
        element: lazy(() => import("../pages/admin-group/group-view/shared-media/SharedMedia")),
    },
    // {
    //     path: "/group-view/chat/:groupId",
    //     element: lazy(() => import("../pages/admin-group/group-view/message/GroupMessageContainer")),
    // },
    // {
    //     path: "/group-view/live-stream/:groupId",
    //     element: lazy(() => import("../pages/admin-group/group-view/livestream/LiveStream"))
    // },

    {
        path: "/group-view/live-stream/upcoming/:groupId",
        element: lazy(() => import("../pages/admin-group/group-view/livestream/upcominglivestream/UpcomingLiveStreams"))
    },
    {
        path: "/group-view/live-stream/ongoing/:groupId",
        element: lazy(() => import("../pages/admin-group/group-view/livestream/ongoinglivestream/OngoingLiveStreams"))
    },
    {
        path: "/group-view/live-stream/completed/:groupId",
        element: lazy(() => import("../pages/admin-group/group-view/livestream/completedlivestream/CompletedLiveStreams"))
    },




    {
        path: "/event/:eventTypeId",
        element: lazy(() => import("../pages/event/AdminGroupEvent")),
    },
    {
        path: "/privacypolicy",
        element: lazy(() => import("../Component/PrivacyPolicy")),
    },


    //setting
    {
        path: "/account-setting/general",
        element: lazy(() => import("../pages/account-setting/general-setting/GeneralSetting")),
    },
    {
        path: "/account-setting/notification",
        element: lazy(() => import("../pages/account-setting/notification-setting/NotificationSetting")),
    },
    {
        path: "/account-setting/privacy",
        element: lazy(() => import("../pages/account-setting/privacy-setting/PrivacySetting")),
    },
    {
        path: "/account-setting/password",
        element: lazy(() => import("../pages/account-setting/password-setting/PasswordSetting")),
    },
    {
        path: "/account-setting/community-permissions",
        element: lazy(() => import("../pages/account-setting/roles-permissions/CommunityPermissions")),
    },
    {
        path: "/account-setting/view-community",
        element: lazy(() => import("../pages/account-setting/view-community/ViewCommunity")),
    },
    {
        path: "/account-setting/stripe",
        element: lazy(() => import("../pages/account-setting/stripe-account/ConnectStripeAccount")),
    },


    {
        path: "/upgrade-plan",
        element: lazy(() => import("../Component/purchase-plan/UpgradePlan")),
    },
    {
        path: "/communities",
        element: lazy(() => import("../pages/admin-community/Template-Dashboard")),
    },
    {
        path: "/create-community",
        element: lazy(() => import("../Component/CreateCommunity")),
    },
    {
        path: "/community-setting/:communityId",
        element: lazy(() => import("../pages/admin-community/community-setting/CommunitySetting")),
    },
    {
        path: "/group-setting/members/:groupId",
        element: lazy(() =>
            import("../pages/admin-group/group-setting/GroupMember")
        ),
    },
    {
        path: "/group-setting/admins/:groupId",
        element: lazy(() =>
            import("../pages/admin-group/group-setting/GroupAdmin")
        ),
    },
    {
        path: "/notification",
        element: lazy(() => import("../pages/notification/Notifications")),
    },
    {
        path: "/vedio-player",
        element: lazy(() => import("../Component/VedioPlayer")),
    },

    {
        path: "/member-view",
        element: lazy(() => import("../pages/Members/MemberView")),
    },
    {
        path: "/invite-member",
        element: lazy(() => import("../Component/Member/InviteMember")),
    },
    {
        path: "/template/edit/1/:siteID",
        element: lazy(() => import("../pages/template-edit/Template1")),
    },
    {
        path: "/template/edit/2/:siteID",
        element: lazy(() => import("../pages/template-edit/Template2")),
    },
    {
        path: "/template/edit/3/:siteID",
        element: lazy(() => import("../pages/template-edit/Template3")),
    },

    {
        path: "/profile/:tabName/:userID",
        element: lazy(() => import("../pages/user-profile/UserProfilePage")),
    },
    {
        path: "/my-profile/:userID",
        element: lazy(() => import("../pages/user-profile/UserProfilePage")),
    },
    {
        path: "/group/:groupID",
        element: lazy(() => import("../Component/Group/CreateGroup")),
    },
    {
        path: "/profile/edit/:userID",
        element: lazy(() =>
            import("../Component/user-profile/updateProfile/UpdateUserProfile")
        ),
    },
    {
        path:"/user/edit/:userId",
        element:lazy(()=> import('../Component/user-profile/Edit/user-edit'))
    },

    {
        path: "/chat/:receiverId",
        element: lazy(() => import("../pages/message/ChatPageModal"))
    },

    {
        path: "/master/support/view/:pageId",
        element: lazy(() => import("../pages/support/master/view")),
    },
    {
        path: "/master/support/form/:pageId",
        element: lazy(() => import("../pages/support/master/form")),
    },
    {
        path: "/master/support",
        element: lazy(() => import("../pages/support/master/list")),
    },
    {
        path: "/support",
        element: lazy(() => import("../pages/support/users/list")),
    },
    {
        path: "/ecommerce",
        element: lazy(() => import("../pages/e-commerce-store/Ecommerce")),
    },
    {
        path: "/admin-dashboard",
        element: lazy(() => import("../pages/analytics-dashboard-admin/AnalyticaDashboardAdmin")),
    },

    {
        path: "/admin-dashboard/members",
        element: lazy(() => import("../pages/analytics-dashboard-admin/admin-members/AdminMembers")),
    },

    {
        path: "/admin-dashboard/community",
        element: lazy(() => import("../pages/analytics-dashboard-admin/admin-communitys/AdminCommunitys")),
    },
    {
        path: "/admin-dashboard/groups",
        element: lazy(() => import("../pages/analytics-dashboard-admin/admin-groups/AdminGroups")),
    },

    {
        path: "/admin-dashboard/events",
        element: lazy(() => import("../pages/analytics-dashboard-admin/admin-events/AdminEventsList")),
    },

    {
        path: "/admin-dashboard/livestrems",
        element: lazy(() => import("../pages/analytics-dashboard-admin/admin-livestreams/AdminLiveStremsList")),
    },

    {
        path: "/dashboard-community",
        element: lazy(() => import("../pages/analytics-dashboard-communityowner/AnalyticaDashboardCommunity")),
    },
   

    //dashboard footer
    {
        path: "/privacy&policy",
        element: lazy(() => import("../pages/privacy-policy/PrivacyPolicy"))
    },
    {
        path: "/about",
        element: lazy(() => import("../pages/about/About"))
    },
    {
        path: "/terms-conditions",
        element: lazy(() => import("../pages/term-condition/TermAndCondition"))
    }
];

export const privateRoutes = [...userPrivateRoutes, ...masterPrivateRoutes];

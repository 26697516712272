import { useDispatch, useSelector } from 'react-redux';
import GroupSharedMediaHeader from './header/GroupSharedMediaEntriesHeader'
import { useEffect } from 'react';
import { SpinnerLoader } from '../../../loader/SpinnerLoader';
import { Row } from 'react-bootstrap';
import SharedMediaDashboard from './dashboard/SharedMediaEntriesDashboard';
import FilePreview from './file-preivew/FilePreview';
import { fetchFolderOfSharedMediaEntriesAsyncThunk } from '../../../../ReduxStore/slice/group-shared-media-entries/group-shared-media-entries-async-thunk';
import { setCurrentFolderInSharedMediaEntriesAction } from '../../../../ReduxStore/slice/group-shared-media-entries/group-shared-media-entries-slice';

const SharedMediaEntries = () => {

  const dispatch = useDispatch();

  const folderId = useSelector(state => state?.groupSharedMediaEntries?.currentFolderId)

  const get_file_entries_loading = useSelector(state => state?.groupSharedMediaEntries?.loading?.get_file_entries)
  const file_preview = useSelector(state => state?.groupSharedMediaEntries?.file_preview?.status)


  useEffect(() => {
    dispatch(fetchFolderOfSharedMediaEntriesAsyncThunk({ folderID: folderId ? folderId : null, loading: true }))
    dispatch(setCurrentFolderInSharedMediaEntriesAction(folderId || null));
  }, [dispatch, folderId])

  return (
    <div className='custom-card'>
      <GroupSharedMediaHeader />
      {get_file_entries_loading ? (
        <SpinnerLoader />
      ) : (
        <Row className='g-3'>
          <SharedMediaDashboard />
        </Row>
      )}
      {file_preview && (<FilePreview />)}
    </div>
  )
}

export default SharedMediaEntries
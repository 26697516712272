import { createAsyncThunk } from "@reduxjs/toolkit";
import GroupService from "../../../Services/group/GroupService";


export const getAllGroupWithCommunityNameAsyncThunk = createAsyncThunk("systemGroup/getAllGroupWithCommunityName", async ({ searchText, pageIndex, pageSize }) => {
    const response = await GroupService.getAllGroupWithCommunityName(searchText, pageIndex, pageSize)
    return response?.data
})

export const deleteSystemGroupActionThunk = createAsyncThunk("systemGroup/deleteSystemGroup", async (groupId) => {
    const response = await GroupService.deleteGroup(groupId);
    if (response?.data?.statusCode !== 200) {
        throw new Error(response?.data?.message);
    } else {
        return response?.data?.data;
    }
})
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component"

const InfiniteScrollComp = ({
    dispatchAction,
    filter,
    dataLength,
    endMessage,
    refreshFunction,
    pagination,
    setPagination,
    ItemsComponent,
    dataList,
    setDataList,
    scrollableTarget,
    height,
    inverse,
    extraData,
    innerStyle,
    loader,
    loading,
    pullDownToRefresh,
    pullDownToRefreshThreshold,
    pullDownToRefreshContent,
    releaseToRefreshContent,
}) => {
    //fetch more data by infinite scrolling
    const fetchMoreData = () => {
        setPagination(prev => ({ ...prev, currentPage: prev?.currentPage + 1 }))
    }
    useEffect(() => {
        dispatchAction && dispatchAction();
    }, [])
    return (
        <InfiniteScroll
            dataLength={dataLength}
            next={fetchMoreData}
            hasMore={Number(dataLength) < Number(pagination?.totalRecords)}
            loader={loader ||
                <div
                    className="spinner-container"
                    style={{
                        display: "grid",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100px"
                    }}
                >
                    <div className="loading-spinner"></div>
                </div>
            }
            endMessage={
                <p className="text-center m-0">
                    <b>{endMessage}</b>
                </p>
            }
            refreshFunction={refreshFunction}
            inverse={inverse}
            scrollableTarget={scrollableTarget}
            height={height}
            style={innerStyle}
            pullDownToRefresh={pullDownToRefresh}
            pullDownToRefreshThreshold={pullDownToRefreshThreshold}
            pullDownToRefreshContent={pullDownToRefreshContent}
            releaseToRefreshContent={releaseToRefreshContent}
        >
            {(dataList?.length > 0 || loading) ? (
                <ItemsComponent
                    dataList={dataList}
                    setDataList={setDataList}
                    refreshFunction={refreshFunction}
                    extraData={extraData}
                />
            ) : (
                <p className='text-center my-4 my-md-5 mb-0'>No record found</p>
            )}
        </InfiniteScroll>
    )
}

export default InfiniteScrollComp
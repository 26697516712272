import EmojiPicker from 'emoji-picker-react';
import { Button, ButtonToolbar, OverlayTrigger, Popover } from 'react-bootstrap';
import { ReactComponent as EmojiIcon } from '../../../../assets/Images/emoji-picker.svg';

const EmojiPickerComp = ({ setEmoji, disabled }) => {


    const popover = (
        <Popover id="popover-emoji-picker">
            <EmojiPicker
                onEmojiClick={(event, emojiObject) => setEmoji(emojiObject?.emoji)}
                disableAutoFocus={false}
                disableSkinTonePicker={true}
                disableSearchBar={true}
                native={true}
                pickerStyle={{ width: '100%', maxWidth: '350px' }}
                groupNames={{ smileys_people: 'People' }}
                groupVisibility={{
                    symbols: false,
                    flags: false,
                    recently_used: false,
                    custom: false,
                }}
                emojiTooltip={true}
                include={['recently_used', 'smileys_people']}
                exclude={['flags']}
            />
        </Popover>
    );

    return (
        <>
            <ButtonToolbar>
                <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="auto"
                    overlay={popover}
                >
                    <Button disabled={disabled}>
                        <EmojiIcon />
                    </Button>

                </OverlayTrigger>
            </ButtonToolbar>
        </>

    )
}

export default EmojiPickerComp;
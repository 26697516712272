import axios from "axios";
import { loginSuccess } from "./ReduxStore/auth/auth.action";
import { logoutHandler } from "./utils/helpers/logoutHandler";

export const API = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`
});

export const apiMiddleware = (storeApi) => (next) => (action) => {

  const { dispatch } = storeApi;

  API.interceptors.request.use((config) => {
    const authToken = storeApi.getState().auth?.loginData?.Token;
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
    (error) => {
      return Promise.reject(error);
    }
  );

  API.interceptors.response.use((response) => response, async (error) => {

    if (error?.response?.status === 403) {
      error.response.data = {
        ...error.response.data,
        message: "403"
      };
    }
    error.response.config.refreshAttempts = error.response.config.refreshAttempts || 0;
    if (error.response.status === 401 && error.response.config.url !== "/User/Login") {
      if (error.response.config.refreshAttempts < 3) {
        error.response.config.refreshAttempts++;
        const refreshToken = localStorage.getItem("lRefreshToken");
        const accessToken = localStorage.getItem("lToken");
        try {
          const response = await API.post('/User/Refresh', { refreshToken, accessToken });
          if (response?.data?.status === "ERROR") {
            logoutHandler();
          } else {
            dispatch(
              loginSuccess({
                Token: response?.data?.data?.accessToken,
                RefreshToken: response?.data?.data?.refreshToken,
              })
            );
            localStorage.setItem("lToken", response?.data?.data?.accessToken);
            localStorage.setItem("lRefreshToken", response?.data?.data?.refreshToken);
            error.response.config.headers["Authorization"] = `Bearer ${response?.data?.data?.accessToken}`;
            return API(error?.response?.config);
          }
        } catch (error) {
          logoutHandler();
          return Promise.reject(error);
        }
      } else {
        logoutHandler();
      }
    }

    return Promise.reject(error);
  }
  );

  // call the next action
  next(action);
};
import { useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import ImageCropper from './ImageCropper';
import ReactEasyCrop from './ReactEasyCrop';

const CombineCropperModal = ({
    show,
    handleClose,
    src,
    aspect,
    canChangeAspect,
    spectString,
    cropRation,
}) => {

    const childRef = useRef()

    const [loading, setLoading] = useState(null);
    const [tab, setTab] = useState(1);

    const renderCropper = () => {
        switch (tab) {
            case 1:
                return <ReactEasyCrop
                    show={show}
                    handleClose={handleClose}
                    src={src}
                    aspect={aspect}
                    canChangeAspect={canChangeAspect}
                    spectString={spectString}
                    setLoading={setLoading}
                    loading={loading}
                    ref={childRef}
                />
            case 2:
                return <ImageCropper
                    show={show}
                    handleClose={handleClose}
                    src={src}
                    cropRation={cropRation}
                    setLoading={setLoading}
                    loading={loading}
                    ref={childRef}
                />

            default:
                return <ReactEasyCrop
                    show={show}
                    handleClose={handleClose}
                    src={src}
                    aspect={aspect}
                    canChangeAspect={canChangeAspect}
                    spectString={spectString}
                    setLoading={setLoading}
                    loading={loading}
                    ref={childRef}
                />
        }
    };

    return (
        <Modal
            show={show}
            onHide={() => loading ? () => null : handleClose()}
            backdrop="static"
            keyboard={false}
            scrollable
            centered
        >
            <Modal.Header closeButton className='image-crop'>
                <Modal.Title className="h5 font-size-18">Image Crop</Modal.Title>
                <Button
                    disabled={loading}
                    className='btn-primary-theme btn-outline z-99 ms-auto'
                    onClick={() => setTab(prev => prev === 1 ? 2 : 1)}
                >
                    {tab === 1 ? "Default crop" : "Crop With aspect ratio"}
                </Button>
            </Modal.Header>
            <Modal.Body>
                {renderCropper()}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
                {spectString && !canChangeAspect && (
                    <small>
                        Required image aspect ratio is{" "}
                        <span className="text-danger">{spectString}</span>
                    </small>
                )}
                <Button
                    className="btn-primary-theme font-size-14 ms-auto"
                    disabled={loading}
                    onClick={() => childRef?.current?.handleImageCrop()}
                >
                    Crop
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CombineCropperModal
import { createAsyncThunk } from "@reduxjs/toolkit";
import GroupService from "../../../Services/group/GroupService";


export const getAllGroupByCommunityIDUserIDActionThunk = createAsyncThunk("communty/getCurrentUserGroup", async (communityID, userId) => {
    const response = await GroupService.GetAllGroupByCommunityIDUserID(communityID)
    if (Array.isArray(response?.data)) {
        return response?.data;
    } else {
        return []
    }
});
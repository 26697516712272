import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { connection, startConnection } from "../../Services/signalR/signalrService";
import { isOwner } from "../../utils/helpers/isOwner";
import { useDispatch, useSelector } from "react-redux";
import { getSingleNotificationFromSignalrAction } from "../../ReduxStore/slice/notification/notificationSlice";

const NotificationToast = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    //group message
    const currentChatBoardId = useSelector(state => state?.groupMessage?.currentChatBoard?.receiverOrSubGroupId);

    //notification toast
    const notificationToastSender = (notification) => {
        toast.clearWaitingQueue();
        switch (notification?.action) {
            case 1:
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")} >
                        <span>{`${notification?.userDetails?.firstName} created newsfeed`}</span>
                    </div>
                );
                break;
            case 2:
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")} >
                        <span>{`${notification?.userDetails?.firstName} liked your newsfeed`}</span>
                    </div>
                );
                break;
            case 3:
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")} >
                        <span>{`${notification?.userDetails?.firstName} comment on your newsfeed`}</span>
                    </div>
                );
                break;
            case 4:
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")} >
                        <span>{`${notification?.userDetails?.firstName} liked your comment`}</span>
                    </div>
                );
                break;
            case 5:
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")} >
                        <span>{`${notification?.userDetails?.firstName} replied to your comment`}</span>
                    </div>
                );
                break;
            case 7:
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")} >
                        <span>{`${notification?.userDetails?.firstName} created discussion`}</span>
                    </div>
                );
                break;
            case 8:
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")} >
                        <span>{`${notification?.userDetails?.firstName} liked your discussion`}</span>
                    </div>
                );
                break;
            case 9:
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")} >
                        <span>{`${notification?.userDetails?.firstName} comment on your discussion`}</span>
                    </div>
                );
                break;
            case 10:
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")} >
                        <span>{`${notification?.userDetails?.firstName} liked your comment`}</span>
                    </div>
                );
                break;
            case 11:
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")} >
                        <span>{`${notification?.userDetails?.firstName} replied to your comment`}</span>
                    </div>
                );
                break;
            case 12:
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")} >
                        <span >{`${notification?.userDetails?.firstName} Liked your reply in comment`}</span>
                    </div>
                )
                break;
            case 13:
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")} >
                        <span >Your plan is upgraded</span>
                    </div>
                )
                break;
            case 15:
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")} >
                        <span >{`${notification?.userDetails?.firstName} left from group`}</span>
                    </div>
                )
                break;
            case 16:
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")} >
                        <span >{`${notification?.userDetails?.firstName} joined group`}</span>
                    </div>
                )
                break;
            case 17:
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")} >
                        <span >{`Group join request of ${notification?.data?.firstName} is accepted`}</span>
                    </div>
                )
                break;
            case 18:
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")} >
                        <span >{`Group join request of ${notification?.data?.firstName} is rejected`}</span>
                    </div>
                )
                break;
            case 19:
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")} >
                        <span >{`Group join request of ${notification?.data?.firstName} is received`}</span>
                    </div>
                )
                break;
            case 20:
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")} >
                        <span >{`${isOwner(notification?.data?.userId) ? "You're" : notification?.data?.firstName + " is"}`} no logner part of group</span>
                    </div>
                )
                break;
            case 21: //created new admin
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")}>
                        <span >{`${isOwner(notification?.data?.userId) ? "You're" : notification?.data?.firstName + " is"}`} now a group admin</span>
                    </div>
                )
                break;
            case 22: //dismiss group admin
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")}>
                        <span >{`${isOwner(notification?.data?.userId) ? "You're" : notification?.data?.firstName + " is"}`} no longer a group admin</span>
                    </div>
                )
                break;
            case 23: //created event
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")}>
                        <span >{`${notification?.userDetails?.firstName} created event`}</span>
                    </div>
                )
                break;
            case 24: //event invitation received
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")}>
                        <span >{`${notification?.userDetails?.firstName} invited you in event`}</span>
                    </div>
                )
                break;
            case 27: //Community site is updated
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")}>
                        <span >{`${notification?.userDetails?.firstName} updated community site`}</span>
                    </div>
                )
                break;
            case 28: //event invitation received
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")}>
                        <span >{`${notification?.userDetails?.firstName} published community`}</span>
                    </div>
                )
                break;
            case 30: //created community admin
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")}>
                        <span >{`${isOwner(notification?.data?.userId) ? "You're" : notification?.data?.firstName + "is"} now a community admin`}</span>
                    </div>
                )
                break;
            case 31: //created community admin
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")}>
                        <span >{`${isOwner(notification?.data?.userId) ? "You're" : notification?.data?.firstName + "is"} no longer a community admin`}</span>
                    </div>
                )
                break;
            case 32: //removed community admin
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")}>
                        <span >{`${isOwner(notification?.data?.userId) ? "You're" : notification?.data?.firstName + "is"} no longer part of community`}</span>
                    </div>
                )
                break;
            case 33: //created group
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")}>
                        <span >{notification?.userDetails?.firstName} created {notification?.data?.groupName} group</span>
                    </div>
                )
                break;
            case 34: //created group
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")}>
                        <span >{notification?.userDetails?.firstName} updated {notification?.data?.groupName} group</span>
                    </div>
                )
                break;
            case 35: //delete group
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")}>
                        <span >{notification?.userDetails?.firstName} deleted {notification?.data?.groupName} group</span>
                    </div>
                )
                break;
            case 36: //received message in one to one
                (notification?.userDetails?.userId !== location?.state?.receiverId) &&
                    toast(
                        <div className="d-flex gap-1" onClick={() => navigate("/notification")}>
                            <span >{notification?.userDetails?.firstName} messaged you</span>
                        </div>
                    )
                break;
            case 37: //event invitation received
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")}>
                        <span >{`${notification?.userDetails?.firstName} sent message`}</span>
                    </div>
                )
                break;
            case 38: //invited to join group
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")}>
                        <span >{`${notification?.userDetails?.firstName} invited to join group`}</span>
                    </div>
                )
                break;
            case 39: //sub group updated
                toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")}>
                        <span >{`${notification?.userDetails?.firstName} updated chat group`}</span>
                    </div>
                );
                break;

            case 40: //sub group message received
                currentChatBoardId !== notification?.data?.groupID && toast(
                    <div className="d-flex gap-1" onClick={() => navigate("/notification")}>
                        <span >{`${notification?.userDetails?.firstName} send message in message group`}</span>
                    </div>
                );
                break;
            default:
                // warningToast("Ongoing cycle. " + notification?.action)
                console.log("Toast is not created. " + notification?.action);
                break;
        }
    };

    useEffect(() => {
        startConnection().then(() => {
            connection.on("getNotifications", (notification) => {
                console.log("Received notification signalR:", notification);
                if (location?.pathname === "/notification") {
                    dispatch(getSingleNotificationFromSignalrAction({ notification }));
                } else {
                    notificationToastSender(notification);
                }
            })
        })
        // Clean up the event subscription when the component unmounts
        return () => {
            connection.off("getNotifications");
        };
    });
}

export default NotificationToast
export default function swDev() {
    let swUrl = "./sw.js"

    //used to check whather the service worker is present in the browser or not becoz many old browser don't support it

    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.register(swUrl).then((response) => {
        }).catch(function (e) {
            // console.log(e,"error")
        }
        )
    }

}

import logo1 from "../../assets/Images/Combo-logo.png";

const ComboLogoLoader = () => {
    return (
        <div className="logo-loader">
            <div className="logo-loader-pulse pulse" id="Idloader">
                <img src={logo1} width={140} alt="logo" />
            </div>
        </div>
    )
}

export default ComboLogoLoader
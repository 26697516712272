import { useFormik } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import * as Yup from "yup";
import { ReactComponent as CloseIconRed } from '../../../../../assets/Images/close-icon-red.svg';
import { ReactComponent as VideoIcon } from '../../../../../assets/Images/video-icon.svg';
import { ReactComponent as ImageIcon } from '../../../../../assets/Images/image-icon.svg';
import { ReactComponent as DocumentIcon } from '../../../../../assets/Images/document-icon.svg';
import { useContext, useEffect } from 'react';
import { NewsfeedModalContext } from '../NewsfeedModalContext';
import EmojiPickerComp from '../../../discussion/create-discussion/EmojiPicker';

const CreateReplyCommentForm = ({ commentID }) => {

    const { loading, createReplyCommentApiCall, closeCommentReplyFormHandler } = useContext(NewsfeedModalContext);

    const profilePicture = useSelector((state) => state.profileData?.profileData?.profilePicture);
    const profileId = useSelector((state) => state.profileData?.profileData?.profileId);

    //check is submit loading is true
    const isSubmitLoading = (commentID) => {
        return loading?.createCommentReplyBtn?.commentID === commentID && loading?.createCommentReplyBtn?.status;
    }


    const handleUploadFile = (event, type) => {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader?.readAsDataURL(file);
        formik?.setValues(() => ({ ...formik?.values, documents: { MediaFileType: type, MediaFile: file } }));
    }

    const formSchema = Yup.object().shape({

        Comments: Yup.string().required("Please enter a comment message"),
        documents: Yup.object(),
        reactionType: Yup.number(),
        reactedOnID: Yup.string(),
        reactorID: Yup.string(),
    });

    const formik = useFormik({
        validationSchema: formSchema,
        initialValues: {
            Comments: "",
            documents: {},
            reactorID: profileId,
        },

        onSubmit: (values, { resetForm }) => {
            const data = {
                ReplyMessage: values?.Comments,
                MediaFileType: values?.documents?.MediaFileType,
                MediaFile: values?.documents?.MediaFile,
                CommentID: commentID,
            }
            createReplyCommentApiCall(data, commentID, resetForm);
        },
    });

    useEffect(() => {
        formik?.setSubmitting((
            loading?.createCommentReplyBtn?.status
            && loading?.createCommentReplyBtn?.commentID === commentID
        ) ? true : false);
    }, [loading, commentID])


    return (
        <>
            <form onSubmit={(e) => formik.handleSubmit(e)}>
                <div className="media-posts-create-cmnt modal-emoji align-items-start">
                    <img
                        className="commentprofile commentprofile2"
                        src={profilePicture || require("../../../../../assets/Images/guest-user.jpg")}
                        alt="img"
                    />
                    <div className="d-flex flex-column flex-fill">
                        <textarea
                            className="form-control react-input-emoji--container"
                            name="Comments"
                            placeholder='Write reply...'
                            value={formik?.values?.Comments}
                            onChange={(event) => {
                                const text = event?.target?.value?.trimStart();
                                formik?.setValues(prev => ({ ...prev, Comments: text }));
                            }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter' && !event.shiftKey) {
                                    event.preventDefault();
                                    !isSubmitLoading(commentID) && formik.handleSubmit(event);
                                }
                            }}
                            disabled={formik?.isSubmitting}
                        />
                        <small className="form-text text-danger">{formik?.touched?.Comments && formik?.errors?.Comments ? formik?.errors?.Comments : ""}</small>
                    </div>
                    <EmojiPickerComp
                        setEmoji={(emoji) => formik?.setValues(val => ({ ...val, Comments: val?.Comments + emoji }))}
                        disabled={formik?.isSubmitting}
                    />
                </div>
                <div className="create-post-outer-box">
                    <div className="create-post-inner-box py-0">
                        {/* <div className="create-post-slct-image">
                            <div className="selct-img"></div>
                        </div>
                        <div className="row form-row">
                            {formik?.values?.documents?.MediaFileType === "image" &&
                                <div className="col-6 col-md-3">
                                    <div className="thumbnail-preview thumbnail-image-post-img">
                                        <img src={URL.createObjectURL(formik?.values?.documents?.MediaFile)} alt="" />
                                        <Button
                                            variant="link"
                                            className="close-icon"
                                            onClick={() => {
                                                formik?.setValues(() => ({ ...formik?.values, documents: {} }));
                                            }}
                                            disabled={formik?.isSubmitting}
                                        >
                                            <CloseIconRed />
                                        </Button>
                                    </div>
                                </div>
                            }
                            {formik?.values?.documents?.MediaFileType === "video" &&
                                <div className="col-6 col-md-3">
                                    <div className="thumbnail-preview thumbnail-video-post-img">
                                        <div className="embed-responsive embed-responsive-4by3">
                                            <iframe
                                                className="embed-responsive-item"
                                                title="#"
                                                src={URL.createObjectURL(formik?.values?.documents?.MediaFile)}
                                            >
                                            </iframe>
                                        </div>
                                        <Button
                                            variant="link"
                                            className="close-icon"
                                            onClick={() => {
                                                formik?.setValues(() => ({ ...formik?.values, documents: {} }));
                                            }}
                                            disabled={formik?.isSubmitting}
                                        >
                                            <CloseIconRed />
                                        </Button>
                                    </div>
                                </div>}
                            {formik?.values?.documents?.MediaFileType === "document" &&
                                <div className="col-6 col-md-3">
                                    <div className="thumbnail-preview thumbnail-image-post-img document docfile d-flex align-items-center justify-content-center">
                                        <Button
                                            variant="link"
                                            className="close-icon ms-2"
                                            onClick={() => {
                                                formik?.setValues(() => ({ ...formik?.values, documents: {} }));
                                            }}
                                            disabled={formik?.isSubmitting}
                                        >
                                            <CloseIconRed />
                                        </Button>
                                        <i
                                            className="fa fa-file-text doc-icon"
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                </div>
                            }
                        </div> */}
                        <div className="d-flex flex-wrap gap-1 create-post py-2 px-0 mt-0">
                            {/* <div className="create-post-icons-upld flex-fill">
                                <Row sm={12} md={3} lg={3} className="d-flex flex-nowrap align-items-center text-center form-row">
                                    <Col>
                                        {loading?.imageUpload ? (
                                            <label>
                                                <ImageIcon className="icon me-2" fill="#27A841" />
                                                <span>Upload Image</span>
                                                <i className="fa fa-spinner fa-spin ms-2"></i>
                                            </label>
                                        ) : (<label>
                                            <input
                                                type="file"
                                                className="d-none"
                                                accept="image/*"
                                                onChange={(event) => {
                                                    handleUploadFile(event, "image")
                                                }}
                                                disabled={formik?.isSubmitting}
                                            />
                                            <ImageIcon className="icon me-2" fill="#27A841" />
                                            <span>Upload Image</span>
                                        </label>)}
                                    </Col>
                                    <Col>
                                        {loading?.documentUpload ? (
                                            <label>
                                                <DocumentIcon className="icon me-2" fill="#1183FD" />
                                                <span>Upload Document</span>
                                                <i className="fa fa-spinner fa-spin ms-2"></i>
                                            </label>
                                        ) : (<label className="text-nowrap">
                                            <input
                                                type="file"
                                                className="d-none"
                                                accept=".pdf"
                                                onChange={(event) => {
                                                    handleUploadFile(event, "document")
                                                }}
                                                disabled={formik?.isSubmitting}
                                            />
                                            <DocumentIcon className="icon me-2" fill="#1183FD" />
                                            <span>Upload Document</span>
                                        </label>)}
                                    </Col>
                                    <Col>
                                        {loading?.videoUpload ? (
                                            <label>
                                                <VideoIcon className="icon me-2" fill="#FF0038" />
                                                <span>Upload Video</span>
                                                <i className="fa fa-spinner fa-spin ms-2"></i>
                                            </label>
                                        ) : (<label>
                                            <input
                                                type="file"
                                                className="d-none"
                                                accept="video/*"
                                                onChange={(event) => {
                                                    handleUploadFile(event, "video")
                                                }}
                                                disabled={formik?.isSubmitting}
                                            />
                                            <VideoIcon className="icon me-2" fill="#FF0038" />
                                            <span>Upload Video</span>
                                        </label>)}
                                    </Col>
                                </Row>
                            </div> */}
                            <div className="d-flex align-items-center ms-auto">
                                <Button
                                    className="btn btn-primary-theme btn-sm"
                                    disabled={formik?.isSubmitting}
                                    type="submit"
                                >
                                    Reply
                                    {formik?.isSubmitting && <i className="fa fa-spinner fa-spin ms-2"></i>}
                                </Button>
                                <Button
                                    className="ms-2 btn btn-primary-theme btn-outline btn-sm"
                                    onClick={() => closeCommentReplyFormHandler(commentID)}
                                    disabled={formik?.isSubmitting}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default CreateReplyCommentForm
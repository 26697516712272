import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as CloseIcon } from "../../../../../assets/Images/close-icon.svg";
import { setFilePreviewInSharedMediaAction } from '../../../../../ReduxStore/slice/group-shared-media/group-shared-media-slice';
import { truncateText } from '../../../../../utils/helpers/truncateHtml';
import { ConditionalWrapper } from '../../../../../Component/conditional-Wrapper/ConditionalWrapper';

const FilePreview = () => {

    const dispatch = useDispatch();

    const file_preview = useSelector(state => state?.groupSharedMediaEntries?.file_preview)

    const handleClose = () => {
        dispatch(setFilePreviewInSharedMediaAction({ state: false, file: null }))
    }

    const isPreviewAvailable = (type) => {

        if (type === "pdf" || type === "audio" || type === "video") {
            return true
        } else {
            return false
        }
    }
    return (
        <Modal
            className="common-modal rounded-0"
            show={file_preview?.status}
            scrollable
            fullscreen
        >
            <Modal.Header className='justify-content-start'>
                <ConditionalWrapper
                    condition={file_preview?.file?.Name?.length > 40}
                    wrapper={(children) => (
                        <OverlayTrigger
                            placement="auto"
                            overlay={
                                <Tooltip>
                                    {file_preview?.file?.Name}
                                </Tooltip>
                            }
                        >
                            {children}
                        </OverlayTrigger>
                    )}
                >
                    <Modal.Title className="text-black-theme">
                        {truncateText(file_preview?.file?.Name, 40)}
                    </Modal.Title>
                </ConditionalWrapper>
                <Button variant="link" onClick={() => handleClose()}>
                    <CloseIcon fill="#212121" />
                </Button>
            </Modal.Header>
            <Modal.Body>
                {file_preview?.file?.FileType === "image" ? (
                    <img
                        src={file_preview?.file?.SRC}
                        alt="Preview"
                    />
                ) : isPreviewAvailable(file_preview?.file?.FileType) ? (
                    <iframe
                        src={file_preview?.file?.SRC}
                        title="File Viewer"
                        width="100%"
                        height="100%"
                    />
                ) : (
                    <div className='text-center mt-5'>File preivew not available</div>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default FilePreview
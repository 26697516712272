import { Form } from "react-bootstrap"
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { getVisitorDataActionThunk } from "../../ReduxStore/auth/auth.actions.async";
import { useNavigate } from "react-router";

const GuestUser = ({ formRef }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClose = () => {
        navigate(-1);
    }

    const formSchema = Yup.object().shape({
        firstName: Yup.string().max(25, ({ max }) => `First name cannot exceed ${max} characters`).min(3, ({ min }) => `First name must be at least ${min} characters`).required("First name is required.").matches(/^[aA-zZ\s]+$/, "Please enter a valid first name"),
        lastName: Yup.string().max(25, ({ max }) => `Last name cannot exceed ${max} characters`).min(3, ({ min }) => `Last name must be at least ${min} characters`).required("Last name is required.").matches(/^[aA-zZ\s]+$/, "Please enter a valid last name"),
        email: Yup.string().email().required("Please enter email"),
        itemType: Yup.number()
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: formSchema,
        initialValues: {
            email: "",
            firstName: "",
            lastName: "",
            itemType: 5
        },
        onSubmit: (values) => {
            dispatch(getVisitorDataActionThunk(values, handleClose))
        }
    });

    return (
        <>
            <Form
                ref={formRef}
                onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                }}
            >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="First Name"
                        name="firstName"
                        value={formik?.values?.firstName}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                    />
                    <small className="form-text text-danger">
                        {formik?.touched?.firstName && formik?.errors?.firstName && formik?.errors?.firstName}
                    </small>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Last Name"
                        name="lastName"
                        value={formik?.values?.lastName}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                    />
                    <small className="form-text text-danger">
                        {formik?.touched?.lastName && formik?.errors?.lastName && formik?.errors?.lastName}
                    </small>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={formik?.values?.email}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                    />
                    <small className="form-text text-danger">
                        {formik?.touched?.email && formik?.errors?.email ? formik?.errors?.email : ""}
                    </small>
                </Form.Group>
            </Form>
        </>
    )
}

export default GuestUser
import { createAsyncThunk } from "@reduxjs/toolkit";
import CommunityService from "../../../Services/community/CommunityService";

/**
 * get community list
 */
export const getCurrentUserCommunityByActionThunk = createAsyncThunk("communty/getCurrentUserCommunity", async (filter) => {
    const response = await CommunityService.getUserCommunityByUserID(
        undefined,
        filter?.published,
        filter?.categoryOne,
        filter?.categoryTwo,
        filter?.search,
    )
    if (Array.isArray(response?.data)) {
        return response?.data
    } else {
        return [];
    };
});

/**
 * update community site published status
 */
export const updateSitePublishStatusActionThunk = createAsyncThunk("community/updateSitePublishStatus", async ({ communityId, publishStatus }) => {
    const response = await CommunityService.updateSitePublishStatus(communityId, publishStatus);
    if (response?.data?.statusCode !== 200) {
        throw new Error(response?.data?.message);
    } else {
        // //send email of publish template
        // const sendEmail = (data) => {
        //     const emailDetails = {
        //         To: data?.ownerEmailId,
        //         Type: "Published",
        //         Url: `${process.env.REACT_APP_BASE_URL}${data?.communityDomain}`
        //     }
        //     UserServices.sendEmail(emailDetails)
        //         .then(res => {
        //         })
        //         .catch(err => {
        //             errorToast("Something went wrong");
        //         });
        return response?.data?.data;
    }
})
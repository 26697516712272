import { BlobServiceClient } from "@azure/storage-blob";
import {
  getExtensionOfFileName,
  getFileNameFromURL,
  getFileNameReplacedSplaceWithUnderScore,
  getFileNameWithoutExtension,
} from "./getFileNameFromURL";
import { errorToast } from "../../Component/toast/toast";

const containerName = process.env.REACT_APP_ENVIRONMENT;

// const sasToken = "sv=2021-06-08&ss=b&srt=sco&sp=rwdlactfx&se=2024-01-10T19:51:32Z&st=2023-01-10T11:51:32Z&spr=https&sig=85roKcvlrUKx9efjr1eFgQzhXbJuz9zrxjIHl311x%2BI%3D";
// const sasToken =
//   "sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-12-01T13:29:39Z&st=2024-01-18T05:29:39Z&spr=https&sig=aLgRohUhpb2kNaDkpEpiX9vFoMcncuGHn1twwQSYPuM%3D";

const sasToken =
  "sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2026-01-01T13:29:39Z&st=2024-01-01T05:29:39Z&spr=https&sig=8kYchTPqkbbAG7f49P%2Fqmn7rw87rlYf2%2FY20iBCDLWg%3D";

const storageAccountName = "donaidestage";

// Feature flag - disable storage feature to app if not configured
export const isStorageConfigured = () => {
  return !storageAccountName || !sasToken ? false : true;
};

// const createBlobInContainer = async (
//   containerClient,
//   file,
//   progressCallback
// ) => {
//   const originalFileName = getFileNameReplacedSplaceWithUnderScore(file?.name);
//   let newFileName = originalFileName;
//   let fileNumber = 1;
//   let blobClient;

//   while (true) {
//     blobClient = containerClient.getBlockBlobClient(newFileName);
//     const blobExists = await blobClient.exists();
//     console.log(blobExists);
//     if (!blobExists) {
//       break;
//     }

//     newFileName = `${getFileNameWithoutExtension(
//       originalFileName
//     )}(${fileNumber})${getExtensionOfFileName(originalFileName)}`;
//     fileNumber++;
//   }

//   const blockBlobClient = blobClient.getBlockBlobClient();

//   await blockBlobClient.uploadBrowserData(file, {
//     blockSize: 4 * 1024 * 1024,
//     concurrency: 20,
//     onProgress: progressCallback
//       ? (ev) =>
//           progressCallback(
//             getFileNameWithoutExtension(originalFileName),
//             Math.round((ev?.loadedBytes / file.size) * 100)
//           )
//       : undefined,
//     blobHTTPHeaders: {
//       blobContentType: file.type, // Set the content type of the blob
//     },
//   });
//   return blockBlobClient?.url;
// };

// const createBlobInContainer = async (
//   containerClient,
//   file,
//   progressCallback
// ) => {
//   const originalFileName = getFileNameReplacedSplaceWithUnderScore(file?.name);
//   let blobClient = containerClient.getBlockBlobClient(originalFileName);

//   try {
//     // Check if the blob already exists
//     const blobExists = await blobClient.exists();

//     // If the blob exists, delete it
//     if (blobExists) {
//       await blobClient.delete();
//     }

//     // Upload the new blob
//     const blockBlobClient =
//       containerClient.getBlockBlobClient(originalFileName);
//     await blockBlobClient.uploadBrowserData(file, {
//       blockSize: 4 * 1024 * 1024,
//       concurrency: 20,
//       onProgress: progressCallback
//         ? (ev) =>
//             progressCallback(
//               getFileNameWithoutExtension(originalFileName),
//               Math.round((ev?.loadedBytes / file.size) * 100)
//             )
//         : undefined,
//       blobHTTPHeaders: {
//         blobContentType: file.type, // Set the content type of the blob
//       },
//     });

//     return blockBlobClient.url;
//   } catch (error) {
//     console.error("Error uploading blob:", error);
//     return null; // Return null to indicate an error
//   }
// };

const createBlobInContainer = async (
  containerClient,
  file,
  progressCallback
) => {
  const originalFileName = getFileNameReplacedSplaceWithUnderScore(file?.name);

  // Generate a unique folder name based on current timestamp
  const folderName = new Date().toISOString().replace(/[^0-9]/g, "");

  // Combine folder name and original file name to create unique blob path
  const blobPath = `${folderName}/${originalFileName}`;
  const blockBlobClient = containerClient.getBlockBlobClient(blobPath);

  try {
    await blockBlobClient.uploadBrowserData(file, {
      blockSize: 4 * 1024 * 1024,
      concurrency: 20,
      onProgress: progressCallback
        ? (ev) =>
            progressCallback(
              getFileNameWithoutExtension(originalFileName),
              Math.round((ev?.loadedBytes / file.size) * 100)
            )
        : undefined,
      blobHTTPHeaders: {
        blobContentType: file.type, // Set the content type of the blob
      },
    });

    return blockBlobClient.url;
  } catch (error) {
    console.error("Error uploading blob:", error);
    return null; // Return null to indicate an error
  }
};

/**
 * upload file to the azure blob storage
 * @param {*} file
 * @param {*} progressCallback
 * @returns
 */
const uploadFileToBlob = async (file, progressCallback) => {
  if (!file) return "";

  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );
  const containerClient = blobService.getContainerClient(containerName);
  await containerClient.createIfNotExists({ access: "container" });

  // upload file
  return await createBlobInContainer(containerClient, file, progressCallback);

  // // // get list of blobs in container
  // return getBlobsInContainer(containerClient);
};

export default uploadFileToBlob;

/**
 * Deletes an array of blobs from Azure Blob Storage.
 *
 * @param {string[]} blobUrls - An array of blob URLs to be deleted.
 * @returns {boolean[]} - An array of booleans indicating the success or failure of each deletion.
 */
export const deleteBlobs = async (blobUrls) => {
  const blobServiceClient = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );

  const results = [];

  for (const blobUrl of blobUrls) {
    // Parse the blob URL to get the container name and blob name
    const urlParts = blobUrl.split("/");
    const containerName = urlParts[3];
    const blobName = getFileNameFromURL(blobUrl);

    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    // Check if the blob exists before attempting to delete it
    const blobExists = await blockBlobClient.exists();

    if (blobExists) {
      // Delete the blob
      try {
        await blockBlobClient.delete();
        results.push(true); // Success
      } catch (error) {
        console.error("Error deleting the blob:", error);
        results.push(false); // Failed to delete the blob
      }
    } else {
      console.error("Blob does not exist:", blobUrl);
      results.push(false); // Blob does not exist
    }
  }
  return results;
};

export const copyBlobInAzureStorage = async (blobUrl) => {
  const blobServiceClient = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );
  const sourceBlobName = getFileNameFromURL(blobUrl);
  const destinationBlobName =
    getFileNameWithoutExtension(sourceBlobName) +
    "_" +
    new Date().getTime() +
    getExtensionOfFileName(sourceBlobName);

  const containerClient = blobServiceClient.getContainerClient(containerName);

  const sourceBlob = containerClient.getBlobClient(sourceBlobName);
  const desBlob = containerClient.getBlobClient(destinationBlobName);

  try {
    // Start the copy operation
    const response = await desBlob.beginCopyFromURL(sourceBlob.url);

    // Wait for the copy operation to complete
    const result = await response.pollUntilDone();

    if (result?.copyStatus === "success") {
      // Get the URL of the copied blob
      return desBlob?.url;
    } else {
      errorToast(
        `Error copying blob '${sourceBlobName}' to '${destinationBlobName}'.`
      );
      return null; // Return null to indicate an error
    }
  } catch (error) {
    console.log(`An error occurred: ${error.message}`);
    return blobUrl; // Return null to indicate an error
  }
};

import {
    LOADING,
    LOADED,
    GET_ALL_COMMUNITY_LIST,
    GET_ALL__GROUP_BY_COMMUNITY_ID,
} from "./calendar.constant";


const INITIAL_STATE = {
    loading: { communityList: false, groupList: false },
    communityList: []
}
const calendar = (state = INITIAL_STATE, action) => {
    switch (action?.type) {

        case LOADING:
            const tempLoading = state.loading;
            tempLoading[action?.payload] = true;
            return { ...state, loading: tempLoading }


        case LOADED:
            const tempLoaded = state?.loading;
            tempLoaded[action?.payload] = false;
            return { ...state, loading: tempLoaded }

        case GET_ALL_COMMUNITY_LIST:
            return { ...state, communityList: Array.isArray(action?.payload) ? action?.payload : [] }

        case GET_ALL__GROUP_BY_COMMUNITY_ID:

            const index = state?.communityList?.findIndex(cmnt => {
                return cmnt?.communityId === action?.payload?.communityId
            });
            if (index > -1) {
                const tempArr = state?.communityList;
                tempArr[index].groupLists = Array.isArray(action?.payload?.groupList) ? action?.payload?.groupList : [];
                return { ...state, communityList: tempArr }
            } else {
                return state;
            }

        default:
            return state;
    }
}
export default calendar;
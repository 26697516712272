import store from "../../ReduxStore/store"

/**
 * get file name from azure storage blob url (uploaded media url)
 * @param {*} url 
 * @returns 
 */
export const getFileNameFromURL = (url) => {

    const lastSlashIndex = url.lastIndexOf('/');
    const fileNameWithQuery = url.substring(lastSlashIndex + 1);

    // Remove the query parameters, if any
    const questionMarkIndex = fileNameWithQuery.indexOf('?');
    const fileName = questionMarkIndex !== -1 ? fileNameWithQuery.substring(0, questionMarkIndex) : fileNameWithQuery;

    return fileName;

};

export const getFileNameReplacedSplaceWithUnderScore = (fileName) => {
    return fileName?.replace(/ /g, "_")
}

export const getFileNameWithoutExtension = (fileName) => {
    const extensionIndex = fileName.lastIndexOf('.');
    return extensionIndex !== -1 ? fileName.slice(0, extensionIndex) : fileName;
}

export const getExtensionOfFileName = (fileName) => {
    const extensionIndex = fileName.lastIndexOf('.');
    return extensionIndex !== -1 ? fileName.slice(extensionIndex) : '';
}


// genetate image name (environmentOfApp/serId/fileName.extension)
export function generateImageName(originalName) {
    const userIdPart = store?.getState()?.profileData?.profileData?.profileId;
    return userIdPart + "/" + originalName;
}
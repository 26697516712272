import * as Yup from "yup";
import { useFormik } from 'formik';
import { useEffect } from "react";


const GuestInvite = ({ eventId, handleSubmitGuestInvitation, formRef, guestSbmtBtnRef, url }) => {

    const formSchema = Yup.object().shape({
        email: Yup.string().email("Please Enter valid email").required("Email is required"),
        message: Yup.string(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: formSchema,
        initialValues: {
            email: "",
            message: "",
        },

        onSubmit: (values, { resetForm }) => {
            const emailList = [values.email]
            handleSubmitGuestInvitation(eventId, url, emailList, values?.message, resetForm)
        },
    });

    useEffect(() => {
        if (guestSbmtBtnRef?.current) {
            formik?.values?.email?.trim()?.length > 0 ? guestSbmtBtnRef?.current.removeAttribute("disabled") : guestSbmtBtnRef?.current.setAttribute("disabled", true)
        }
    }, [formik?.values?.email, guestSbmtBtnRef]);

    return (
        <>
            <h6 className="text-black-theme mb-3">Invite via social media</h6>
            <div className="invite-members-social-media custom-email-box mt-3">
                <form
                    className="form-row row"
                    onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                    }}
                    ref={formRef}
                >
                    <div className="col-md-12">
                        <div className="form-group mb-3 required">
                            <label className="form-label">To Email</label>
                            <input
                                placeholder="Enter Email"
                                value={formik.values.email}
                                name="email"
                                className="form-control h-auto"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <small className="form-text text-danger">{formik.touched.email && formik.errors.email ? formik.errors.email : ""}</small>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group mb-0">
                            <label className="form-label">Message</label>
                            <input
                                type="text"
                                className="form-control h-auto"
                                placeholder="Enter Message"
                                name="message"
                                value={formik.values.message}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default GuestInvite
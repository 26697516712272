import { GET_SIDEBAR_LIST_MORE_SUCCESS, GET_SIDEBAR_LIST_INITIAL_PENDING, GET_SIDEBAR_LIST_INITIAL_SUCCESS, GET_SIDEBAR_LIST_PAGINATION, SET_CURRENT_CHATBOARD } from "./groupMessage.constant";

const INITIAL_STATE = {
  sidebar: {
    loading: false,
    pagination: { totalRecords: 0, totalPages: 0, hasNextPage: false },
    sidebarList: []
  },
  currentChatBoard: {
    boardType: 0,//oneToOne:2, subGroup:1
    receiverOrSubGroupId: "",
    itemObj: null
  }
};


const groupMessageReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case GET_SIDEBAR_LIST_PAGINATION:
      return { ...state, sidebar: { ...state?.sidebar, pagination: action?.payload } }

    case GET_SIDEBAR_LIST_INITIAL_PENDING:
      return { ...state, sidebar: { ...state?.sidebar, loading: true } };

    case GET_SIDEBAR_LIST_INITIAL_SUCCESS:
      return { ...state, sidebar: { ...state?.sidebar, loading: false, sidebarList: action?.payload } };

    case GET_SIDEBAR_LIST_MORE_SUCCESS:
      return { ...state, sidebar: { ...state?.sidebar, loading: false, sidebarList: [...state?.sidebar?.sidebarList, ...action?.payload] } };

    case SET_CURRENT_CHATBOARD:
      return { ...state, currentChatBoard: action?.payload }

    default:
      return state;
  }
};
export default groupMessageReducers;

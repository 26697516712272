import { GET_ALL_MEMBERSHIP_PLAN, GET_PLANS_BY_PLAN_ID, MEMBERSHIP_LOADED, MEMBERSHIP_LOADING } from "./memberShipPlan.constant"

const INITIAL_STATE = {
    allMembershipPlans: { 0: [], 1: [] },
    singlePlan: null,
    loading: { all: false, single: false }
}

export const memberShipPlanReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case MEMBERSHIP_LOADING:
            return { ...state, loading: { ...state?.loading, ...(action?.payload === "all" ? { all: true } : { single: true }) } }

        case MEMBERSHIP_LOADED:
            return { ...state, loading: { ...state?.loading, ...(action?.payload === "all" ? { all: false } : { single: false }) } }

        case GET_ALL_MEMBERSHIP_PLAN:
            const month = action?.payload?.monthlyList?.map((d) => ({
                _id: d?._id,
                planName: d?.planName,
                price: d?.price,
                noOfCommunity: d?.noOfCommunity,
                availableFeatures: d?.availableFeatures,
                valid: "Month"
            }));
            const year = action?.payload?.yearlyList?.map((d) => ({
                _id: d?._id,
                planName: d?.planName,
                price: d?.price,
                annuallyPrice: `$${Number(d?.price) * 12} Billed Annually`,
                noOfCommunity: d?.noOfCommunity,
                availableFeatures: d?.availableFeatures,
                valid: "Month"
            }))
            return { ...state, allMembershipPlans: { 0: month, 1: year } };

        case GET_PLANS_BY_PLAN_ID:
            return { ...state, singlePlan: action?.payload };

        default:
            return state;
    }
}
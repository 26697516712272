import {
    CLOSE_CONNECTION,
    GET_CONNECTION_ESTABLISHED,
    GET_CONNECTION_FAILED,
    GET_CONNECTION_PENDING,
    GET_CONNECTION_SUCCESS,
} from "./signalrConnection.constant";

export const getConnectionActionCreator = (connection) => ({ type: GET_CONNECTION_SUCCESS, payload: connection });

export const getConnectionPendingActionCreator = () => ({ type: GET_CONNECTION_PENDING });

export const getConnectionFailedActionCreator = () => ({ type: GET_CONNECTION_FAILED });

export const getConnectionEstablishedActionCreator = () => ({ type: GET_CONNECTION_ESTABLISHED })

export const closeConnectionActionCreator = () => ({ type: CLOSE_CONNECTION });

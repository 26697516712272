import { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import { SpinnerLoaderFullPage } from '../Component/loader/SpinnerLoaderFullPage';
import { useDispatch, useSelector } from 'react-redux';
import NotFound from '../pages/404-not-found/NotFound';
import { privateRoutes, publicRoutes } from './routes';
import PublicRoute from './PublicRoute';
import { connection, startConnection } from '../Services/signalR/signalrService';
import ComboLogoLoader from '../Component/loader/ComboLogoLoader';
import Template3 from '../pages/template-view/template3/Template3';
import InstallPwaPage from '../Component/admin-community/install-pwa-btn/InstallPwaPage';
import { getOrSetCurrentDashboardCommunityActionThunk } from '../ReduxStore/slice/dashboard/dashboard-async-thunk';

const JoinGroup = lazy(() => import("../Component/JoinGroup"));
const TemplateView = lazy(() => import("../pages/template-view/TemplateView"));
const EventPublicPage = lazy(() => import("../pages/event/EventPublicPage"));

function AppRoute() {

    const dispatch = useDispatch();

    const isAuthenticated = useSelector((state) => state.auth?.token);
    const profileDataLoading = useSelector(state => state?.profileData?.loading?.profileData);
    const communityLoading = useSelector(state => state?.communityByUser?.loading);
    const dashboardCommunityLoading = useSelector(state => state?.dashboardCommunity?.loading);
    const signalRConnectionLoading = useSelector(state => state?.signalrConnection?.loading);


    useEffect(() => {
        if (isAuthenticated) {
            dispatch(getOrSetCurrentDashboardCommunityActionThunk());
        }
    }, [dispatch, isAuthenticated])

    // for signal r connection
    useEffect(() => {
        startConnection();
    }, [connection?.state])

    //show loader
    if (dashboardCommunityLoading || profileDataLoading || communityLoading
        || signalRConnectionLoading
    ) return <ComboLogoLoader />

    return (
        <BrowserRouter>
            <Suspense fallback={<SpinnerLoaderFullPage />}>
                <Routes>

                    {/* common routes which can access when user is loged in or not */}
                    <Route path="/join-group" element=<JoinGroup /> />
                    <Route exact path="/site" element={<TemplateView />} />
                    <Route exact path="/site/install" element={<InstallPwaPage />} />
                    <Route exact path="/public-event/:eventID" element={<EventPublicPage />} />
                    <Route exact path="/new-advanced-template/3" element={<Template3 />} />

                    {privateRoutes?.map(({ element: Element, path }, index) => (
                        <Route
                            key={index}
                            path={path}
                            element={
                                <ProtectedRoute>
                                    <Element />
                                </ProtectedRoute>
                            }
                        />
                    ))}
                    {publicRoutes?.map(({ element: Element, path }, index) => (
                        <Route
                            key={index}
                            path={path}
                            element={
                                <PublicRoute>
                                    <Element />
                                </PublicRoute>
                            }
                        />
                    ))}

                    {/* if any routes not match, 404 not found page */}
                    <Route path="/*" element={<NotFound />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}

export default AppRoute;
import { useFormik } from "formik";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { ReactComponent as CloseIconRed } from "../../../../../assets/Images/close-icon-red.svg";
import { ReactComponent as VideoIcon } from "../../../../../assets/Images/video-icon.svg";
import { ReactComponent as ImageIcon } from "../../../../../assets/Images/image-icon.svg";
import { ReactComponent as DocumentIcon } from "../../../../../assets/Images/document-icon.svg";
import { ReactComponent as FilesIcon } from "../../../../../assets/Images/files-icon.svg";
import { ReactComponent as GalleryIcon } from "../../../../../assets/Images/gallery-icon.svg";
import { useContext, useEffect, useState } from "react";
import { NewsfeedModalContext } from "../NewsfeedModalContext";
import EmojiPickerComp from "../../../discussion/create-discussion/EmojiPicker";
import { errorToast } from "../../../../toast/toast";
import uploadFileToBlob from "../../../../../utils/helpers/azure-storage-blob";
import CombineCropperModal from "../../../../image-cropper/CombineCropperModal";
import UploadFromSharedMedia from "../../../upload-from-shared-media/UploadFromSharedMedia";
import { setSharedMediaEntriesModalsAction } from "../../../../../ReduxStore/slice/group-shared-media-entries/group-shared-media-entries-slice";

const CreateCommentForm = () => {
  const dispatch = useDispatch();

  const { loading, createCommentApiCall, newsfeedData } =
    useContext(NewsfeedModalContext);
  const profilePicture = useSelector(
    (state) => state.profileData?.profileData?.profilePicture
  );
  const [localLoading, setLocalLoading] = useState({
    documentUpload: false,
    imageUpload: false,
    videoUpload: false,
  });

  const handleCloseGroupSharedMediaEntries = (selected_files) => {
    if (selected_files?.length > 0) {
      selected_files?.map((file) => {
        file?.FileType === "image"
          ? formik?.setValues((prev) => ({
              ...prev,
              mediaFileURL: file?.SRC,
              mediaFileType: "image",
              FileName: file?.Name,
            }))
          : file?.mediaFileType === "video"
          ? formik?.setValues((prev) => ({
              ...prev,
              mediaFileURL: file?.SRC,
              mediaFileType: "video",
              FileName: file?.Name,
            }))
          : formik?.setValues((prev) => ({
              ...prev,
              mediaFileURL: file?.SRC,
              mediaFileType: "document",
              FileName: file?.Name,
            }));
      });
    }
    dispatch(
      setSharedMediaEntriesModalsAction({
        show_group_shared_media_entries_modal: { status: false, groupId: "" },
      })
    );
  };

  const handleUploadFile = (event, type) => {
    let file = event.target.files[0];
    const imageName = file?.name;
    const updatedFile = new File([file], imageName, { type: file.type });
    let reader = new FileReader();
    reader.readAsDataURL(updatedFile);
    uploadAttechments(updatedFile, type, imageName);
    //event.target.value = null;
  };

  const uploadAttechments = (file, type) => {
    setLocalLoading((prev) => ({
      ...prev,
      ...(type === "image"
        ? { imageUpload: true }
        : type === "video"
        ? { videoUpload: true }
        : type === "document" && { documentUpload: true }),
    }));
    uploadFileToBlob(file)
      .then((fileUrl) => {
        if (fileUrl) {
          formik?.setValues((prev) => ({
            ...prev,
            mediaFileURL: fileUrl,
            mediaFileType: type,
          }));
        }
        setLocalLoading((prev) => ({
          ...prev,
          ...(type === "image"
            ? { imageUpload: false }
            : type === "video"
            ? { videoUpload: false }
            : type === "document" && { documentUpload: false }),
        }));
      })
      .catch((err) => {
        errorToast("Something went wrong");
        setLocalLoading((prev) => ({
          ...prev,
          ...(type === "image"
            ? { imageUpload: false }
            : type === "video"
            ? { videoUpload: false }
            : type === "document" && { documentUpload: false }),
        }));
      });
  };

  /**
   * image input field onChange handler
   */
  const [showImageCroperModal, setShowImageCroperModal] = useState({
    status: false,
  });
  const [src, setSrc] = useState({ image: null, fileName: "", fileType: "" });

  const changeHandlerImage = (event, type) => {
    setSrc({
      image: URL.createObjectURL(event.target.files[0]),
      fileName: event.target.files[0]?.name,
      fileType: event.target.files[0]?.type,
    });
    setShowImageCroperModal({ status: true, type: type });
    //event.target.value = null;
  };

  /**
   * close image crop modal and set the selected image in setBackroundImage props
   * @param {*} blob
   */
  const handleCloseImageCropModal = (fileUrl) => {
    setShowImageCroperModal({ status: false });

    if (fileUrl) {
      formik?.setValues((prev) => ({
        ...prev,
        mediaFileURL: fileUrl,
        mediaFileType: "image",
      }));
    }
  };

  const formSchema = Yup.object().shape({
    comments: Yup.string().required("Please enter a comment message"),
    mediaFileType: Yup.string(),
    MediaFileURL: Yup.string(),
  });

  const formik = useFormik({
    validationSchema: formSchema,
    initialValues: {
      comments: "",
      mediaFileType: "",
      mediaFileURL: "",
    },
    onSubmit: (values, { resetForm }) => {
      createCommentApiCall(values, resetForm);
    },
  });

  useEffect(() => {
    formik?.setSubmitting(loading?.createCommentBtn ? true : false);
  }, [loading?.createCommentBtn]);

  return (
    <form onSubmit={(e) => formik.handleSubmit(e)}>
      <div className="media-posts-create-cmnt modal-emoji align-items-start">
        <img
          className="commentprofile commentprofile2"
          src={
            profilePicture ||
            require("../../../../../assets/Images/guest-user.jpg")
          }
          alt="img"
        />
        <div className="d-flex flex-column flex-fill">
          <textarea
            className="form-control react-input-emoji--container"
            name="comments"
            placeholder="Write comment..."
            disabled={formik?.isSubmitting}
            value={formik?.values?.comments}
            onChange={(event) => {
              const text = event?.target?.value?.trimStart();
              formik?.setValues((prev) => ({ ...prev, comments: text }));
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter" && !event.shiftKey) {
                event.preventDefault();
                !formik?.isSubmitting && formik.handleSubmit(event);
              }
            }}
          />
          <small className="form-text text-danger">
            {formik?.touched?.comments && formik?.errors?.comments
              ? formik?.errors?.comments
              : ""}
          </small>
        </div>
        <EmojiPickerComp
          setEmoji={(emoji) =>
            formik?.setValues((val) => ({
              ...val,
              comments: val?.comments + emoji,
            }))
          }
          disabled={formik?.isSubmitting}
        />
      </div>
      <div className="create-post-outer-box">
        <div className="create-post-inner-box py-0 abc">
          <div className="create-post-slct-image">
            <div className="selct-img"></div>
          </div>
          <div className="row form-row">
            {formik?.values?.mediaFileType === "image" && (
              <div className="col-6 col-md-3">
                <div className="thumbnail-preview thumbnail-image-post-img">
                  <img src={formik?.values?.mediaFileURL} alt="" />
                  <Button
                    variant="link"
                    className="close-icon"
                    disabled={formik?.isSubmitting}
                    onClick={() => {
                      formik?.setValues(() => ({
                        ...formik?.values,
                        mediaFileType: "",
                        mediaFileURL: "",
                      }));
                    }}
                  >
                    <CloseIconRed />
                  </Button>
                </div>
              </div>
            )}
            {formik?.values?.mediaFileType === "video" && (
              <div className="col-6 col-md-3">
                <div className="thumbnail-preview thumbnail-video-post-img">
                  <div className="embed-responsive embed-responsive-4by3">
                    <iframe
                      className="embed-responsive-item"
                      title="#"
                      src={formik?.values?.mediaFileURL}
                    ></iframe>
                  </div>
                  <Button
                    variant="link"
                    className="close-icon"
                    disabled={formik?.isSubmitting}
                    onClick={() => {
                      formik?.setValues(() => ({
                        ...formik?.values,
                        mediaFileType: "",
                        mediaFileURL: "",
                      }));
                    }}
                  >
                    <CloseIconRed />
                  </Button>
                </div>
              </div>
            )}
            {formik?.values?.mediaFileType === "document" && (
              <div className="col-6 col-md-3">
                <div className="thumbnail-preview thumbnail-image-post-img document docfile d-flex align-items-center justify-content-center">
                  <Button
                    variant="link"
                    className="close-icon ms-2"
                    disabled={formik?.isSubmitting}
                    onClick={() => {
                      formik?.setValues(() => ({
                        ...formik?.values,
                        mediaFileType: "",
                        mediaFileURL: "",
                      }));
                    }}
                  >
                    <CloseIconRed />
                  </Button>
                  <i
                    className="fa fa-file-text doc-icon"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            )}
          </div>
          <div className="d-flex flex-wrap gap-1 create-post shared-media-post py-2 px-0 mt-0">
            <div className="create-post-icons-upld">
              <Row className="text-center g-2">
                <Col>
                  {localLoading?.imageUpload ? (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Image</Tooltip>}
                    >
                      <label>
                        <ImageIcon fill="#27A841" />
                        <i className="fa fa-spinner fa-spin ms-2"></i>
                      </label>
                    </OverlayTrigger>
                  ) : (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Image</Tooltip>}
                    >
                      <label>
                        <input
                          type="file"
                          className="d-none"
                          accept="image/*"
                          onChange={(event) => {
                            changeHandlerImage(event, "image");
                          }}
                          disabled={formik?.isSubmitting}
                        />
                        <ImageIcon fill="#27A841" />
                      </label>
                    </OverlayTrigger>
                  )}
                </Col>
                <Col>
                  {localLoading?.documentUpload ? (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Document</Tooltip>}
                    >
                      <label>
                        <DocumentIcon fill="#1183FD" />
                        <i className="fa fa-spinner fa-spin ms-2"></i>
                      </label>
                    </OverlayTrigger>
                  ) : (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Document</Tooltip>}
                    >
                      <label>
                        <input
                          type="file"
                          className="d-none"
                          accept=".pdf"
                          onChange={(event) => {
                            handleUploadFile(event, "document");
                          }}
                          disabled={formik?.isSubmitting}
                        />
                        <DocumentIcon fill="#1183FD" />
                      </label>
                    </OverlayTrigger>
                  )}
                </Col>
                <Col>
                  {localLoading?.videoUpload ? (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Video</Tooltip>}
                    >
                      <label>
                        <VideoIcon fill="#FF0038" />
                        <i className="fa fa-spinner fa-spin ms-2"></i>
                      </label>
                    </OverlayTrigger>
                  ) : (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Video</Tooltip>}
                    >
                      <label>
                        <input
                          type="file"
                          className="d-none"
                          accept="video/*"
                          onChange={(event) => {
                            handleUploadFile(event, "video");
                          }}
                          disabled={formik?.isSubmitting}
                        />
                        <VideoIcon fill="#FF0038" />
                      </label>
                    </OverlayTrigger>
                  )}
                </Col>
                <UploadFromSharedMedia
                  handleClose={handleCloseGroupSharedMediaEntries}
                  groupId={newsfeedData?.data?.groupID}
                  multiple={false}
                />
                <Col>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Files</Tooltip>}
                  >
                    <label
                      onClick={() =>
                        dispatch(
                          setSharedMediaEntriesModalsAction({
                            show_group_shared_media_entries_modal: {
                              status: true,
                              groupId: newsfeedData?.data?.groupID,
                              selection_type: "single",
                            },
                          })
                        )
                      }
                    >
                      <FilesIcon fill="#0dcaf0" />
                      {/* <i className="fa fa-spinner fa-spin ms-2"></i> */}
                    </label>
                  </OverlayTrigger>
                </Col>
                <Col>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>My Media</Tooltip>}
                  >
                    <label
                      onClick={() =>
                        dispatch(
                          setSharedMediaEntriesModalsAction({
                            show_group_shared_media_entries_modal: {
                              status: true,
                              selection_type: "single",
                            },
                          })
                        )
                      }
                    >
                      <GalleryIcon fill="#ffc107" />
                      {/* <i className="fa fa-spinner fa-spin ms-2"></i> */}
                    </label>
                  </OverlayTrigger>
                </Col>
              </Row>
            </div>
            <div className="d-flex align-items-center">
              <Button
                className="btn btn-primary-theme btn-sm"
                disabled={
                  formik?.isSubmitting ||
                  localLoading?.videoUpload ||
                  localLoading?.imageUpload ||
                  localLoading?.documentUpload
                }
                type="submit"
              >
                Post
                {formik?.isSubmitting && (
                  <i className="fa fa-spinner fa-spin ms-2"></i>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {showImageCroperModal?.status && (
        <CombineCropperModal
          show={showImageCroperModal?.status}
          handleClose={handleCloseImageCropModal}
          src={src}
          aspect={1 / 1}
          canChangeAspect={true}
          spectString="1 / 1"
          cropRation={{
            unit: "%",
            x: 0,
            y: 0,
            // aspect: 1,
            height: 100,
            width: 100,
          }}
        />
      )}
    </form>
  );
};

export default CreateCommentForm;

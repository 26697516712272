import { CLOSE_CONNECTION, GET_CONNECTION_ESTABLISHED, GET_CONNECTION_FAILED, GET_CONNECTION_PENDING, GET_CONNECTION_SUCCESS} from "./signalrConnection.constant";

const initialState = {
    loading: false,
    connection: null,
};

const signalrConnectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CONNECTION_PENDING:
            return {
                ...state,
                loading: true,
            };
        case GET_CONNECTION_FAILED:
            return {
                ...state,
                loading: false,
            };
        case GET_CONNECTION_ESTABLISHED:
            return {
                ...state,
                loading: false,
            }
        case GET_CONNECTION_SUCCESS:
            return {
                ...state,
                loading: false,
                connection: action?.payload
            };

        case CLOSE_CONNECTION:
            return {
                ...state,
                connection: null
            }
        default:
            return state;
    }
};

export default signalrConnectionReducer;
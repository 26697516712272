import { REHYDRATE } from "redux-persist"
import { DO_EMPTY_PROFILE_DATA, GET_PROFILE_DATA, PROFILE_DATA_LOADED, PROFILE_DATA_LOADING, ROLE_AND_PERMISSIONS_FAIL, ROLE_AND_PERMISSIONS_REQUEST, ROLE_AND_PERMISSIONS_SUCCESS, UPDATE_PROFILE_DATA, UPDATE_PROFILE_DATA_LOADED, UPDATE_PROFILE_DATA_LOADING } from "./profileData.constant"

const INITIAL_STATE = {
    loading: { profileData: false, updateProfileData: false, permissions: false },
    profileData: {},
    permissions: []
}

const profileDataReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        // get profile data
        case PROFILE_DATA_LOADING:
            return { ...state, loading: { ...state?.loading, profileData: true } }

        case PROFILE_DATA_LOADED:
            return { ...state, loading: { ...state?.loading, profileData: false } }

        case GET_PROFILE_DATA:
            return { ...state, profileData: { ...action?.payload } }

        //update profile data
        case UPDATE_PROFILE_DATA_LOADING:
            return { ...state, loading: { ...state?.loading, updateProfileData: true } }

        case UPDATE_PROFILE_DATA_LOADED:
            return { ...state, loading: { ...state?.loading, updateProfileData: false } }

        case UPDATE_PROFILE_DATA:
            return { ...state, profileData: { ...action.payload } };

        case DO_EMPTY_PROFILE_DATA:
            return { ...state, profileData: null, permissions: [] };

        case ROLE_AND_PERMISSIONS_REQUEST:
            return { ...state, loading: { ...state?.loading, permissions: true } }

        case ROLE_AND_PERMISSIONS_SUCCESS:
            return {
                ...state,
                loading: { ...state?.loading, permissions: false },
                permissions: action.payload
            };

        case ROLE_AND_PERMISSIONS_FAIL:
            {
                return {
                    ...state,
                    loading: { ...state?.loading, permissions: false },
                    permissions: []
                }
            }

        case REHYDRATE:
            if (action?.payload && action?.payload?.profileData) {
                return { ...action?.payload?.profileData, loading: { ...action?.payload?.profileData?.loading, profileData: false } }
            }
            return state;
        default:
            return state;
    }
}
export default profileDataReducer;
import { createAsyncThunk } from "@reduxjs/toolkit";
import StoreService from "../../../Services/e-commerce-store/StoreService";

export const getAlleCommerceStore = createAsyncThunk(
  "Site/GetCommunityAndStoreDetailsByUserID",
  async (userId) => {
    const response = await StoreService.GetAllStore();

    if (response?.status !== 200) {
      throw new Error(response?.data?.message);
    } else {
      return response?.data;
    }
  }
);

export const UpdateCommunityEcommerceStoreStatusActionThunk = createAsyncThunk(
  "Site/UpdateCommunityEcommerceStoreStatus",
  async ({ communityId, storeStatus }) => {
    const response = await StoreService.updateEcommerceStoreStatus(
      communityId,
      storeStatus
    );
    if (response?.status !== 200) {
      throw new Error(response?.data?.message);
    } else {
      return response?.data;
    }
  }
);

import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";

import PhotoGallery from "../../common/PhotoGallery/PhotoGallery";
import EditModal from "./modal/EditModal";
import { useSelector } from "react-redux";
import { Slide } from "react-awesome-reveal";
import { ReactComponent as ArrowIconDefault } from "../../../../assets/Images/arrow-icon-default.svg";
import PhotoGalleryInModal from "../../common/PhotoGallery/Modal/PhotoGalleryInModal";
import TruncateMessage from "../../../truncat-message/TruncateMessage";

const Section6 = ({ children, isEdit }) => {
  const section6Data = useSelector(
    (state) => state?.template3?.template_3_sections?.section6
  );
  const section1Data = useSelector(
    (state) => state?.template3?.template_3_sections?.section1
  );

  const [data, setData] = useState(null);
  const [showGalleryModal, setShowGalleryModal] = useState(false);

  /**
   * group lists modal handler
   */
  const openGallerysModal = () => {
    setShowGalleryModal(true);
  };

  const closeGalleryModal = () => {
    setShowGalleryModal(false);
  };

  useEffect(() => {
    if (section6Data) {
      setData(section6Data);
    }
  }, [section6Data]);

  console.log("IsEdit:" + isEdit);
  return (
    <>
      {isEdit ? (
        <section
          className="fitness-gallery-section"
          style={{ backgroundColor: data?.BackgroundColor }}
        >
          <Container>
            <div className="section-title text-center mb-4 mb-lg-5">
              <h2
                style={{
                  fontSize: "50px",
                  fontFamily: "Sofia Pro",
                  fontWeight: "400",
                  marginBottom: "25px",
                  color: `#333333`,
                  lineHeight: "62px",
                }}
              >
                {data?.Heading}
              </h2>
              <TruncateMessage
                message={data?.Content}
                length={300}
                pStyle={{
                  fontSize: "22px",
                  fontWeight: "400",
                  marginBottom: "30px",
                  color: "#757575",
                  lineHeight: "34px",
                  maxWidth: "775px",
                  margin: "0 auto",
                }}
                readBntStyle={{ color: `#757575` }}
              />
            </div>
            <Slide direction="right">
              <PhotoGallery folder={data?.GalleryImages?.slice(0, 5)} />
            </Slide>
            <div className="d-flex justify-content-center">
              <Button
                style={{
                  backgroundColor: `transparent`,
                  borderColor: section1Data?.ThemeColor,
                  color: section1Data?.ThemeColor,
                }}
                variant="primary"
                size="lg"
                className="d-flex align-items-center with-icon"
                onClick={() => openGallerysModal()}
              >
                More Photos
                <ArrowIconDefault
                  fill={section1Data?.ThemeColor}
                  className="ms-3"
                />
              </Button>
              {children}
            </div>
          </Container>
        </section>
      ) : (
        data?.GalleryImages?.length > 0 && (
          <section
            className="fitness-gallery-section"
            style={{ backgroundColor: data?.BackgroundColor }}
          >
            <Container>
              <div className="section-title text-center mb-4 mb-lg-5">
                <h2
                  style={{
                    fontSize: "50px",
                    fontFamily: "Sofia Pro",
                    fontWeight: "400",
                    marginBottom: "25px",
                    color: `#333333`,
                    lineHeight: "62px",
                  }}
                >
                  {data?.Heading}
                </h2>
                <TruncateMessage
                  message={data?.Content}
                  length={300}
                  pStyle={{
                    fontSize: "22px",
                    fontWeight: "400",
                    marginBottom: "30px",
                    color: "#757575",
                    lineHeight: "34px",
                    maxWidth: "775px",
                    margin: "0 auto",
                  }}
                  readBntStyle={{ color: `#757575` }}
                />
              </div>
              <Slide direction="right">
                <PhotoGallery folder={data?.GalleryImages?.slice(0, 5)} />
              </Slide>
              <div className="d-flex justify-content-center">
                <Button
                  style={{
                    backgroundColor: `transparent`,
                    borderColor: section1Data?.ThemeColor,
                    color: section1Data?.ThemeColor,
                  }}
                  variant="primary"
                  size="lg"
                  className="d-flex align-items-center with-icon"
                  onClick={() => openGallerysModal()}
                >
                  More Photos
                  <ArrowIconDefault
                    fill={section1Data?.ThemeColor}
                    className="ms-3"
                  />
                </Button>
                {children}
              </div>
            </Container>
          </section>
        )
      )}
      <EditModal data={data} setData={setData} />

      {showGalleryModal && (
        <PhotoGalleryInModal
          show={showGalleryModal}
          handleClose={closeGalleryModal}
          folder={data?.GalleryImages}
        />
      )}
    </>
  );
};

export default Section6;

import { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import EditModal from "./modal/EditModal";
import { Slide } from "react-awesome-reveal";

const Section8 = ({ children }) => {
  const section1Data = useSelector(
    (state) => state?.template3?.template_3_sections?.section1
  );
  const section8Data = useSelector(
    (state) => state?.template3?.template_3_sections?.section8
  );

  const [data, setData] = useState(null);

  useEffect(() => {
    if (section8Data) {
      setData(section8Data);
    }
  }, [section8Data]);

  return (
    <>
      <footer style={{ backgroundColor: "#1C1C1C" }}>
        <Container>
          <Row className="align-items-center">
            <Col md={4}>
              <Slide direction="left">
                <div className="d-flex align-items-end justify-content-center justify-content-md-start text-white logo-text">
                  {(typeof data?.IsLogoVisible === "undefined" ||
                    data?.IsLogoVisible) && (
                    <img src={section1Data?.Logo} height="34" alt="logo" />
                  )}

                  {/* <img src={section1Data?.Logo} height="34" alt="logo" /> */}
                  <span> {section1Data?.BrandName} </span>
                </div>
              </Slide>
            </Col>
            <Col md={4} className="text-center mt-3 mb-3 mt-md-0 mb-md-0">
              <Slide direction="up">
                <span style={{ color: "#A9A9A9", fontWeight: "400" }}>
                  Powered By {data?.PoweredBy}
                </span>
              </Slide>
            </Col>
            <Col md={4}>
              <Slide direction="right">
                <div className="d-flex align-items-center justify-content-center justify-content-md-end social-icons">
                  <a
                    style={{
                      border: `1px solid ${section1Data?.ThemeColor}`,
                      color: section1Data?.ThemeColor,
                      backgroundColor: "transparent",
                      borderRadius: "4px",
                    }}
                    href={data?.Facebook}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                  <a
                    style={{
                      border: `1px solid ${section1Data?.ThemeColor}`,
                      color: section1Data?.ThemeColor,
                      backgroundColor: "transparent",
                      borderRadius: "4px",
                    }}
                    href={data?.Twitter}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                  <a
                    style={{
                      border: `1px solid ${section1Data?.ThemeColor}`,
                      color: section1Data?.ThemeColor,
                      backgroundColor: "transparent",
                      borderRadius: "4px",
                    }}
                    href={data?.LinkedIN}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                  {children}
                </div>
              </Slide>
            </Col>
          </Row>
        </Container>
      </footer>

      <EditModal data={data} setData={setData} />
    </>
  );
};

export default Section8;

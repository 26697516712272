import { Badge } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const NotificationBadge = () => {

    const unreadNotificaionCount = useSelector((state) => state?.notification?.unread_notification_count)

    return (
        <>
            {unreadNotificaionCount > 0 && (<Badge bg="danger" pill className='ms-auto lh-11'>{unreadNotificaionCount}</Badge>)}
        </>
    )
}

export default NotificationBadge
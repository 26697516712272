import { useRef, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as CalendarIcon } from "../../../assets/Images/calendar-icon.svg";
import CalendarInModal from "../../templates/common/calendar/CalendarInModal";
import MemberListDD from "../drop-downs/MemberListDD";

const GroupCard2 = ({ group, ThemeColor, isAdvance, removeAppend }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const ddRef = useRef();

  const [showCalendarInModal, setShowCalendarInModal] = useState({
    status: false,
    groupID: "",
  });

  /**
   * show calendar in modal handler
   */
  const calendarInModalCloseHandler = () => {
    setShowCalendarInModal({ status: false, groupID: "" });
  };

  return (
    <>
      <div
        className="group-box"
        style={{ backgroundColor: "#eff8fb", borderRadius: "20px" }}
      >
        <div
          className="group-image"
          style={{ borderBottom: "1px solid #e5e3e3" }}
        >
          <img
            src={
              group?.coverImage ||
              require("../../../assets/Images/group_cover_img.png")
            }
            alt="Group"
            style={{
              borderRadius: "20px 20px 0 0",
              width: "100%",
              height: "190px",
              objectFit: "cover",
              ...(group?.coverImage
                ? { objectFit: "cover" }
                : { objectFit: "contain" }),
            }}
          />
          <div className="group-image-hover-content">
            <span
              style={{
                fontSize: "28px",
                fontFamily: "Quicksand",
                fontWeight: "700",
                color: "#ffffff",
              }}
            >
              {group?.groupName}
            </span>
            <div className="text-center mt-3">
              <Button
                variant="primary"
                size="sm"
                style={
                  ThemeColor && {
                    backgroundColor: `${ThemeColor}`,
                    borderColor: `${ThemeColor}`,
                  }
                }
                onClick={() => {
                  if (group?.joinedStatus) {
                    navigate("/group-view/newsfeed/" + group?.groupID);
                  } else {
                    navigate(location?.pathname, {
                      state: {
                        joinGroupConfirmModal: true,
                        groupId: group?.groupID,
                        groupName: group?.groupName,
                      },
                    });
                  }
                }}
              >
                {group?.joinedStatus ? "Visit Now" : "Join Now"}
              </Button>
            </div>
          </div>
        </div>
        <div
          className="group-content"
          style={{ borderRadius: "0 0 20px 20px" }}
        >
          <div className="d-flex align-items-center flex-wrap gap-1 p-2 px-2 px-md-3">
            {group?.memberCount > 100 && (
              <>
                <MemberListDD
                  profilePictureList={group?.profilePictureList}
                  groupId={group?.groupID}
                  removeAppend={removeAppend ? true : false}
                  ddRef={ddRef}
                />
                <p
                  className="m-0"
                  style={{
                    color: "#2C393E",
                    fontFamily: "Quicksand",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (ddRef.current) {
                      ddRef.current.click();
                    }
                  }}
                >
                  {("0" + group?.memberCount).slice(-2)} Member
                </p>
              </>
            )}
            <span
              className="group-type-label ms-auto"
              style={{
                color: ThemeColor || "#ed6f03",
                border: "1px solid",
                borderColor: ThemeColor || "#ed6f03",
              }}
            >
              {group?.isPrivate ? "Private" : "Public"}
            </span>
            {isAdvance && (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-1`}>Calendar</Tooltip>}
              >
                <Button
                  variant="link"
                  className="ms-1 p-0"
                  style={{ marginTop: "-1px" }}
                  onClick={() =>
                    setShowCalendarInModal({
                      status: true,
                      groupID: group?.groupID,
                    })
                  }
                >
                  <CalendarIcon
                    height={18}
                    width={18}
                    fill={ThemeColor || "#ed6f03"}
                  />
                </Button>
              </OverlayTrigger>
            )}
          </div>
        </div>
      </div>
      {showCalendarInModal?.status && (
        <CalendarInModal
          show={showCalendarInModal?.status}
          handleClose={calendarInModalCloseHandler}
          groupID={showCalendarInModal?.groupID}
        />
      )}
    </>
  );
};

export default GroupCard2;

import { API } from "../../http-Common";

class UserService {
  profileCreate(data) {
    return API.post("/User/Profile", data);
  }

  profileUpdate(userID, data) {
    return API.put(`/User/UpdateProfile/${userID}`, data);
  }

  activeDeactiveUserProfile(userID) {
    return API.put(`/User/ActiveDeactiveUserProfile/${userID}`);
  }

  changeUserRole(id, roleID) {
    return API.put(`/User/ChangeUserRole?userID=${id}&roleID=${roleID}`);
  }

  deleteProfileByUserID(id) {
    return API.delete(`/User/DeleteProfileByUserID/${id}`);
  }

  login(email, password,deviceId) {
    return API.post("/User/Login", { email, password, deviceId });
  }

  resetPassword(id, password, confirmPassword) {
    return API.post(
      `/User/ResetPassword?userID=${id}&newPassword=${password}&confirmPassword=${confirmPassword}`
    );
  }

  payment(data) {
    return API.post("/Payment/Pay", data);
  }

  sendEmail(data) {
    return API.post("/SendEmail/SendEmail", data);
  }

  upgradePlan(data) {
    return API.put("/User/UpgradePlan", data);
  }

  getUserDetailsByUserID(userid) {
    return API.get(`/User/GetProfileDetailsByUserID?userID=${userid}`);
  }
  GetAllOwnerAdminList(pageIndex, pageSize) {
    return API.get("/User/GetAllOwnerAdminList", {
      params: { pageIndex, pageSize },
    });
  }
  GetUserPermissionById(userId, roleID) {
    return API.get(`/User/GetUserPermissionById?id=${userId}&roleID=${roleID}`);
  }
  GetUserProfileDataById(ProfileId) {
    return API.get(`/User/GetUserProfileDataById?ProfileId=${ProfileId}`);
  }
  GetMemberShipByUserId(UserId) {
    return API.get(`/Site/GetUserCommunityCount?userId=${UserId}`);
  }

  CheckExistingEmailID(emailID) {
    return API.get("/User/CheckExistingEmailID", { params: { emailID } });
  }
  AddMyMedia(data) {
    return API.post("/User/AddMyMedia", data);
  }
  GetAllMyMedia(mediaType, search, pageIndex, pageSize) {
    return API.get("/User/GetAllMyMedia", {
      params: { mediaType, search, pageIndex, pageSize },
    });
  }
  DeleteMyMedia(id) {
    return API.delete("/User/DeleteMyMedia", { params: { id } });
  }

  GetAllUsers( pageIndex, pageSize, nameSearched,roleId, planId) {
    return API.get("/User/GetAllUsers", {
      params: {  pageIndex, pageSize, nameSearched,roleId,planId },
    });
  }

  changePassword = (oldPassword, newPassword, confirmPassword) => {
    return API.post("/User/ChangePassword", null, {
      params: { oldPassword, newPassword, confirmPassword },
    });
  };

  getOrSetCurrentSelectedCommunity = (communityID) => {
    return API.get("/User/GetOrSetCurrentSelectedCommunity", {
      params: { communityID },
    });
  };

  setProfilePrivacySettings = (profilePrivacy, messagePrivacy) => {
    return API.post("/User/SetProfilePrivacySettings", {
      profilePrivacy,
      messagePrivacy,
    });
  };
  getProfilePrivacySettings = () => {
    return API.get("/User/GetProfilePrivacySettings");
  };

  /**
   *
   * @param {*} settingObj {  "communitySite": true,  "event": true,  "discussion": true,  "message": true,  "newsfeed": true}
   * @returns
   */
  setNotificationSettings = (settingObj) => {
    return API.post("/User/SetNotificationSettings", { ...settingObj });
  };

  getNotificationSettings = () => {
    return API.get("/User/GetNotificationSettings");
  };

  createFolder = (folderName, parentFolderId, groupId) => {
    return API.post("/User/CreateUserFolder", {
      folderName,
      parentFolderId,
      groupId,
    });
  };

  fetchUserFolder = (folderID, groupId) => {
    return API.post("/User/FetchUserFolder", { folderID, groupId });
  };

  //delete the folder
  deleteUserFolder = (ids) => {
    return API.delete("/User/DeleteUserFolder", { data: ids });
  };

  /**
   * API for add file in user drive
   */
  addUserFile = (fileObj) => {
    return API.post("/User/AddUserFile", { ...fileObj });
  };

  /**
   * rename folder
   * @param {*} folderID
   * @param {*} folderName
   * @returns
   */
  updateUserFolder = (folderID, folderName) => {
    return API.put("/User/UpdateUserFolder", { folderID, folderName });
  };

  /**
   * rename file
   * @param {*} fileID
   * @param {*} fileName
   * @returns
   */
  updateUserFile = (fileID, fileName) => {
    return API.put("/User/UpdateUserFile", { fileID, fileName });
  };

  //delete the file
  deleteUserFile = (ids) => {
    return API.delete("/User/DeleteUserFile", { data: ids });
  };

  fetchUserStorageUsage = () => {
    return API.post("/User/FetchUserStorageUsage");
  };

  DeleteUser(userId) {
    return API.delete(`User/DeleteUser?userId=${userId}`);
  };
  UpdateUser(data) {
    return API.delete(`User/UpdateUserByUserID?data=${data}`);
  };
  moveUserFileAndFolder = (newFolderId, fileIdList, folderIdList) => {
    return API.put("/User/MoveUserFileAndFolder", {
      newFolderId,
      fileIdList,
      folderIdList,
    });
  };
}
const userService = new UserService();
export default userService;

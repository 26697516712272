import { useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { ReactComponent as PermissionIcon } from '../../assets/Images/permission-icon.svg';
import { ReactComponent as ToolsIcon } from '../../assets/Images/tools-icon.svg';
import { ReactComponent as BellIcon } from '../../assets/Images/bell-icon.svg';
import { ReactComponent as PowerIcon } from '../../assets/Images/power-icon.svg';
import { ReactComponent as SwitchRoleIcon } from '../../assets/Images/switch-role-icon.svg';
import { ReactComponent as PaymentCardIcon } from '../../assets/Images/payment-card-icon.svg';
import { logoutHandler } from "../../utils/helpers/logoutHandler";
import { ReactComponent as SettingsIconGray } from '../../assets/Images/settings-icon-gray.svg';
import { roleIdToRoleName } from "../../utils/helpers/roleIdToRoleName";
import NotificationBadge from "../../pages/notification/NotificationBadge";

let SideBar = ({ toggleNav, setToggleNav }) => {

  const navigate = useNavigate();
  const location = useLocation();

  const communityId = useSelector(state => state?.dashboardCommunity?.communityId);
  const selectedCmntObj = useSelector((state) => state?.currentUserCommunity?.communitiy_list?.filter(cmnt => cmnt?.communityId === state?.dashboardCommunity?.communityId)[0]);
  const profileData = useSelector((state) => state?.profileData?.profileData);
  const roleID = useSelector(state => state?.profileData?.profileData?.roleID);
  const currentRole = useSelector(state => {
    if (state?.dashboardCommunity?.communityId == 2 || state?.dashboardCommunity?.communityId == 1) {
      return state?.dashboardCommunity?.communityId
    } else {
      return state?.currentUserCommunity?.communitiy_list?.filter(cmnt => cmnt?.communityId === state?.dashboardCommunity?.communityId)[0]?.userRoleInCommunity
    }
  });

  const clickOnTabHandler = () => {
    if (toggleNav) {
      setToggleNav(false);
    }
  }

  return (
    <>
      <div className={toggleNav ? "left-sidebar mar-nav-left-0 border-end" : "left-sidebar"}>
        <div className="sidebar-inner">
          <div className="sidebar-user-profile">
            {/* <Link to="/admin-tools" className="navbar-brand d-none d-lg-block" href="#">
              <img src={logo} height={20} alt="logo" />
            </Link> */}
            {(communityId == 2 || communityId == 1) ? (
              <>
                <div className="mt-2 mt-lg-5 text-center">
                  <div className="sidebar-user-img mb-3">
                    <img
                      src={profileData?.profilePicture || require("../../assets/Images/guest-user.jpg")}
                      alt="profile"
                    />
                  </div>
                  <h6 className="mb-2 text-break">
                    {profileData?.firstName} {profileData?.lastName}
                  </h6>
                  <small className="d-block mb-2">
                    {roleIdToRoleName(currentRole)}
                  </small>
                </div>
              </>
            ) : (
              <>
                <div className="text-center mt-2 mt-lg-4 mb-3">
                  <div className="sidebar-user-img community mb-2">
                    <img
                      className="cover mb-1"
                      src={selectedCmntObj?.communityLogo ?? require("../../assets/Images/guest-user.jpg")}
                      alt=""
                    />
                  </div>
                  <h6 className="mb-2 font-size-15 text-break">
                    {selectedCmntObj?.communitySiteName}
                  </h6>
                </div>
                <div className="d-flex align-items-center justify-content-center gap-2 border-top sidebar pt-3 mb-2 mb-lg-0">
                  <img
                    height={40}
                    width={40}
                    className="rounded-circle"
                    src={profileData?.profilePicture || require("../../assets/Images/guest-user.jpg")}
                    alt=""
                  />
                  <div>
                    <h6 className="font-size-14 mb-0 mt-1">{profileData?.firstName} {profileData?.lastName}</h6>
                    <small className="font-size-12">{roleIdToRoleName(currentRole)}</small>
                  </div>
                </div>
              </>
            )}
            {roleID !== 1 && (
              <div className="text-center">
                <Button
                  variant="primary"
                  className="nav-upgrade-plan-btn d-none d-lg-inline-block mt-3"
                  size="sm"
                  onClick={() => { navigate("/upgrade-plan"); clickOnTabHandler(); }}
                >
                  {profileData?.planDetails ? "Change Plan" : "Purchase Plan"}
                </Button>
              </div>
            )}
          </div>
          <ul className={`${roleID !== 1 ? "change-plan-btn" : ""} sidebar-list-outer`}>
            <li>
              <NavLink to="/admin-tools" onClick={() => clickOnTabHandler()}
                className={(navData) => navData.isActive ? "text-decoration-none d-flex align-items-center active" : "text-decoration-none d-flex align-items-center"}>
                <div className="icon">
                  <ToolsIcon fill="#5F6C85" />
                </div> Admin Tools
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/profile/about/" + profileData?.profileId}
                onClick={() => clickOnTabHandler()}
                className={[
                  "/profile/about",
                  "/profile/community",
                  "/profile/group",
                  "/profile/newsfeed",
                  "/profile/discussion",
                  "/profile/event",
                  "/profile/media"
                ]?.includes(location?.pathname?.replace(/\/[a-f0-9]{24}$/i, "")) ? "text-decoration-none d-flex align-items-center active" : "text-decoration-none d-flex align-items-center"}
              >
                <div className="icon">
                  <PermissionIcon fill="#5F6C85" />
                </div>
                My Profile
              </NavLink>
            </li>
            <li>
              <NavLink
                // to="/account-setting/general"
                to="/account-setting/privacy"

                onClick={() => clickOnTabHandler()}
                className={[
                  // "/account-setting/general",
                  "/account-setting/privacy",
                  "/account-setting/email",
                  "/account-setting/password",
                  ...(!profileData?.isStripeButtonEnable ? ["/account-setting/stripe"] : [])
                ]?.includes(location?.pathname) ? "text-decoration-none d-flex align-items-center active" : "text-decoration-none d-flex align-items-center"}
              >
                <div className="icon">
                  <SettingsIconGray fill="#5F6C85" />
                </div>
                Settings
              </NavLink>
            </li>
            <li>
              <NavLink to="/notification" onClick={() => clickOnTabHandler()} className={(navData) => navData.isActive ? "text-decoration-none d-flex align-items-center active" : "text-decoration-none d-flex align-items-center"}>
                <div className="icon">
                  <BellIcon fill="#5F6C85" />
                </div>
                Notifications <NotificationBadge />
              </NavLink>
            </li>
            {profileData?.isStripeButtonEnable && (
              <li>
                <NavLink
                  to="/account-setting/stripe"
                  onClick={() => clickOnTabHandler()}
                  className={(navData) => navData.isActive ? "text-decoration-none d-flex align-items-center active" : "text-decoration-none d-flex align-items-center"}
                >
                  <div className="icon">
                    <PaymentCardIcon fill="#5F6C85" style={{width: '20px'}} />
                  </div>
                  Connect Stripe Account
                </NavLink>
              </li>)}
            <li onClick={() => navigate(location?.pathname, { state: { ...location?.state, showSwitchroleModal: true }, replace: true })}>
              <Link to="" className={"text-decoration-none d-flex align-items-center"}>
                <div className="icon">
                  <SwitchRoleIcon fill="#5F6C85" />
                </div>
                Switch Role
              </Link>
            </li>
            <li onClick={() => logoutHandler()}>
              <Link to="" className={"text-decoration-none d-flex align-items-center"}>
                <div className="icon">
                  <PowerIcon fill="#5F6C85" />
                </div>
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SideBar;
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { loginActionThunk } from "../../../ReduxStore/auth/auth.actions.async";
import GroupService from "../../../Services/group/GroupService";
import { errorToast, successToast, warningToast } from "../../toast/toast";

const Login = ({ formRef, setLoading, navigateToGroup }) => {

  const dispatch = useDispatch();

  const modalReducer = useSelector((state) => state.modalReducer);

  const [togglePassword, setTogglePassword] = useState(true);

  //Join group API call
  const JoinGroup = (UserID) => {
    setLoading(prev => ({ ...prev, joinGrpAPICall: true }));
    GroupService.JoinGroup(UserID, modalReducer.groupId, modalReducer?.communityID)
      .then(res => {
        if (res?.data?.statusCode === 404) {
          warningToast("Already you are in group.");
        } else {
          successToast("Group joined successfully.");
          navigateToGroup();
        }
        setLoading(prev => ({ ...prev, joinGrpAPICall: false }));
      })
      .catch(err => {
        errorToast("something went wrong");
        setLoading(prev => ({ ...prev, joinGrpAPICall: false }));
      })
  }

  const loginSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    password: Yup.string().required('Please Enter your password')
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: loginSchema,
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values, { resetForm }) => {
      dispatch(
        loginActionThunk(
          {
            email: values.email,
            password: values.password,
          },
          false,
          JoinGroup
        )
      );
    }
  });
  return (
    <form
      className="form-row row"
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
      ref={formRef}
    >
      <div className="row form-row">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="form-group mb-3">
            <label className="form-label">Email</label>
            <input
              name="email"
              className="form-control form-control-lg"
              placeholder="Email"
              value={formik?.values?.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email ? (
              <small className="form-text text-danger">
                {formik.errors.email}
              </small>
            ) : null}
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="form-group mb-3 position-relative password-field">
            <label className="form-label">Password</label>
            <input
              type={togglePassword ? "password" : "text"}
              name="password"
              value={formik?.values?.password}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              className="form-control"
              placeholder="Password"
            />
            {formik.touched.password && formik.errors.password ? (
              <small className="form-text text-danger">
                {formik.errors.password}
              </small>
            ) : null}
            <button
              className="btn btn-outline-primary hide-show-eye shadow-none me-2"
              type="button"
              onClick={() => setTogglePassword(pass => !pass)}
            >
              <i
                className={togglePassword ? "fa fa-eye-slash" : "fa fa-eye"}
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default Login
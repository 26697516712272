import { isOwner } from '../../../utils/helpers/isOwner';
import { formatDateForChat } from '../../../utils/helpers/formatDateForChat';
import TruncateMessage from '../../truncat-message/TruncateMessage';
import { useNavigate } from 'react-router';
import { CheckCircle, CheckCircleOutline } from '@mui/icons-material';
import { markMessageAsReadOrUnReadSignalr } from '../../../Services/signalR/signalrService';

const MessageCard = ({ message, receiverData }) => {

    const navigate = useNavigate();

    //make marsk as read message 
    const markMessageAsReadOrUnReadHandler = (receiverId, messageId, isRead) => {
        markMessageAsReadOrUnReadSignalr(receiverId, messageId, isRead)
    }

    return (
        <>
            {isOwner(message?.senderID) ? (
                <div className='outgoing-msg-box'>
                    <div className="outgoing-msg">
                        <div className='outgoing-msg-content'>
                            <TruncateMessage
                                message={message?.messageString}
                                length={500}
                            />
                            <small className='d-block text-end'>{formatDateForChat(message?.timestamp)} {message?.isRead ? <CheckCircle style={{ color: "#25D366", height: "18px" }} /> : <CheckCircleOutline style={{ color: "#34B7F1", height: "18px" }} />} </small>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='incoming-msg-box'>
                    <div className="incoming-msg">
                        <div className="incoming-msg-content">
                            <p
                                className='name mb-1 cursor-pointer'
                                onClick={() => navigate("/profile/about/" + receiverData?.profileId)}
                            >{receiverData?.firstName}</p>
                            <TruncateMessage
                                message={message?.messageString}
                                length={500}
                            />
                            <small className='d-block text-end'> {formatDateForChat(message?.timestamp)} </small>
                            {markMessageAsReadOrUnReadHandler(message?.receiverID, message?.id, true)}
                        </div>
                    </div>
                </div>
            )}
        </>

    );
};

export default MessageCard;

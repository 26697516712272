import { componentWillAppendToBody } from "react-append-to-body";

const MyComponent = ({ children }) => {
  return (
    <>
      {children}
    </>
  );
};

export const AppendedMyComponent = componentWillAppendToBody(MyComponent);

import { createSlice } from "@reduxjs/toolkit";
import { donwloadFileFromSharedMediaEntriesAsyncThunk, fetchFolderOfSharedMediaEntriesAsyncThunk } from "./group-shared-media-entries-async-thunk";
import { errorToast } from "../../../Component/toast/toast";

const initialState = {

    currentFolderId: null,

    loading: {
        get_file_entries: false,
        file_downloading_progress: [],
        download_file: false
    },

    errors: {
        file_entries: null,
        get_file_entries: null,
        download_file: null,
    },

    modals: {
        show_group_shared_media_entries_modal: {
            status: false,
            groupId: "",
            selection_type: "",
            from: ""// create_newsfeed, edit_newsfeed_comment,create_newsfeed_comment, create_discussion, create_discussion_comment, edit_discussion_comment
        }
    },

    file_selection: {
        is_enable: false,
        selected_files: [], //{ FileID: "", SRC: "" }
    },

    file_enteries: { SubFolders: [], Files: [] },

    file_preview: { status: false, file: null },

};

const groupSharedMediaEntriesSlice = createSlice({
    name: "groupSharedMediaEntries",
    initialState,
    reducers: {
        setCurrentFolderInSharedMediaEntriesAction: (state, action) => {
            state.currentFolderId = action?.payload;
            // fetchUserFolderAsyncThunk({ folderID: action?.payload, loading: true });
        },
        setSharedMediaEntriesModalsAction: (state, action) => {
            state.modals = action?.payload;
            if (action?.payload?.show_group_shared_media_entries_modal?.status) {
                state.file_selection.selected_files = []
                state.currentFolderId = null;
            }
        },

        setFilePreviewInSharedMediaEntriesAction: (state, action) => {
            state.file_preview.status = action?.payload?.status;
            state.file_preview.file = action?.payload?.file;
        },
        handleUploadProgressInSharedMediaEntriesAction: (state, action) => {
            const existingIndex = state.file_upload_progress.findIndex((item) => Object.keys(item)[0] === action?.payload?.fileName);
            if (existingIndex > -1) {
                state.file_upload_progress[existingIndex] = { [action?.payload?.fileName]: action?.payload?.progress, };
            } else {
                state.file_upload_progress.push({ [action?.payload?.fileName]: action?.payload?.progress, });
            }
        },
        handleDownloadProgressInSharedMediaEntriesAction: (state, action) => {
            const existingIndex = state.loading.file_downloading_progress.findIndex((item) => Object.keys(item)[0] === action?.payload?.fileName);
            if (existingIndex > -1) {
                state.loading.file_downloading_progress[existingIndex] = { [action?.payload?.fileName]: action?.payload?.progress, };
            } else {
                state.loading.file_downloading_progress.push({ [action?.payload?.fileName]: action?.payload?.progress, });
            }
        },
        cancelFileSelectionInSharedMediaEntriesAction: (state, action) => {
            state.file_selection.is_enable = false;
            state.file_selection.selected_files = [];
        },
        selectFileInSharedMediaEntriesAction: (state, action) => {
            const index = state?.file_selection?.selected_files?.findIndex(file => file?.FileID === action?.payload?.FileID);
            if (index > -1) {
                state?.file_selection?.selected_files?.splice(index, 1);
            } else if (state?.modals?.show_group_shared_media_entries_modal?.selection_type === "single") {
                state.file_selection.selected_files = [action?.payload]
            } else {
                state.file_selection.selected_files = [...state.file_selection.selected_files, action?.payload]
            }
        },
    },

    extraReducers: (builder) => {
        builder
            // get file entries
            .addCase(fetchFolderOfSharedMediaEntriesAsyncThunk.pending, (state, action) => {
                state.loading.get_file_entries = action?.meta?.arg?.loading ? true : false;
                state.errors.get_file_entries = null;
            })
            .addCase(fetchFolderOfSharedMediaEntriesAsyncThunk.fulfilled, (state, action) => {
                state.loading.get_file_entries = false;
                state.errors.get_file_entries = null;
                const folderData = JSON.parse(action?.payload?.data);
                if (action?.meta?.arg?.folderID === state?.currentFolderId) {
                    state.file_enteries = folderData;
                }
            })
            .addCase(fetchFolderOfSharedMediaEntriesAsyncThunk.rejected, (state, action) => {
                state.loading.get_file_entries = false;
                state.errors.get_file_entries = action?.payload?.message;
                state.file_enteries = { SubFolders: [], Files: [] };
                errorToast(action?.error?.message);
            })


            // download files
            .addCase(donwloadFileFromSharedMediaEntriesAsyncThunk.pending, (state, action) => {
                state.loading.download_file = true;
                state.errors.download_file = null;
            })
            .addCase(donwloadFileFromSharedMediaEntriesAsyncThunk.fulfilled, (state, action) => {
                state.loading.download_file = false;
                state.errors.download_file = null;
                state.loading.file_downloading_progress = [];
            })
            .addCase(donwloadFileFromSharedMediaEntriesAsyncThunk.rejected, (state, action) => {
                state.loading.download_file = false;
                state.errors.download_file = action?.error?.message;
                state.loading.file_downloading_progress = [];
                errorToast(action?.error?.message);
            })
    },
});

export const {
    setCurrentGroupIdInSharedMediaEntriesAction,
    handleDownloadProgressInSharedMediaEntriesAction,
    setSharedMediaEntriesModalsAction,
    setCurrentFolderInSharedMediaEntriesAction,
    selectFileInSharedMediaEntriesAction,
} = groupSharedMediaEntriesSlice.actions;

const groupSharedMediaEntriesReducer = groupSharedMediaEntriesSlice?.reducer;

export default groupSharedMediaEntriesReducer;

import moment from 'moment';

export const formatDateForChat = (dateObj) => {

  var localTime = moment.utc(dateObj).toDate()

  const date = moment(localTime);
  const formattedDate = date.calendar(null, {
    lastDay: '[Yesterday], h:mm A',
    sameDay: 'h:mm A',
    lastWeek: 'dddd, h:mm A',
    sameElse: 'MMM DD, YYYY, h:mm A'
  });
  return formattedDate;
};

export const formatDateForCreatedDate = (dateObj) => {
  var localTime = moment.utc(dateObj).toDate()

  return moment(localTime).format('YYYY-MM-DD hh:mm A');
}

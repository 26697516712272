import { useEffect, useState } from "react";
import { Button, Col, Dropdown, Modal, Row } from "react-bootstrap";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import GroupService from "../../../Services/group/GroupService";
import { ReactComponent as CloseIcon } from "../../../assets/Images/close-icon.svg";
import { ReactComponent as EyeIcon } from "../../../assets/Images/eye-icon.svg";
import { roleIdToRoleName } from "../../../utils/helpers/roleIdToRoleName";
import { AppendedMyComponent } from "../../appendToBody/appendToBody";
import { SpinnerLoader } from "../../loader/SpinnerLoader";
import { errorToast } from "../../toast/toast";
import LiveStreamMemberListDD from "./LiveStreamMemberListDD";
import ViewLiveStreamTimer from "./count-timer/ViewLiveStreamTimer";
import LiveStreamSubGroupMessageBoard from "./live-stream-group-message-board/LiveStreamSubGroupMessageBoard";
import moment from "moment";

const ViewLiveStreamModel = ({
  show,
  handleClose,
  url,
  subGroupID,
  liveStreamMember,
  setLiveStreamMember,
  liveStremListType,
  liveStreamDetail,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const getGroupID = params.groupId;
  const profileId = useSelector(
    (state) => state?.profileData?.profileData?.profileId
  );
  const [subGroupData, setSubGroupData] = useState(null);
  // const [liveStreamMember, setLiveStreamMember] = useState();
  // const [loading, setLoading] = useState({
  //   receiverData: false,
  //   groupConversation: false,
  //   leaveSubGroup: false,
  //   subGroupData: false,
  // });
  const [loading, setLoading] = useState({ userLoading: false });
  const [livestreamUsers, setLiveStreamUsers] = useState([]);

  // const currentChatBoard ="653b5a099f03b6dc2ab52b48"
  // const getGroupID = "652e34d45b23b9edb4e160ac"

  //get subgroup data
  // const getSubGroupBysubGroupIdApiCall = (subGroupId) => {
  //   setLoading((prev) => ({ ...prev, subGroupData: true }));
  // GroupService.getSubGroupsBySubGroupID(subGroupId)
  //     .then((res) => {
  //       setSubGroupData(res?.data);
  //       setLoading((prev) => ({ ...prev, subGroupData: false }));
  //     })
  //     .catch((err) => {
  //       errorToast("Something went wrong.");
  //       setLoading((prev) => ({ ...prev, subGroupData: false }));
  //     });
  // };

  // useEffect(() => {
  //   getSubGroupBysubGroupIdApiCall(subGroupID);
  // }, []);

  const getAllLiveStreamMemberList = () => {
    setLoading((prev) => ({ ...prev, userLoading: true }));
    GroupService.getLiveStremsMemberList(subGroupID)
      .then((res) => {
        setLiveStreamUsers(res?.data?.entities);
        setLoading((prev) => ({ ...prev, userLoading: false }));
      })
      .catch((err) => {
        errorToast("Something went wrong");
        setLoading((prev) => ({ ...prev, userLoading: false }));
      });
  };

  useEffect(() => {
    getAllLiveStreamMemberList();
  }, []);

  const handleNavigate = (userId) => {
    if(userId !== profileId){
    navigate(`/profile/about/${userId}`)
    }
  }

  return (
    <div>
      <Modal
        className="common-modal live-stream-modal simple-modal"
        show={show}
        onClose={handleClose}
        contentLabel="Example Modal"
        backdrop="static"
        centered
        scrollable
        size="xl"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title className="text-black-theme"> Live Stream</Modal.Title>
          <Button variant="link" onClick={handleClose}>
            <CloseIcon fill="#212121" />
          </Button>
        </Modal.Header>
        <Modal.Body className="common-modal-body common-fields">
          {liveStremListType !== 1 && show ? (
            <div>
              <Row>
                <Col xs={12} md={12} lg={6}>
                  <div className="live-stream-video">
                    <ReactPlayer
                      url={url}
                      controls
                      width="100%"
                      title="YouTube video"
                      // playing={isVideoPlaying}
                      allowFullScreen
                    />
                    <div className="d-flex align-items-center live-video-image-count">
                      <div className="live-video-members custom-card">
                        <LiveStreamMemberListDD
                          livestreamUsers={livestreamUsers}
                          loading={loading}
                          getAllLiveStreamMemberList={
                            getAllLiveStreamMemberList
                          }
                        />
                      </div>
                      <div
                        className="live-video-count ms-2"
                        // onClick={() => getAllLiveStreamMemberList()}
                      >
                        <Dropdown>
                          <Dropdown.Toggle
                            className="btn btn-link"
                            id="dropdown-basic-2"
                          >
                            <div
                        onClick={() => getAllLiveStreamMemberList()}
                            
                            >

                            <EyeIcon
                              fill="#ffffff"
                              height={9}
                              className="me-1"
                              //  onClick={() => getAllLiveStreamMemberList()}
                            />
                            {liveStreamMember?.memberCount > 0 
                              ? liveStreamMember?.memberCount 
                              : "0"}{" "}
                            LIVE
                            </div>
                          </Dropdown.Toggle>
                          <AppendedMyComponent>
                            <Dropdown.Menu
                              className="group-dropdown-menu live-video-dropdown-menu"
                              align="end"
                            >
                              <div className="arrow-up"></div>
                              <ul className="list-unstyled m-0">
                                {loading?.userLoading ? (
                                  <SpinnerLoader />
                                ) : Array?.isArray(livestreamUsers) &&
                                  livestreamUsers?.length > 0 ? (
                                  livestreamUsers?.map((cmnt, index) => (
                                    <li key={index}>
                                      <Button
                                        variant="link"
                                        onClick={() => handleNavigate(cmnt?.userId)}
                                        // onClick={() => navigateToTemplate(cmnt?.communityDomain)}
                                      >
                                        <div className="d-flex align-items-center">
                                          <img
                                            src={
                                              cmnt?.profilePicture ||
                                              require("../../../assets/Images/group_logo_img.png")
                                            }
                                            alt="User"
                                            height={30}
                                            className="ms-0 me-2 rounded-circle"
                                          />
                                          <div className="d-flex flex-column">
                                            <div style={{ textAlign: "left" }}>
                                              {" "}
                                              {cmnt?.firstName + " " + cmnt?.lastName } 
                                            </div>
                                            <div
                                              className=" text-wrap"
                                              style={{
                                                fontSize: "10px",
                                                ...(cmnt?.roleId <= 3 && {
                                                  fontWeight: "600",
                                                }),
                                                textAlign: "left",
                                              }}
                                            >
                                              {roleIdToRoleName(cmnt?.roleId)}
                                            </div>
                                          </div>
                                        </div>
                                      </Button>
                                    </li>
                                  ))
                                ) : (
                                  <li>
                                    <Button variant="link">
                                      <div className="d-flex align-items-center">
                                        No Users Found
                                      </div>
                                    </Button>
                                  </li>
                                )}
                              </ul>
                            </Dropdown.Menu>
                          </AppendedMyComponent>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={12} lg={6}>
                  <div className="live-chat border mt-3 mt-lg-0 h-100">
                    <div className="card-header border-bottom p-3">
                      <h6 className="font-size-17 m-0">Live Chat</h6>
                    </div>
                    <LiveStreamSubGroupMessageBoard
                      subGroupId={subGroupID}
                      key={subGroupID + getGroupID}
                      setLiveStreamMember={setLiveStreamMember}
                      liveStreamMember={liveStreamMember}
                      liveStremListType={liveStremListType}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div>
              <Row>
                <Col xs={12} md={12} lg={6}>
                  <div className="live-stream-image position-relative">
                    <ViewLiveStreamTimer
                      startDate={moment.utc(liveStreamDetail?.liveStartTime).local()}
                      liveStreamDetail={liveStreamDetail}
                    />
                    <img
                      src={
                        liveStreamDetail?.image ||
                        require("../../../assets/Images/group_logo_img.png")
                      }
                      // className="top-img"
                      alt=""
                      // style={{
                      //   ...(!liveStreamDetail?.coverImage && { objectFit: "contain" }),
                      // }}
                    />
                  </div>
                </Col>
                <Col xs={12} md={12} lg={6}>
                  <div className="live-chat border mt-3 mt-lg-0 h-100 upcoming-video">
                    <div className="card-header border-bottom p-3">
                      <h6 className="font-size-17 m-0">Live Chat</h6>
                    </div>
                    {/* <LiveStreamSubGroupMessageBoard
                  subGroupId={subGroupID}
                  key={subGroupID + getGroupID}
                  setLiveStreamMember={setLiveStreamMember}
                  liveStreamMember={liveStreamMember}
                  liveStremListType={liveStremListType}
                /> */}
                  </div>
                </Col>
              </Row>
            </div>
          )}

          {/* )}  */}
        </Modal.Body>
        {/* <Modal.Footer className="common-modal-footer">
          <Button
            className="btn-secondary-theme btn-outline"
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
};
export default ViewLiveStreamModel;

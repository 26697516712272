import { DO_EMPTY_PROFILE_DATA, GET_PROFILE_DATA, PROFILE_DATA_LOADED, PROFILE_DATA_LOADING, UPDATE_PROFILE_DATA, UPDATE_PROFILE_DATA_LOADED, UPDATE_PROFILE_DATA_LOADING } from "./profileData.constant";

/**
 * get profile data loading action creator
 * @returns 
 */
export const profileDataLoadingAction = () => ({ type: PROFILE_DATA_LOADING });
export const profileDataLoadedAction = () => ({ type: PROFILE_DATA_LOADED });

/**
 * get profile data action creator
 * @param data 
 * @returns 
 */
export const getProfilerofileDataAction = (data) => ({ type: GET_PROFILE_DATA, payload: data });

/**
 * update profile data loading
 * @returns 
 */
export const updateProfileDataLoading = () => ({ type: UPDATE_PROFILE_DATA_LOADING })
export const updateProfileDataLoaded = () => ({ type: UPDATE_PROFILE_DATA_LOADED })

/**
 * update profile data action creator
 * @param data 
 * @returns 
 */
export const updateProfileDataAction = (data) => ({ type: UPDATE_PROFILE_DATA, payload: data });

/**
 * do empty profile data state action creator
 * @returns 
 */
export const doEmptyProfileAction = () => ({ type: DO_EMPTY_PROFILE_DATA });
import { forwardRef, useImperativeHandle, useState } from "react";
import ReactCrop from 'react-image-crop'
import uploadFileToBlob from "../../utils/helpers/azure-storage-blob";
import { SpinnerLoader } from "../loader/SpinnerLoader";
import { errorToast } from "../toast/toast";
import { generateImageName } from "../../utils/helpers/getFileNameFromURL";

const ImageCropper = forwardRef(({ handleClose, src, cropRation, loading, setLoading }, ref) => {

  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState(cropRation);

  const onLoad = (img) => {
    setImage(img)
  };

  async function getCroppedImg() {

    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const canvasWidth = crop.width * scaleX;
    const canvasHeight = crop.height * scaleY;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      canvasWidth,
      canvasHeight,
      0,
      0,
      canvasWidth,
      canvasHeight
    );

    const imageTypeLower = src?.fileType?.toLowerCase();

    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        resolve({ file: file, url: URL.createObjectURL(file) });
      }, imageTypeLower);
    });
  }

  useImperativeHandle(ref, () => ({
    async handleImageCrop() {
      try {
        const { file, url } = await getCroppedImg();
        console.log('');
        const imageName = generateImageName(src?.fileName);
        file.name = imageName;
        setLoading(true);
        uploadFileToBlob(file)
          .then((fileUrl) => {
            setLoading(false);
console.log('123', fileUrl?.split('?')[0]);

            handleClose(fileUrl?.split('?')[0]);
          })
          .catch((err) => {
            errorToast("Something went wrong.");
            setLoading(false);
            handleClose("");
          });
      } catch (e) {
        errorToast(e);
      }
    }
  }))

  return (

    <>
      {loading ? (
        <SpinnerLoader />
      ) : (
        src && (
          <>
            <ReactCrop
              src={src?.image}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={setCrop}
              crossorigin="anonymous"
              cropShape="round"
              borderRadius={50}
            />
          </>
        )
      )}
    </>
  );
});

export default ImageCropper;

import { FolderCopy } from "@mui/icons-material";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setCurrentFolderInSharedMediaEntriesAction } from "../../../../../../ReduxStore/slice/group-shared-media-entries/group-shared-media-entries-slice";

const FolderCard = ({ fileObj }) => {

    const dispatch = useDispatch();

    //open folder
    const handleDoubleClick = (folderId) => {
        dispatch(setCurrentFolderInSharedMediaEntriesAction(folderId));
    };

    return (
        <>
            <div className="media-file-card-container">
                <div
                    className="media-file-card d-flex flex-column justify-content-center align-items-center m-0"
                    onDoubleClick={() => handleDoubleClick(fileObj?.FolderID)}
                >
                    <FolderCopy style={{ fontSize: "40px", color: "#818080" }} />
                </div>
                <div className="name">
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id={`tooltip-1`}>{fileObj?.FolderName}</Tooltip>}
                    >
                        <small>{fileObj?.FolderName} </small>
                    </OverlayTrigger>
                </div>
            </div>
        </>
    )
}

export default FolderCard
import { createSlice } from "@reduxjs/toolkit";
import { deleteSystemCommunityActionThunk, getAllUserCommunityAsyncThunk, updateSystemCommunityPublishStatusActionThunk } from "./system-community-async-thunk";

const initialState = {
    loading: {
        fetch_community_list: false,
        update_publish_status: { status: false, communityId: "" },
        delete_community: { status: false, communityId: "" },
    },
    community_list: {
        list: [],
        totalRecords: 0
    },
    errors: {
        fetch_community_list: null,
        update_publish_status: null,
        delete_community: null
    }
}

const systemCommunitiesSlice = createSlice({
    name: 'systemCommunities',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder

            // get community lists
            .addCase(getAllUserCommunityAsyncThunk.pending, (state, action) => {
                state.loading.fetch_community_list = true;
                state.errors.fetch_community_list = null;
            })
            .addCase(getAllUserCommunityAsyncThunk.fulfilled, (state, action) => {
                state.loading.fetch_community_list = false;
                state.errors.fetch_community_list = null;
                state.community_list.list = action?.payload?.entities;
                state.community_list.totalRecords = action?.payload?.totalRecords;
            })
            .addCase(getAllUserCommunityAsyncThunk.rejected, (state, action) => {
                state.loading.fetch_community_list = false;
                state.errors.fetch_community_list = action.error.message;
                state.community_list.list = [];
                state.community_list.totalRecords = 0;
            })

            //update community public status
            .addCase(updateSystemCommunityPublishStatusActionThunk.pending, (state, action) => {
                state.loading.update_publish_status = { communityId: action?.meta?.arg?.communityId, status: true }
                state.errors.update_publish_status = null
            })
            .addCase(updateSystemCommunityPublishStatusActionThunk.fulfilled, (state, action) => {
                state.loading.update_publish_status = { communityId: "", status: false }
                state.errors.update_publish_status = null
                const index = state?.community_list?.list?.findIndex(community => community?.communityId === action?.payload?.communityId)
                if (index > -1) {
                    state.community_list.list[index].status = action?.meta?.arg?.publishStatus;
                }
            })
            .addCase(updateSystemCommunityPublishStatusActionThunk.rejected, (state, action) => {
                state.loading.update_publish_status = { communityId: "", status: false }
                state.errors.update_publish_status = action?.error?.message;
            })

            //delete system community
            .addCase(deleteSystemCommunityActionThunk.pending, (state, action) => {
                state.loading.delete_community = { communityId: action?.meta?.arg, status: true }
                state.errors.delete_community = null
            })
            .addCase(deleteSystemCommunityActionThunk.fulfilled, (state, action) => {
                state.loading.delete_community = { communityId: "", status: false }
                state.errors.delete_community = null
            })
            .addCase(deleteSystemCommunityActionThunk.rejected, (state, action) => {
                state.loading.delete_community = { communityId: "", status: false }
                state.errors.delete_community = action?.error?.message;
            })
    }
});


export const actions = systemCommunitiesSlice.actions;

const systemCommunitiesRducer = systemCommunitiesSlice.reducer;
export default systemCommunitiesRducer;
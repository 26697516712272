import { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Slide } from "react-awesome-reveal";
import CalendarInModal from "../../common/calendar/CalendarInModal";
import GroupListModal from "../../common/GroupListModal";
import EditModal from "./modal/EditModal";
import JoinGroupModal from "../../../Group/ join-group-modal/JoinGroupModal";
import GroupCard2 from "../../../Group/group-list-card/GroupCard2";
import { ReactComponent as ArrowIconDefault } from "../../../../assets/Images/arrow-icon-default.svg";
import TruncateMessage from "../../../truncat-message/TruncateMessage";

const Section5 = ({ children }) => {
  const siteID = useSelector((state) => state?.template3?.siteID);
  const groupsLists = useSelector((state) => state?.template3?.group_list);
  const section1Data = useSelector(
    (state) => state?.template3?.template_3_sections?.section1
  );
  const section5Data = useSelector(
    (state) => state?.template3?.template_3_sections?.section5
  );
  const is_Editable = useSelector(
    (state) => state?.template3?.is_Editable?.status
  );

  const [data, setData] = useState(null);
  const [showCalendarInModal, setShowCalendarInModal] = useState(false);
  const [showGroupListModal, setShowGroupListModal] = useState(false);

  /**
   * group lists modal handler
   */
  const openGroupListsModal = () => {
    setShowGroupListModal(true);
  };

  const closeGroupListModal = () => {
    setShowGroupListModal(false);
  };

  /**
   * show calendar in modal handler
   */
  const calendarInModalCloseHandler = () => {
    setShowCalendarInModal(false);
  };

  useEffect(() => {
    if (section5Data) {
      setData(section5Data);
    }
  }, [section5Data]);

  return (
    (groupsLists?.length || is_Editable) && (
      <>
        <section
          className="group-section-template "
          style={{ backgroundColor: data?.BackgroundColor }}
        >
          <Container>
            <div className="section-title text-center mb-4 mb-lg-5">
              <h2
                style={{
                  fontSize: "50px",
                  fontFamily: "Sofia Pro",
                  fontWeight: "400",
                  marginBottom: "25px",
                  color: `#333333`,
                  lineHeight: "62px",
                }}
              >
                {data?.Heading}
              </h2>
              <TruncateMessage
                message={data?.Content}
                length={300}
                pStyle={{
                  fontSize: "22px",
                  fontWeight: "400",
                  marginBottom: "30px",
                  color: "#757575",
                  lineHeight: "34px",
                  maxWidth: "775px",
                  margin: "0 auto",
                }}
                readBntStyle={{ color: `#757575` }}
              />
            </div>
            <Row className="justify-content-center align-items-center">
              {groupsLists?.length > 0 &&
                groupsLists?.slice(0, 3)?.map((group, index) => (
                  <Col md={6} lg={4} className="mb-4 mb-lg-5">
                    <Slide direction="left">
                      <GroupCard2
                        group={group}
                        ThemeColor={section1Data?.ThemeColor}
                        isAdvance={true}
                      />
                    </Slide>
                  </Col>
                ))}
            </Row>
            <div className="d-flex justify-content-center">
              <Button
                style={{
                  backgroundColor: `transparent`,
                  borderColor: section1Data?.ThemeColor,
                  color: section1Data?.ThemeColor,
                }}
                variant="primary"
                size="lg"
                className="d-flex align-items-center with-icon"
                onClick={() => openGroupListsModal()}
              >
                More Groups
                <ArrowIconDefault
                  fill={section1Data?.ThemeColor}
                  className="ms-3"
                />
              </Button>
              {children}
            </div>
          </Container>
        </section>

        <EditModal data={data} setData={setData} />

        {showGroupListModal && (
          <GroupListModal
            show={showGroupListModal}
            handleClose={closeGroupListModal}
            siteID={siteID}
            ThemeColor={section1Data?.ThemeColor}
            isAdvance={true}
          />
        )}
        {showCalendarInModal?.status && (
          <CalendarInModal
            show={showCalendarInModal?.status}
            groupID={showCalendarInModal?.groupID}
            handleClose={calendarInModalCloseHandler}
          />
        )}
        <JoinGroupModal />
      </>
    )
  );
};

export default Section5;

import { createSlice } from "@reduxjs/toolkit"
import { getNotificationListActionThunk } from "./notification-async-thunks";


const initialState = {
    loading: { fetch_list: false },
    notifications: {
        count: 0,
        notifications: []
    },
    unread_notification_count: 0,
    errors: {
        fetch_list: null,
    }
}

const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        getSingleNotificationFromSignalrAction: (state, action) => {
            state.notifications.count = state.notifications.count + 1;
            state.notifications.notifications = [action?.payload?.notification, ...state?.notifications?.notifications];
        },
        getUnreadNotificationCountAction: (state, action) => {
            state.unread_notification_count = action?.payload?.count;
        },
        updateNotificationViewStatusAction: (state, action) => {
            const index = state.notifications.notifications?.findIndex(ntfcn => ntfcn?.notification?.id === action?.payload?.notificationId)
            if (index > -1) {
                state.notifications.notifications[index].isViewd = true
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNotificationListActionThunk.pending, (state, action) => {
                state.loading.fetch_list = action?.meta?.arg?.needLoading;
                state.errors.fetch_list = null;
            })
            .addCase(getNotificationListActionThunk.fulfilled, (state, action) => {
                state.loading.fetch_list = false;
                state.errors.fetch_list = null;
                state.notifications.notifications = action?.payload?.data?.entities
                state.notifications.count = action?.payload?.data?.totalRecords
            })
            .addCase(getNotificationListActionThunk.rejected, (state, action) => {
                state.errors.fetch_list = action?.error?.message;
                state.loading.fetch_list = false;
            })
    }
});

export const {
    getSingleNotificationFromSignalrAction,
    getUnreadNotificationCountAction,
    updateNotificationViewStatusAction,
} = notificationSlice.actions;

const notificationReducer = notificationSlice?.reducer;

export default notificationReducer;

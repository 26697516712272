import { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import EditModal from "./modal/EditModal";
import { Slide } from "react-awesome-reveal";
import TruncateMessage from "../../../truncat-message/TruncateMessage";

const Section2 = ({ children }) => {
  const section1Data = useSelector(
    (state) => state?.template3?.template_3_sections?.section1
  );
  const section2Data = useSelector(
    (state) => state?.template3?.template_3_sections?.section2
  );

  const [data, setData] = useState(null);

  useEffect(() => {
    if (section2Data) {
      setData(section2Data);
    }
  }, [section2Data]);

  return (
    <>
      <section
        className="common-section"
        style={{ backgroundColor: data?.BackgroundColor }}
      >
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <Slide direction="left">
                <div className="section-content pe-lg-5">
                  <p
                    style={{
                      fontSize: "22px",
                      color: "#999999",
                      textTransform: "uppercase",
                    }}
                  >
                    {data?.TabContent}
                  </p>
                  <h3
                    style={{
                      fontSize: "32px",
                      fontWeight: "700",
                      color: section1Data?.TextColor2,
                      textTransform: "uppercase",
                      borderBottom: "1px solid #D2D2D2",
                      paddingBottom: "30px",
                      marginBottom: "20px",
                    }}
                  >
                    {data?.TabHeading}
                  </h3>
                  <h2
                    style={{
                      fontSize: "50px",
                      fontFamily: "Sofia Pro",
                      fontWeight: "400",
                      marginBottom: "25px",
                      color: `#333333`,
                      lineHeight: "65px",
                    }}
                  >
                    {data?.Heading}
                  </h2>
                  <TruncateMessage
                    message={data?.Content}
                    length={300}
                    pStyle={{
                      fontSize: "22px",
                      fontWeight: "400",
                      marginBottom: "30px",
                      color: `${section1Data?.TextColor}`,
                      lineHeight: "34px",
                    }}
                    readBntStyle={{ color: `${section1Data?.TextColor}` }}
                  />
                  {data?.Button?.Name && (
                    <Button
                      style={{
                        backgroundColor: `transparent`,
                        borderColor: section1Data?.ThemeColor,
                        color: section1Data?.ThemeColor,
                      }}
                      variant="primary"
                      size="lg"
                    >
                      {data?.Button?.Name}
                    </Button>
                  )}
                  {children}
                </div>
              </Slide>
            </Col>
            <Col lg={6} className="mt-4 mt-md-5 mt-lg-0 text-lg-end">
              <img
                style={{
                  visibility:
                    data?.Section2ImageVisible !== undefined
                      ? data?.Section2ImageVisible
                        ? " visible"
                        : " hidden"
                      : " visible",
                }}
                src={data?.Image}
                alt="About 1"
              />
            </Col>
          </Row>
        </Container>
      </section>
      <EditModal data={data} setData={setData} />
    </>
  );
};

export default Section2;

import { ADD_SUPPORT_FAILED, ADD_SUPPORT_PENDING, ADD_SUPPORT_SUCCESS, GET_SINGLE_SUPPORT_FAILED, GET_SINGLE_SUPPORT_PENDING, GET_SINGLE_SUPPORT_SUCCESS, GET_SUPPORT_FAILED, GET_SUPPORT_PENDING, GET_SUPPORT_SUCCESS, UPDATE_SUPPORT_PENDING, UPDATE_SUPPORT_SUCCESS } from "./support.constant";

const initialState = {
	supportPageList: { count: 0, supportPages: [] },
	loading: false,
	singleSupportPage: { question: "", answer: "", images: [], videos: [], documents: [] },
};

const supportReducer = (
	state = initialState,
	action
) => {
	switch (action.type) {
		case GET_SUPPORT_PENDING:
			return {
				...state,
				loading: true,
			};

		case GET_SUPPORT_SUCCESS:
			return {
				...state,
				supportPageList: { count: action?.payload?.totalRecords, supportPages: action.payload?.entities },
				loading: false,
			};

		case GET_SUPPORT_FAILED:
			return {
				...state,
				loading: false,
			};
		case GET_SINGLE_SUPPORT_SUCCESS:
			return {
				...state,
				loading: false,
				singleSupportPage: action.payload,
			};
		case GET_SINGLE_SUPPORT_PENDING:
			return {
				...state,
				loading: true,
				singleSupportPage: { question: "", answer: "", images: [], videos: [], documents: [] },
			};
		case GET_SINGLE_SUPPORT_FAILED:
			return {
				...state,
				loading: false,
			};

		case ADD_SUPPORT_FAILED:
			return { ...state, loading: false }

		case ADD_SUPPORT_PENDING:
			return { ...state, loading: true }

		case ADD_SUPPORT_SUCCESS:
			return { ...state, loading: false, }
		case UPDATE_SUPPORT_PENDING:
			return {
				...state,
				loading: true,
			};
		case UPDATE_SUPPORT_SUCCESS:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
};

export default supportReducer;

import { createSlice } from "@reduxjs/toolkit";
import { getAllEventsListData } from "./system-events-async-thunk";

const initialState = {
  loading: {
    fetch_events_list: false,
  },
  events_list: {
    list: [],
    totalRecords: 0
  },
  errors: {
    fetch_events_list: null,
    // delete_group: null
  }
}

const systemEventsSlice = createSlice({
  name: 'systemEvents',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder

      // get group lists
      .addCase(getAllEventsListData.pending, (state, action) => {
        state.loading.fetch_events_list = true;
        state.errors.fetch_events_list = null;
      })
      .addCase(getAllEventsListData.fulfilled, (state, action) => {
        state.loading.fetch_events_list = false;
        state.errors.fetch_events_list = null;
        state.events_list.list = action?.payload?.entities;
        state.events_list.totalRecords = action?.payload?.totalRecords;
      })
      .addCase(getAllEventsListData.rejected, (state, action) => {
        state.loading.fetch_events_list = false;
        state.errors.fetch_events_list = action.error.message;
        state.events_list.list = [];
        state.events_list.totalRecords = 0;
      })

    //   //delete system group
    //   .addCase(deleteSystemGroupActionThunk.pending, (state, action) => {
    //     state.loading.delete_group = { groupId: action?.meta?.arg, status: true }
    //     state.errors.delete_group = null
    //   })
    //   .addCase(deleteSystemGroupActionThunk.fulfilled, (state, action) => {
    //     state.loading.delete_group = { groupId: "", status: false }
    //     state.errors.delete_group = null
    //   })
    //   .addCase(deleteSystemGroupActionThunk.rejected, (state, action) => {
    //     state.loading.delete_group = { groupId: "", status: false }
    //     state.errors.delete_group = action?.error?.message;
    //   })
  }
});


export const actions = systemEventsSlice.actions;

const systemEventsRducer = systemEventsSlice.reducer;
export default systemEventsRducer;
import { createAsyncThunk } from "@reduxjs/toolkit";
import CommunityService from "../../../Services/community/CommunityService";


export const getAllUserCommunityAsyncThunk = createAsyncThunk("systemCommunity/getAllUserCommunity", async ({ searchText, pageIndex, pageSize }) => {
    const response = await CommunityService.getAllUserCommunity(searchText, pageIndex, pageSize)
    return response?.data
})

export const updateSystemCommunityPublishStatusActionThunk = createAsyncThunk("systemCommunity/updateSystemCommunityPublishStatus", async ({ communityId, publishStatus }) => {
    const response = await CommunityService.updateSitePublishStatus(communityId, publishStatus);
    if (response?.data?.statusCode !== 200) {
        throw new Error(response?.data?.message);
    } else {
        return response?.data?.data;
    }
})

export const deleteSystemCommunityActionThunk = createAsyncThunk("systemCommunity/deleteSyustemCommunity", async (communityId) => {
    const response = await CommunityService.DeleteCommuinty(communityId);
    if (response?.data?.statusCode !== 200) {
        throw new Error(response?.data?.message);
    } else {
        return response?.data?.data;
    }
})
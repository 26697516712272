import { Button, Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import EditModal from "./modal/EditModal";
import WebFont from "webfontloader";

import { logoutHandler } from "../../../../utils/helpers/logoutHandler";
import { CreateDynamicPWAManifest } from "../../../../utils/helpers/update-manifest";
import InstallAppButton from "../../common/install-app-button/InstallAppButton";
import { Fade } from "react-awesome-reveal";

const Section1 = ({ children }) => {
  const navigate = useNavigate();

  const token = useSelector((state) => state.auth?.token);
  const section1Data = useSelector(
    (state) => state?.template3?.template_3_sections?.section1
  );
  const is_preview = useSelector(
    (state) => state?.template3?.is_preview?.status
  );

  const [bannerStyle, setBannerStyle] = useState({
    backgroundImage: "",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundColor: "",
  });

  const [data, setData] = useState(null);

  useEffect(() => {
    if (section1Data) {
      setData(section1Data);
      if (section1Data?.BrandFont) {
        WebFont.load({
          google: {
            families: [section1Data?.BrandFont],
          },
        });
      }
    }
  }, [section1Data]);

  useEffect(() => {
    setBannerStyle((prev) => ({
      ...prev,
      backgroundImage:
        data?.Section1BackgroundVisible !== undefined
          ? data.Section1BackgroundVisible
            ? `url('${data?.BackgroundImage}')`
            : null
          : `url('${data?.BackgroundImage}')`,

      // `url('${data?.BackgroundImage}')`,
    }));
  }, [data?.BackgroundImage, data?.Section1BackgroundVisible, is_preview]);

  useEffect(() => {
    setBannerStyle((prev) => ({
      ...prev,
      backgroundColor: data?.Section1BackgroundColor,
      // data?.Section1BackgroundVisible !== undefined
      //   ? data.Section1BackgroundVisible
      //     ? null
      //     : data?.Section1BackgroundColor
      //   : data?.Section1BackgroundColor,

      // `url('${data?.BackgroundImage}')`,
    }));
  }, [
    data?.Section1BackgroundColor,
    data?.Section1BackgroundVisible,
    is_preview,
  ]);

  useEffect(() => {
    CreateDynamicPWAManifest(
      section1Data?.BrandName,
      section1Data?.Logo,
      "#ffffff",
      "#3F444D"
    );
    return () => {
      const existingManifest = document.querySelector("link[rel='manifest']");
      if (existingManifest) {
        existingManifest.href = process.env.PUBLIC_URL + "/manifest.json";
      } else {
        const link = document.createElement("link");
        link.rel = "manifest";
        link.href = process.env.PUBLIC_URL + "/manifest.json";
        document.head.appendChild(link);
      }
    };
  }, [section1Data?.BrandName, section1Data?.Logo]);

  return (
    <>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand
            href="/"
            className="d-flex align-items-center text-white logo-text"
            style={{ fontFamily: `${data?.BrandFont}` }}
          >
            <div
              style={{ height: "100px", display: "flex", alignItems: "center" }}
              className="me-3"
            >
              {(typeof data?.IsLogoVisible === "undefined" ||
                data?.IsLogoVisible) && (
                <img src={data?.Logo} height="100" alt="logo" />
              )}
            </div>

            {/* <img src={data?.Logo} height="100" alt="logo" /> */}
            <span style={{ color: data?.TextColor1 }}>{data?.BrandName}</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              {data?.NavItems?.map((btn, i) => (
                <Nav.Link
                  href={btn?.Link}
                  target="__blank"
                  style={{ color: `${data?.TextColor1}` }}
                  key={i}
                >
                  {btn?.Text}
                </Nav.Link>
              ))}
              {data?.ShowShop && (
                <Nav.Link
                  href={data?.StoreURL}
                  target="__blank"
                  style={{ color: data?.TextColor1 }}
                >
                  Shop
                </Nav.Link>
              )}
              <InstallAppButton
                themeColor={section1Data?.ThemeColor}
                key={section1Data?.BrandName}
              />
              <Button
                variant="link"
                className="ms-3"
                style={{
                  color: data?.ThemeColor,
                  borderColor: data?.ThemeColor,
                }}
                onClick={() => {
                  if (token) {
                    logoutHandler();
                  } else {
                    navigate("/login");
                  }
                }}
              >
                {token ? "Logout" : "Join / Login"}
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <section
        className="banner-section"
        id="template-3-banner"
        style={bannerStyle}
      >
        <div
          className="banner-section"
          id="template-first-section-shadow"
          style={{
            backgroundColor: `rgba(33, 43, 50, ${data?.BackgroundOpacity})`,
            width: "-webkit-fill-available", // Adjust as needed
          }}
        >
          <Container>
            <Row className="align-items-center">
              <Col md={12} lg={9} xl={7}>
                <Fade direction="left">
                  <div className="banner-content position-relative text-start">
                    <h1
                      style={{
                        fontFamily: '"Sofia Pro", sans-serif !important,',
                        fontSize: "60px",
                        fontWeight: 500,
                        lineHeight: "74px",
                        marginBottom: "25px",
                        paddingTop: "50px",
                        color: data?.TextColor1,
                      }}
                    >
                      {data?.HeadingLine1} <br />
                      {data?.HeadingLine2} <br />
                      <span
                        style={{
                          fontFamily: '"Sofia Pro", sans-serif !important,',
                          fontWeight: 700,
                          color: data?.ThemeColor,
                        }}
                      >
                        {data?.HeadingLine3}
                      </span>
                    </h1>
                    <p
                      style={{
                        fontFamily: '"Sofia Pro", sans-serif !important',
                        fontSize: "22px",
                        fontWeight: 300,
                        marginBottom: "30px",
                        lineHeight: "40px",
                        color: data?.TextColor1,
                        width: "90%",
                      }}
                      dangerouslySetInnerHTML={{ __html: data?.Content }}
                    />
                    {data?.Button?.Name && (
                      <Button
                        style={{
                          backgroundColor: data?.ThemeColor,
                          borderColor: data?.ThemeColor,
                          color: data?.TextColor1,
                        }}
                        variant="primary"
                        size="lg"
                        href={data?.Button?.Link}
                      >
                        {data?.Button?.Name}
                      </Button>
                    )}
                    {children}
                  </div>
                </Fade>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <EditModal data={data} setData={setData} />
    </>
  );
};

export default Section1;

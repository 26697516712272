import { createSlice } from "@reduxjs/toolkit";
import { getAllProfileUserGroupByCommunityIDActionThunk } from "./profile-user-groups-async-thunk";

const initialState = {
    loading: { get_groups: false },
    group_list: [],
    errors: {
        get_groups: null,
    }
}

const profileUserGroupSlice = createSlice({
    name: 'profileUserGroups',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllProfileUserGroupByCommunityIDActionThunk.pending, (state, action) => {
                state.loading.get_groups = true;
                state.errors.get_groups = null;
            })
            .addCase(getAllProfileUserGroupByCommunityIDActionThunk.fulfilled, (state, action) => {
                state.loading.get_groups = false;
                state.errors.get_groups = null;
                state.group_list = action?.payload
            })
            .addCase(getAllProfileUserGroupByCommunityIDActionThunk.rejected, (state, action) => {
                state.loading.get_groups = false;
                state.errors.get_groups = action.error.message;
                state.group_list = [];
            })
    }
});


export const actions = profileUserGroupSlice.actions;

const profileUserGroupReducer = profileUserGroupSlice.reducer;
export default profileUserGroupReducer;
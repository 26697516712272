import { createSlice } from "@reduxjs/toolkit";
import { getCurrentUserCommunityByActionThunk, updateSitePublishStatusActionThunk } from "./current-user-community-async-thunk";

const initialState = {
    loading: {
        get_community: false,
        update_publish_status: { communityId: "", status: false }
    },
    communitiy_list: [],
    errors: {
        get_community: null,
        update_publish_status: null,
    }
}

const currentUserCommunitySlice = createSlice({
    name: 'currentUserCommunity',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getCurrentUserCommunityByActionThunk.pending, (state, action) => {
                state.loading.get_community = true;
                state.errors.get_community = null;
            })
            .addCase(getCurrentUserCommunityByActionThunk.fulfilled, (state, action) => {
                state.loading.get_community = false;
                state.errors.get_community = null;
                state.communitiy_list = action?.payload
            })
            .addCase(getCurrentUserCommunityByActionThunk.rejected, (state, action) => {
                state.loading.get_community = false;
                state.errors.get_community = action.error.message;
                state.communitiy_list = []
            })

            .addCase(updateSitePublishStatusActionThunk.pending, (state, action) => {
                state.loading.update_publish_status = { communityId: action?.meta?.arg?.communityId, status: true }
                state.errors.update_publish_status = null
            })
            .addCase(updateSitePublishStatusActionThunk.fulfilled, (state, action) => {
                state.loading.update_publish_status = { communityId: "", status: false }
                state.errors.update_publish_status = null
                const index = state?.communitiy_list?.findIndex(community => community?.communityId === action?.payload?.communityId)
                if (index > -1) {
                    state.communitiy_list[index].status = action?.meta?.arg?.publishStatus;
                }
            })
            .addCase(updateSitePublishStatusActionThunk.rejected, (state, action) => {
                state.loading.update_publish_status = { communityId: "", status: false }
                state.errors.update_publish_status = action?.error?.message;
            })
    }
});


export const actions = currentUserCommunitySlice.actions;

const currentUserCommunityReducer = currentUserCommunitySlice.reducer;
export default currentUserCommunityReducer;
export const renderContentWithLinks = (text) => {

  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const phoneRegex = /^\+?\d{10}$|^\(\d{3}\)\s?-?\d{6}$/;
  let newText = text?.replace(urlRegex, function (url) {
    return '<a target="__blank" href="' + url + '">' + url + '</a>';
  })

  newText = newText?.replace(phoneRegex, function (phone){
    return `<a target="__blank" href="tel:${phone}">${phone}</a>`
  })
  return newText
};
import { useCallback, useEffect, useRef, useState } from 'react';
import { Form, Card, Button, Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as SendIcon } from "../../assets/Images/send-icon.svg";
import { ReactComponent as ChevronIcon } from "../../assets/Images/chevron-icon.svg";
import { useSelector } from 'react-redux';
import MessageBoardServices from '../../Services/messageBoard/MessageBoardServices';
import InputEmoji from "react-input-emoji";
import { errorToast } from '../../Component/toast/toast';
import UserServices from '../../Services/user/UserServices';
import { SpinnerLoader } from '../../Component/loader/SpinnerLoader';
import InfiniteScrollComp from '../../Component/infinite-scroll/InfiniteScroll';
import MessageList from '../../Component/message/MessageList';
import { ReactComponent as CloseIcon } from "../../assets/Images/close-icon.svg";
import { connection, joinOneToOneSignalrMethod, leaveOneToOneSignalrMethod, sendOneToOneMessage, startConnection } from '../../Services/signalR/signalrService';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

const ChatPageModal = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const showChat = location?.state?.showChat;
    const receiverId = location?.state?.receiverId;

    const messageEndRef = useRef();

    const profileId = useSelector((state) => state?.profileData?.profileData?.profileId)

    const [newMessage, setNewMessage] = useState('');
    const [isMessageEndInView, setIsMessageEndInView] = useState(false);

    const [unreadMessageIds, setUnreadMessageIds] = useState([]);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState({ receiverData: false, conversation: false });
    const [receiverData, setReceiverData] = useState({});
    const [pagination, setPagination] = useState({ currentPage: 1, totalRecords: 0, pageSize: 10, totalPages: 0, hasNextPage: false });

    const handleClose = () => {
        navigate(location?.pathname, { state: { ...location?.state, showChat: false, receiverId: "" }, replace: true });
    }

    /**
     * get receiver data
     * @param {*} userID 
     */
    const getUserProfileDataById = (userID) => {
        setLoading(prev => ({ ...prev, receiverData: true }));
        UserServices.GetUserProfileDataById(userID)
            .then(res => {
                setReceiverData(res?.data);
                setLoading(prev => ({ ...prev, receiverData: false }));
            })
            .catch(err => {
                errorToast("something went wrong at getUserProfileDataById", err?.message)
                setLoading(prev => ({ ...prev, receiverData: false }));
            })
    }

    /**
    * get initial group discussion
    */
    const getConversation = useCallback(() => {
        setLoading(prev => ({ ...prev, conversation: true }));
        setPagination(prev => ({ ...prev, currentPage: 1 }))
        MessageBoardServices.getConversation(profileId, receiverId, 1, pagination?.pageSize)
            .then(res => {
                Array.isArray(res?.data?.entities) && setMessages(() => updateIsReadStatusHandler(res?.data?.entities, unreadMessageIds));
                setPagination(prev => ({ ...prev, totalRecords: res?.data?.totalRecords, totalPages: res?.data?.totalPages, hasNextPage: res?.data?.hasNextPage }))
                setLoading(prev => ({ ...prev, conversation: false }));
            })
            .catch(err => {
                errorToast("Something went wrong.")
                setMessages([])
                setLoading(prev => ({ ...prev, conversation: false }));
            })
    }, [pagination?.pageSize]);

    const getConversationMore = useCallback(() => {
        MessageBoardServices.getConversation(profileId, receiverId, pagination?.currentPage, pagination?.pageSize)
            .then(res => {
                setMessages(prevList => [...prevList, ...updateIsReadStatusHandler(res?.data?.entities, unreadMessageIds)])
                setPagination(prev => ({ ...prev, totalRecords: res?.data?.totalRecords, totalPages: res?.data?.totalPages, hasNextPage: res?.data?.hasNextPage }))
            })
            .catch(err => {
                errorToast("Something went wrong")
                setLoading(prev => ({ ...prev, conversation: false }));
            })
    }, [pagination?.currentPage, pagination?.pageSize]);

    //message read status update handler
    const updateIsReadStatusHandler = (messageLists, unreadMsjIds) => {
        const updatedList = messageLists?.map(msj => {
            const tempMsj = msj;
            tempMsj.isRead = unreadMsjIds?.some(unReadId => unReadId === msj?.id) ? false : true
            return tempMsj;
        })
        return updatedList
    }

    /**
     * send message
     */
    const sendMessage = () => {
        if (newMessage.trim() !== '') {
            sendOneToOneMessage(profileId, receiverId, newMessage, "1")
                .then(res => {
                    if (res) {
                        setMessages(prev => [res, ...prev])
                        setNewMessage('');
                        messageEndRef?.current?.scrollIntoView();
                    }
                })
                .catch(err => {
                    // errorToast("Something went wrong at message send " + err)
                })
        }
    };

    useEffect(() => {

        // Start SignalR connection when the component mounts
        startConnection().then(res => {
            if (res) {
                //join one to one message board
                joinOneToOneSignalrMethod(profileId, receiverId);
                //  get received one to one messages
                connection.on("newMessage", (message) => {
                    setMessages((prevMessages) => [message, ...prevMessages]);
                    messageEndRef?.current?.scrollIntoView();
                });

                connection.invoke("GetReadUnReadMessageIds", profileId, receiverId, false).then(ids => {
                    const tempIds = ids?.map(msj => msj?.id)
                    setUnreadMessageIds(tempIds)
                })
                connection.on("unReadMessageIds", (ids) => {
                    const tempIds = ids?.map(msj => msj?.id);
                    setUnreadMessageIds(tempIds);
                });
            }
        });



        return (() => {
            leaveOneToOneSignalrMethod(profileId, receiverId);
            connection.off("newMessage");
            connection.off("unReadMessageIds");
        })
    }, []);

    useEffect(() => {
        setMessages(() => updateIsReadStatusHandler(messages, unreadMessageIds));
    }, [unreadMessageIds])

    useEffect(() => {
        if (pagination?.currentPage > 1) {
            getConversationMore();
        }
    }, [getConversationMore, pagination?.currentPage])

    useEffect(() => {
        if (receiverId) {
            getConversation();
            getUserProfileDataById(receiverId)
        }
    }, [receiverId]);


    return (
        <>
            <Modal
                show={showChat}
                onHide={handleClose}
                className="custom-card common-card"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                {loading?.receiverData ? (
                    <SpinnerLoader />
                ) : (
                    <>
                        <Modal.Header className="border-0 py-2 py-md-3 border-bottom">
                            <div className="media-posts-card-header w-100">
                                <div className="chat-posts-card-profile"
                                    onClick={() => navigate("/profile/about/" + receiverData?.profileId)}
                                >
                                    <div className="media-posts-card-profile-pic">
                                        <img
                                            className='rounded-circle cursor-pointer'
                                            src={receiverData?.profilePicture || require("../../assets/Images/guest-user.jpg")}
                                            height={38}
                                            width={38}
                                            alt="Avatar"
                                        />
                                    </div>
                                    <div className="media-posts-card-profile-name ms-2">
                                        <p className="text-dark">{receiverData?.firstName + " " + receiverData?.lastName}</p>
                                        <span>{receiverData?.email}</span>
                                    </div>
                                </div>
                                <div>
                                    <Button variant="link" onClick={handleClose} className='px-0 px-md-2'>
                                        <CloseIcon fill="#212121" />
                                    </Button>
                                </div>
                            </div>
                        </Modal.Header>
                        <Modal.Body className="p-0">
                            <Card.Body className='p-3'>
                                <div className='position-relative'>
                                    <div
                                        style={{ height: "300px", overflow: "auto", display: "flex", flexDirection: "column-reverse" }}
                                        id='scrollableDive2'
                                        onScroll={(e) => {
                                            if (e.target?.scrollTop > -150) {
                                                setIsMessageEndInView(false)
                                            } else {
                                                setIsMessageEndInView(true)
                                            }
                                        }}
                                    >
                                        <div ref={messageEndRef} />
                                        <InfiniteScrollComp
                                            dataLength={messages?.length}
                                            refreshFunction={getConversation}
                                            pagination={pagination}
                                            setPagination={setPagination}
                                            endMessage=""
                                            dataList={messages}
                                            ItemsComponent={MessageList}
                                            inverse={true}
                                            scrollableTarget="scrollableDive2"
                                            extraData={receiverData}
                                            innerStyle={{ display: "flex", flexDirection: "column-reverse" }}
                                            loader={<div className='text-center text-muted small'>Loading history...</div>}
                                        />
                                    </div>
                                    <div className='scroll-to-down-icon'>
                                        {isMessageEndInView && (
                                            <Button
                                                variant="link"
                                                className="btn-primary-theme btn-outline"
                                                onClick={() => messageEndRef.current.scrollIntoView({ behavior: 'smooth' })}
                                            >
                                                <ChevronIcon fill="#047ED6" />
                                            </Button>
                                        )}
                                    </div>
                                </div>
                                <div className='border-top pt-3'>
                                    <Form className="d-flex align-items-center gap-2">
                                        <Form.Group className='w-100 chat-message' controlId="newMessage">
                                            <InputEmoji
                                                value={newMessage}
                                                onChange={(text) => setNewMessage(text)}
                                                cleanOnEnter
                                                onEnter={sendMessage}
                                                placeholder="Type a message"
                                            />
                                        </Form.Group>
                                        <Button onClick={sendMessage} className="btn btn-primary-theme send-btn">
                                            <SendOutlinedIcon />
                                        </Button>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Modal.Body>
                    </>
                )}
            </Modal>

        </>
    );
};

export default ChatPageModal;
import { errorToast } from "../../Component/toast/toast";
import GroupService from "../../Services/group/GroupService";
import { getSidebarListInitialFailActionCreator, getSidebarListMoreSuccessActionCreator, getSidebarListPaginationActionCreator, getSidebarListInitialPendingActionCreator, getSidebarListInitialSuccessActionCreator } from "./groupMessage.actions";

/**
 * get initial sidebar list action thunk 
 * @param {*} groupId 
 * @param {*} search 
 * @param {*} pageIndex 
 * @param {*} itemsPerPage 
 * @returns 
 */
export const getSidebarListInitialActionThunk = (groupId, search, pageIndex, itemsPerPage) => {
    return (dispatch) => {
        dispatch(getSidebarListInitialPendingActionCreator());
        GroupService.getGroupMembersListByChatTiming(groupId, search, pageIndex, itemsPerPage)
            .then(res => {
                dispatch(getSidebarListInitialSuccessActionCreator(res.data?.entities || []));
                dispatch(getSidebarListPaginationActionCreator({ totalRecords: res?.data?.totalRecords || 0, totalPages: res?.data?.totalPages || 0, hasNextPage: res?.data?.hasNextPage || false }));
            })
            .catch(err => {
                errorToast("Something went wrong.");
                dispatch(getSidebarListInitialFailActionCreator());
            })
    };
};

/**
 * get more sidebar list action thunk 
 * @param {*} groupId 
 * @param {*} search 
 * @param {*} pageIndex 
 * @param {*} itemsPerPage 
 * @returns 
 */
export const getSidebarListMoreActionThunk = (groupId, search, pageIndex, itemsPerPage) => {
    return (dispatch) => {
        GroupService.getGroupMembersListByChatTiming(groupId, search, pageIndex, itemsPerPage)
            .then(res => {
                dispatch(getSidebarListMoreSuccessActionCreator(res.data?.entities || []));
                dispatch(getSidebarListPaginationActionCreator({ totalRecords: res?.data?.totalRecords, totalPages: res?.data?.totalPages, hasNextPage: res?.data?.hasNextPage }));
            })
            .catch(err => {
                errorToast("Something went wrong.");
            })
    };
};
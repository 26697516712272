import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SideBar from "../SideBar/SideBar";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import NotificationToast from "../../pages/notification/NotificationToast";
import ChatPageModal from "../../pages/message/ChatPageModal";
import EventDetailModal from "../event/event-detail-modal/EventDetailModal";
import NewsFeedPostModal from "../../pages/admin-group/group-view/newsfeed/newsfeed-modal/NewsFeedPostModal";
import logo from "../../assets/Images/Combo-logo.png";
import MediaView from "../media-view/MediaView";
import SwitchRoleModal from "../switch-role/SwitchRoleModal";
import { warningToast } from "../toast/toast";

export const AdminHeader = ({ children }) => {

  const navigate = useNavigate();
  const location = useLocation();

  const roleID = useSelector((state) => state?.profileData?.profileData?.roleID);
  const profileData = useSelector((state) => state?.profileData?.profileData);
  const communityId = useSelector(state => state?.dashboardCommunity?.communityId);
  const communityList = useSelector(state => state?.currentUserCommunity?.communitiy_list);
  const showMediaView = useSelector(state => state?.mediaView?.status);

  const [toggleNav, setToggleNav] = useState(false);

  const handleToggle = () => {
    setToggleNav(!toggleNav);
  };

  useEffect(() => {
    if (communityId != 2 && communityId != 1) {
      const isMember = communityList?.filter(cmnt => cmnt?.communityId === communityId)?.length > 0;
      if (!isMember) {
        warningToast("You left from selected community, Please select another role")
        navigate(location?.pathname, { state: { ...location?.state, showSwitchroleModal: true }, replace: true })
      }
    }
  }, [communityId, communityList]);

  return (
    <>
      <div className="wrapper">
        <Container>
          <Row className="mid-small-row">
            <Col lg={3}>
              <SideBar toggleNav={toggleNav} setToggleNav={setToggleNav} />
              <nav
                className="navbar navbar-expand-md custom-navbar fixed-top d-flex d-lg-none border-bottom"
                id="top-admin-header"
              >
                <Container>
                  <button className="button-toogle" onClick={handleToggle}>
                    <i className="fa fa-bars" aria-hidden="true"></i>
                  </button>
                  <Link to={""} className="navbar-brand ms-2" href="/#">
                    <img src={logo} height={22} alt="logo" />
                  </Link>
                  <ul className="navbar-nav adm-header-nav ms-auto">
                    <li className="nav-item m-0">
                      {roleID !== 1 && (
                        <Button
                          variant="primary"
                          className="nav-upgrade-plan-btn me-0"
                          size="sm"
                          onClick={() => navigate("/upgrade-plan")}
                        >
                          {profileData?.planDetails ? "Change Plan" : "Purchase Plan"}
                        </Button>
                      )}
                    </li>
                  </ul>
                </Container>
              </nav>
            </Col>
            <Col lg={9}>
              {children}
            </Col>
          </Row>
          <NotificationToast />
          {location?.state?.showChat && <ChatPageModal />}
          {location?.state?.showEvent && (<EventDetailModal />)}
          {location?.state?.showNewsfeed && (<NewsFeedPostModal />)}
          {showMediaView && (<MediaView />)}
          {location?.state?.showSwitchroleModal && (<SwitchRoleModal />)}
        </Container>
      </div>
    </>
  );
};

export default AdminHeader;
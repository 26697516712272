import { selectFileInSharedMediaEntriesAction } from "../../../../../ReduxStore/slice/group-shared-media-entries/group-shared-media-entries-slice";
import { setFilePreviewInSharedMediaAction } from "../../../../../ReduxStore/slice/group-shared-media/group-shared-media-slice";
import store from "../../../../../ReduxStore/store";


export const openPreviewOfFileHandler = (file) => {
    store?.dispatch(setFilePreviewInSharedMediaAction({ status: true, file: file }));
}

export const onClickOnFileHandler = (file) => {
    store?.dispatch(selectFileInSharedMediaEntriesAction(file))
}
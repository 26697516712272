
export const GET_SIDEBAR_LIST_INITIAL_SUCCESS = "GET_SIDEBAR_LIST_INITIAL_SUCCESS";
export const GET_SIDEBAR_LIST_INITIAL_PENDING = "GET_SIDEBAR_LIST_INITIAL_PENDING";
export const GET_SIDEBAR_LIST_INITIAL_FAIL = "GET_SIDEBAR_LIST_INITIAL_FAIL";

export const GET_SIDEBAR_LIST_MORE_SUCCESS = "GET_SIDEBAR_LIST_MORE_SUCCESS";
export const GET_SIDEBAR_LIST_MORE_PENDING = "GET_SIDEBAR_LIST_MORE_PENDING";
export const GET_SIDEBAR_LIST_MORE_FAIL = "GET_SIDEBAR_LIST_MORE_FAIL";

export const GET_SIDEBAR_LIST_PAGINATION = "GET_SIDEBAR_LIST_PAGINATION";

export const SET_CURRENT_CHATBOARD = "SET_CURRENT_CHATBOARD";
import { createContext, memo, useEffect, useState } from 'react'
import GroupService from '../../../../Services/group/GroupService';
import { errorToast, successToast } from '../../../toast/toast';
import { useLocation, useNavigate } from 'react-router';
import { confirmAlert } from 'react-confirm-alert';

export const NewsfeedModalContext = createContext();

const NewsfeedModalProvider = ({ children }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const postId = location?.state?.newsfeedId;
    const commentId = location?.state?.commentID

    const [loading, setLoading] = useState({
        newsfeedData: false, //get newsfeed data
        comments: false, //get initial comment list
        createCommentBtn: false, // create parent comment
        likeComment: { status: false, commentID: "" }, // like parent comment
        createCommentReplyBtn: { status: false, commentID: "" } // create comment reply
    });

    const [newsfeedData, setNewsfeedData] = useState({});
    const [comments, setComments] = useState([]);

    const [showEditCommentModal, setShowEditCommentModal] = useState({ status: false, commentData: null, isChild: false });
    const [showCommentReplyForm, setShowCommentReplyForm] = useState({ status: false, commentID: "" });

    //comment reply form handler
    const showCommentReplyFormHandler = (commentID) => {
        setShowCommentReplyForm({ status: true, commentID });
    }

    //comment reply form handler
    const closeCommentReplyFormHandler = (commentID) => {
        setShowCommentReplyForm({ status: false, commentID: "" });
    }

    /**
     * delete newsfeed
     */
    const deleteNewsfeedHandlerFun = () => {
        setLoading(prev => ({ ...prev, newsfeedData: true }));
        GroupService.DeleteGroupPostDetails(postId)
            .then(res => {
                successToast("Newsfeed deleted successfully.");
                navigate(location?.pathname, { state: { ...location?.state, showNewsfeed: false, newsfeedId: "" } })
                setLoading(prev => ({ ...prev, newsfeedData: false }));
            })
            .catch(err => {
                errorToast("Something went wrong.");
                setLoading(prev => ({ ...prev, newsfeedData: false }));
            })
    }

    /**
     * like newsfeed
     * @param {*} postID 
     */
    const likeNewsfeedHandlerFun = (postID) => {
        setLoading(prev => ({ ...prev, likeNewsfeed: true }));
        GroupService.AddPostLike(postID)
            .then((response) => {
                const tempData = newsfeedData;
                tempData.data.likeCount = response?.data?.data?.likeCount;
                tempData.data.isLikeOrUnlike = response?.data?.data?.isLikeOrUnlike;
                setNewsfeedData(tempData);
                setLoading(prev => ({ ...prev, likeNewsfeed: false }));
            })
            .catch((e) => {
                errorToast("Something went wrong");
                setLoading(prev => ({ ...prev, likeNewsfeed: false }));
            });
    }

    /**
     * get newsfeed by newsfeed id
     */
    const getPostDetailsByPostIDApiCall = () => {
        setLoading(prev => ({ ...prev, newsfeedData: true }));
        GroupService.getPostDetailsByPostID(postId)
            .then(res => {
                setNewsfeedData(res?.data);
                setLoading(prev => ({ ...prev, newsfeedData: false }));
            })
            .catch(err => {
                errorToast("Something went wrong.");
                setLoading(prev => ({ ...prev, newsfeedData: false }));
            })
    };

    //scroll into created comment and highlight the comment
    const scrollIntoCreatedComment = (commentID) => {
        setTimeout(() => {
            const element = window.document.getElementById(commentID)
            if (!element) return;

            // Function to set the background color with a transition and revert it after a delay
            const setBackgroundColorWithTransition = (color, time) => {
                element.style.transition = 'background-color 0.5s ease';
                element.style.backgroundColor = color;
                return new Promise((resolve) => {
                    setTimeout(() => {
                        element.style.backgroundColor = ''; // Restore the original background color
                        resolve();
                    }, time);
                });
            };

            // Scroll the element into view smoothly
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });

            // Add the background color transition and wait for it to complete
            setBackgroundColorWithTransition('#eaf3ff', 2000)
                .then(() => {
                    // Remove the transition after another delay
                    setTimeout(() => {
                        element.style.transition = ''; // Remove the transition
                    }, 400);
                });

        }, [3])
    }


    /**
     * create comment
     * @param {*} data 
     */
    const createCommentApiCall = (data, resetForm) => {
        data.postID = postId
        const config = {
            headers: { "content-type": "multipart/form-data" },
        };
        setLoading(prev => ({ ...prev, createCommentBtn: true }));
        GroupService.createCommunityGroupComments(data, config)
            .then((res) => {
                if (!res?.data) {
                    errorToast("API is inprogress")
                    return false
                }
                setComments([...comments, res?.data?.data]);
                setNewsfeedData(prev => ({ ...prev, commentCount: prev?.commentCount + 1 }))
                resetForm && resetForm();
                successToast("Comment added successfully");
                setLoading(prev => ({ ...prev, createCommentBtn: false }));
                scrollIntoCreatedComment(res?.data?.data?.commentID);
            })
            .catch((err) => {
                errorToast("something went wrong " + err)
                setLoading(prev => ({ ...prev, createCommentBtn: false }));
            })
    };

    //add reply of comment
    const createReplyCommentApiCall = (data, commentID, resetForm) => {

        const config = {
            headers: { "content-type": "multipart/form-data" },
        };
        setLoading(prev => ({ ...prev, createCommentReplyBtn: { status: true, commentID } }));
        GroupService.AddCommentReply(data, config)
            .then((res) => {
                const tempComments = [...comments];
                const curCmntIndex = tempComments?.findIndex(comment => comment?.commentID === commentID);
                if (curCmntIndex > -1) {
                    tempComments[curCmntIndex].commentReply.push(res?.data?.data)
                }
                setComments(tempComments);
                resetForm && resetForm();
                successToast("Comment added successfully")
                setLoading(prev => ({ ...prev, createCommentReplyBtn: { status: false, commentID: "" } }));
                closeCommentReplyFormHandler(commentID);
                scrollIntoCreatedComment(res?.data?.data?.replyID)
            })
            .catch(err => {
                errorToast("Something went wrong.")
                setLoading(prev => ({ ...prev, createCommentReplyBtn: { status: false, commentID: "" } }));
            });
    }

    /**
     * get comments of newsfeed
     */
    const getPostCommentsApiCall = () => {
        setLoading(prev => ({ ...prev, comments: true }));
        GroupService.getPostComments(
            postId,
            commentId ? commentId : undefined
        )
            .then(res => {
                Array.isArray(res?.data) && setComments(res?.data);
                setLoading(prev => ({ ...prev, comments: false }))
            })
            .catch(err => {
                errorToast("Something went wrong.");
                setLoading(prev => ({ ...prev, comments: false }))
            })
    };

    /**
     * add like on parent comment
     * @param {*} commentID 
     * @returns 
     */
    const likeCommentFunc = (commentID) => {
        const data = { commentID, postId, groupID: newsfeedData?.data?.groupID }
        setLoading(prev => ({ ...prev, likeComment: { status: true, commentID } }));
        GroupService.AddCommentLike(data)
            .then((res) => {
                const commentsTemp = [...comments];
                const commentIndex = commentsTemp?.findIndex(cmnt => cmnt?.commentID === commentID)
                if (commentIndex > -1) {
                    commentsTemp[commentIndex].likeCount = res?.data?.data?.likeCounts;
                    commentsTemp[commentIndex].isLiked = res?.data?.data?.isLiked;
                }
                setComments(commentsTemp);
                setLoading(prev => ({ ...prev, likeComment: { status: false, commentID: "" } }));
            })
            .catch((e) => {
                errorToast("Something went wrong");
                setLoading(prev => ({ ...prev, likeComment: { status: false, commentID: "" } }));
            });
    };

    /**
     * delete comment
     * @param {*} commentID 
     */
    const deleteCommentHandlerFun = (commentID) => {
        confirmAlert({
            title: "Delete Comment",
            message: "Are you sure to delete comment?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        setLoading(prev => ({ ...prev, deleteComment: { status: true, commentID } }));
                        GroupService.DeleteCommunityGroupComments(commentID)
                            .then((res) => {
                                const commentsTemp = [...comments]?.filter(cmnt => cmnt?.commentID !== commentID);
                                setComments(commentsTemp);
                                successToast("Comment deleted");
                                setNewsfeedData(prev => ({ ...prev, commentCount: prev?.commentCount - 1 }));
                                setLoading(prev => ({ ...prev, deleteComment: { status: false, commentID: "" } }));
                            })
                            .catch(err => {
                                errorToast("Something went wrong.");
                                setLoading(prev => ({ ...prev, deleteComment: { status: false, commentID: "" } }));
                            })
                    },
                },
                {
                    label: "No",
                    onClick: () => { },
                },
            ],
        });
    };

    //Edit comment modal open/close handler 
    const showEditCommentModalHandler = (status, commentData, isChild) => {
        setShowEditCommentModal({ status, commentData, isChild });
    }

    const editParentCommentApiCall = (data) => {
        setLoading(prev => ({ ...prev, editParentComment: true }));
        GroupService.updateCommunityGroupComments(data)
            .then(res => {
                const tempComments = [...comments]
                const index = tempComments?.findIndex(c => c?.commentID === res?.data?.data?.commentID)
                if (index > -1) {
                    tempComments[index] = res?.data?.data
                    setComments(tempComments);
                    successToast("Comment updated successfully")
                    setLoading(prev => ({ ...prev, editParentComment: false }));
                    showEditCommentModalHandler(false, null, false);
                } else {
                    throw new Error();
                }
            })
            .catch(err => {
                errorToast("Something went wrong");
                setLoading(prev => ({ ...prev, editParentComment: false }));
            })
    }

    const editChildCommentApiCall = (data) => {
        setLoading((prev) => ({ ...prev, editParentComment: true }));

        GroupService.updateCommentReply(data)
            .then((res) => {
                const tempComments = [...comments];
                const parentCommentIndex = tempComments.findIndex((c) => c?.commentID === res?.data?.data?.commentID);

                if (parentCommentIndex > -1) {
                    const tempReplys = [...tempComments[parentCommentIndex]?.commentReply];
                    const replyCommentIndex = tempReplys.findIndex((cc) => cc?.replyID === res?.data?.data?.replyID);

                    if (replyCommentIndex > -1) {
                        tempReplys[replyCommentIndex] = res?.data?.data;
                        tempComments[parentCommentIndex].commentReply = tempReplys;
                        setComments(tempComments);
                        successToast("Comment updated successfully");
                    } else {
                        throw new Error();
                    }
                }
            })
            .catch((err) => {
                errorToast("Something went wrong");
            })
            .finally(() => {
                setLoading((prev) => ({ ...prev, editParentComment: false }));
                showEditCommentModalHandler(false, null, false);
            });
    };


    useEffect(() => {
        getPostDetailsByPostIDApiCall();
        getPostCommentsApiCall();
    }, []);

    return (
        <NewsfeedModalContext.Provider
            value={{
                loading, //loading
                newsfeedData, //newsfeed object
                deleteNewsfeedHandlerFun, // delte newsfeed handler function
                likeNewsfeedHandlerFun, // like newsfeed handler function
                comments, // comment list
                likeCommentFunc, // like unlike of comment handler
                createReplyCommentApiCall,// create reply comment
                deleteCommentHandlerFun, //delete comment handler function
                createCommentApiCall, // create comment api call
                setShowCommentReplyForm, // set show comment reply form
                showCommentReplyForm,
                showCommentReplyFormHandler,// set show comment reply form
                closeCommentReplyFormHandler,// close comment reply form

                showEditCommentModal,//edit comment modal status
                showEditCommentModalHandler,//comment modal open handler
                editParentCommentApiCall,// edit parent comment api call
                editChildCommentApiCall,// edit child comment api call
            }}
        >
            {children}
        </NewsfeedModalContext.Provider>
    )
}

export default memo(NewsfeedModalProvider);

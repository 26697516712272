import {  getProfilerofileDataAction, profileDataLoadedAction, profileDataLoadingAction, updateProfileDataAction, updateProfileDataLoaded, updateProfileDataLoading } from "./profileData.actions";
import { NotificationManager } from "react-notifications";
import UserServices from "../../Services/user/UserServices";
import GroupService from "../../Services/group/GroupService";
import { errorToast, successToast } from "../../Component/toast/toast";

/**
 * get profileData thunk
 * @param {*} profileId 
 * @param {boolean} needLoading
 * @returns 
 */
export const getProfileDataActionThunk = (profileId, needLoading) => {
    return (dispatch) => {
        needLoading && dispatch(profileDataLoadingAction());
        UserServices.GetUserProfileDataById(profileId)
            .then(res => {
                dispatch(getProfilerofileDataAction(res.data));
                dispatch(profileDataLoadedAction());
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    NotificationManager.error(error.response.data.ErrorMessage, "Error Message");
                } else {
                    NotificationManager.error("something went wrong");
                }
                dispatch(profileDataLoadedAction());
            })
    }
}

/**
 * update profileData by id thunk
 * @param {*} values 
 * @param {*} config 
 * @returns 
 */
export const updateProfileDataActionThunk = (values, afterSubmitedForm) => {
    return (dispatch) => {
        dispatch(updateProfileDataLoading());
        GroupService.UpdateUserPofile(values)
            .then(res => {
                if (res?.data?.status === "SUCCESS" || res?.data?.statusCode === 200) {
                    dispatch(updateProfileDataAction(res.data?.data));
                    successToast("Profile Updated successfully");
                    dispatch(updateProfileDataLoaded());
                    afterSubmitedForm && afterSubmitedForm();
                } else {
                    errorToast("something went wrong")
                    dispatch(updateProfileDataLoaded());
                    afterSubmitedForm && afterSubmitedForm();
                }
            })
            .catch((error) => {
                dispatch(updateProfileDataLoaded());
                if (error.response && error.response.data) {
                    errorToast(error.response.data.ErrorMessage);
                    afterSubmitedForm && afterSubmitedForm();
                } else {
                    errorToast("something went wrong");
                    afterSubmitedForm && afterSubmitedForm();
                }
            })
    }
};
export const updateUserProfileDataActionThunk = (values, afterSubmitedForm) => {
    return (dispatch) => {
        dispatch(updateProfileDataLoading());
        GroupService.UpdateUserPofile(values)
            .then(res => {
                if (res?.data?.status === "SUCCESS" || res?.data?.statusCode === 200) {
                   // dispatch(updateProfileDataAction(res.data?.data));
                    successToast("Profile Updated successfully");
                    dispatch(updateProfileDataLoaded());
                    afterSubmitedForm && afterSubmitedForm();
                } else {
                    errorToast("something went wrong")
                    dispatch(updateProfileDataLoaded());
                    afterSubmitedForm && afterSubmitedForm();
                }
            })
            .catch((error) => {
                dispatch(updateProfileDataLoaded());
                if (error.response && error.response.data) {
                    errorToast(error.response.data.ErrorMessage);
                    afterSubmitedForm && afterSubmitedForm();
                } else {
                    errorToast("something went wrong");
                    afterSubmitedForm && afterSubmitedForm();
                }
            })
    }
}
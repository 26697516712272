import { useDispatch, useSelector } from 'react-redux';
import { selectFileInSharedMediaEntriesAction } from '../../../../../ReduxStore/slice/group-shared-media-entries/group-shared-media-entries-slice';


const FileOptionsDD = ({ fileObj }) => {

    const dispatch = useDispatch();

    const selected_files = useSelector(state => state?.groupSharedMediaEntries?.file_selection?.selected_files);

    const isSelectedFile = (file) => {
        const index = selected_files?.findIndex(fl => fl?.FileID === file?.FileID)
        if (index > -1) {
            return true
        } else {
            return false
        }
    }

    return (
        <div
            onDoubleClick={e => e?.stopPropagation()}
            onClick={e => e?.stopPropagation()}
        >
            <input
                type="checkbox"
                className='form-check-input rounded-0 pointer'
                checked={isSelectedFile(fileObj)}
                onChange={() => {
                    dispatch(selectFileInSharedMediaEntriesAction(fileObj))
                }}
            />
        </div>
    )
}

export default FileOptionsDD